import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../../config/config";

const FAQ = () => {
  return (
    <div className="max-w-screen-xl mx-auto p-8 my-8 bg-black-main shadow-lg rounded-lg border-2 border-primary">
      <h2 className="text-4xl text-inter font-bold tracking-wide uppercase flex justify-center mb-12 flex flex-row items-center justify-center">
        <FontAwesomeIcon icon={faBrain} className="mr-2 text-pink-400" /> Frequently Asked Questions
      </h2>
      <ul className="flex flex-wrap justify-center gap-8">
        {config.faq_custom_title_1 && config.faq_custom_content_1 && <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            {config.faq_custom_title_1}
          </p>
          <p className="mt-2 text-base leading-6">
            {config.faq_custom_content_1}
          </p>
        </li>}
        {config.faq_custom_title_2 && config.faq_custom_content_2 && <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            {config.faq_custom_title_2}
          </p>
          <p className="mt-2 text-base leading-6">
            {config.faq_custom_content_2}
          </p>
        </li>}
        {config.faq_custom_title_3 && config.faq_custom_content_3 && <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            {config.faq_custom_title_3}
          </p>
          <p className="mt-2 text-base leading-6">
            {config.faq_custom_content_3}
          </p>
        </li>}
        {config.faq_custom_title_4 && config.faq_custom_content_4 && <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            {config.faq_custom_title_4}
          </p>
          <p className="mt-2 text-base leading-6">
            {config.faq_custom_content_4}
          </p>
        </li>}
        {config.landing_page_package_type === 'One-Time' && <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            Do you guarantee a passing score?
          </p>
          <p className="mt-2 text-base leading-6">
            Yes! We are so confident in our AI tools and features that we guarantee a passing score on your exam. If you do not pass, we will refund your purchase. Terms and conditions apply.
          </p>
        </li>}
        <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            How can I get started with {config.siteName}?
          </p>
          {config.landing_page_package_type === 'Monthly' && <p className="mt-2 text-base leading-6">
            You can start by signing up for a free account on our website or through the app. New users get 5,000 free AI credits to try out {config.siteName}. With your free credits, you can explore and fall in love with all of our AI tools and features.
          </p>}
          {config.landing_page_package_type === 'One-Time' && <p className="mt-2 text-base leading-6">
            You can start by creating your user account and selecting your perfect package. Choose a package based on how long you expect your exam preparation to last. As soon as you complete your package purchase, you will immediately be able to access your {config.siteName} dashboard and all features of the app.
          </p>}
        </li>
        <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            What are the subscription options for {config.siteName}?
          </p>
          {config.landing_page_package_type === 'Monthly' && <p className="mt-2 text-base leading-6">
            All new users recieve 5,000 FREE AI credits when first creating an account, no strings attached. {config.siteName} offers several subscription options including Starter, Standard, and VIP plans. You can also purchase additional image credits as needed. Browse our subscription plans above for more details.
          </p>}
          {config.landing_page_package_type === 'One-Time' && <p className="mt-2 text-base leading-6">
            {config.siteName} offers several subscription options including a 3-Month, 6-Month, and 12-Month package so you can choose the perfect time frame for your preparation. Be careful not to underestimate the time you need to prepare for your exam.
          </p>}
        </li>
        {config.landing_page_package_type === 'Monthly' && <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            Do credits rollover to the next month if unused?
          </p>
          <p className="mt-2 text-base leading-6">
            Yes! Any unused credits will rollover to the next month. You don't have to worry about losing any credits you've already paid for. Rest assured that they will be in your account, waiting for you to use them.
          </p>
        </li>}
        {config.landing_page_package_type === 'Monthly' && <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            How do AI credits work?
          </p>
          <p className="mt-2 text-base leading-6">
            When you interact with the AI tools and features on our website, you use AI credits. The amount used depends entirely on the amount of data going between you and the tool. Long messages require more credits than short messages.
          </p>
        </li>}
        {config.landing_page_package_type === 'Monthly' && <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            What happens when I run out of AI credits?
          </p>
          <p className="mt-2 text-base leading-6">
            If you are on the free plan and run out of credits, you can upgrade to a paid plan and instantly get your monthly AI credits. If you are on a paid plan and run out of credits, you can upgrade to a plan with more monthly credits or wait until the next month when your credits reset.
          </p>
        </li>}
        {config.landing_page_package_type === 'One-Time' && <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            Are there any hidden fees or charges?
          </p>
          <p className="mt-2 text-base leading-6">
            No! All of our packages are one-time payments with no recurring fees or charges. You will never be charged more than the price of your package. We believe in transparent pricing and want you to know exactly what you are paying for.
          </p>
        </li>}
        <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            What kind of AI is powering {config.siteName}?
          </p>
          <p className="mt-2 text-base leading-6">
            {config.siteName} is powered by <a href="https://gaim.ai" target="_blank" rel="noreferrer" className="underline">GAIM.AI</a>, a leading AI technology provider. GAIM.AI provides cutting-edge artificial intelligence technology built on the world's AI leading platforms.
          </p>
        </li>
        {config.landing_page_package_type === 'One-Time' && <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            Are there any limitations on quizzes, tutoring, or other features?
          </p>
          <p className="mt-2 text-base leading-6">
            No! All of our paid packages come with unlimited access to all of our AI tools and features. You can use them as much as you want, whenever you want. We want you to have the best possible experience and get the most out of your subscription.
          </p>
        </li>}
        <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            What if I want a refund?
          </p>
          {config.landing_page_package_type === 'Monthly' && <p className="mt-2 text-base leading-6">
            All of our plans come with a 7-day money back guarantee. If you have purchased a paid plan and are not satisfied, you can request a refund within 7 days of purchase by simply requesting a refund at {config.email}.
          </p>}
          {config.landing_page_package_type === 'One-Time' && <p className="mt-2 text-base leading-6">
            If you have purchased a paid plan and are not satisfied, you can contact us within the first 30 days of your subscription at {config.email} to discuss refund options.
          </p>}
        </li>
        <li className="md:w-2/5 text-gray-100 bg-primary px-6 py-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
          <p className="text-lg text-inter font-bold leading-6">
            Is {config.siteName} safe and secure?
          </p>
          <p className="mt-2 text-base leading-6">
            Yes! {config.siteName} takes your privacy and security very seriously. We use the latest technology to protect your data and ensure that your interactions with our AI tools are secure and private. Additionally, all purchases are handled securely through Stripe.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default FAQ;
