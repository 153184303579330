import React from 'react';
import Bookshelf from '../components/bookshelf/Bookshelf';

function BookshelfPage() {
  return (
    <div className='min-h-screen'>
      <Bookshelf />

    </div>
  );
}

export default BookshelfPage;
