import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ searchTerm, setSearchTerm, handleSearch }) => {
  return (
    <form onSubmit={handleSearch} className="flex-1 mr-0">
      <div className="relative">
        <input
          type="text"
          placeholder="Search bookshelf..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 pl-10 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary text-gray-800"
        />
        <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-3 text-gray-400" />
      </div>
    </form>
  );
};

export default SearchBar;