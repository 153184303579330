// src/utils/soundEffects.js

const flipSound = new Audio('https://otp.nyc3.cdn.digitaloceanspaces.com/sounds/short-woosh-109592.mp3');
const masterySound = new Audio('https://otp.nyc3.cdn.digitaloceanspaces.com/sounds/game-bonus-144751.mp3');

export const playFlipSound = () => {
  flipSound.play();
};

export const playMasterySound = () => {
  masterySound.play();
};