import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsNewsletterOpen, closeNewsletterModal } from '../features/games/modalNewsletterSlice';

const ModalNewsletter = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(selectIsNewsletterOpen);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        //console.log('Submitting:', { email, firstName });
        // Add actual submission logic here
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={()=>dispatch(closeNewsletterModal())}>
            <div className="relative top-20 mx-auto p-5 border w-11/12 lg:w-2/3 bg-blue-500 text-white rounded-md shadow-lg" onClick={(e) => e.stopPropagation()}>
                <div className="flex justify-between items-start">
                    <div className='hidden w-1/6 lg:flex flex-col justify-start items-center gap-2'>
                        <img src="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AndyBaseAvatar.png" alt="Avatar" className="w-16 h-auto" />
                        <span className='text-lg text-white text-poetsen'>Awesome, dude.</span>
                    </div>
                    <div className="flex flex-col items-center gap-2 w-5/6 lg:w-4/6 px-4">
                        <h4 className="text-xl lg:text-3xl text-center text-yellow-400 font-medium text-poetsen">Sign up and score big!</h4>
                        <p className="text-sm lg:text-base font-bold">Want to get more out of Andy's Place without busting your wallet? 🚀 Join Andy's super-secret, insider's circle completely for free and we'll hook you up big time! 🎉</p>

                        <p className="text-sm">Sign up and BOOM! Instantly score a chill 15% off your first treasure trove of Andy's Place merch. From tees that scream “I love crypto” to hoodies that might just make you the coolest cat at your next meetup.</p>

                        <p className="text-sm">And because we're all about that high-flying, digital lifestyle, we're tossing in <strong>2500 FREE AI credits</strong> to supercharge your new Andy's Place account. Imagine all the epic stuff you can do with that!</p>

                        <p className="text-sm hidden lg:block">Don't miss out. Dive into the crypto wave with us and let's ride the highs (and the lows) together. Join now, and let's get this Andy party started! 🌿💨🐶</p>
                        <p className="text-sm font-bold">Your discount codes will be sent to your email, so make sure you provide your best email address.</p>

                    </div>
                    <div className='w-1/6 flex justify-end items-center gap-2'>
                        <button className="text-black text-2xl close-modal" onClick={()=>dispatch(closeNewsletterModal())}><FontAwesomeIcon icon={faXmarkCircle} /></button>
                    </div>
                </div>
                <form className="mt-5" onSubmit={handleSubmit}>
                    <label htmlFor="firstName" className="block text-sm font-medium text-yellow-400">First Name</label>
                    <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="text-black mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />

                    <label htmlFor="email" className="block text-sm font-medium text-yellow-400 mt-4">Email Address</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="text-black mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />

                    <button type="submit" className="text-poetsen mt-6 w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-xl font-medium text-blue-500 bg-yellow-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Gimme my discount codes, man.
                    </button>
                </form>
                <button className="mt-4 w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" onClick={()=>dispatch(closeNewsletterModal())}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default ModalNewsletter;
