// login.jsx
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../features/user/userSlice";
import { authUser } from "../api/userFunctions";
import { StytchLogin } from "@stytch/react";
import { setSession, deleteSession } from "../features/session/sessionSlice";
import config from "../config/config";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faShoppingCart,
  faTachometerAlt,
  faMugHot,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons";
const sitename = config.siteName;
const logo = config.favicon;
const stytch_login_url = process.env.REACT_APP_STYTCH_LOGIN_PURCHASE_URL;

const LoginPurchasePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    const stytch_session = Cookies.get("stytch_session");

    const autoAuthenticate = async () => {
      if (stytch_session) {
        const user = await authUser(stytch_session);
        if (user?.success) {
          dispatch(setUser(user?.data));
          dispatch(setSession({ stytch_session_token: stytch_session }));
          navigate("/complete-purchase");
        } else {
          dispatch(setUser(null));
          dispatch(deleteSession());
          Cookies.remove("stytch_session");
          Cookies.remove("stytch_session_jwt");
          //navigate('/login');
        }
      }
    };

    autoAuthenticate();
  }, [dispatch, navigate]);

  const handleBack = () => {
    navigate("/");
  };

  const stytch_config = {
    products: ["emailMagicLinks"],
    products: ["oauth", "emailMagicLinks"],
    oauthOptions: {
      providers: [
        {
          type: "google",
        },
      ],
      loginRedirectURL: stytch_login_url,
      signupRedirectURL: stytch_login_url,
    },
    emailMagicLinksOptions: {
      loginRedirectURL: stytch_login_url,
      loginExpirationMinutes: 30,
      signupRedirectURL: stytch_login_url,
      signupExpirationMinutes: 30,
      loginTemplateId: config.loginTemplateId,
      signupTemplateId: config.signupTemplateId,
    },
  };
  const styles = {
    container: {
      backgroundColor: config.light_background_color,
      borderColor: config.light_primaryColor,
      borderRadius: "8px",
      width: "360px",
    },
    colors: {
      primary: config.light_primaryColor,
      secondary: config.light_secondaryColor,
      success: "#0C5A56",
      error: "#8B1214",
    },
    buttons: {
      primary: {
        backgroundColor: "#FFFFFF",
        textColor: config.light_primaryColor,
        borderColor: config.light_primaryColor,
        borderRadius: "4px",
      },
      secondary: {
        backgroundColor: "#FFFFFF",
        textColor: "#19303D",
        borderColor: "#19303D",
        borderRadius: "4px",
      },
    },
    inputs: {
      backgroundColor: "#FFFFFF",
      borderColor: config.light_primaryColor,
      borderRadius: "4px",
      placeholderColor: "#282828",
      textColor: "#121212",
    },
    fontFamily: "Arial",
    hideHeaderText: false,
    logo: {
      logoImageUrl: logo,
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-700 to-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 mx-auto">
        <div className="text-center">
          <img
            src={logo}
            alt="Logo"
            className="mx-auto h-24 w-24 rounded-full border-4 border-primary"
          />
          <h2 className="mt-6 text-3xl font-extrabold text-gray-100 text-poetsen">
            {sitename}
          </h2>
          <p className="mt-2 text-sm text-gray-200">
            Create an account or log in to continue your purchase
          </p>
        </div>

        <div className="bg-white shadow-md rounded-lg p-6 space-y-6">
          <div className="space-y-4">
            <h3 className="text-xl text-blue-800 text-poetsen">
              <FontAwesomeIcon icon={faMugHot} className="mr-2" />
              Easy Purchase Flow
            </h3>
            <div className="flex items-center space-x-3">
              <div className="flex-shrink-0">
                <FontAwesomeIcon
                  icon={faUserPlus}
                  className="h-6 w-6 text-blue-400"
                />
              </div>
              <div className="flex-1">
                <p className="text-sm font-medium text-gray-900">
                  Step 1: Create Account
                </p>
                <p className="text-sm text-gray-500">
                  Sign up or log in to your account
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <div className="flex-shrink-0">
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  className="h-6 w-6 text-blue-400"
                />
              </div>
              <div className="flex-1">
                <p className="text-sm font-medium text-gray-900">
                  Step 2: Select Package
                </p>
                <p className="text-sm text-gray-500">
                  Choose your subscription package
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <div className="flex-shrink-0">
                <FontAwesomeIcon
                  icon={faTachometerAlt}
                  className="h-6 w-6 text-blue-400"
                />
              </div>
              <div className="flex-1">
                <p className="text-sm font-medium text-gray-900">
                  Step 3: Access Dashboard
                </p>
                <p className="text-sm text-gray-500">
                  Start using {sitename} immediately
                </p>
              </div>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-6 flex justify-center">
            <StytchLogin config={stytch_config} styles={styles} />
          </div>
        </div>
        <button
          onClick={() => {
            handleBack();
          }}
          className="mt-2 flex items-center w-full text-primary border border-primary rounded hover:bg-gray-700 justify-center p-1"
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} className="mr-2" />
          Back to Home Page
        </button>

        <div className="text-center">
          <a
            href="https://gaim.ai"
            alt="GAIM.AI"
            target="_blank"
            rel="noreferrer noopener"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            <img
              src="https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png"
              alt="GAIM.AI Logo"
              className="w-5 h-5 mr-2"
            />
            Powered By GAIM.AI
          </a>
        </div>

        <p className="text-center text-xs text-gray-500 mt-8">
          We value your privacy. Your data will never be sold or used for spam.
        </p>
      </div>
    </div>
  );
};

export default LoginPurchasePage;
