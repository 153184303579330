import HomepageLayout from "../layout/homepageLayout";
import Footer from "../components/Footer";
import Card from "../components/Card";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import PricingCardsMonthly from "../components/pricingCardsMonthly";
import PricingCards from "../components/pricingCards";
import FAQ from "../components/FAQ";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoltLightning,
  faCheck,
  faCheckDouble,
  faCheckSquare,
  faFire,
  faGraduationCap,
  faHandHoldingDollar,
  faMedal,
  faRightToBracket,
  faRobot,
  faStar,
  faThumbsDown,
  faWandMagicSparkles,
  faX,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import config from "../../../config/config";
import React, { useState, useEffect, useCallback } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { useDispatch } from "react-redux";
import { fetchPackages } from "../../../features/packages/packageSlice";
import VideoPlayer from "../../../components/VideoPlayer";
import ExitIntentPopup from "../components/ExitIntentPopup";
import { useSelector } from "react-redux";
import { faCheckCircle, faHandshake } from "@fortawesome/free-regular-svg-icons";
import LeadMagnetCard from "../../../components/LeadMagnetCard";
import PricingCardsOGFlow from "../components/pricingCardsOGFlow";


const FeatureCard = ({ title, icon, description }) => {
  return (
    <div className="flex flex-col items-center p-6 space-y-3 text-center bg-white rounded-xl shadow-lg dark:bg-gray-800 border border-primary">
      <div className="bg-primary text-gray-100 p-3 rounded-full">
        <FontAwesomeIcon icon={icon} size="2x" />
      </div>
      <h3 className="text-xl font-bold text-primary">{title}</h3>
      <p className="text-gray-600 dark:text-gray-300">{description}</p>
    </div>
  );
};

const ComparisonTable = () => {
  return (
    <div className="overflow-x-auto rounded">
      <table className="lg:w-full text-sm lg:text-lg text-left text-gray-500 overflow-auto">
        <thead className="text-gray-100 uppercase bg-primary text-inter font-bold border-b border-gray-900">
          <tr>
            <th scope="col" className="pl-2 lg:px-6 py-3">
              Feature
            </th>
            <th scope="col" className="text-center lg:px-6 py-3">
              {config.siteName}
            </th>
            <th scope="col" className="text-center lg:px-6 py-3">
              Competitors
            </th>
          </tr>
        </thead>
        <tbody>
          {config.comparisonTableRows.map((row, index) => (
            <tr key={index} className="bg-white border-b dark:bg-gray-800">
              <th
                scope="row"
                className="px-1 lg:px-6 py-4 font-bold text-primary whitespace-nowrap"
              >
                {row.feature}
              </th>
              <td className="text-center px-1 lg:px-6 py-4 text-gray-100 bg-primary font-bold">
                <FontAwesomeIcon icon={faCheckSquare} className="mr-2" />
                {row.nclexStudyPro}
              </td>
              <td className="text-center lg:px-6 py-4">{row.competitors}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const images = [
  config.landing_page_testimonial_1_image,
  config.landing_page_testimonial_2_image,
  config.landing_page_testimonial_3_image,
  config.landing_page_testimonial_4_image,
  config.landing_page_testimonial_5_image,
  config.landing_page_testimonial_6_image,
  config.landing_page_testimonial_7_image,
];

const OverlappingImages = ({ images }) => {
  return (
    <div className="flex items-center justify-center">
      {images.map((image, index) => (
        <div
          key={index}
          className="w-10 h-10 rounded-full border-2 border-gray-100 overflow-hidden"
          style={{
            position: "relative",
            zIndex: index,
            marginLeft: index !== 0 ? "-24px" : "0",
          }}
        >
          <img
            src={image}
            alt={`Profile ${index + 1}`}
            className="w-full h-full object-cover"
          />
        </div>
      ))}
      <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 text-4xl ml-2" />
    </div>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showExitPopup, setShowExitPopup] = useState(false);
  const [hasShownPopup, setHasShownPopup] = useState(false);
  const leadMagnet = useSelector(state => state.tools.items.leadMagnetId);

  const handleMouseOut = useCallback(
    (e) => {
      if (!hasShownPopup && e.clientY <= 0) {
        setShowExitPopup(true);
        setHasShownPopup(true);
      }
    },
    [hasShownPopup]
  );

  const handleKeyDown = useCallback((e) => {
    if (e.key === "Escape") {
      setShowExitPopup(false);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchPackages());

    document.addEventListener("mouseout", handleMouseOut);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mouseout", handleMouseOut);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, handleMouseOut, handleKeyDown]);

  return (<>
  <div className="w-full text-right lg:hidden">
        <Link to={'/login'} className="text-primary text-inter">
        Members Login <FontAwesomeIcon icon={faRightToBracket} className="ml-1" />
        </Link>
      </div>
    <div className="flex flex-col px-1 lg:px-4 border-4 border-primary rounded bg-black-main">
      
      <Card>
        <div className="max-w-full text-center text-gray-100">
          
          <div className="grid lg:grid-cols-2 gap-4">
            <div className="grid grid-cols-1 mb-16 md:mb-20 lg:mb-20 md:my-auto order-1 lg:order-1">
              <div className="">
                <div className="md:text-5xl text-4xl text-gray-100 font-bold mt-0 mx-auto max-w-6xl text-inter font-bold">
                  {config.landing_page_hero_headline}
                  {/*<FontAwesomeIcon
                    icon={faGraduationCap}
                    className="ml-4 text-primary"
                  />*/}
                </div>
                <div className="text-xl bold mt-4 mx-auto max-w-4xl">
                  {config.landing_page_hero_subheadline}
                </div>
                <div className="mx-auto my-10 h-16 text-center ">
                  <button
                    onClick={() => navigate("/loginpurchase")}
                    className="text-inter font-bold mt-2 py-2 md:px-6 px-10 border-none border-primary hover:bg-gray-700 gradient-red-blue focus:ring-blue-500 focus:ring-offset-blue-200 text-gray-100 w-full transition ease-in duration-200 text-center text-xl sm:text-3xl shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-full "
                  >
                    👉 {config.landing_page_hero_button_text}
                  </button>
                  <div className="mt-4 italic text-gray-100">
                    <FontAwesomeIcon
                      icon={faMedal}
                      className="mr-2 text-yellow-600"
                    />
                    {config.landing_page_package_type === "Monthly" && (
                      <span>7-day money back guarantee.</span>
                    )}
                    {config.landing_page_package_type === "One-Time" && (
                      <span>Pass your exam or get a 100% refund!</span>
                    )}
                  </div>
                  <div className="p-4">
                    <OverlappingImages images={images} />
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className={`order-2 lg:order-2`}>
              {/*<img src={config.landing_page_hero_graphic} alt={`Get started with ${config.siteTitle}`} className="h-100 max-h-100 md:max-w-sm border border-2 border-gray-400 shadow-xl rounded-xl mx-auto p-1 " style={{pointerEvents: "none"}} />*/}
              <div className="hidden lg:flex lg:flex-col items-center p-2 rounded-lg shadow-xl mb-2 text-primary">
                <VideoPlayer
                  url={config.landing_page_hero_graphic_desktop}
                  width={500}
                />
              </div>
              <div
                className={`lg:hidden md:max-w-sm w-fit border border-4 border-gray-400 shadow-xl rounded-xl mx-auto p-1`}
              >
                <img
                  src={config.landing_page_hero_graphic_mobile}
                  alt="AI-Powered Exam Prep"
                  className="rounded"
                  style={{ pointerEvents: "none" }}
                />
              </div>
              <div className="rounded-full px-4 py-2 bg-body border-none border-blue-600 text-primary text-2xl mt-4 max-w-2xl text-inter font-bold mx-auto">
          <FontAwesomeIcon icon={faHandshake} className="mr-2" />{config.leadMagnetBottomHeadline}
        </div>
            </div>
          </div>

          <div class="mb-10 flex justify-center items-center text-center">
            <div class="mt-10">
              <div className="justify-center items-center text-inter font-bold text-center text-4xl font-bold mt-10 flex flex-col lg:flex-row gap-2 lg:gap-0">
                <p
                  dangerouslySetInnerHTML={{
                    __html: config.landing_page_plans_headline,
                  }}
                ></p>
                <div>
                  <FontAwesomeIcon
                    icon={faHandHoldingDollar}
                    className="text-red-500 lg:ml-2"
                  />
                </div>
              </div>
              {config.landing_page_package_type === "monthly" ? (
                <PricingCardsMonthly />
              ) : (
                <PricingCardsOGFlow isFrontPage={true} />
              )}
            </div>
          </div>
          <hr className="w-48 h-1 mx-auto bg-primary border-0 rounded my-20"></hr>

          {/* Key Features Section */}
          {config.keyFeatures.length > 0 && (
            <section className="my-20">
              <h2 className="text-2xl lg:text-3xl text-inter font-bold mb-10">
                Why Choose {config.siteName}? 🧐
              </h2>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {config.keyFeatures.map((feature, index) => (
                  <FeatureCard
                    key={index}
                    title={feature.title}
                    icon={feature.icon}
                    description={feature.description}
                  />
                ))}
              </div>
            </section>
          )}

          <hr className="w-48 h-1 mx-auto bg-primary border-0 rounded my-20"></hr>
          <div className="max-w-screen-xl mx-auto mt-20 lg:mt-32 mb-24 lg:mb-10">
            <h2 className="text-inter font-bold text-4xl font-bold tracking-wide flex justify-center mb-3">
              <p
                dangerouslySetInnerHTML={{
                  __html: config.landing_page_headline_2,
                }}
              ></p>
            </h2>
            <div className="text-xl">{config.landing_page_subheadline_2}</div>
          </div>
          <div className="grid lg:grid-cols-2 gap-4 ">
            <div class="flex items-start rounded-xl p-4 shadow-lg border-2 border-primary">
              <div class="lg:ml-4 h-full flex flex-col justify-between">
                <div className="text-center text-sm italic h-8">
                  Example Interaction
                </div>
                <h2 class="font-semibold lg:text-2xl lg:ml-3 text-center">
                  {" "}
                  <span class="bg-primary text-gray-100 mr-2 px-4 py-2 rounded text-inter font-bold">
                    <FontAwesomeIcon
                      icon={faWandMagicSparkles}
                      className="mr-2"
                    />
                    {config.landing_page_example_1_request}
                  </span>
                </h2>

                <ReactMarkdown
                  className="markdown-content landing-page text-left"
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                >
                  {config.landing_page_example_1_response}
                </ReactMarkdown>
                <div className="flex flex-wrap justify-center gap-2">
                  {config.landing_page_example_1_next_action_options?.map(
                    (option, index) => (
                      <div key={index} className="flex items-center">
                        <span class="bg-primary text-gray-100 mr-2 px-4 py-2 rounded text-inter font-bold">
                          <FontAwesomeIcon
                            icon={faWandMagicSparkles}
                            className="mr-2"
                          />
                          {option}
                        </span>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div class="flex items-start rounded-xl p-4 shadow-lg border-2 border-primary">
              <div class="lg:ml-4 h-full flex flex-col justify-between">
                <div className="text-center text-sm italic h-8">
                  Example Interaction
                </div>
                <h2 class="font-semibold lg:text-2xl lg:ml-3 text-center">
                  {" "}
                  <span class="bg-primary text-gray-100 mr-2 px-4 py-2 rounded text-inter font-bold">
                    <FontAwesomeIcon
                      icon={faWandMagicSparkles}
                      className="mr-2"
                    />
                    {config.landing_page_example_2_request}
                  </span>
                </h2>

                <ReactMarkdown
                  className="markdown-content landing-page text-left"
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                >
                  {config.landing_page_example_2_response}
                </ReactMarkdown>
                <div className="flex flex-wrap justify-center gap-2">
                  {config.landing_page_example_2_next_action_options?.map(
                    (option, index) => (
                      <div key={index} className="flex items-center">
                        <span class="bg-primary text-gray-100 mr-2 px-4 py-2 rounded text-inter font-bold">
                          <FontAwesomeIcon
                            icon={faWandMagicSparkles}
                            className="mr-2"
                          />
                          {option}
                        </span>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr className="w-48 h-1 mx-auto bg-primary border-0 rounded my-20"></hr>

          {/* Comparison Table */}
          <section className="my-20">
            <h2 className="text-3xl text-inter font-bold mb-10">
              <FontAwesomeIcon icon={faFire} className="mr-2 text-orange-400" />
              Why You Should Choose {config.siteName}
            </h2>
            <ComparisonTable />
          </section>

          <div className="my-20">
            <div className="text-inter font-bold text-center text-4xl font-bold flex flex-col lg:flex-row justify-center items-center">
              Don't just take it from us...
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="text-yellow-400 ml-2"
                />
                <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
                <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
                <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
                <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
              </div>
            </div>

            <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-4 max-w-7xl mx-auto  border border-2 border-primary rounded-xl shadow-md py-2 px-4">
              <div className=" max-w-2xl   px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
                <div className="text-inter font-bold">
                  <span className="text-primary text-5xl text-inter font-bold">"</span>
                  {config.landing_page_testimonial_1}
                  <p className="text-primary text-5xl text-inter font-bold text-right">
                    "
                  </p>
                  <img
                    className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary"
                    src={config.landing_page_testimonial_1_image}
                  />
                  <p className="mt-4">
                    {config.landing_page_testimonial_1_author}
                  </p>
                  <img
                    className="mb-4 mt-4 w-28 mx-auto"
                    src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png"
                  />
                </div>
              </div>
              <div className=" max-w-2xl   px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
                <div className="text-inter font-bold">
                  <span className="text-primary text-5xl text-inter font-bold">"</span>
                  {config.landing_page_testimonial_2}
                  <p className="text-primary text-5xl text-inter font-bold text-right">
                    "
                  </p>
                  <img
                    className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary"
                    src={config.landing_page_testimonial_2_image}
                  />
                  <p className="mt-4">
                    {config.landing_page_testimonial_2_author}
                  </p>
                  <img
                    className="mb-4 mt-4 w-28 mx-auto"
                    src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png"
                  />
                </div>
              </div>
              <div className=" max-w-2xl px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
                <div className="text-inter font-bold">
                  <span className="text-primary text-5xl text-inter font-bold">"</span>
                  {config.landing_page_testimonial_3}
                  <p className="text-primary text-5xl text-inter font-bold text-right">
                    "
                  </p>
                  <img
                    className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary"
                    src={config.landing_page_testimonial_3_image}
                  />
                  <p className="mt-4">
                    {config.landing_page_testimonial_3_author}
                  </p>
                  <img
                    className="mb-4 mt-4 w-28 mx-auto"
                    src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png"
                  />
                </div>
              </div>
            </div>
          </div>

          <hr className="w-48 h-1 mx-auto bg-primary border-0 rounded my-20"></hr>
          <div className="border-2 border-primary shadow-xl rounded-xl p-10 max-w-8xl mx-auto bg-gray-800 text-gray-100 mt-10 flex flex-col lg:flex-row lg:justify-center">
            <div className="hidden lg:flex justify-center items-center px-10">
              <img
                className="lg:hidden w-48 mx-auto rounded-full border-2"
                src={config.favicon}
                alt={config.siteName}
              />
              <div className={`order-2 lg:order-2`}>
                <img
                  src={config.landing_page_hero_graphic_mobile}
                  alt={`Your personal exam tutor with ${config.siteTitle}`}
                  className="hidden lg:block h-100 max-h-100 md:max-w-sm border border-2 border-gray-400 shadow-xl rounded-xl mx-auto p-1 "
                  style={{ pointerEvents: "none" }}
                />
              </div>
            </div>
            <div className="text-center">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="bg-secondary text-5xl mx-auto mb-4 border-2 border-primary p-3 py-3.5 rounded-full"
              />
              <h2 className="text-lg lg:text-3xl tracking-wide text-inter font-bold flex justify-center mb-4">
                {config.landing_page_headline_3}
              </h2>
              <div className="flex flex-row gap-4 justify-center items-center">
                <FontAwesomeIcon
                  icon={faBoltLightning}
                  className="text-secondary text-xl mb-4 border-2 border-primary p-4 py-3.5 rounded-full"
                />
                <FontAwesomeIcon
                  icon={faWandMagicSparkles}
                  className="text-secondary text-xl mb-4 border-2 border-primary p-3 py-3.5 rounded-full"
                />
                <FontAwesomeIcon
                  icon={faRobot}
                  className="text-secondary text-xl mb-4 border-2 border-primary p-2.5 py-3.5 rounded-full"
                />
              </div>
              <div className="text-left lg:text-2xl my-4 mx-auto w-full lg:w-2/3">
                <ul className="list-disc list-inside space-y-1">
                  {config.landing_page_headline_3_features.map(
                    (feature, index) => (
                      <li key={index}>{feature}</li>
                    )
                  )}
                </ul>
              </div>
              <button
                onClick={() => navigate("/loginpurchase")}
                className="mt-6 py-2 md:px-6 px-2 text-inter font-bold border-gray-200 hover:bg-gray-700 hover:text-gray-100 bg-white focus:ring-white-500 focus:ring-offset-white-200 text-primary transition ease-in duration-200 text-center text-2xl shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              >
                👉 {config.landing_page_hero_button_text}
              </button>
            </div>
          </div>

          <div className="flex flex-row gap-2 justify-center items-center text-red-500 text-4xl mt-14 line-through">
              <FontAwesomeIcon icon={faXmarkCircle} className="" />
              FAIL
            </div>
          <div className="rounded-full px-4 py-2 bg-body border-none border-blue-600 text-red-500 text-2xl lg:text-4xl max-w-4xl text-inter font-bold mx-auto">
            <FontAwesomeIcon icon={faThumbsDown} className="mr-2" />{config.landing_page_headline_4}
          </div>
          <div className="flex flex-row gap-4 justify-center items-center text-inter font-bold">
            
            <div className="flex flex-row gap-2 justify-center items-center text-green-500 text-4xl">
              <FontAwesomeIcon icon={faCheck} className="" />
              <FontAwesomeIcon icon={faCheck} className="" />
              PASS
            </div>
          </div>
          {/* Guarantee Section */}
          <div className="mt-14 bg-primary rounded">
            <div className="max-w-7xl mx-auto p-8">
              <div className="text-center text-gray-100">
                <img
                  className="w-52 mx-auto mb-6"
                  src="https://otp.nyc3.cdn.digitaloceanspaces.com/passExamGuarantee.png"
                  alt="Pass your exam. Guaranteed."
                />
                <h2 className="text-xl lg:text-3xl text-inter font-bold font-bold mb-4">
                  Pass Your Exam or Get a 100% Refund! 🎓💰
                </h2>
                <p className="text-lg">
                  Get ready to crush your certification exams with{" "}
                  {config.siteName}—where success is just a click away! We're so
                  confident in our AI-powered exam prep tools that we're
                  offering an unbeatable Money-Back Guarantee. Whether you
                  choose our 3-month, 6-month, or 12-month package, we've got
                  your back. Use our app, follow the plan, and if you don't pass
                  your exam, we'll refund your money. It's that simple. Dive in,
                  study smart, and conquer your goals with zero risk!
                </p>
                <p className="text-sm mt-4">
                  *{" "}
                  <Link
                    className="text-gray-100 hover:text-gray-500 transition-colors duration-200"
                    to="/terms-and-conditions"
                    target="_blank"
                  >
                    Terms And conditions
                  </Link>{" "}
                  apply.
                </p>
              </div>
            </div>
          </div>

          <FAQ />
          {leadMagnet && <div className="my-20 rounded border-4 p-4 border-red-600 border-dashed">
            {/*<BottomLeadMagnetForm />*/}
            
        <div className="text-lg text-gray-700 bg-yellow-100 border-2 border-yellow-600 px-4 py-2 rounded-full max-w-2xl text-inter font-bold mx-auto">
          {config.leadMagnetHeroSubheader}
        </div>
            <LeadMagnetCard leadMagnet={leadMagnet} />
          </div>
          }


        <div className="mt-10">
          <img src="https://otp.nyc3.cdn.digitaloceanspaces.com/trustBadges.png" alt="Trust Badges" className="mx-auto w-96" />
        </div>
        <div className="rounded-full px-4 py-2 bg-body border-none border-blue-600 text-primary text-2xl mt-4 max-w-2xl text-inter font-bold mx-auto">
          <FontAwesomeIcon icon={faHandshake} className="mr-2" />{config.leadMagnetBottomHeadline}
        </div>

          <div className="p-8 mt-6 rounded-xl shadow-xl bg-primary text-gray-100">
            <div className="mb-6 font-bold text-2xl">
              Get instant access{" "}
              <FontAwesomeIcon
                icon={faBoltLightning}
                className="mx-2 text-yellow-400"
              />{" "}
              and start using {config.siteName} today.
            </div>
            <button
              onClick={() => navigate("/loginpurchase")}
              className="mt-2 py-2 md:px-6 px-2 text-inter font-bold border-gray-200 hover:bg-gray-700 hover:text-gray-100 bg-white focus:ring-white-500 focus:ring-offset-white-200 text-primary transition ease-in duration-200 text-center text-2xl shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            >
              👉 {config.landing_page_hero_button_text}
            </button>

            <div className="mt-4 italic text-gray-100">
              <FontAwesomeIcon
                icon={faMedal}
                className="mr-2 text-yellow-600"
              />
              {config.landing_page_package_type === "Monthly" && (
                <span>7-day money back guarantee.</span>
              )}
              {config.landing_page_package_type === "One-Time" && (
                <span>Pass your exam or get a 100% refund!</span>
              )}
            </div>
            <div className="text-center mt-10">
              <a
                href="https://gaim.ai"
                alt="GAIM.AI"
                target="_blank"
                rel="noreferrer noopener"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-100 bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                <img
                  src="https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png"
                  alt="GAIM.AI Logo"
                  className="w-5 h-5 mr-2"
                />
                Powered By GAIM.AI
              </a>
            </div>
          </div>
          <Footer />
        </div>
      </Card>
      {showExitPopup && (
        <ExitIntentPopup onClose={() => setShowExitPopup(false)} />
      )}
    </div>
  </>);
};

const Homepage = () => {
  return <HomepageLayout Component={Home} />;
};

export default Homepage;
