import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { setCurrentProfile } from '../features/currentTool/currentProfileSlice';
import { openComponentModal } from '../features/modals/modalcomponentSlice';

function SidebarProfilesCard({ onProfileClick, header }) {
  const dispatch = useDispatch();
  const profiles = useSelector(state => state.tools.items.profiles);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSetCurrentProfile = (profile) => {
    dispatch(setCurrentProfile(profile));
    onProfileClick(profile.content); // Call the function to update inputValue in the parent component
  };

  const filteredProfiles = profiles?.filter(profile => 
    profile.name.toLowerCase().includes(searchQuery) ||
    (profile.description && profile.description.toLowerCase().includes(searchQuery))
  );

  return (
    <div className='bg-primary max-h-96 overflow-auto slim-scrollbar py-2 rounded'>
      {profiles?.length > 0 ? (<> <div className='flex flex-col gap-1 justify-center items-center bg-primary p-3 w-full rounded mx-auto'>
        
        <div className='flex justify-center items-center text-xl text-poetsen font-bold'>
          <FontAwesomeIcon icon={faAddressBook} className='mr-2' />Profiles
        </div>
        <div className='text-sm text-center'>
          {header}
        </div>
        <div className='mt-2'>
            <button onClick={()=>dispatch(openComponentModal())} className="text-poetsen ml-auto flex uppercase text-primary hover:text-white flex-col font-bold bg-body p-1 px-3 hover:bg-gray-500 rounded">
                <span><FontAwesomeIcon icon={faPlusCircle} className='mr-2'/>Manage</span>
            </button>
        </div>
      </div>

             
        <div className="flex flex-col justify-center">
          {filteredProfiles?.map((profile, index) => (
            <div key={profile.name + index} onClick={() => handleSetCurrentProfile(profile)} className="cursor-pointer flex flex-col border justify-center bg-primary p-2 hover:bg-gray-500 rounded mx-2 my-1">
              <div className="text-left overflow-x-scroll">
                <button className='text-center text-inverted'>{profile.name}</button>
              </div>
            </div>
          ))}
        </div>
      </>) : (
        <div className="flex flex-col items-center justify-center mt-5 bg-primary w-full p-5 rounded">
          <div className="text-xl font-bold text-black mb-5 text-center text-poetsen">No profiles added yet...</div>
          <div className="text-lg font-bold text-black mb-5 text-center">Click
            <button onClick={()=>dispatch(openComponentModal())} className="text-poetsen uppercase text-primary hover:text-white font-bold bg-body p-1 px-3 mx-2 hover:bg-gray-500 rounded">
                <span><FontAwesomeIcon icon={faPlusCircle} className='mr-2'/>Manage</span>
            </button>
            to add your first profile.</div>
        </div>
      )}
    </div>
  );
}

export default SidebarProfilesCard;
