import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState} from "react";
import { Link } from "react-router-dom";
import LeadMagnetCard from "../LeadMagnetCard";
import config from "../../config/config";
import { useSelector } from "react-redux";

const BlogPost = ({ post }) => {
  const leadMagnet = useSelector(state => state.tools.items.leadMagnetId);
  
  return (
    <article className="container mx-auto p-4 text-gray-200">
      <img
        src={post.headerImage}
        alt={post.title}
        className="w-full h-64 object-cover rounded mb-4"
      />
      <h1 className="text-xl lg:text-4xl font-header text-primary mb-4 uppercase text-center text-inter font-bold">
        {post.title}
      </h1>
      <div className="flex justify-center">
        <div className="text-gray-400 text-inter font-bold">
          Written by: {post.author},{" "}
          {new Date(post.dateWritten).toLocaleDateString()}
        </div>
      </div>
      <div className="flex justify-center flex-wrap mt-4 gap-1">
          {post.tags.map((tag) => (
            <span key={tag} className="text-xs text-primary border border-primary rounded-full leading-none px-2 py-1">
              {tag}
            </span>
          ))}
        </div>
      <div className="markdown-content text-body mb-20">
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </div>
      {leadMagnet && <div className="my-20 rounded border-4 p-4 border-red-600 border-dashed">
            {/*<BottomLeadMagnetForm />*/}
            
        <div className="text-lg text-gray-700 bg-yellow-100 border-2 border-yellow-600 px-4 py-2 rounded-full max-w-2xl text-inter font-bold mx-auto">
          {config.leadMagnetHeroSubheader}
        </div>
            <LeadMagnetCard leadMagnet={leadMagnet} />
          </div>
          }
      <div className="w-full flex justify-center mt-10">
        <Link
          to="/blog"
          className="mt-4 inline-block mx-auto px-4 py-2 rounded bg-primary text-inter font-bold"
        >
          <FontAwesomeIcon icon={faBackward} className="mr-2" />
          Back to Blog Home
        </Link>
      </div>
    </article>
  );
};

export default BlogPost;
