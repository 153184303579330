import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
  },
  reducers: {
    setUser: (state, action) => {
      //console.log('Setting user:', action.payload);
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
    reduceAICredits(state, action) {
      // Reduces ai_credits by a certain amount
      if (state.user && state.user.ai_credits) {
        state.user.ai_credits -= action.payload;
      }
    }
  },
});

export const { setUser, logout, reduceAICredits } = userSlice.actions;

export const selectUser = (state) => state.user.user;

export default userSlice.reducer;
