import axios from 'axios';
import config from '../config/config';

const apiURL = process.env.REACT_APP_API_URL;
const app_id = config.app_id;

export const userStatsApi = {
  getUserStats: (userId) => {
    return axios.get(`${apiURL}/quiz/user-stats/overall`, {
      params: { app_id: app_id, userId: userId},
    });
  },
  getIncorrectAnswers: (userId) => {
    return axios.get(`${apiURL}/quiz/user-stats/incorrect-answers`, {
      params: { app_id: app_id, userId: userId},
    });
  },
  getCategoryPerformance: (userId) => {
    return axios.get(`${apiURL}/quiz/user-stats/category-performance`, { params: { app_id, userId } });
  },
  getProgressOverTime: (userId) => {
    return axios.get(`${apiURL}/quiz/user-stats/progress-over-time`, { params: { app_id, userId } });
  },
  getSubjectAreaPerformance: (userId) => {
    return axios.get(`${apiURL}/quiz/user-stats/subject-area-performance`, { params: { app_id, userId } });
  },
  getScoreDistribution: (userId) => {
    return axios.get(`${apiURL}/quiz/user-stats/score-distribution`, { params: { app_id, userId } });
  },
  getImprovementAreas: (userId) => {
    return axios.get(`${apiURL}/quiz/user-stats/improvement-areas`, { params: { app_id, userId } });
  },
  getLearningCurve: (userId) => {
    return axios.get(`${apiURL}/quiz/user-stats/learning-curve`, { params: { app_id, userId } });
  },
  
  // New API calls for updateXP and updateStreak
  updateXP: (userId, xpGained) => {
    return axios.post(`${apiURL}/quiz/flashcard/update-xp`, 
      { xpGained },
      { params: { app_id, userId } }
    );
  },
  /*updateStreak: (userId) => {
    return axios.post(`${apiURL}/quiz/flashcard/update-streak`, 
      {},
      { params: { app_id, userId } }
    );
  }*/
};