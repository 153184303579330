import React from "react";
import { Link } from "react-router-dom";
import config from "../../../config/config";

const GuaranteeSectionHome = () => {
  return (
    <>
      <div className="mt-14 bg-primary rounded">
        <div className="max-w-7xl mx-auto p-8">
          <div className="text-center text-gray-100">
            <img
              className="w-52 mx-auto mb-6"
              src="https://otp.nyc3.cdn.digitaloceanspaces.com/passExamGuarantee.png"
              alt="Pass your exam. Guaranteed."
            />
            <h2 className="text-xl lg:text-3xl text-inter font-bold font-bold mb-4">
              Pass Your Exam or Get a 100% Refund! 🎓💰
            </h2>
            <p className="text-lg">
              Get ready to crush your certification exams with {config.siteName}
              —where success is just a click away! We're so confident in our
              AI-powered exam prep tools that we're offering an unbeatable
              Money-Back Guarantee. Whether you choose our 3-month, 6-month, or
              12-month package, we've got your back. Use our app, follow the
              plan, and if you don't pass your exam, we'll refund your money.
              It's that simple. Dive in, study smart, and conquer your goals
              with zero risk!
            </p>
            <p className="text-sm mt-4">
              *{" "}
              <Link
                className="text-gray-100 hover:text-gray-500 transition-colors duration-200"
                to="/terms-and-conditions"
                target="_blank"
              >
                Terms And conditions
              </Link>{" "}
              apply.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuaranteeSectionHome;
