import { createSlice } from '@reduxjs/toolkit';

export const modalNewsletterSlice = createSlice({
  name: 'modalNewsletter',
  initialState: {
    isOpen: false
  },
  reducers: {
    openNewsletterModal: state => {
      state.isOpen = true;
    },
    closeNewsletterModal: state => {
      state.isOpen = false;
    }
  }
});

export const { openNewsletterModal, closeNewsletterModal } = modalNewsletterSlice.actions;

export const selectIsNewsletterOpen = state => state.modalNewsletter.isOpen;

export default modalNewsletterSlice.reducer;