import React, { useState } from 'react';
import styled from 'styled-components';
import CurrentToolCard from './CurrentToolCard';
import SuggestedPrompts from './SuggestedPrompts';

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px;
`;

const CharacterSidebar = () => {
  return (
    <SidebarContainer>
      <div className='text-center text-lg mb-4'>CURRENT CHARACTER</div>
      <CurrentToolCard />
      <SuggestedPrompts />
    </SidebarContainer>
  );
};

export default CharacterSidebar;