// packageItemSlice.js
import { createSlice } from '@reduxjs/toolkit';

const packageItemSlice = createSlice({
  name: 'packageItem',
  initialState: null,  // Set to null initially, or use {} if you prefer an empty object when no item is selected
  reducers: {
    setPackageItem(state, action) {
      console.log('action.payload:', action.payload);
      return action.payload;  // Directly return the new state which is the package item object
    },
    clearPackageItem() {
      return null;  // Clear the package item, setting it back to null
    }
  }
});

export const getPackageItem = (state) => state.packageItem;
export const { setPackageItem, clearPackageItem } = packageItemSlice.actions;

export default packageItemSlice.reducer;
