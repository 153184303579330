import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ProgressLineChart = ({ showInfo }) => {
  const { progressOverTime, loading, error } = useSelector((state) => state.userStats);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64 bg-gray-900">
        <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-blue-500" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center bg-gray-900 p-4">Error loading data</div>;
  }

  if (!progressOverTime || progressOverTime.length === 0) {
    return <div className="text-gray-500 text-center bg-gray-900 p-4">No data available</div>;
  }

  const data = {
    labels: progressOverTime.map(entry => new Date(entry.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Average Score',
        data: progressOverTime.map(entry => entry.score),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: { color: 'white' }
      },
      title: {
        display: true,
        text: 'Your Progress Over Time',
        color: 'white',
        font: { size: 16, weight: 'bold' }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        title: {
          display: true,
          text: 'Score (%)',
          color: 'white'
        },
        ticks: { color: 'white' },
        grid: { color: 'rgba(255, 255, 255, 0.1)' }
      },
      x: {
        title: {
          display: true,
          text: 'Date',
          color: 'white'
        },
        ticks: { color: 'white' },
        grid: { color: 'rgba(255, 255, 255, 0.1)' }
      }
    }
  };

  return (
    <div className={`bg-gray-900 lg:p-4 rounded-lg shadow-md border-4 border-primary ${!showInfo ? 'h-52 max-h-52 w-full' : 'h-96 max-h-96 w-full lg:h-96 lg:max-h-96 overflow-auto'} flex flex-col items-center`}>
      <Line data={data} options={options} />
      {showInfo && <div className="mt-4 text-white pt-2 px-1 leading-none">
        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
        <span className="text-xs">
          This line chart tracks your average quiz scores over time. It helps you visualize your 
          learning progress and identify trends in your performance. An upward trend indicates 
          improvement, while plateaus or dips may suggest areas needing more focus. Use this chart 
          to set goals and track your journey towards exam readiness.
        </span>
      </div>}
    </div>
  );
};

export default ProgressLineChart;