import React from "react";
import { useNavigate } from "react-router-dom";
import config from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoltLightning,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";

const BottomCTAHome = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="p-8 mt-6 rounded-xl shadow-xl bg-primary text-gray-100">
        <div className="mb-6 font-bold text-2xl">
          Get instant access{" "}
          <FontAwesomeIcon
            icon={faBoltLightning}
            className="mx-2 text-yellow-400"
          />{" "}
          and start using {config.siteName} today.
        </div>
        <button
          onClick={() => navigate("/loginpurchase")}
          className="mt-2 py-2 md:px-6 px-2 text-inter font-bold border-gray-200 hover:bg-gray-700 hover:text-gray-100 bg-white focus:ring-white-500 focus:ring-offset-white-200 text-primary transition ease-in duration-200 text-center text-2xl shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
        >
          👉 {config.landing_page_hero_button_text}
        </button>

        <div className="mt-4 italic text-gray-100">
          <FontAwesomeIcon icon={faMedal} className="mr-2 text-yellow-600" />
          {config.landing_page_package_type === "Monthly" && (
            <span>7-day money back guarantee.</span>
          )}
          {config.landing_page_package_type === "One-Time" && (
            <span>Pass your exam or get a 100% refund!</span>
          )}
        </div>
        <div className="text-center mt-10">
          <a
            href="https://gaim.ai"
            alt="GAIM.AI"
            target="_blank"
            rel="noreferrer noopener"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-100 bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            <img
              src="https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png"
              alt="GAIM.AI Logo"
              className="w-5 h-5 mr-2"
            />
            Powered By GAIM.AI
          </a>
        </div>
      </div>
    </>
  );
};

export default BottomCTAHome;
