import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../features/user/userSlice";
import { fetchUserStats } from "../../features/quiz/userStatsSlice";
import {
  fetchFlashcards,
  selectAllFlashcards,
  selectFlashcardsStatus,
} from "../../features/flashcards/flashcardsSlice";
import { flashcardApi } from "../../api/quizApi";
import QuizGenerator from "./QuizGenerator";
import QuizComponent from "./QuizComponent";
import QuizResults from "./QuizResults";
import UserStatistics from "./UserStatistics";
import QuizForm from "../newQuiz/QuizForm";
import {
  generateQuiz,
  submitQuizResults,
  fetchQuiz,
} from "../../features/quiz/quizSlice";
import config from "../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoltLightning } from "@fortawesome/free-solid-svg-icons";

const QuizPage = () => {
  const [quizStarted, setQuizStarted] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const quiz = useSelector((state) => state.quiz.currentQuiz);
  const bites = useSelector((state) => state.tools.items.bites);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const [randomBite, setRandomBite] = useState(null);
  const pageTopRef = useRef(null);

  const selectRandomBite = () => {
    if (bites && bites.length > 0) {
      const randomIndex = Math.floor(Math.random() * bites.length);
      setRandomBite(bites[randomIndex]);
    } else {
      setRandomBite(null);
    }
  };

  // When isLoading is true, generate a new random bite every 5 seconds
  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        selectRandomBite();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [isLoading]);

  // Scroll to top when quizCompleted changes to true
  useEffect(() => {
    if (quizCompleted) {
      pageTopRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [quizCompleted]);

  const handleStartQuiz = (quizOptions) => {
    selectRandomBite();
    setIsLoading(true);
    dispatch(fetchQuiz({ quizOptions, userId: user?._id }))
      .unwrap()
      .then(() => {
        setQuizStarted(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to generate quiz:", error);
        setIsLoading(false);
      });
  };

  const handleQuizComplete = async (results) => {
    const quizData = {
      ...results,
      app_id: config.app_id,
      userId: user?._id,
      quizType: quiz.quizType,
    };

    try {
      await dispatch(submitQuizResults(quizData)).unwrap();
      setQuizCompleted(true);
      await dispatch(fetchUserStats(user?._id));
      await flashcardApi.generateFlashcards(user?._id);
      await dispatch(fetchFlashcards({ userId: user?._id }));
    } catch (error) {
      console.error("Failed to submit quiz results:", error);
    }
  };

  const handleRetakeQuiz = () => {
    pageTopRef.current?.scrollIntoView({ behavior: 'smooth' });
    setQuizStarted(false);
    setQuizCompleted(false);
  };

  const Loader = () => (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-primary p-5 rounded-lg shadow-lg text-center border-2 border-gray-700 lg:max-w-1/2">
        <div className="animate-spin rounded-full h-16 w-16 border-b-4 border-gray-700 mx-auto mb-4 flex justify-center items-center">
          <img
            src={config.favicon}
            alt={`${config.quiz_page_title}`}
            className="w-12 h-12 rounded-full"
          />
        </div>
        <p className="text-lg text-poetsen">
          <FontAwesomeIcon
            icon={faBoltLightning}
            className="text-yellow-400 mr-2"
          />
          Generating Your Fresh Quiz...
        </p>
        <p className="text-sm">Please wait, this may take a minute or two.</p>
        {randomBite && (
          <div className="flex flex-col mt-4 text-lg w-full">
            <div className="text-poetsen text-2xl">Study Tip</div>
            <div>{randomBite}</div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8 min-h-screen" ref={pageTopRef}>
      <div className="flex items-center justify-center mb-4 gap-4">
        <div>
          <img
            src={config.logoUrl}
            alt={`${config.quiz_page_title}`}
            className="w-12 h-auto rounded-full"
          />
        </div>
        <div className="text-3xl font-bold text-poetsen text-primary">
          {config.quiz_page_title || "Quizzes"}
        </div>
      </div>
      {isLoading && <Loader />}
      {!quizStarted && !quizCompleted && !isLoading && (
        <QuizGenerator onStartQuiz={handleStartQuiz} />
      )}
      {quizStarted && !quizCompleted && quiz && (
        <QuizComponent quiz={quiz} onComplete={handleQuizComplete} />
      )}
      {quizCompleted && (
        <>
          <QuizResults />
          <button
            onClick={handleRetakeQuiz}
            className="mt-4 bg-primary hover:bg-gray-700 hover:text-white py-2 px-4 rounded"
          >
            Take Another Quiz
          </button>
        </>
      )}
      {!quizStarted && (
        <div className="min-h-96">
          <UserStatistics />
        </div>
      )}
    </div>
  );
};

export default QuizPage;