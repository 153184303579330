// src/features/flashcards/flashcardsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { flashcardApi } from '../../api/quizApi';

export const fetchFlashcards = createAsyncThunk(
  'flashcards/fetchFlashcards',
  async ({ userId, category, sort }, { rejectWithValue }) => {
    try {
      const response = await flashcardApi.getFlashcards(userId, category, sort);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateFlashcard = createAsyncThunk(
  'flashcards/updateFlashcard',
  async ({ flashcardId, newMasteryLevel }, { rejectWithValue }) => {
    try {
      const response = await flashcardApi.updateFlashcardMastery(flashcardId, newMasteryLevel);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createFlashcard = createAsyncThunk(
  'flashcards/createFlashcard',
  async ({ userId, flashcardData }, { rejectWithValue }) => {
    try {
      const response = await flashcardApi.createCustomFlashcard(userId, flashcardData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFlashcard = createAsyncThunk(
  'flashcards/deleteFlashcard',
  async (flashcardId, { rejectWithValue }) => {
    try {
      await flashcardApi.deleteFlashcard(flashcardId);
      return flashcardId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addFlashcardPack = createAsyncThunk(
  'flashcards/addFlashcardPack',
  async ({ userId, flashcardPackId }, { rejectWithValue }) => {
    try {
      const response = await flashcardApi.addFlashcardPack(userId, flashcardPackId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const flashcardsSlice = createSlice({
  name: 'flashcards',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFlashcards.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFlashcards.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchFlashcards.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateFlashcard.fulfilled, (state, action) => {
        const updatedFlashcard = action.payload;
        const index = state.items.findIndex(card => card._id === updatedFlashcard.flashcard._id);
        if (index !== -1) {
          state.items[index] = updatedFlashcard.flashcard;
        }
      })
      .addCase(createFlashcard.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      .addCase(deleteFlashcard.fulfilled, (state, action) => {
        state.items = state.items.filter(card => card._id !== action.payload);
      })
      .addCase(addFlashcardPack.fulfilled, (state, action) => {
        state.items = [...state.items, ...action.payload];
      });
  },
});

export default flashcardsSlice.reducer;

export const selectAllFlashcards = (state) => state.flashcards.items;
export const selectFlashcardsByCategory = (state, category) => 
  state.flashcards.items.filter(card => card.category === category);
export const selectFlashcardsStatus = (state) => state.flashcards.status;
export const selectFlashcardsError = (state) => state.flashcards.error;