import React from 'react';
import { faPlusCircle, faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from 'react-redux';
import { selectPackages } from '../../../features/packages/packageSlice';
import { setPackage } from '../../../features/packages/selectedPackageSlice';
import config from '../../../config/config';
import { motion } from 'framer-motion';

const PricingCards = ({ isRenewal = false, onPackageSelect }) => {
  const dispatch = useDispatch();
  const packages = useSelector(selectPackages);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const cardVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100
      }
    }
  };

  const handlePackageSelection = (packageItem) => {
    dispatch(setPackage(packageItem));
    localStorage.setItem('selectedPackage', packageItem);
    if (onPackageSelect) {
      onPackageSelect(packageItem);
    }
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="space-y-6 max-w-4xl mx-auto text-inter"
    >
      <h2 className="text-3xl font-bold text-inter text-center text-primary mb-4">Choose Your Perfect Package 👇</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {packages
          .filter(packageItem =>
            packageItem.type === config.landing_page_package_type &&
            packageItem.isRenewal === isRenewal
          )
          .map((packageItem, index) => (
            <motion.div
              key={packageItem._id}
              variants={cardVariants}
              className="bg-white rounded-xl shadow-lg overflow-hidden border-2 border-primary hover:border-green-400 transition-all duration-300"
            >
              <div className="p-6 flex flex-col h-full">
                {index !== 0 && (<div className="flex items-center justify-between mb-2 px-4 border-2 border-green-500 text-green-500 rounded-full text-inter font-bold mx-auto uppercase">
                  {index === 1 && (<span className='text-center'>🥳 3 MONTHS<br />for the price of 2!</span>)}
                  {index === 2 && (<span className='text-center'>😍 6 MONTHS<br />for the price of 3!</span>)}
                  {index === 3 && (<span className='text-center'>🤯 12 MONTHS<br />for the price of 4!</span>)}
                </div>)}
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-2xl font-bold text-primary text-left">{packageItem.name}</h3>
                  <img
                    src={packageItem.image_url}
                    alt={packageItem.name}
                    className="w-20 h-20 rounded-full object-cover"
                  />
                </div>
                <ul className="space-y-2 mb-6 flex-grow text-left">
                  {packageItem?.features?.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <FontAwesomeIcon icon={faCheck} className="mt-1 mr-2 text-green-500" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="mt-auto">
                  <p className="text-sm text-gray-900 mb-1">One-time payment. No recurring fees.</p>
                  <div className="flex flex-col lg:flex-row gap-2 items-center justify-between mb-4">
                    <div>
                      <p className="text-lg font-semibold text-primary">Special Launch Price:</p>
                      <p className="text-3xl font-bold text-primary">${packageItem.usd_cost}</p>
                      <p className="text-sm text-red-500 line-through">Reg. ${packageItem.usd_cost * 2}</p>
                    </div>
                    <button
                      onClick={() => handlePackageSelection(packageItem)}
                      className="bg-primary hover:bg-green-600 text-white font-bold py-2 px-6 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1"
                    >
                      
                      {isRenewal ? 'Renew Now!' : 'Get Started!'}<FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                    </button>
                  </div>
                </div>
                {index === 2 && (
                  <div className="mt-2 text-center text-lg text-inter font-bold text-gradient-green-blue">
                    Most Popular with {config.graduate_name}s!
                  </div>
                )}
                {index === 0 && (<h2 className="text-lg font-bold mb-2 mt-4 text-inter text-gray-800 mx-auto">🔥 For the Go-Getters</h2>)}
                {index === 1 && (<h2 className="text-lg font-bold mb-2 mt-4 text-inter text-gray-800 mx-auto">🌟 For the Steady Planners</h2>)}
                  {index === 2 && (<h2 className="text-lg font-bold mb-2 mt-4 text-inter text-gray-800 mx-auto">🤓 For the Thorough Learners</h2>)}
                  {index === 3 && (<h2 className="text-lg font-bold mb-2 mt-4 text-inter text-gray-800 mx-auto">💪 For the Serious Preppers</h2>)}
              </div>
            </motion.div>
          ))}
      </div>
      <div className="mt-8 flex justify-center">
        <img
          className="h-16 md:h-24"
          src="https://otp.nyc3.cdn.digitaloceanspaces.com/trustBadges.png"
          alt="Payment options"
        />
      </div>
    </motion.div>
  );
};

export default PricingCards;