import React from 'react';
import SettingsForm from '../components/SettingsForm';

function Settings() {
  return (
    <div>
      <SettingsForm />

    </div>
  );
}

export default Settings;