// VideoCard.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faClock } from '@fortawesome/free-solid-svg-icons';

const VideoCard = ({ video, onClick, inView, delay }) => {
  return (
    <div
      className={`transform transition duration-300 hover:scale-105 hover:shadow-lg text-inter cursor-pointer ${
        inView ? 'animate-fade-in' : 'opacity-0'
      }`}
      style={{ animationDelay: `${delay}ms` }}
      onClick={onClick}
    >
      <div className="relative overflow-hidden rounded-lg shadow-md">
        <img
          src={video.thumbnailUrl}
          alt={video.title}
          className="w-full h-48 object-cover"
          loading="lazy"
        />
        <div className="absolute inset-0 bg-black bg-opacity-0 flex items-center justify-center">
          <FontAwesomeIcon
            icon={faPlay}
            className="text-yellow-500 text-4xl pulse"
          />
        </div>
        {video.duration && (
          <div className="absolute bottom-2 right-2 bg-black bg-opacity-70 text-white px-2 py-1 rounded-md text-sm">
            <FontAwesomeIcon icon={faClock} className="mr-1" />
            {video.duration}
          </div>
        )}
        {video.featured && (
          <div className="absolute top-2 left-2 bg-yellow-400 text-black px-2 py-1 rounded-md text-sm font-bold">
            Featured
          </div>
        )}
        {video.new && (
          <div className="absolute top-2 left-2 bg-green-400 text-black px-2 py-1 rounded-md text-sm font-bold">
            New!
          </div>
        )}
      </div>
      <div className="mt-2 text-center">
        <h3 className="text-lg font-bold text-inter truncate">{video.title}</h3>
        <p className="text-gray-300">{video.description}</p>
      </div>
    </div>
  );
};

export default VideoCard;