import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeToolsModal } from '../features/tools/toolsModalSlice';
import { setCurrentTool, selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { setThreadId } from '../features/threads/threadSlice';

const ToolsModal = () => {
    const isOpen = useSelector((state) => state.toolsModal.toolsModalIsOpen);
    const tools = useSelector((state) => [
        ...state.tools.items.tools,
        ...state.tools.items.assistants
    ].sort((a, b) => a.name.localeCompare(b.name)));
    const dispatch = useDispatch();
    const currentTool = useSelector(selectCurrentTool);

    const [filter, setFilter] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [expertiseFilter, setExpertiseFilter] = useState(null);
    const [expertiseOptions, setExpertiseOptions] = useState([]);

    const closeModal = () => {
        dispatch(closeToolsModal());
    };

    const typeMap = {
        assistant: 'Assistants',
        chatbot: 'Chatbots',
        tool: 'Tools',
        lab: 'Lab'
    };

    const toolTypes = Array.from(new Set(tools.map(tool => tool.type)));

    const filteredTools = tools.filter(tool => {
        return (filter === 'all' || tool.type === filter) &&
               (tool.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                tool.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
               (!expertiseFilter || tool.expertise === expertiseFilter);
    });

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
        if (newFilter !== 'chatbot') {
            setExpertiseFilter(null);
        }
    };

    useEffect(() => {
        if (filter === 'chatbot') {
            const chatbotTools = tools.filter(tool => tool.type === 'chatbot');
            const uniqueExpertises = Array.from(new Set(chatbotTools.map(tool => tool.expertise).filter(Boolean)));
            setExpertiseOptions(uniqueExpertises);
        }
    }, [filter]);

    if (!isOpen) return null;

    return (
        <div className={`fixed inset-0 bg-gray-600 bg-opacity-70 overflow-y-auto w-full`}>
            <div className="relative top-0 md:top-1 mx-auto p-5 lg:pt-3 border w-full md:w-1/2 h-full md:h-full flex flex-col justify-center items-center shadow-lg rounded-md bg-primary">
                <div className='mb-4 text-4xl text-poetsen'>All Tools</div>
                <div className='mb-2'>
                    <input
                        type="text"
                        placeholder="Search tools..."
                        className="px-4 py-2 w-full rounded-md border border-gray-300"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className='flex flex-wrap gap-2 mb-2 justify-center text-poetsen'>
                    {toolTypes.map((type) => (
                        <button
                          key={type}
                          className={`px-4 py-2 text-lg font-medium rounded-md focus:outline-none ${filter === type ? 'bg-secondary text-white' : ''}`}
                          onClick={() => handleFilterChange(type)}>
                            {typeMap[type]}
                        </button>
                    ))}
                </div>
                {filter === 'chatbot' && (
                    <div className='flex flex-wrap gap-2 mb-4 justify-center text-poetsen'>
                        <button 
                        className={`px-4 py-2 font-medium rounded-md focus:outline-none ${!expertiseFilter ? 'bg-secondary text-white' : ''}`}
                        onClick={() => setExpertiseFilter(null)}>
                            All
                        </button>
                        {expertiseOptions.map(expertise => (
                            <button
                            key={expertise}
                            className={`px-4 py-2 font-medium rounded-md capitalize focus:outline-none ${expertiseFilter === expertise ? 'bg-secondary text-white' : ''}`}
                            onClick={() => setExpertiseFilter(expertise)}>
                                {expertise}
                            </button>
                        ))}
                    </div>
                )}
                <div className='overflow-y-auto' style={{height: '65vh'}}>
                    {filteredTools.map((tool) => (
                        <div key={tool.name} onClick={() => {
                            if (currentTool._id !== tool._id) {
                                dispatch(setCurrentTool(tool));
                            dispatch(setThreadId(null));
                            }
                            dispatch(closeToolsModal());
                        }} className='p-2 m-2 bg-body rounded shadow flex flex-col md:flex-row items-center cursor-pointer hover:bg-gray-700'>
                            <div className='flex flex-col w-full md:w-1/5 items-center'>
                                <h3 className='text-lg font-bold text-center mb-2 leading-none text-primary text-poetsen'>{tool.name}</h3>
                                <img src={tool.image_data.example_url} alt={tool.name} className='border border-primary border-2 w-20 md:h-20 object-cover rounded-full mb-2 md:mb-0' />
                            </div>
                            <div className='w-full md:w-4/5 pl-4 bg-secondary rounded p-2 text-white text-sm md:text-base'>
                                <p>{tool.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <button 
                  className='px-4 py-2 mt-8 text-poetsen bg-body border text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500' 
                  onClick={closeModal}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default ToolsModal;
