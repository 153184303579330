import axios from 'axios';
import config from '../config/config';

const apiURL = process.env.REACT_APP_API_URL;
const app_id = config.app_id;

const api = axios.create({
  baseURL: apiURL
});

export const fetchAvailableGamesApi = () => {
  return axios.get(`${apiURL}/livegame/available`);
};

export const joinGameApi = (gameId, userId) => {
  return axios.post(`${apiURL}/livegame/${gameId}/join`, { userId });
};

export const leaveGameApi = (gameId, userId) => {
  return axios.post(`${apiURL}/livegame/${gameId}/leave`, { userId });
};

export const startGameApi = (gameId) => {
  return axios.post(`${apiURL}/livegame/${gameId}/start`);
};

export const submitAnswerApi = (gameId, userId, answer) => {
  return axios.post(`${apiURL}/livegame/${gameId}/answer`, { userId, answer });
};

export const fetchGameStateApi = (gameId) => {
  return axios.get(`${apiURL}/livegame/${gameId}/state`);
};

export const createGameApi = (gameData) => {
  return axios.post(`${apiURL}/livegame/create`, gameData);
};

export const saveGameResultsApi = (gameId) => {
  return axios.post(`${apiURL}/livegame/${gameId}/results`);
};