import React, { useEffect } from 'react';
import { useStytch, useStytchSession } from '@stytch/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../features/user/userSlice';
import { setSession } from '../../../features/session/sessionSlice';
import { authUser } from '../../../api/userFunctions';

const GoogleAuthCallback = () => {
  const stytchClient = useStytch();
  //const { session } = useStytchSession();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleAuth = async () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');

      if (token) {
        try {
          const response = await stytchClient.oauth.authenticate(token, {
            session_duration_minutes: 43830
          });
          
          const userDb = await authUser(response.session_token);
          dispatch(setUser(userDb.data));
          dispatch(setSession({
            stytch_session_token: response.session_token,
          }));
          if (userDb.data.subscription_id === '6451434ac0267c72e2b0ddc9') {
            navigate('/g-purchase');
          } else {
            navigate('/dashboard');
          }

          // Navigate to checkout or appropriate next step
          //navigate('/g-purchase');
        } catch (error) {
          console.error('Authentication error:', error);
          navigate('/loginpurchase');
        }
      } else {
        navigate('/loginpurchase');
      }
    };

    handleAuth();
  }, [stytchClient, navigate, dispatch]);

  return <div>Authenticating...</div>;
};

export default GoogleAuthCallback;