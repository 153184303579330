import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      const element = document.getElementById('main-content');
      if (element) {
        element.scrollTop = 0;
      }
    }, [pathname]);
  
    return null;
  };

export default ScrollToTop;
