import React, { useState } from 'react';
import Quiz from 'react-quiz-component';
import QuizForm from "../newQuiz/QuizForm";
import Timer from './Timer';

const customLocale = {
    landingHeaderText: "<questionLength> Questions",
    question: "Question",
    startQuizBtn: "Start the Quiz",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Next",
    prevQuestionBtn: "Prev",
    resultPageHeaderText: "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions correct.",
    resultPagePoint: "You scored <correctPoints> out of <totalPoints>.",
    singleSelectionTagText: 'Single Selection',
    multipleSelectionTagText: 'Multiple Selection',
    pickNumberOfSelection: 'Pick <numberOfSelection>',
    marksOfQuestion: '**<marks> point(s)**',
  };

// Add this CSS either in a separate file and import it, or use a CSS-in-JS solution
/*const quizStyles = `
  .react-quiz-container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  .react-quiz-container .questionWrapper {
    width: 100%;
    max-width: 100%;
  }
  .react-quiz-container .answerBtn {
    white-space: normal;
    word-wrap: break-word;
    height: auto;
    min-height: 44px;
    padding: 10px 15px;
    font-weight: 400;
    border: 1px solid #969696 !important;
  }
.react-quiz-container .startQuizBtn {
  background-color: #90ee90;
  color: #fff;
    border: 1px solid #969696;
    border-radius: 5px;
    padding: 10px 15px;
}
  .react-quiz-container .explanation {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .react-quiz-container .nextQuestionBtn {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;*/

const QuizComponent = ({ quiz, onComplete }) => {
  const [remainingTime, setRemainingTime] = useState(quiz.timer || null);

  const handleQuizComplete = (obj) => {
    console.log('Quiz results OBJ:', obj);
    /*const formattedResults = {
      ...obj,
      remainingTime,
      userInput: obj.questions.map((q, index) => ({
        isCorrect: q.correctAnswer === String(obj.userInput[index]),
        userAnswer: obj.userInput[index],
        correctAnswer: q.correctAnswer,
        question: q.question,
        explanation: q.explanation,
        category: q.questionCategory,
      }))
    };*/
    onComplete(obj);
  };

  // Transform the quiz data to match the expected format
  const transformedQuiz = {
    ...quiz,
    appLocale: customLocale,
    questions: quiz.questions.map((q, index) => ({
      ...q,
      questionIndex: index,
      answerSelectionType: "single",
      answers: q.answers,
      correctAnswer: String(Number(q.correctAnswer) + 1),
    }))
  };

  return (
    <div className="w-full min-h-70vh">
      <QuizForm
        quiz={transformedQuiz}
        shuffle={true}
        showInstantFeedback={true}
        onComplete={handleQuizComplete}
        customResultPage={() => null}
        appLocale={customLocale}
      />
    </div>
  );
};

export default QuizComponent;