import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
};

const modalComponentSlice = createSlice({
    name: 'modalComponent',
    initialState,
    reducers: {
        openComponentModal: (state) => {
            state.isOpen = true;
        },
        closeComponentModal: (state) => {
            state.isOpen = false;
        },
    },
});

export const { openComponentModal, closeComponentModal } = modalComponentSlice.actions;

export const selectIsComponentModalOpen = (state) => state.modalComponent.isOpen;

export default modalComponentSlice.reducer;
