import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const LearningCurveAreaChart = ({ showInfo }) => {
  const { learningCurve, loading, error } = useSelector((state) => state.userStats);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64 bg-gray-900">
        <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-blue-500" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center bg-gray-900 p-4">Error loading data</div>;
  }

  if (!learningCurve || learningCurve.length === 0) {
    return <div className="text-gray-500 text-center bg-gray-900 p-4">No data available</div>;
  }

  const data = {
    labels: learningCurve.map(entry => new Date(entry.date).toLocaleDateString()),
    datasets: [
      {
        fill: true,
        label: 'Cumulative Correct Answers',
        data: learningCurve.map(entry => entry.cumulativeCorrect),
        borderColor: 'rgb(132, 224, 132)',
        backgroundColor: 'rgba(132, 224, 132, 0.5)',
      },
      {
        fill: true,
        label: 'Cumulative Incorrect Answers',
        data: learningCurve.map(entry => entry.cumulativeIncorrect),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { 
        position: 'top',
        labels: { color: 'white' }
      },
      title: { 
        display: true, 
        text: 'Learning Curve',
        color: 'white',
        font: { size: 16, weight: 'bold' }
      }
    },
    scales: {
      y: {
        stacked: true,
        title: { display: true, text: 'Cumulative Answers', color: 'white' },
        ticks: { color: 'white' }
      },
      x: {
        title: { display: true, text: 'Date', color: 'white' },
        ticks: { color: 'white' }
      }
    }
  };

  return (
    <div className={`bg-gray-900 lg:p-4 rounded-lg shadow-md border-4 border-primary ${!showInfo ? 'h-52 max-h-52 w-full' : 'h-96 max-h-96 w-full lg:h-96 lg:max-h-96 overflow-auto'} flex flex-col items-center`}>
      <Line data={data} options={options} />
      {showInfo && <div className="mt-4 text-white pt-2 px-1 leading-none">
        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
        <span className="text-xs">
          This chart shows your cumulative correct and incorrect answers over time. 
          It helps you visualize your learning progress and identify trends in your performance. 
          A steeper curve for correct answers indicates faster learning and improvement, 
          bringing you closer to exam success.
        </span>
      </div>}
    </div>
  );
};

export default LearningCurveAreaChart;