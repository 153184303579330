// src/features/ui/uiSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    modal: {
      isVisible: false,
      content: null,
      copy: false,
    },
    modalAPI: {
      isVisible: false,
      service: null,
      service_name: null,
      action: null,
      user: null,
    },
    toasts: [],
  },
  reducers: {
    showModal: (state, action) => {
      state.modal = { 
        isVisible: true, 
        content: action.payload.content, 
        copy: action.payload.copy ?? false // Set copy value, default to false if not provided
      };
    },
    hideModal: (state) => {
      state.modal = { isVisible: false, content: null };
    },
    showModalAPI: (state, action) => {
      state.modalAPI = {
        ...state.modalAPI,
        isVisible: true,
        ...action.payload, // Expecting payload to be an object with service, action, and user
      };
    },
    hideModalAPI: (state) => {
      state.modalAPI = {
        ...state.modalAPI,
        isVisible: false,
        service: null,
        service_name: null,
        action: null,
        user: null,
      };
    },
    addToast: (state, action) => {
      state.toasts.push({ id: new Date().getTime(), message: action.payload });
    },
    removeToast: (state, action) => {
      state.toasts = state.toasts.filter(toast => toast.id !== action.payload);
    },
  },
});

export const { showModal, hideModal, showModalAPI, hideModalAPI, addToast, removeToast } = uiSlice.actions;

export default uiSlice.reducer;
