import axios from 'axios';
import config from '../config/config';

const apiURL = process.env.REACT_APP_API_URL;
const app_id = config.app_id;

const api = axios.create({
  baseURL: apiURL
});

export const getLeadMagnets = async () => {
    try {
      const response = await api.get('/leadmagnets');
      return response.data; // Return the actual data instead of the whole response
    } catch (error) {
      console.error('Error fetching leadmagnets:', error);
      throw error;
    }
  };
  
  export const getLeadMagnetById = async (id) => {
    try {
      const response = await api.get(`/leadmagnets/${id}`);
      return response.data; // Return the actual data instead of the whole response
    } catch (error) {
      console.error(`Error fetching leadmagnet with id ${id}:`, error);
      throw error;
    }
  };
  
  export const downloadLeadMagnet = async (id, email, groupId, quizResults=null) => {
    try {
      const response = await api.post('/leadmagnets/download', {
        id,
        email,
        groupId,
        app_id,
        quizResults
      });
      return response.data;
    } catch (error) {
      console.error('Error downloading lead magnet:', error);
      throw error;
    }
  };