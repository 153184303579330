import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import config from '../../../config/config';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';

const ChatSpaceExamples = () => {
    return (<>
    <div className="max-w-screen-xl mx-auto mt-20 lg:mt-32 mb-24 lg:mb-10">
              <h2 className="text-inter font-bold text-4xl font-bold tracking-wide flex justify-center mb-3">
                <p
                  dangerouslySetInnerHTML={{
                    __html: config.landing_page_headline_2,
                  }}
                ></p>
              </h2>
              <div className="text-xl">{config.landing_page_subheadline_2}</div>
            </div>
            <div className="grid lg:grid-cols-2 gap-4 ">
              <div class="flex items-start rounded-xl p-4 shadow-lg border-2 border-primary">
                <div class="lg:ml-4 h-full flex flex-col justify-between">
                  <div className="text-center text-sm italic h-8">
                    Example Interaction
                  </div>
                  <h2 class="font-semibold lg:text-2xl lg:ml-3 text-center">
                    {" "}
                    <span class="bg-primary text-gray-100 mr-2 px-4 py-2 rounded text-inter font-bold">
                      <FontAwesomeIcon
                        icon={faWandMagicSparkles}
                        className="mr-2"
                      />
                      {config.landing_page_example_1_request}
                    </span>
                  </h2>

                  <ReactMarkdown
                    className="markdown-content landing-page text-left"
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {config.landing_page_example_1_response}
                  </ReactMarkdown>
                  <div className="flex flex-wrap justify-center gap-2">
                    {config.landing_page_example_1_next_action_options?.map(
                      (option, index) => (
                        <div key={index} className="flex items-center">
                          <span class="bg-primary text-gray-100 mr-2 px-4 py-1 rounded text-inter font-bold">
                            <FontAwesomeIcon
                              icon={faWandMagicSparkles}
                              className="mr-2"
                            />
                            {option}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div class="flex items-start rounded-xl p-4 shadow-lg border-2 border-primary">
                <div class="lg:ml-4 h-full flex flex-col justify-between">
                  <div className="text-center text-sm italic h-8">
                    Example Interaction
                  </div>
                  <h2 class="font-semibold lg:text-2xl lg:ml-3 text-center">
                    {" "}
                    <span class="bg-primary text-gray-100 mr-2 px-4 py-2 rounded text-inter font-bold">
                      <FontAwesomeIcon
                        icon={faWandMagicSparkles}
                        className="mr-2"
                      />
                      {config.landing_page_example_2_request}
                    </span>
                  </h2>

                  <ReactMarkdown
                    className="markdown-content landing-page text-left"
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {config.landing_page_example_2_response}
                  </ReactMarkdown>
                  <div className="flex flex-wrap justify-center gap-2">
                    {config.landing_page_example_2_next_action_options?.map(
                      (option, index) => (
                        <div key={index} className="flex items-center">
                          <span class="bg-primary text-gray-100 mr-2 px-4 py-1 rounded text-inter font-bold">
                            <FontAwesomeIcon
                              icon={faWandMagicSparkles}
                              className="mr-2"
                            />
                            {option}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
    </>);
  };

export default ChatSpaceExamples;
