import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

const OverlappingImages = ({ images }) => {
  return (
    <div className="flex items-center justify-center">
      {images.map((image, index) => (
        <div
          key={index}
          className="w-10 h-10 rounded-full border-2 border-gray-100 overflow-hidden"
          style={{
            position: "relative",
            zIndex: index,
            marginLeft: index !== 0 ? "-24px" : "0",
          }}
        >
          <img
            src={image}
            alt={`Profile ${index + 1}`}
            className="w-full h-full object-cover"
          />
        </div>
      ))}
      <FontAwesomeIcon
        icon={faCheckCircle}
        className="text-green-500 text-4xl ml-2"
      />
    </div>
  );
};

export default OverlappingImages;
