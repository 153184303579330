// Footer.js
import React from "react";
import config from "../../config/config";

const FooterLandingPage = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-gradient-to-b from-blue-300 to-blue-700 py-4 px-4 text-inter rounded text-center mt-20">
      <div>
        <a
          href={`${config.app_url}`}
          target="_blank"
          className="hover:underline"
          rel="noopener noreferrer"
        >
          <img
            src={config.logoUrl}
            alt={config.siteName}
            className="mx-auto w-24 h-24 border border-gray-100 shadow-xl rounded-full"
          />
        </a>
      </div>
      <h2 className="font-bold text-gray-100 text-inter leading-none py-2 px-4 mt-2">
        Pass your {config.exam_name}. Guaranteed.
      </h2>
      <div>
        <img
          src="https://otp.nyc3.cdn.digitaloceanspaces.com/trustBadges.png"
          alt="Trust Badges"
          className="mx-auto w-96"
        />
      </div>
      <p className="text-sm">
        &copy; {currentYear}{" "}
        <a
          href={`${config.app_url}`}
          target="_blank"
          className="hover:underline"
          rel="noopener noreferrer"
        >
          {config.siteName}
        </a>
        . All rights reserved.
      </p>
      <p className="text-sm">
        <a
          href={`${config.app_url}/privacy-policy`}
          target="_blank"
          className="hover:underline"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        {" | "}
        <a
          href={`${config.app_url}/terms-of-service`}
          target="_blank"
          className="hover:underline"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>
      </p>
    </footer>
  );
};

export default FooterLandingPage;
