import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../features/user/userSlice';
import { Link } from 'react-router-dom';
import { fetchUserStats, fetchCategoryPerformance, fetchProgressOverTime, fetchSubjectAreaPerformance, fetchScoreDistribution, fetchImprovementAreas, fetchLearningCurve } from '../../../features/quiz/userStatsSlice';
import PerformanceRadarChart from './PerformanceRadarChart';
import ProgressLineChart from './ProgressLineChart';
import CorrectVsIncorrectDoughnut from './CorrectVsIncorrectDoughnut';
import SubjectAreaBarChart from './SubjectAreaBarChart';
import ScoreDistributionHistogram from './ScoreDistributionHistogram';
import ImprovementAreasBubbleChart from './ImprovementAreasBubbleChart';
import LearningCurveAreaChart from './LearningCurveAreaChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faChartColumn, faFileCircleQuestion, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const charts = [
  { component: ProgressLineChart, name: 'Progress Over Time' },
  { component: SubjectAreaBarChart, name: 'Subject Area Performance' },
  { component: ScoreDistributionHistogram, name: 'Score Distribution' },
  { component: ImprovementAreasBubbleChart, name: 'Improvement Areas' },
  { component: LearningCurveAreaChart, name: 'Learning Curve' },
  { component: CorrectVsIncorrectDoughnut, name: 'Correct vs Incorrect' },
  { component: PerformanceRadarChart, name: 'Performance Overview' },
];

const UserStatsVisualizations = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { data: userStats, loading, error } = useSelector((state) => state.userStats);
  const [selectedChart, setSelectedChart] = useState(charts[0]);
  const topRef = useRef(null);

  useEffect(() => {
    if (user && user._id && userStats && !error) {
      dispatch(fetchUserStats(user._id));
      dispatch(fetchCategoryPerformance(user._id));
      dispatch(fetchProgressOverTime(user._id));
      dispatch(fetchSubjectAreaPerformance(user._id));
      dispatch(fetchScoreDistribution(user._id));
      dispatch(fetchImprovementAreas(user._id));
      dispatch(fetchLearningCurve(user._id));
    }
  }, [dispatch, user._id]);

  if (!userStats || error) {
    return <div className="border-2 border-primary shadow-md rounded px-8 pt-6 pb-8 mb-4 bg-primary min-h-96 flex flex-col justify-center items-center text-poetsen">
        <h2 className="text-3xl text-poetsen mb-6"><FontAwesomeIcon icon={faChartColumn} className="mr-2" />Your Quiz Performance Analytics</h2>
        No data available, yet. Take some quizzes to start generating statistics.
      </div>;
  }

  const SelectedChartComponent = selectedChart.component;

  return (
    <div ref={topRef} className="container mx-auto pl-1 lg:px-4 pb-4 pt-2 bg-gray-900 text-white">
      <div className="flex flex-col mb-2">
        <div className='w-full text-sm lg:text-base flex items-center justify-between lg:mb-4 mx-1 lg:mx-0'>
            <Link to="/dashboard" className="text-blue-400 hover:text-blue-300">
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />Back to Dashboard
            </Link>
            <Link to="/quiz" className="text-blue-400 hover:text-blue-300">
                Go to Quiz Space<FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </Link>
        </div>
        <h1 className="lg:text-3xl font-bold text-primary mb-0 text-center lg:text-left">
          <FontAwesomeIcon icon={faChartColumn} className="mr-2" />
          Your Quiz Performance Analytics
        </h1>
      </div>

      <div className="bg-gray-900 lg:p-6 pt-0 rounded-lg shadow-lg lg:mb-8 min-h-55vh max-h-55vh lg:min-h-72vh lg:max-h-72vh overflow-auto">
        <h2 className="hidden lg:block lg:text-2xl text-poetsen font-semibold mb-0 text-center">{selectedChart.name}</h2>
        <div className="w-full flex items-center justify-center pt-2 min-h-55vh max-h-55vh">
          <SelectedChartComponent showInfo={true} />
        </div>
      </div>
      <div className='mt-4 lg:mt-10'>
        <p className="text-gray-300 mb-4 text-center text-sm px-2 lg:px-0">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          Click on any chart below to view it in detail. The selected chart will be displayed in the main area above.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {charts.map((chart, index) => {
          const ChartComponent = chart.component;
          return (
            <div 
              key={index}
              className={`h-64 bg-gray-800 p-4 rounded-lg shadow-md cursor-pointer transition-all duration-300 transform hover:scale-105 ${selectedChart === chart ? 'ring-2 ring-blue-500' : ''}`}
              onClick={() => {
                setSelectedChart(chart);
                topRef.current.scrollIntoView({ behavior: 'smooth' });  // Add this line
              }}
            >
              {/*<h3 className="text-lg font-semibold mb-2 text-center">{chart.name}</h3>*/}
              <div className="h-full flex items-center justify-center">
                <ChartComponent showInfo={false} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserStatsVisualizations;