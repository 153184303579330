import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {

  return (
    <div className='min-h-96 p-20 text-inter font-bold flex flex-col'>
      Page Not Found
      <div className='mt-10'>
        <Link to='/' className='bg-primary px-4 py-2 rounded'>Go back to Home</Link>
      </div>
    </div>
  );
};

export default NotFound;