// login.jsx
import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser, selectUser } from '../features/user/userSlice';
import { authUser } from '../api/userFunctions';
import StandaloneAuthForm from '../components/StandaloneAuthForm';
import { StytchLogin } from '@stytch/react';
import { setSession, deleteSession, selectSession } from '../features/session/sessionSlice';
import config from '../config/config';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Footer from './Homepage/components/Footer';
const sitename = config.siteName;
const logo = config.favicon;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const session = useSelector(selectSession);
  useEffect(() => {
    const stytch_session = Cookies.get('stytch_session');
    
    const autoAuthenticate = async () => {
      if (stytch_session && user && session) {
        const user = await authUser(stytch_session);
        if (user?.success) {
          dispatch(setUser(user?.data));
          dispatch(setSession({ stytch_session_token: stytch_session }));
          navigate('/dashboard');
        } else {
          dispatch(setUser(null));
          dispatch(deleteSession());
          Cookies.remove('stytch_session');
          Cookies.remove('stytch_session_jwt');
          //navigate('/login');
        }
      }
    };

    autoAuthenticate();
  }, [dispatch, navigate]);

  return (<>
    <div className='w-full mx-auto px-5 flex flex-col justify-center items-center pb-10'>
        
    <div className="text-sm md:text-base flex justify-center text-body text-inter text-center">
        Success is one step away. 👇
      </div>
      
      <div className='mt-6 lg:mt-16'>
        <StandaloneAuthForm />
      </div>
        
        <p className='text-center mt-6 text-sm'>*We will never sell your data or spam you.</p>

        <button onClick={() => navigate('/')} className='text-sm text-primary mt-6 hover:text-gray-300'><FontAwesomeIcon icon={faArrowLeft} className='mr-2' />Back To Home</button>
    </div>
    <Footer />
    
    
    
    </>);
}

export default Login;