import { useDispatch, useSelector } from 'react-redux';
import { closeModalIFrame, selectModalIFrameState } from '../features/modals/modaliframeSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import IFrameComponent from './IFrameComponent';

const ModalIFrame = () => {
  const { isOpen, url } = useSelector(selectModalIFrameState);
  const dispatch = useDispatch();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="relative p-5 bg-white rounded-lg shadow-lg max-w-lg w-full">
        <div className="absolute top-3 right-3 cursor-pointer" onClick={() => dispatch(closeModalIFrame())}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <IFrameComponent src={url} />
        <button
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => dispatch(closeModalIFrame())}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ModalIFrame;
