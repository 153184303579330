// src/components/PackageModal.js
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPackages } from '../features/packages/packageSlice';
import PurchaseCreditsComponent from '../components/PurchaseCreditsComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import config from '../config/config';

function PackagePage() {
  const packages = useSelector(selectPackages);
  const user = useSelector((state) => state.user.user);

  return (
    <div className={`w-full rounded py-5`}>
      <div className="mx-auto p-5 border w-full lg:w-2/3 xl:w-1/2 shadow-lg rounded-md bg-primary">
        <div className="mt-3 text-center">
          <h3 className="text-2xl leading-6 font-medium text-poetsen">AI Credits Packages</h3>
          <div className='mt-2'>
            <p className="text-sm">AI credits are required to use and interact with all of the AI tools in this app. Subscribe to a monthly plan to enjoy premium responses, rollover credits, and more great benefits. Credit usage is based on the length of messages both from you to a tool, as well as from a tool back to you.</p>
            <p className=""><span className='font-bold text-poetsen'>All purchases are handled securely through Stripe.</span></p>
          </div>
          <div className="mt-2">
            {packages.filter(packageItem => 
            packageItem.type === config.landing_page_package_type && !packageItem.isRenewal).map(packageItem => (
              packageItem._id !== '6451434ac0267c72e2b0ddc9' && (
                <div key={packageItem._id} className="flex flex-col gap-4 md:gap-0 md:flex-row justify-between items-center p-4 border-4 rounded m-2 bg-white hover:border-green-400">
                  <div className='md:pl-3'>
                    <img
                      src={packageItem.image_url}
                      alt="Purchase AI Credits"
                      className={`w-32 h-auto rounded-full`}
                    /> 
                  </div>
                  <div className='flex flex-col gap-2'>
                    <p className="text-2xl mb-2 text-primary leading-none text-poetsen">{packageItem.name}</p>
                    <p className="text-gray-500 leading-none text-poetsen">
                        <FontAwesomeIcon icon={faPlusCircle} className='mr-2 text-green-400' />
                        {packageItem.ai_credits.toLocaleString('en-US')} Monthly Credits
                    </p>
                    <p className="text-gray-500 leading-none text-poetsen">
                        <FontAwesomeIcon icon={faPlusCircle} className='mr-2 text-green-400' />
                        ~{(packageItem.ai_credits * 5).toLocaleString('en-US')} Monthly Words
                    </p>
                    <p className="text-gray-500 leading-none text-poetsen"><FontAwesomeIcon icon={faPlusCircle} className='mr-2 text-green-400' />Premium Responses</p>
                    <p className="text-gray-500 leading-none text-poetsen"><FontAwesomeIcon icon={faPlusCircle} className='mr-2 text-green-400' />Rollover Credits</p>
                  </div>
                  <div className='flex flex-col gap-3'>
                    {user?.subscription_id !== packageItem._id && <PurchaseCreditsComponent packageItem={packageItem} />}
                    {user?.subscription_id === packageItem._id && <p className='text-green-400 text-poetsen text-xl font-bold mr-6'><FontAwesomeIcon icon={faCheckCircle} className='mr-2 text-green-400' />Current Plan</p>}
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagePage;
