const readinessQuizData = [
    {
        "_id" : "668ca543eeb221241c522d1c",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "Which of the following groups of symptoms indicates a ruptured abdominal aortic aneurysm?",
        "questionCategory" : "Physiological Adaptation",
        "answers" : [
            "Lower back pain, increased blood pressure, decreased red blood cell (RBC) count, increased white blood (WBC) count",
            "Severe lower back pain, decreased blood pressure, decreased RBC count, increased WBC count",
            "Severe lower back pain, decreased blood pressure, decreased RBC count, decreased RBC count, decreased WBC count",
            "Intermittent lower back pain, decreased blood pressure, decreased RBC count, increased WBC count"
        ],
        "correctAnswer" : "1",
        "explanation" : "Severe lower back pain indicates an aneurysm rupture, secondary to pressure being applied within the abdominal cavity. When rupture occurs, the pain is constant because it can't be alleviated until the aneurysm is repaired. Blood pressure decreases due to the loss of blood. After the aneurysm ruptures, the vasculature is interrupted and blood volume is lost, so blood pressure wouldn't increase. For the same reason, the RBC count has decreased – not increased. The WBC count increases as cells migrate to the site of injury.",
        "difficulty" : "hard",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'Severe lower back pain, decreased blood pressure, decreased RBC count, increased WBC count'."
    },
    {
        "_id" : "668ca57ceeb221241c522d1f",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "Emily is in danger of respiratory arrest following the administration of a narcotic analgesic. An arterial blood gas value is obtained. Nurse Sarah would expect the paco2 to be which of the following values?",
        "questionCategory" : "Reduction of Risk Potential",
        "answers" : [
            "15 mm Hg",
            "30 mm Hg",
            "40 mm Hg",
            "80 mm Hg"
        ],
        "correctAnswer" : "3",
        "explanation" : "A client about to go into respiratory arrest will have inefficient ventilation and will be retaining carbon dioxide. The value expected would be around 80 mm Hg. All other values are lower than expected.",
        "difficulty" : "hard",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is '80 mm Hg'."
    },
    {
        "_id" : "668ca57ceeb221241c522d20",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "Jacob's arterial blood gas (ABG) results are as follows; pH 7.16; Paco2 80 mm Hg; Pao2 46 mm Hg; HCO3- 24 mEq/L; Sao2 81%. This ABG result represents which of the following conditions?",
        "questionCategory" : "Reduction of Risk Potential",
        "answers" : [
            "Metabolic acidosis",
            "Metabolic alkalosis",
            "Respiratory acidosis",
            "Respiratory alkalosis"
        ],
        "correctAnswer" : "2",
        "explanation" : "Because Paco2 is high at 80 mm Hg and the metabolic measure, HCO3- is normal, the client has respiratory acidosis.",
        "difficulty" : "hard",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'Respiratory acidosis'."
    },
    {
        "_id" : "668ca5aaeeb221241c522d24",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "Tommy is diagnosed with acute lymphoid leukemia (ALL) and beginning chemotherapy. His father states to the nurse that it is hard to see Tommy with no hair. The best response for the nurse is:",
        "questionCategory" : "Psychosocial Integrity",
        "answers" : [
            "Tommy looks very nice wearing a hat",
            "You should not worry about his hair, just be glad that he is alive",
            "Yes, it is upsetting. But try to cover up your feelings when you are with him or else he may be upset",
            "This is only temporary; Tommy will re-grow new hair in 3-6 months but may be different in texture"
        ],
        "correctAnswer" : "3",
        "explanation" : "This is the appropriate response. The nurse should help the father cope with his own feelings regarding the child's disease so as not to affect the child negatively. When the hair grows back, it is still of the same color and texture.",
        "difficulty" : "easy",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'This is only temporary; Tommy will re-grow new hair in 3-6 months but may be different in texture'."
    },
    {
        "_id" : "668ca5aaeeb221241c522d25",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "James who is undergoing chemotherapy for his esophageal cancer is experiencing stomatitis. To promote oral hygiene and comfort, the nurse-in-charge should:",
        "questionCategory" : "Basic Care and Comfort",
        "answers" : [
            "Provide frequent mouthwash with normal saline",
            "Apply viscous Lidocaine to oral ulcers as needed",
            "Use lemon glycerine swabs every 2 hours",
            "Rinse mouth with Hydrogen Peroxide"
        ],
        "correctAnswer" : "1",
        "explanation" : "Stomatitis can cause pain and this can be relieved by applying topical anesthetics such as lidocaine before mouth care.",
        "difficulty" : "intermediate",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'Apply viscous Lidocaine to oral ulcers as needed'."
    },
    {
        "_id" : "668ca5aaeeb221241c522d26",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "During the administration of chemotherapy agents, Nurse Alex observed that the IV site is red and swollen when the IV is touched and John shouts in pain. The first nursing action to take is:",
        "questionCategory" : "Management of Care",
        "answers" : [
            "Notify the physician",
            "Flush the IV line with saline solution",
            "Immediately discontinue the infusion",
            "Apply an ice pack to the site, followed by warm compress"
        ],
        "correctAnswer" : "2",
        "explanation" : "Edema or swelling at the IV site is a sign that the needle has been dislodged and the IV solution is leaking into the tissues causing the edema. The patient feels pain as the nerves are irritated by pressure and the IV solution. The first action of the nurse would be to discontinue the infusion right away to prevent further edema and other complications.",
        "difficulty" : "intermediate",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'Immediately discontinue the infusion'."
    },
    {
        "_id" : "668ca5cbeeb221241c522d2a",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "A 19-year-old female client admitted with heat stroke begins to show signs of disseminated intravascular coagulation (DIC). Which of the following laboratory findings is most consistent with DIC?",
        "questionCategory" : "Reduction of Risk Potential",
        "answers" : [
            "Low platelet count",
            "Elevated fibrinogen levels",
            "Low levels of fibrin degradation products",
            "Reduced prothrombin time"
        ],
        "correctAnswer" : "0",
        "explanation" : "In DIC, platelets and clotting factors are consumed, resulting in microthrombi and excessive bleeding. As clots form, fibrinogen levels decrease and the prothrombin time increases.",
        "difficulty" : "hard",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'Low platelet count'."
    },
    {
        "_id" : "668ca5cbeeb221241c522d2d",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "Danny was diagnosed with acute lymphoid leukemia (ALL). He was discharged from the hospital following his chemotherapy treatments. Which statement of Danny's mother indicated that she understands when she will contact the physician?",
        "questionCategory" : "Health Promotion and Management",
        "answers" : [
            "I should contact the physician if Danny has difficulty in sleeping",
            "I will call my doctor if Danny has persistent vomiting and diarrhea",
            "My physician should be called if Danny is irritable and unhappy",
            "Should Danny have continued hair loss, I need to call the doctor"
        ],
        "correctAnswer" : "1",
        "explanation" : "Persistent (more than 24 hours) vomiting, anorexia, and diarrhea are signs of toxicity and the patient should stop the medication and notify the healthcare provider.",
        "difficulty" : "intermediate",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'I will call my doctor if Danny has persistent vomiting and diarrhea'."
    },
    {
        "_id" : "668ca612eeb221241c522d32",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "The nurse knows that neurologic complications of multiple myeloma (MM) usually involve which of the following body systems?",
        "questionCategory" : "Physiological Adaptation",
        "answers" : [
            "Brain",
            "Muscle spasm",
            "Renal dysfunction",
            "Myocardial irritability"
        ],
        "correctAnswer" : "1",
        "explanation" : "Back pain or paresthesia in the lower extremities may indicate impending spinal cord compression from a spinal tumor. This should be recognized and treated promptly as the progression of the tumor may result in paraplegia.",
        "difficulty" : "hard",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'Muscle spasm'."
    },
    {
        "_id" : "668ca67eeeb221241c522d39",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "Patrick who is diagnosed with liver cirrhosis is experiencing symptoms of hepatic encephalopathy. The physician ordered 50 ml of Lactulose p.o. every 2 hours. Patrick suddenly develops diarrhea. The nurse best action would be:",
        "questionCategory" : "Pharmacological and Parenteral Therapies",
        "answers" : [
            "I'll see if your physician is in the hospital.",
            "Maybe you're reacting to the drug; I will withhold the next dose.",
            "I'll lower the dosage as ordered so the drug causes only 2 to 4 stools a day.",
            "Frequently, bowel movements are needed to reduce sodium level."
        ],
        "correctAnswer" : "2",
        "explanation" : "Lactulose is given to a patient with hepatic encephalopathy to reduce absorption of ammonia in the intestines by binding with ammonia and promoting more frequent bowel movements. If the patient experiences diarrhea, it indicates overdosage and the nurse must reduce the amount of medication given to the patient. The stool will be mushy or soft. Lactulose is also very sweet and may cause cramping and bloating.",
        "difficulty" : "intermediate",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'I'll lower the dosage as ordered so the drug causes only 2 to 4 stools a day.'."
    },
    {
        "_id" : "66833b8331a65951e0e372ae",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "A nurse is caring for a patient who has just undergone internal radiation therapy for cervical cancer. Which of the following instructions is most important for the nurse to provide to the patient?",
        "questionCategory" : "Safety and Infection Control",
        "answers" : [
            "Avoid all physical contact with family members for a month",
            "Limit close contact with others, especially pregnant women and children",
            "Wear a lead apron when in public spaces",
            "Isolate yourself completely for two weeks"
        ],
        "correctAnswer" : "1",
        "explanation" : "After internal radiation therapy, patients may emit low doses of radiation. It's important to limit close contact with others, especially pregnant women and children, until the implants are removed. Complete isolation is not necessary, but precautions should be taken.",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job! Limiting close contact, especially with pregnant women and children, is the most appropriate instruction.",
        "messageForIncorrectAnswer" : "❌ While precautions are necessary, complete isolation or wearing lead aprons in public is not required. Limiting close contact, especially with pregnant women and children, is the key safety measure."
    },
    {
        "_id" : "66903e51d156bf7e0e928718",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "The nurse is planning room assignments for the day. Which client should be assigned to a private room if only one is available?",
        "questionCategory" : "Basic Care and Comfort",
        "answers" : [
            "The client with Addison's disease",
            "The client with type 2 diabetes",
            "The client with gigantism",
            "The client with Cushing's syndrome"
        ],
        "correctAnswer" : "3",
        "explanation" : "The client with Cushing's syndrome has hypercortisolism, which causes immunosuppression. This client is at higher risk for infections and should be placed in a private room to minimize exposure to pathogens.",
        "difficulty" : "intermediate",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'The client with Cushing's syndrome'."
    },
    {
        "_id" : "66903eb0d156bf7e0e92871d",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "Which information should be reported to the state Board of Nursing?",
        "questionCategory" : "Basic Care and Comfort",
        "answers" : [
            "The facility fails to provide literature in both Spanish and English.",
            "The narcotic count has been incorrect on the unit for the past 3 days.",
            "The client fails to receive an itemized account of his bills and services received during his hospital stay.",
            "The nursing assistant assigned to the client with hepatitis fails to feed the client and give the bath."
        ],
        "correctAnswer" : "1",
        "explanation" : "An incorrect narcotic count indicates potential issues with medication management and safety, which must be reported to the state Board of Nursing for further investigation.",
        "difficulty" : "intermediate",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'The narcotic count has been incorrect on the unit for the past 3 days'."
    },
    {
        "_id" : "66903f0bd156bf7e0e928721",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "The nurse is discussing meal planning with the mother of a 2-year-old toddler. Which of the following statements, if made by the mother, would require a need for further instruction?",
        "questionCategory" : "Health Promotion and Management",
        "answers" : [
            "It is okay to give my child white grape juice for breakfast.",
            "My child can have a grilled cheese sandwich for lunch.",
            "We are going on a camping trip this weekend, and I have bought hot dogs to grill for his lunch.",
            "For a snack, my child can have ice cream."
        ],
        "correctAnswer" : "2",
        "explanation" : "Hot dogs pose a risk of aspiration due to their size and shape, which can block a toddler's trachea. It is important to avoid foods that may cause choking.",
        "difficulty" : "intermediate",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'We are going on a camping trip this weekend, and I have bought hot dogs to grill for his lunch.'."
    },
    {
        "_id" : "668c7e3aeeb221241c522c20",
        "app_id" : "6674797b7a3ff16c14f8eaa4",
        "userId" : "667487d31f60c034d5110aa8",
        "questionType" : "text",
        "question" : "It's a busy evening shift, and the hospital has just sounded an alarm for a disaster drill. The unit manager informs the nurse that they need to make room for potential mass casualty admissions. The nurse is given four clients and must decide who to prioritize for discharge to accommodate new admissions. Who should be considered FIRST for discharge?",
        "questionCategory" : "Safety and Infection Control",
        "answers" : [
            "A. An elderly client with chronic obstructive pulmonary disease (COPD) who was admitted for an acute exacerbation three days ago.",
            "B. A middle-aged client with Type 1 diabetes admitted for diabetic ketoacidosis two days ago.",
            "C. A young adult with a recent diagnosis of appendicitis and awaiting surgery.",
            "D. A teenager with a history of asthma admitted for acute bronchitis four days ago."
        ],
        "correctAnswer" : "3",
        "explanation" : "The best candidate for discharge is one who is most likely stable and can continue their care at home. The teenager with a history of asthma admitted for acute bronchitis four days ago is likely to be the most stable and can be safely discharged to make room for new admissions.",
        "difficulty" : "intermediate",
        "point" : "1",
        "messageForCorrectAnswer" : "🥳 Great job!",
        "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'D. A teenager with a history of asthma admitted for acute bronchitis four days ago.'"
    },
]    

export default readinessQuizData;

/*

{
    "_id" : "668c8ea8eeb221241c522c71"),
    "app_id" : "6674797b7a3ff16c14f8eaa4",
    "userId" : "667487d31f60c034d5110aa8",
    "questionType" : "text",
    "question" : "Prolonged occlusion of the right coronary artery produces an infarction in which of the following areas of the heart?",
    "questionCategory" : "Physiological Adaptation",
    "answers" : [
        "Anterior",
        "Apical",
        "Inferior",
        "Lateral"
    ],
    "correctAnswer" : "2",
    "explanation" : "The right coronary artery supplies the right ventricle or the inferior portion of the heart. Therefore, prolonged occlusion could produce an infarction in that area.",
    "difficulty" : "intermediate",
    "point" : "1",
    "messageForCorrectAnswer" : "🥳 Great job!",
    "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'Inferior.'"
}

{
    "_id" : "66903f0bd156bf7e0e928723",
    "app_id" : "6674797b7a3ff16c14f8eaa4",
    "userId" : "667487d31f60c034d5110aa8",
    "questionType" : "text",
    "question" : "Which instruction should be given to the client who is fitted for a behind-the-ear hearing aid?",
    "questionCategory" : "Health Promotion and Management",
    "answers" : [
        "Remove the mold and clean every week.",
        "Store the hearing aid in a warm place.",
        "Clean the lint from the hearing aid with a toothpick.",
        "Change the batteries weekly."
    ],
    "correctAnswer" : "1",
    "explanation" : "The hearing aid should be stored in a warm, dry place to prevent damage. Moisture and heat can harm the electronic components.",
    "difficulty" : "intermediate",
    "point" : "1",
    "messageForCorrectAnswer" : "🥳 Great job!",
    "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'Store the hearing aid in a warm place.'."
}

{
    "_id" : "668ca6c9eeb221241c522d43",
    "app_id" : "6674797b7a3ff16c14f8eaa4",
    "userId" : "667487d31f60c034d5110aa8",
    "questionType" : "text",
    "question" : "Brittany who is undergoing chemotherapy for her throat cancer is experiencing stomatitis. To promote oral hygiene and comfort, the nurse-in-charge should:",
    "questionCategory" : "Basic Care and Comfort",
    "answers" : [
        "Provide frequent mouthwash with normal saline.",
        "Apply viscous Lidocaine to oral ulcers as needed.",
        "Use lemon glycerine swabs every 2 hours.",
        "Rinse mouth with Hydrogen Peroxide."
    ],
    "correctAnswer" : "1",
    "explanation" : "Stomatitis can cause pain and this can be relieved by applying topical anesthetics such as lidocaine before mouth care.",
    "difficulty" : "intermediate",
    "point" : "1",
    "messageForCorrectAnswer" : "🥳 Great job!",
    "messageForIncorrectAnswer" : "❌ That's not quite correct. The correct answer is 'Apply viscous Lidocaine to oral ulcers as needed.'."
}

*/