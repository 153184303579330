import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../features/ui/uiSlice';
import { setUser } from '../features/user/userSlice';
import { setPackageItem } from '../features/packages/packageItemSlice';
import { setPackage } from "../features/packages/selectedPackageSlice";
import config from "../config/config";
import { useUser, useSDK, useLogin, useConnect, Web3Button } from "@thirdweb-dev/react";
import axios from "axios";
import BigNumber from "bignumber.js";
import { useNavigate } from 'react-router-dom';
import { useIsMobileOrTablet, useIsLaptopOrDesktop } from '../utils/useScreenSize';
import { useActiveWallet } from "thirdweb/react";
import ConnectButtonComponent from './ConnectButton';
import ConnectButtonMobileComponent from './ConnectButtonMobile';
import { selectSession } from '../features/session/sessionSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const PurchaseCreditsComponent = ({ packageItem }) => {
  const dispatch = useDispatch();
  const session = useSelector(selectSession);
  const navigate = useNavigate();

const handleSelectPackage = () => {
  dispatch(setPackageItem(packageItem));
  //navigate('/checkout');
};

  if (!packageItem) {
    return <div>Loading packages...</div>;
}

  return (<>
    <div className="flex flex-row items-center justify-center gap-4 text-poetsen">
    {!session ? (<>Login to Purchase
        </>) : (<>
          <button onClick={()=> {dispatch(setPackage(packageItem)); navigate('/checkout');}} className="bg-primary hover:bg-gray-700 hover:text-white py-2 px-4 rounded">
        
        
        <div className="flex items-center">
          <FontAwesomeIcon icon={faPlusCircle} className='mr-2 text-green-400' />
              {session ? (<>
                Subscribe for<br />${packageItem.usd_cost} USD/month
                </>) : (
                <>
                Login to Purchase
                </>
                )}
            </div>
        </button>
        </>)}

      



    </div>
  </>);
}

export default PurchaseCreditsComponent;