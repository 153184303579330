import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../../../features/user/userSlice';
import PricingCards from './pricingCards';
import { CheckoutForm, Return } from '../../../components/StripeCheckout';
import ProgressTracker from './ProgressTracker';
import { motion, AnimatePresence } from 'framer-motion';

const LoggedInPurchaseFlow = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [topMessage, setTopMessage] = useState("💪 Ready to pass your exam?");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const isInitialMount = useRef(true);
  const user = useSelector(selectUser);

  const steps = ['Choose Package', 'Checkout', 'Complete'];

  useEffect(() => {
    if (!user) {
      navigate('/login'); // Redirect to login if no user is found
    }
  }, [user, navigate]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (containerRef.current && currentStep > 0) {
        containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [currentStep]);

  const handlePackageSelection = (packageItem) => {
    setSelectedPackage(packageItem);
    setCurrentStep(1);
    setTopMessage(`🔥 You're one step away from prepping like a pro. Can you feel the success?`);
    localStorage.setItem('selectedPackage', packageItem);
  };

  const handleCheckoutComplete = () => {
    setCurrentStep(2);
    setTopMessage(`🎉 Congratulations! You're all set to ace your exam.`);
  };

  return (
    <div ref={containerRef} className="min-h-screen flex flex-col justify-start py-12 px-4 sm:px-6 lg:px-8">
      <ProgressTracker currentStep={currentStep} steps={steps} />
      
      <div className="text-xs md:text-base flex justify-center text-white mb-8 text-inter text-center">{topMessage}</div>
      
      <AnimatePresence mode="wait">
        {currentStep === 0 && (
          <motion.div
            key="pricing-cards"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-7xl mx-auto"
          >
            <PricingCards onPackageSelect={handlePackageSelection} />
          </motion.div>
        )}

        {currentStep === 1 && (
          <motion.div
            key="checkout"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-4xl mx-auto"
          >
            <CheckoutForm onCheckoutComplete={handleCheckoutComplete} selectedPackage={selectedPackage} />
            <button onClick={() => setCurrentStep(0)} className="block mx-auto mt-4 text-center text-primary font-bold text-sm hover:underline">Go Back</button>
          </motion.div>
        )}

        {currentStep === 2 && (
          <motion.div
            key="return"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-4xl mx-auto"
          >
            <Return />
            <button onClick={() => navigate('/dashboard')} className="block pulse mx-auto mt-6 text-center border-2 border-green-500 text-white px-6 py-2 hover:bg-green-500 rounded-full font-bold">Go To Your Dashboard 🎉</button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LoggedInPurchaseFlow;