// src/components/Toast.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeToast } from '../features/ui/uiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Toast = () => {
  const toasts = useSelector((state) => state.ui.toasts);
  const dispatch = useDispatch();

  useEffect(() => {
    toasts.forEach(toast => {
      setTimeout(() => dispatch(removeToast(toast.id)), 3000);
    });
  }, [toasts, dispatch]);

  return (
    <div className="fixed w-full bottom-0 text-center p-4 space-y-2 z-50">
      {toasts.map((toast) => (
        <div 
          key={toast.id} 
          className="bg-inverted text-inverted p-4 mx-auto rounded border-4 border-primary w-full sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4"
        >
          <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />{toast.message}
        </div>
      ))}
    </div>
  );
};

export default Toast;
