import React from 'react';
import { Link } from 'react-router-dom';
import { stripHtml } from '../../utils/stripHtml';

const BlogList = ({ posts }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {posts.map(post => (
        <Link key={post._id} to={`/post/${post.slug}`} className="bg-gray-800 p-4 rounded block">
          <img src={post.headerImage} alt={post.title} className="w-full h-48 object-cover rounded mb-4" />
          <h2 className="text-2xl text-primary font-header mb-2 text-inter font-bold">{post.title}</h2>
          <p className='text-gray-300'>{stripHtml(post.excerpt || post.content).substring(0, 150)}...</p>
          <div className='flex justify-between items-center'>
            <div className="text-primary mt-2 inline-block text-inter font-bold">Read more</div>
            <div className="text-gray-400 text-sm">{post.author}</div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default BlogList;

