import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTool, selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { close } from '../features/mobile/menuSlice';
import { setThreadId } from '../features/threads/threadSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, } from '@fortawesome/free-solid-svg-icons';
import { faFaceFrown } from '@fortawesome/free-regular-svg-icons';
import config from '../config/config';

const ThreadHistoryCard = () => {
  const dispatch = useDispatch();
  const threads = useSelector(state => state.threadHistory.threads);
  const tools = useSelector((state) => [
    ...(state.tools.items.tools ?? []), // Use optional chaining or default to empty array
    ...(state.tools.items.assistants ?? [])
  ].sort((a, b) => a.name.localeCompare(b.name)));

const truncateContent = (content, maxLength = 100) => {
  if (!content) return 'No content available.';
  return content.length > maxLength ? content.slice(0, maxLength) + '...' : content;
};



  return (
    <div className='w-full'>
    <div className="w-full text-poetsen flex flex-col gap-2 rounded shadow max-h-fit overflow-auto slim-scrollbar">
      <div className="flex items-center justify-center space-x-4 w-full">
        {config.thread_history_chat_space_title}
      </div>
      {/* Navigation Items */}
      <div className="text-primary text-sm flex flex-col gap-2 mt-2 lg:mt-5">
        {threads?.length > 0 ? (threads?.map((thread) => (
            <div key={thread._id} className='flex flex-col gap-1 rounded px-2 cursor-pointer hover:bg-gray-200' onClick={() => {dispatch(setThreadId(thread.oai_threadId)); dispatch(close()); dispatch(setCurrentTool(tools.find(tool => tool.oai_assistantId === thread.oai_assistantId)))}}>
              <div className='flex items-center justify-start'>
                <FontAwesomeIcon icon={faMessage} className='mr-2' />{truncateContent(thread.messages[0].content, 65)}
              </div>
              <div className='flex items-center justify-end text-gray-500'>
                {/* <img src={tools.find(tool => tool.oai_assistantId === thread.oai_assistantId)?.image_data.example_url} alt="Tool" className="w-6 h-6 rounded-full" /> */}
                {new Date(thread.lastUpdated).toLocaleString('en-US')}
                
              </div>
              <hr className='border-primary' />
            </div>
          ))) : (<>
            <div className='text-poetsen text-lg text-center mb-4 flex flex-row justify-center items-center'><FontAwesomeIcon icon={faFaceFrown} className='mr-2 text-2xl' />No recent threads found.</div>
          </>)}
      </div>
    </div>
    </div>
  );
};

export default ThreadHistoryCard;
