import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(ArcElement, Tooltip, Legend);

const CorrectVsIncorrectDoughnut = ({ showInfo }) => {
  const { data: userStats, loading, error } = useSelector((state) => state.userStats);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64 bg-gray-900">
        <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-blue-500" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center bg-gray-900 p-4">Error loading data</div>;
  }

  if (!userStats) {
    return <div className="text-gray-500 text-center bg-gray-900 p-4">No data available</div>;
  }

  const data = {
    labels: ['Correct', 'Incorrect'],
    datasets: [
      {
        data: [userStats.correctAnswers, userStats.incorrectAnswers],
        backgroundColor: [
          'rgba(132, 224, 132, 0.8)',
          'rgba(255, 99, 132, 0.8)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'white', // This makes the legend text white for better visibility on dark background
        },
      },
      title: {
        display: true,
        text: 'Correct vs Incorrect Answers',
        color: 'white', // This makes the title text white
      }
    }
  };

  return (
    <div className={`bg-gray-900 lg:p-4 rounded-lg shadow-md border-4 border-primary ${!showInfo ? 'h-52 max-h-52 w-full' : 'h-96 max-h-96 w-full lg:h-96 lg:max-h-96 overflow-auto'} flex flex-col items-center`}>
        <Doughnut data={data} options={options} />
        {showInfo && <div className="mt-4 text-white pt-2 px-1 leading-none">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
            <span className="text-xs">
                This stacked bar chart illustrates your performance across different subject areas. Each bar 
                represents a subject, with the green portion showing correct answers and the red portion 
                showing incorrect answers. Longer bars indicate more questions attempted in that subject. 
                Use this chart to:
                <ul className="list-disc list-inside mt-2">
                <li>Identify your strongest and weakest subject areas</li>
                <li>Focus your study efforts on subjects with larger red portions</li>
                <li>Ensure you're balancing your practice across all relevant subjects</li>
                </ul>
                Aim to increase the green portion in each bar as you progress in your exam preparation.
            </span>
        </div>}
    </div>
  );
};

export default CorrectVsIncorrectDoughnut;