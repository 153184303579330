import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/user/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faMapMarkerAlt,
  faStore,
  faRightToBracket,
  faTachometerAlt,
  faPaperclip,
  faCommentDots,
  faCloudArrowUp,
  faArrowAltCircleUp,
  faAngleDoubleUp,
  faShop,
  faFileCircleQuestion,
  faThumbTack,
} from "@fortawesome/free-solid-svg-icons";
import ThemeSwitcher from "./ThemeSwitcher";
import HamburgerMenu from "./HamburgerMenu";
import config from "../config/config";
import { fullLogout } from "../api/userFunctions";
import { selectSession, deleteSession } from "../features/session/sessionSlice";
import "../index.css";
import { fetchBoards } from "../features/boards/boardSlice";
import { selectUser } from "../features/user/userSlice";

const siteLogo = config.logoUrl;

const Header = () => {
  const [isTalking, setIsTalking] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useSelector(selectSession);
  const user = useSelector(selectUser);
  const boards = useSelector((state) => state.boards.boards);
  const boardStatus = useSelector((state) => state.boards.status);

  const handleLogout = async () => {
    try {
      // First, clear the local state
      dispatch(logout());
      dispatch(deleteSession());

      // Then, call the backend to invalidate the session
      await fullLogout(session);

      // Finally, navigate to the home page
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Logout error:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  useEffect(() => {
    if (boardStatus === "idle") {
      dispatch(fetchBoards({userId: user._id}));
    }
  }, [boardStatus, dispatch]);

  useEffect(() => {
    const toggleTalking = () => {
      setIsTalking(true);
      setTimeout(() => {
        setIsTalking(false);
      }, 1200);
    };
    toggleTalking();
    const intervalId = setInterval(toggleTalking, 7000);
    return () => clearInterval(intervalId);
  }, []);

  const getDisplayPath = () => {
    if (pathname.startsWith("/boards/")) {
      const boardId = pathname.split("/")[2];
      const board = boards.find((b) => b._id === boardId);
      return board
        ? `${config.boards_title.toUpperCase()} / ${board.name.toUpperCase()}`
        : "BOARDS";
    }
    return pathname.toUpperCase().substring(1) || "CHAT";
  };

  return (
    <>
      <div className="flex xl:justify-between items-center p-4 pt-4 xl:pt-2 pb-2 mb-2 text-primary text-poetsen h-5vh">
        <div className="flex justify-start items-center flex-row text-primary w-2/5 gap-3">
          {session && (
            <div className="mr-2 xl:mr-0">
              <HamburgerMenu />
            </div>
          )}
          {!session && (
            <div onClick={() => navigate("/login")}>
              <FontAwesomeIcon
                icon={faRightToBracket}
                className="text-xl bg-primary text-inverted rounded-full p-3 cursor-pointer"
              />
            </div>
          )}
          <div className="items-center justify-start md:flex-row hidden md:flex">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
            <div className="hidden md:block">{"/ " + getDisplayPath()}</div>
          </div>
        </div>

        <div
          onClick={() => navigate("/dashboard")}
          className="cursor-pointer text-center flex flex-row items-center justify-center w-full xl:justify-center space-x-4 w-1/2 xl:w-1/5"
        >
          <img
            src={config.favicon}
            alt={config.siteName}
            className={`w-8 h-auto border border-primary bg-primary rounded-full ${
              isTalking ? "" : ""
            }`}
          />
          <div className="text-poetsen leading-none lg:text-2xl">
            {config.siteName}
          </div>
        </div>
        <div className="flex flex-col justify-end items-end space-y-2 hidden md:flex w-1/4 md:w-2/5">
          {session && (
            <div className="flex-row items-center space-x-4 justify-end hidden lg:flex">
              <button
                onClick={() => navigate("/dashboard")}
                className="flex items-center hover:text-blue-600"
              >
                <FontAwesomeIcon icon={faTachometerAlt} />
                <span className="ml-2">Dashboard</span>
              </button>
              {/*{config.chat_space && (
                <button
                  onClick={() => navigate("/chat")}
                  className="flex items-center hover:text-gray-500"
                >
                  <FontAwesomeIcon icon={faCommentDots} />
                  <span className="ml-2">
                    {config.chat_space_title || "Chat"}
                  </span>
                </button>
              )}
              {config.quizzes && (
                <Link
                  to="/quiz"
                  alt={`${config.siteName} Quizzes`}
                  className="flex items-center hover:text-gray-500"
                >
                  <FontAwesomeIcon icon={faFileCircleQuestion} />
                  <span className="ml-2">Quiz Space</span>
                </Link>
              )}
              {config.flashcards && (
                <Link
                  to="/flashcards"
                  alt={`${config.siteName} Flashcards`}
                  className="flex items-center hover:text-gray-500"
                >
                  <FontAwesomeIcon icon={faThumbTack} />
                  <span className="ml-2">Flashcards</span>
                </Link>
              )}
              {config.boards && (
                <button
                  onClick={() => navigate("/boards")}
                  className="flex items-center hover:text-gray-500"
                >
                  <FontAwesomeIcon icon={faPaperclip} />
                  <span className="ml-2">
                    {config.boards_title || "Boards"}
                  </span>
                </button>
              )}
              {config.shopUrl && config.shopUrl_title && (
                <Link
                  to={config.shopUrl}
                  alt={config.shopUrl_title}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="flex items-center hover:text-gray-500"
                >
                  <FontAwesomeIcon icon={faShop} />
                  <span className="ml-2">{config.shopUrl_title || "Shop"}</span>
                </Link>
              )}*/}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
