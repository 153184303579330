// src/components/Flashcard/FlashcardCreationForm.js

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../features/user/userSlice';
import { createFlashcard } from '../../features/flashcards/flashcardsSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { addToast } from '../../features/ui/uiSlice';

const FlashcardCreationForm = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [formData, setFormData] = useState({
    question: '',
    answer: '',
    explanation: '',
    category: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createFlashcard({ userId: user?._id, flashcardData: formData }));
    dispatch(addToast(`Flashcard Added!`, 'success'));
    setFormData({ question: '', answer: '', explanation: '', category: '' });
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto mt-8 text-gray-800">
      <h2 className="text-2xl text-poetsen text-primary mb-4"><FontAwesomeIcon icon={faPlusCircle} className='mr-2' />Create Custom Flashcard</h2>
      <div className="mb-4">
        <label htmlFor="question" className="block text-sm font-bold mb-2">
          Question
        </label>
        <input
          type="text"
          id="question"
          name="question"
          value={formData.question}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="answer" className="block text-sm font-bold mb-2">
          Answer
        </label>
        <input
          type="text"
          id="answer"
          name="answer"
          value={formData.answer}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="explanation" className="block text-sm font-bold mb-2">
          Explanation
        </label>
        <textarea
          id="explanation"
          name="explanation"
          value={formData.explanation}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          rows="3"
        ></textarea>
      </div>
      <div className="mb-4">
        <label htmlFor="category" className="block text-sm font-bold mb-2">
          Category
        </label>
        <input
          type="text"
          id="category"
          name="category"
          value={formData.category}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <button
        type="submit"
        className="transition duration-300 bg-primary hover:bg-gray-700 hover:text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-6"
      >
        <FontAwesomeIcon icon={faPlusCircle} className='mr-2' />Create Flashcard
      </button>
    </form>
  );
};

export default FlashcardCreationForm;