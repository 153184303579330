import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faMedal, faFire, faBolt } from '@fortawesome/free-solid-svg-icons';

const streakBadgeMap = {
  1: 'https://otp.nyc3.cdn.digitaloceanspaces.com/badgeStreak1.png',
  2: 'https://otp.nyc3.cdn.digitaloceanspaces.com/badgeStreak2.png',
  5: 'https://otp.nyc3.cdn.digitaloceanspaces.com/badgeStreak5.png',
  10: 'https://otp.nyc3.cdn.digitaloceanspaces.com/badgeStreak10.png',
  25: 'https://otp.nyc3.cdn.digitaloceanspaces.com/badgeStreak25.png',
  50: 'https://otp.nyc3.cdn.digitaloceanspaces.com/badgeStreak50.png',
};

const FlashcardGameElement = () => {
  const userStats = useSelector(state => state.userStats);

  if (!userStats.xp) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl text-inter font-bold text-gray-800 mb-4">
          <FontAwesomeIcon icon={faChartLine} className="mr-2 text-blue-500" />
          Your Progress
        </h2>
        <p className="text-gray-600 text-inter">No progress yet! Work through your flashcards to earn progress points.</p>
      </div>
    );
  }

  const calculateLevel = (xp) => Math.floor(Math.sqrt(xp / 100)) + 1;
  const currentLevel = calculateLevel(userStats.xp);
  const nextLevelXP = (currentLevel + 1) ** 2 * 100;
  const xpProgress = ((userStats.xp - (currentLevel - 1) ** 2 * 100) / (nextLevelXP - (currentLevel - 1) ** 2 * 100)) * 100;

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl text-inter font-bold text-gray-800">
          <FontAwesomeIcon icon={faChartLine} className="mr-2 text-blue-500" />
          Your Progress
        </h2>
        <span className="bg-blue-500 text-white px-4 py-2 rounded-full font-bold text-inter">
          <FontAwesomeIcon icon={faMedal} className="mr-2" />
          Level {currentLevel}
        </span>
      </div>
      <div className="mb-6">
        <div className="flex justify-between text-sm text-gray-600 mb-2 text-inter">
          <span>XP: {userStats.xp}</span>
          <span>Next Level: {nextLevelXP}</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-4">
          <div
            className="bg-blue-500 h-4 rounded-full transition-all duration-500 ease-out"
            style={{ width: `${xpProgress}%` }}
          ></div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faFire} className="text-orange-500 mr-2 text-2xl" />
          <div>
            <p className="text-gray-600 text-inter">Streak</p>
            <p className="text-2xl font-bold text-gray-800 text-inter">{userStats.data.streak} days</p>
          </div>
        </div>
        <img
          src={streakBadgeMap[userStats.data.streak] || streakBadgeMap[50]}
          alt={`Streak Badge ${userStats.data.streak}`}
          className="h-16 w-16 rounded-full"
        />
      </div>
    </div>
  );
};

export default FlashcardGameElement;