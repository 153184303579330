import React from 'react';
import { useSelector } from 'react-redux';
import Chat from '../components/Chat';
import ImageChat from '../components/ImageChat';
import ChatSidebar from '../components/ChatSidebar';
import CharacterSidebar from '../components/CharacterSidebar';
import { selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { setThreadId, updateThreadId, deleteThreadId } from '../features/threads/threadSlice';

function Workspace() {
    const currentTool = useSelector(selectCurrentTool);
    const currentThreadId = useSelector((state) => state.thread.currentThreadId);


    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row'}}>
            <div className="w-3/5 pr-10">
                {currentTool.type === 'image' ? <ImageChat /> : <Chat />}
            </div>
            <div className="w-2/5">
                <ChatSidebar />
            </div>
        </div>
    );
}

export default Workspace;
