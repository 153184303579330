import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectSession, deleteSession } from '../features/session/sessionSlice';
import { selectUser, logout } from '../features/user/userSlice';
import config from '../config/config';

const RequireAuth = ({ children, fallback }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const session = useSelector(selectSession);
    const user = useSelector(selectUser);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkAuth = () => {
            if (session && user) {
                setIsAuthenticated(true);
                
                if (config.app_auth_type === 'package_required') {
                    const currentDate = new Date();
                    const subscriptionEndDate = new Date(user.subscription_end_date);

                    if (user.subscription_id === '6451434ac0267c72e2b0ddc9' && location.pathname !== '/complete-purchase') {
                        navigate('/complete-purchase');
                        return;
                    }

                    if (user.subscription_id !== '6451434ac0267c72e2b0ddc9' && 
                        (!subscriptionEndDate || subscriptionEndDate < currentDate) && 
                        location.pathname !== '/renew') {
                        navigate('/renew');
                        return;
                    }
                }
            } else {
                setIsAuthenticated(false);
                if (location.pathname !== '/' && location.pathname !== '/login') {
                    navigate('/', { replace: true });
                }
            }
            setIsLoading(false);
        };

        checkAuth();
    }, [session, user, navigate, location.pathname, dispatch]);

    if (isLoading) return <div>Loading...</div>;
    return isAuthenticated ? children : fallback || null;
};

export default RequireAuth;