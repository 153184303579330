import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './app/store';
import App from './App';
import Modal from './components/Modal';
import Toast from './components/Toast';
import ModalUserPrompts from './components/ModalUserPrompts';
import ModalIFrame from './components/ModalIFrame';
import ModalNewsletter from './components/ModalNewsletter';
import PackageModal from './components/PackageModal';
import ModalComponent from './components/ModalComponent';
import ModalJSON from './components/ModalJSON';
import config from './config/config';
import { QueryClient, QueryClientProvider } from 'react-query';
import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';
//import ToolsModal from './components/ToolsModal';
//import { ThirdwebProvider } from "thirdweb/react";
//import { ThirdwebProvider, ThirdwebSDKProvider } from "@thirdweb-dev/react";
//import { Base, Ethereum, Polygon, Binance } from "@thirdweb-dev/chains";
//import { createThirdwebClient } from "thirdweb";
//import { useIsMobileOrTablet, useIsLaptopOrDesktop } from './utils/useScreenSize';
//import { useActiveWallet } from 'thirdweb/react';

const stytch_public_token = process.env.REACT_APP_STYTCH_PUBLIC_TOKEN;

const stytchClient = new StytchUIClient(stytch_public_token);
//public-token-live-b7c04a5d-12b5-4e2a-a4ad-c93e4d0a1953
//public-token-test-87fd74cf-f520-4946-a3cd-32f67dde94aa


const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StytchProvider stytch={stytchClient}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
        <Modal />
        <ModalIFrame />
        <ModalUserPrompts />
        <ModalNewsletter />
        <PackageModal />
        <ModalComponent />
        <ModalJSON />
        <Toast />
      </Provider>
    </QueryClientProvider>
    </StytchProvider>
  </React.StrictMode>
);





/*function AuthUrlProvider({ children }) {
const apiURL = process.env.REACT_APP_API_URL;
  const userWalletMobile = useActiveWallet();
  const userAddressMobile = userWalletMobile?.getAccount()?.address;
  const isLaptopOrDesktop = useIsLaptopOrDesktop();
  const authUrl = isLaptopOrDesktop ? `${apiURL}/crypto/auth` : `${apiURL}/crypto/get-user/${userAddressMobile}`;

  return (
    <ThirdwebProvider clientId="841cd7c05f6572ec017b34f5ce3b0920" dAppMeta={{
      name: "Andy's Place",
      description: "A place to celebrate ANDY with AI tools, merch, and fun.",
      image: "https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AndyBaseAvatar.png",
      url: "https://andys.place",
      isDarkMode: true,
      }} activeChain={Base} supportedChains={[Base, Ethereum, Polygon]}>
      {children}
    </ThirdwebProvider>
  );
}*/
//authConfig={{authUrl: `${authUrl}`, domain: 'app.andys.place'}}
/*const client = createThirdwebClient({
  clientId: "841cd7c05f6572ec017b34f5ce3b0920",
});*/


    /*<AuthUrlProvider>*/
    /*</AuthUrlProvider>*/