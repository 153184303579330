import { createSlice } from '@reduxjs/toolkit';

const toolsModalSlice = createSlice({
  name: 'toolsModal',
  initialState : {
    toolsModalIsOpen: false
  },
  reducers: {
    openToolsModal(state) {
      state.toolsModalIsOpen = true;
    },
    closeToolsModal(state) {
      state.toolsModalIsOpen = false;
    }
  }
});

export const { openToolsModal, closeToolsModal } = toolsModalSlice.actions;
export default toolsModalSlice.reducer;