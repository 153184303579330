import React from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoltLightning, faGraduationCap, faRobot, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';

const PersonalAITutorSection = () => {
  const navigate = useNavigate();
    return (<>
    <div className="border-2 border-primary shadow-xl rounded-xl p-10 max-w-8xl mx-auto bg-gray-800 text-gray-100 mt-10 flex flex-col lg:flex-row lg:justify-center">
              <div className="hidden lg:flex justify-center items-center px-10">
                <img
                  className="lg:hidden w-48 mx-auto rounded-full border-2"
                  src={config.favicon}
                  alt={config.siteName}
                />
                <div className={`order-2 lg:order-2`}>
                  <img
                    src={config.landing_page_hero_graphic_mobile}
                    alt={`Your personal exam tutor with ${config.siteTitle}`}
                    className="hidden lg:block h-100 max-h-100 md:max-w-sm border border-2 border-gray-400 shadow-xl rounded-xl mx-auto p-1 "
                    style={{ pointerEvents: "none" }}
                  />
                </div>
              </div>
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faGraduationCap}
                  className="bg-secondary text-5xl mx-auto mb-4 border-2 border-primary p-3 py-3.5 rounded-full"
                />
                <h2 className="text-lg lg:text-3xl tracking-wide text-inter font-bold flex justify-center mb-4">
                  {config.landing_page_headline_3}
                </h2>
                <div className="flex flex-row gap-4 justify-center items-center">
                  <FontAwesomeIcon
                    icon={faBoltLightning}
                    className="text-secondary text-xl mb-4 border-2 border-primary p-4 py-3.5 rounded-full"
                  />
                  <FontAwesomeIcon
                    icon={faWandMagicSparkles}
                    className="text-secondary text-xl mb-4 border-2 border-primary p-3 py-3.5 rounded-full"
                  />
                  <FontAwesomeIcon
                    icon={faRobot}
                    className="text-secondary text-xl mb-4 border-2 border-primary p-2.5 py-3.5 rounded-full"
                  />
                </div>
                <div className="text-left lg:text-2xl my-4 mx-auto w-full lg:w-2/3">
                  <ul className="list-disc list-inside space-y-1">
                    {config.landing_page_headline_3_features.map(
                      (feature, index) => (
                        <li key={index}>{feature}</li>
                      )
                    )}
                  </ul>
                </div>
                <button
                  onClick={() => navigate("/loginpurchase")}
                  className="mt-6 py-2 md:px-6 px-2 text-inter font-bold border-gray-200 hover:bg-gray-700 hover:text-gray-100 bg-white focus:ring-white-500 focus:ring-offset-white-200 text-primary transition ease-in duration-200 text-center text-2xl shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                >
                  👉 {config.landing_page_hero_button_text}
                </button>
              </div>
            </div>
    </>);
  };

export default PersonalAITutorSection;
