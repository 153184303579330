import React from 'react';

const ProgressTracker = ({ currentStep, steps }) => {
  return (
    <div className="w-full max-w-4xl mx-auto mb-4 px-4 text-inter">
      <div className="relative">
        <div className="absolute top-4 left-0 w-full h-1 bg-gray-200">
          <div 
            className="h-full bg-green-400 transition-all duration-300"
            style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
          ></div>
        </div>
        
        <ol className="relative z-10 flex justify-between w-full">
          {steps.map((step, index) => (
            <li key={index} className="flex flex-col items-center">
              <div className={`flex items-center justify-center w-8 h-8 rounded-full text-xs font-bold ${
                currentStep > index
                  ? 'bg-green-400 text-white'
                  : currentStep === index
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-600'
              }`}>
                {currentStep > index ? '✓' : index + 1}
              </div>
              <span className="mt-2 text-[10px] sm:text-xs text-center text-gray-100 max-w-[60px]">
                {step}
              </span>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default ProgressTracker;