import React, { useEffect, useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../features/user/userSlice';
import config from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCircleQuestion, faCommentDots, faThumbTack, faChartBar, faUser, faCog, faBars, faTimes, faPaperclip, faStopwatch, faClock, faBook } from '@fortawesome/free-solid-svg-icons';
import { fetchThreadHistory, setThreadHistory } from '../features/threads/threadHistorySlice';
import DashboardSummary from '../components/quiz/charts/DashboardSummary';
import FlashcardStack from '../components/flashcards/FlashcardStack';
import DashboardThreadHistoryCard from '../components/DashboardThreadHistoryCard';
import DashboardProfilesCard from '../components/DashboardProfilesCard';
import DashAddBoardItem from '../components/DashAddBoardItem';
import CurrentSubscriptionCard from '../components/CurrentSubscriptionCard';

function Dashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const [isNavOpen, setIsNavOpen] = useState(false);

    const fetchThreads = useCallback(() => {
        if (user?._id) {
            dispatch(fetchThreadHistory(user?._id)).then((response) => {
                if (response.type === 'threadHistory/fetchThreadHistory/fulfilled') {
                    dispatch(setThreadHistory(response.payload));
                }
            });
        }
    }, [dispatch, user?._id]);

    useEffect(() => {
        fetchThreads();
    }, [fetchThreads]);

    const navItems = [
        { name: 'Quiz Space', icon: faFileCircleQuestion, link: '/quiz', enabled: config.quizzes },
        { name: 'Chat Space', icon: faCommentDots, link: '/chat', enabled: config.chat_space },
        { name: 'Flashcards Space', icon: faThumbTack, link: '/flashcards', enabled: config.flashcards },
        { name: 'Quiz Analytics', icon: faChartBar, link: '/performance-analytics', enabled: config.quizzes },
        { name: config.boards_title, icon: faPaperclip, link: '/boards', enabled: config.boards },
        { name: 'Bookshelf', icon: faBook, link: '/bookshelf', enabled: config.bookshelf },
    ].filter(item => item.enabled);

    const toggleNav = () => setIsNavOpen(!isNavOpen);

    return (
        <div className="bg-body min-h-screen text-gray-800">
            <header className="bg-primary text-gray-100 shadow-md">
                <div className="container mx-auto px-4 py-1 flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                        <img src={user?.pic} alt="NCLEX Study Pro" className="w-10 h-10 rounded-full" />
                        <h1 className="text-xl md:text-2xl text-inter font-bold block">Dashboard</h1>
                    </div>
                    <div className="flex items-center space-x-4">
                        <span className="hidden sm:inline">{user?.name || user?.username}</span>
                        <Link to="/settings" className="hover:text-blue-200">
                            <FontAwesomeIcon icon={faCog} />
                        </Link>
                        <button onClick={toggleNav} className="sm:hidden">
                            <FontAwesomeIcon icon={isNavOpen ? faTimes : faBars} />
                        </button>
                    </div>
                </div>
            </header>

            <nav className={`bg-white max-w-7xl rounded-b mx-auto shadow-md text-inter font-semibold transition-all duration-300 ease-in-out ${isNavOpen ? 'max-h-screen' : 'max-h-0 sm:max-h-screen'} overflow-hidden sm:overflow-visible`}>
                <div className="container mx-auto px-4 py-2 lg:py-0">
                    <ul className="flex flex-col sm:flex-row sm:justify-center sm:space-x-4 space-y-2 sm:space-y-0">
                        {navItems.map((item, index) => (
                            <li key={index}>
                                <Link 
                                    to={item.link} 
                                    className="flex items-center px-3 py-2 rounded-md hover:bg-blue-100 transition-colors"
                                    onClick={() => setIsNavOpen(false)}
                                >
                                    <FontAwesomeIcon icon={item.icon} className="mr-2" />
                                    <span>{item.name}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>

            <main className="container bg-body mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {config.quizzes && (
                        <div className="bg-white rounded-lg shadow-md p-2 xl:p-6">
                            <h2 className="text-xl text-inter font-bold mb-2"><FontAwesomeIcon icon={faChartBar} className="mr-2 text-blue-500" />Performance Summary</h2>
                            <DashboardSummary />
                        </div>
                    )}
                    {config.flashcards && (
                        <div className="bg-white rounded-lg shadow-md p-2 xl:p-6">
                            <h2 className="text-xl text-inter font-bold mb-2"><FontAwesomeIcon icon={faThumbTack} className="mr-2 text-blue-500" />Flashcards</h2>
                            <FlashcardStack />
                        </div>
                    )}
                    {config.recent_threads_dash && (
                        <div className="bg-white rounded-lg shadow-md p-2 xl:p-6">
                            <h2 className="text-xl text-inter font-bold mb-2"><FontAwesomeIcon icon={faCommentDots} className="mr-2 text-blue-500" />Recent Threads</h2>
                            <DashboardThreadHistoryCard />
                        </div>
                    )}
                    {config.profiles && config.profile_dash && (
                        <div className="bg-white rounded-lg shadow-md p-2 xl:p-6">
                            <h2 className="text-xl text-inter font-bold mb-2">Profiles</h2>
                            <DashboardProfilesCard />
                        </div>
                    )}
                    {config.add_board_item_dash && (
                        <div className="bg-white rounded-lg shadow-md p-2 xl:p-6">
                            <h2 className="text-xl text-inter font-bold mb-2"><FontAwesomeIcon icon={faPaperclip} className="mr-2 text-blue-500" />Add New {config.boards_title_singular} Item</h2>
                            <DashAddBoardItem />
                        </div>
                    )}
                    <div className="bg-white rounded-lg shadow-md p-2 xl:p-6">
                        <h2 className="text-xl text-inter font-bold mb-2"><FontAwesomeIcon icon={faClock} className="mr-2 text-blue-500" />Current Subscription</h2>
                        <CurrentSubscriptionCard />
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Dashboard;