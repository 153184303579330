import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/user/userSlice';
import { selectPackages } from '../features/packages/packageSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const CurrentSubscriptionCard = () => {
  const packages = useSelector(selectPackages);
  const user = useSelector(selectUser);
  const [currentPackage, setCurrentPackage] = useState(null);
  const navigate = useNavigate();

  const getRemainingTime = (endDate) => {
    const now = moment();
    const end = moment(endDate);
    const duration = moment.duration(end.diff(now));
    const months = duration.months();
    const days = duration.days();
    return { months, days };
  };

  const { months, days } = getRemainingTime(user?.subscription_end_date);

  useEffect(() => {
    if (packages.length > 0) {
      const currentPackage = packages.find(
        (packagex) => packagex._id === user?.subscription_id
      );
      if (currentPackage) {
        setCurrentPackage(currentPackage);
      }
    }
  }, [packages, user?.subscription_id]);

  return (
    <div className="bg-blue-500 rounded-lg shadow-lg p-6 text-white min-h-96">
      <div className='flex items-center justify-between mb-4'>
      <h2 className="text-2xl font-bold text-inter">Current Plan</h2>
      <div className="bg-red-500 rounded-full w-20 h-20 flex items-center justify-center">
          <div className="text-center text-inter">
            <div className="text-2xl font-bold">12</div>
            <div className="text-xs">MONTHS</div>
          </div>
        </div>
      </div>
      
      <div className="bg-blue-400 rounded-lg p-3 mb-4">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faArrowRight} className="mr-2" />
          <span className="text-lg font-semibold">{currentPackage?.name || '12-Month All-Access Plan'}</span>
        </div>
      </div>
      
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-1">Subscription Access Remaining:</h3>
        <div className="flex items-center text-xl">
          <FontAwesomeIcon icon={faClock} className="mr-2 text-green-300" />
          <span>{months} months and {days} days</span>
        </div>
      </div>
    </div>
  );
};

export default CurrentSubscriptionCard;