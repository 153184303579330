import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import ScrollToTop from './components/ScrollToTop';
import Layout from './components/layout';
//import Arcade from './components/arcade/components/Home';
import Blog from './pages/Blog';
import Board from './components/Board';
import BoardItem from './components/BoardItem';
import BookshelfPage from './pages/BookshelfPage';
import CompletePurchasePage from './pages/CompletePurchasePage';
import Dashboard from './pages/Dashboard';
//import DosageCalculatorPage from './pages/DosageCalculatorPage';
//import FlashCards from './components/arcade/games/FlashCards';
import FlashcardPage from './components/flashcards/FlashcardPage';
//import MemoryMatch from './components/arcade/games/MemoryMatch';
import Home from './pages/Home';
import HomePage from './pages/Homepage';
import HomepageNew from './pages/Homepage/pages/homepage_old_version';
import GameBrowser from './components/livegames/GameBrowser';
import GameCreation from './components/livegames/GameCreation';
import GetYourGuide from './pages/GetYourGuide';
import GoogleAuthCallback from './pages/Homepage/components/GoogleAuthCallback';
import GooglePurchaseFlow from './pages/Homepage/components/GooglePurchaseFlow';
import LandingPage from './pages/Landingpage/LandingPage';
import LeadMagnetQuizPage from './components/newQuiz/LeadMagnetQuiz/LeadMagnetQuizPage';
import LiveGame from './components/livegames/LiveGame';
import Login from './pages/Login';
import LoginOld from './pages/Login copy';
import LoginPurchasePage from './pages/LoginPurchasePage';
import LoginPurchasePageCopy from './pages/LoginPurchasePage copy';
import LoggingIn from './pages/LoggingIn';
import LoggingInPurchase from './pages/LoggingInPurchase';
//import NewQuizPage from './components/newQuiz/QuizPage';
import NotFound from './components/NotFound';
import PackagePage from './pages/PackagePage';
import Post from './pages/Post';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import Profile from './pages/Profile';
import PurchasePage from './pages/PurchasePage';
//import QuizGame from './components/arcade/games/QuizGame';
import QuizPage from './components/quiz/QuizPage';
import RenewPage from './pages/RenewPage';
//import ScoreBoard from './components/arcade/components/ScoreBoard';
import Settings from './pages/Settings';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import UserStatsVisualizations from './components/quiz/charts/UserStatsVisualizations';
import Workspace from './pages/Workspace';
import { CheckoutForm } from './components/StripeCheckout';
import { Return } from './components/StripeCheckout';

const AppRouter = () => (
  <Router>
    <ScrollToTop />
    <Routes>
      
      {/* Routes without Layout */}
      <Route path="/" element={<HomePage />} />
      <Route path="/homenew" element={<HomepageNew />} />
      <Route path="/readinessquiz" element={<LeadMagnetQuizPage />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/checkout" element={<CheckoutForm />} />
      <Route path="/complete-purchase" element={<CompletePurchasePage />} />
      {/*<Route path="/dosage-calculator" element={<DosageCalculatorPage />} />*/}
      <Route path="/get-your-guide/:id" element={<GetYourGuide />} />
      <Route path="/google-auth-callback" element={<GoogleAuthCallback />} />
      <Route path="/g-purchase" element={<GooglePurchaseFlow />} />
      <Route path="/landing/:id" element={<LandingPage />} />
      <Route path="/specialoffer" element={<LandingPage />} />
      <Route path="/specialoffer/:id" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/loginpurchase" element={<LoginPurchasePage />} />
      <Route path="/loginpurchasenew" element={<LoginPurchasePageCopy />} />
      <Route path="/loggingin" element={<LoggingIn />} />
      <Route path="/logginginpurchase" element={<LoggingInPurchase />} />
      <Route path="/post/:slug" element={<Post />} /> 
      <Route path="/purchase" element={<PurchasePage />} />
      <Route path="/renew" element={<RenewPage />} />
      <Route path="/return" element={<Return />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
      

      {/* New Routes 
        <Route path="/arcade" element={<RequireAuth><Layout><Arcade /></Layout></RequireAuth>} />
        <Route path="/memory-match" element={<RequireAuth><Layout><MemoryMatch /></Layout></RequireAuth>} />
        <Route path="/flash-cards" element={<RequireAuth><Layout><FlashCards /></Layout></RequireAuth>} />
        <Route path="/quiz-game" element={<RequireAuth><Layout><QuizGame /></Layout></RequireAuth>} />
        <Route path="/score-board" element={<RequireAuth><Layout><ScoreBoard /></Layout></RequireAuth>} />

        <Route path="/new-quiz" element={<RequireAuth><Layout><NewQuizPage /></Layout></RequireAuth>} />*/}
        

      {/* Routes with Layout */}
      <Route path="/chat" element={<RequireAuth><Layout><Home /></Layout></RequireAuth>} />
      <Route path="/boards" element={<RequireAuth><Layout><Board /></Layout></RequireAuth>} />
      <Route path="/boards/:id" element={<RequireAuth><Layout><BoardItem /></Layout></RequireAuth>} />
      <Route path="/bookshelf" element={<RequireAuth><Layout><BookshelfPage /></Layout></RequireAuth>} />
      <Route path="/dashboard" element={<RequireAuth><Layout><Dashboard /></Layout></RequireAuth>} />
      <Route path="/flashcards" element={<RequireAuth><Layout><FlashcardPage /></Layout></RequireAuth>} />
      {/*<Route path="/live-games" element={<RequireAuth><Layout><GameBrowser /></Layout></RequireAuth>} />
      <Route path="/live-games/create" element={<RequireAuth><Layout><GameCreation /></Layout></RequireAuth>} />
      <Route path="/live-games/:gameId" element={<RequireAuth><Layout><LiveGame /></Layout></RequireAuth>} />*/}
      <Route path="/packages" element={<RequireAuth><Layout><PackagePage /></Layout></RequireAuth>} />
      <Route path="/performance-analytics" element={<RequireAuth><Layout><UserStatsVisualizations /></Layout></RequireAuth>} />
      <Route path="/profile" element={<RequireAuth><Layout><Profile /></Layout></RequireAuth>} />
      <Route path="/quiz" element={<RequireAuth><Layout><QuizPage /></Layout></RequireAuth>} />
      {/*<Route path="/renew" element={<RequireAuth><Layout><RenewPage /></Layout></RequireAuth>} />*/}
      <Route path="/settings" element={<RequireAuth><Layout><Settings /></Layout></RequireAuth>} />
      <Route path="/workspace" element={<RequireAuth><Layout><Workspace /></Layout></RequireAuth>} />
      {/*<Route path="/checkout" element={<RequireAuth><Layout><CheckoutForm /></Layout></RequireAuth>} />
      <Route path="/return" element={<RequireAuth><Layout><Return /></Layout></RequireAuth>} />*/}

      <Route path="*" element={<Layout><NotFound /></Layout>} />
    </Routes>
  </Router>
);

export default AppRouter;
