// src/components/Flashcard/FlashcardStack.js

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../features/user/userSlice';
import { useNavigate } from 'react-router-dom';
import FlashcardItem from './FlashcardItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faExternalLinkAlt, faThumbTack } from '@fortawesome/free-solid-svg-icons';
import {
  fetchFlashcards,
  selectAllFlashcards,
  selectFlashcardsStatus,
} from "../../features/flashcards/flashcardsSlice";

const FlashcardStack = () => {
  const dispatch = useDispatch();
  const flashcards = useSelector(selectAllFlashcards);
  const user = useSelector(selectUser);
  const userId = user ? user._id : null;
  const status = useSelector(selectFlashcardsStatus);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (status !== "loading") {
      dispatch(fetchFlashcards({ userId }));
    }
  }, [userId]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % flashcards.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + flashcards.length) % flashcards.length);
  };

  if (flashcards.length === 0) {
    return (
      <div className="text-center text-gray-600">
        <FontAwesomeIcon icon={faThumbTack} className="text-4xl mb-4 text-blue-500" />
        <p className="mb-4">No flashcards available.</p>
        <p>Take some quizzes to generate flashcards automatically, or visit the Flashcards Space to add your own.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-96">
      <div className="flex-grow overflow-y-auto mb-2 p-0 border border-gray-200 rounded">
        <FlashcardItem flashcard={flashcards[currentIndex]} />
      </div>
      <div className="flex justify-between items-center px-2 py-1 bg-gray-100 rounded">
        <button
          className="bg-blue-500 hover:bg-blue-700 px-4 rounded text-gray-100 transition-colors"
          onClick={handlePrevious}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <span className="text-xs text-gray-600">
          {currentIndex + 1} / {flashcards.length}
        </span>
        <button
          className="bg-blue-500 hover:bg-blue-700 px-4 rounded text-gray-100 transition-colors"
          onClick={handleNext}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <button
        className="mt-2 w-full py-2 px-4 bg-blue-500 text-white text-sm rounded hover:bg-blue-600 transition duration-300 flex items-center justify-center"
        onClick={() => navigate('/flashcards')}
      >
        <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-2" />
        Go to Flashcards Space
      </button>
    </div>
  );
};

export default FlashcardStack;