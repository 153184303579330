// src/components/Modal.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal, selectModalOpen } from '../features/userPrompts/userPromptsSlice';
import UserPrompts from './UserPrompts';

const ModalUserPrompts = () => {
    
  const isModalOpen = useSelector(selectModalOpen);
  const dispatch = useDispatch();

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-primary p-4 rounded text-black text-center w-3/5">
        <UserPrompts />
        <button className='bg-gray-300 hover:bg-gray-400 rounded p-2 pr-4 pl-4 mt-2 border border-gray-500' onClick={() => dispatch(closeModal())}>Close</button>
      </div>
    </div>
  );
};

export default ModalUserPrompts;
