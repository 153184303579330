// punchyHeadlines.js
import config from "../../../../config/config";
const punchyHeadlines = [
    `Proper preparation is the #1 key to passing the ${config.exam_name}.`,
    `Ace the ${config.exam_name} with confidence. Conquer your fear of failure.`,
    `Your dream ${config.graduate_name_adj} license is just one exam away.`,
    `Master the ${config.exam_name} and secure your future.`,
    `Don't go into the ${config.exam_name} unprepared. Get the tools you need to pass.`,
    `Success is no accident—prepare and pass. It's that simple.`,
    `Beat the ${config.exam_name} with smart preparation and proper study tools.`,
    `Your path to ${config.exam_name} success starts now. Keep going!`,
    `Don't go at the ${config.exam_name} alone—use all of the tools at your disposal.`,
    `Conquer the ${config.exam_name}—don't leave it to chance.`,
    `Every question right brings you closer to becoming a ${config.graduate_name}.`,
    `Preparation today, ${config.graduate_name_adj} career tomorrow.`,
    `Practice and prep are the secrets to ${config.exam_name} success.`,
    `Proper preparation is the #1 key to passing the ${config.exam_name}.`,
  ];
  
  export default punchyHeadlines;
  