import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config/config';

const apiURL = process.env.REACT_APP_API_URL;
const app_id = config.app_id;

export const fetchBoardItems = createAsyncThunk('boardItems/fetchBoardItems', async ({boardId = null, userId}) => {
    console.log('Fetching board items for board:', boardId);
    const response = await axios.get(`${apiURL}/boards/items/${app_id}/${userId}`);
    return response.data;
});

export const addBoardItem = createAsyncThunk('boardItems/addBoardItem', async ({ boardId, content, itemType, userId }) => {
    const url = `${apiURL}/boards/items/${app_id}`;
    const response = await axios.post(url, { boardId, content, itemType, userId });
    return response.data;
});

export const deleteBoardItem = createAsyncThunk('boardItems/deleteBoardItem', async (itemId) => {
    const url = `${apiURL}/boards/items/${itemId}`;
    const response = await axios.delete(url);
    return response.data;
});

const boardItemSlice = createSlice({
    name: 'boardItems',
    initialState: { items: [], status: 'idle', error: null },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBoardItems.pending, (state) => {
                console.log('fetchBoardItems.pending');
                state.status = 'loading';
            })
            .addCase(fetchBoardItems.fulfilled, (state, action) => {
                console.log('fetchBoardItems.fulfilled:', action.payload);
                state.items = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchBoardItems.rejected, (state, action) => {
                console.log('fetchBoardItems.rejected:', action.error.message);
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(addBoardItem.fulfilled, (state, action) => {
                console.log('addBoardItem.fulfilled:', action.payload);
                state.items.push(action.payload);
                state.status = 'idle'; // Reset status to idle after adding an item
            })
            .addCase(deleteBoardItem.fulfilled, (state, action) => {
                console.log('deleteBoardItem.fulfilled:', action.meta.arg);
                state.items = state.items.filter(item => item._id !== action.meta.arg);
                state.status = 'idle'; // Reset status to idle after deleting an item
            });
    }
});

export default boardItemSlice.reducer;
