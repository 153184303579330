import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { quizApi } from '../../api/quizApi';

export const generateQuiz = createAsyncThunk(
    'quiz/generate',
    async ({ quizOptions, userId }, { rejectWithValue }) => {
      try {
        const response = await quizApi.generateQuiz(quizOptions, userId);
        console.log('generateQuiz response:', response.data)
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const fetchQuiz = createAsyncThunk(
    'quiz/fetch',
    async ({ quizOptions, userId }, { rejectWithValue }) => {
      try {
        const response = await quizApi.fetchQuiz(quizOptions, userId);
        console.log('fetchQuiz response:', response.data);
        // Wait for 3 seconds
        await new Promise(resolve => setTimeout(resolve, 3500));
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  export const submitQuizResults = createAsyncThunk(
    'quiz/submitResults',
    async (quizData, { rejectWithValue }) => {
      try {
        const response = await quizApi.submitQuizResults(quizData);
        
        // Format the results
        const formattedResults = {
          numberOfCorrectAnswers: quizData.userInput.filter(q => q.isCorrect).length,
          numberOfQuestions: quizData.userInput.length,
          totalPoints: quizData.userInput.filter(q => q.isCorrect).length,
          userInput: quizData.userInput
        };
  
        console.log('Formatted quiz results:', formattedResults);
  
        return formattedResults;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

const quizSlice = createSlice({
  name: 'quiz',
  initialState: {
    currentQuiz: null,
    lastResults: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateQuiz.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateQuiz.fulfilled, (state, action) => {
        state.loading = false;
        state.currentQuiz = action.payload;
      })
      .addCase(generateQuiz.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchQuiz.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchQuiz.fulfilled, (state, action) => {
        state.loading = false;
        state.currentQuiz = action.payload;
      })
      .addCase(fetchQuiz.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(submitQuizResults.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitQuizResults.fulfilled, (state, action) => {
        state.loading = false;
        state.lastResults = action.payload;
      })
      .addCase(submitQuizResults.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default quizSlice.reducer;