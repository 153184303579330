import { createSlice } from '@reduxjs/toolkit';

const selectedPackageSlice = createSlice({
  name: 'selectedPackage',
  initialState: null, // Initial state is null, indicating no package is selected
  reducers: {
    setPackage(state, action) {
      return action.payload; // Set the state to the package object passed in action.payload
    },
    clearPackage() {
      return null; // Reset the state to null
    }
  }
});

export const { setPackage, clearPackage } = selectedPackageSlice.actions;
export default selectedPackageSlice.reducer;

// Selector
export const getSelectedPackage = (state) => state.selectedPackage;
