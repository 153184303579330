import {
    ThirdwebProvider,
    ConnectButton,
    darkTheme,
    //defineChain,
  } from "thirdweb/react";
  import {
    createWallet,
    walletConnect,
  } from "thirdweb/wallets";
  import { base } from "thirdweb/chains";
  import { ConnectWallet, useUser } from "@thirdweb-dev/react";
  import { createThirdwebClient } from "thirdweb";
  import { get_user } from "../api/userFunctions";
  import { useDispatch, useSelector } from 'react-redux';
  import { setUser } from "../features/user/userSlice";

const client = createThirdwebClient({ clientId: '841cd7c05f6572ec017b34f5ce3b0920'});

  
      
      
      /*<ThirdwebProvider>
      <ConnectButton
        client={client}
        wallets={wallets}
        theme={darkTheme({
          colors: {
            accentButtonBg: "#2A80F8",
            secondaryText: "#FDC801",
            accentText: "#2A80F8",
            borderColor: "#FDC801",
            separatorLine: "#FDC801",
            primaryButtonBg: "#FDC801",
          },
        })}
        connectButton={{
          label: "Connect Wallet/Sign In",
        }}
        onConnect={(user) => {
          const userAddress = user?.getAccount().address;
          console.log('userAddress in connect button: ', userAddress);
          get_user(userAddress).then((res) => {
            console.log('Get user response in connect button: ', res);
            setUser(res);
          });
        }}
        connectModal={{
          size: "wide",
          title: "Connect & Sign In",
          titleIcon:
            "https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AndyBaseAvatar.png",
          welcomeScreen: {
            img: {
              src: "https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AndyBaseAvatar.png",
              width: 150,
              height: 150,
            },
            title:
              "Connect your wallet to get started, man.",
            subtitle:
              "Dude. You're pretty freakin' close to joining the ANDY party.",
          },
          showThirdwebBranding: false,
        }}
      />
    </ThirdwebProvider>*/

const wallets = [
    createWallet("io.metamask"),
    createWallet("com.coinbase.wallet"),
    walletConnect(),
    createWallet("com.trustwallet.app"),
    createWallet("io.zerion.wallet"),
    createWallet("me.rainbow"),
    createWallet("app.phantom"),
  ];

  const displayBalanceToken = {
    [base.chainId]: "0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022",
  };

const ConnectButtonComponent = () => {
  return (
    
<ConnectWallet
        auth={
          {
            onLogin: async (user) => {
              //alert("user: " + user);
            },
          }
        }
        onConnect={(user) => {
          console.log('User in connect button: ', user);
        }
        }
        theme={darkTheme({
          colors: {
            borderColor: "#FDC801",
            separatorLine: "#FDC801",
            primaryText: "#ededef",
            secondaryText: "#FDC801",
            accentButtonBg: "#2A80F8",
            accentText: "#2A80F8",
            primaryButtonBg: "#FDC801",
          },
        })}
        hideTestnetFaucet={true}
        btnTitle= "Connect Wallet/Sign In"
        modalSize="wide"
        modalTitle="Connect"
        displayBalanceToken={displayBalanceToken}
        modalTitleIconUrl="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AndyBaseAvatar.png"
        showThirdwebBranding={false}
        welcomeScreen={{
          img: {
            src: "https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AndyBaseAvatar.png",
            width: 150,
            height: 150,
          },
          subtitle:
            "Connect your wallet to get started, man.",
          title:
            "Dude. You're pretty freakin' close to joining the ANDY party.",
        }}
        
        
      />
  );
}

export default ConnectButtonComponent;