import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../features/user/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
  faMedal,
  faRocket,
  faStar,
  faUserGraduate,
  faSignOutAlt,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons";
import PricingCards from "./Homepage/components/pricingCards";
import config from "../config/config";
import { selectSession, deleteSession } from "../features/session/sessionSlice";
import { logout } from "../features/user/userSlice";
import { fullLogout } from "../api/userFunctions";
import LoggedInPurchaseFlow from "./Homepage/components/LoggedInPurchaseFlow";

function CompletePurchasePage() {
    const dispatch = useDispatch();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
  const user = useSelector(selectUser);
  const session = useSelector(selectSession);

  const handleLogout = async () => {
    try {
      // First, clear the local state
      dispatch(logout());
      if (session) {
        dispatch(deleteSession());
        // Then, call the backend to invalidate the session
        await fullLogout(session);
      }

      

      // Finally, navigate to the home page
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Logout error:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  return (
    <div className="w-full min-h-screen bg-black-main py-10 px-5">
      <div className="max-w-4xl lg:max-w-5xl mx-auto text-center">
        <h1 className="text-4xl text-poetsen text-white mb-6">
          You're One Step Away from {config.exam_name} Success!
        </h1>
        <div className="bg-white rounded-lg shadow-xl p-8 mb-10">
          <FontAwesomeIcon
            icon={faUserGraduate}
            className="text-5xl text-blue-700 mb-2"
          />
          <h2 className="text-3xl text-poetsen text-blue-700 mb-4">
            Welcome, Future {config.graduate_name || "Graduate"}!
          </h2>
          <p className="text-xl text-gray-600 mb-6 text-poetsen">
            You've made a great decision to invest in your future. Let's secure
            your path to becoming a successful {config.graduate_name}!
          </p>

          <div className="bg-red-100 border-l-4 border-red-500 p-4 mb-6">
            <div className="flex flex-col lg:flex-row items-center">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-red-500 text-2xl mr-4"
              />
              <div className="text-gray-800 text-poetsen w-full text-xl lg:text-2xl">
                Don't risk it! Students who don't use a comprehensive{" "}
                {config.exam_name} prep service are
                <div className="text-red-600 uppercase lg:text-4xl my-1">
                  ⛔️ 3 times more likely to fail ⛔️
                </div>
                on their first attempt.
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-6 mb-8">
            <div className="bg-blue-100 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-blue-800 mb-2 text-poetsen">
                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                Boost Your Confidence
              </h3>
              <p className="text-blue-700">
                Our AI-powered system adapts to your learning style, ensuring
                you're fully prepared for exam day.
              </p>
            </div>
            <div className="bg-blue-100 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-blue-800 mb-2 text-poetsen">
                <FontAwesomeIcon icon={faRocket} className="mr-2" />
                Accelerate Your Career
              </h3>
              <p className="text-blue-700">
                Pass the {config.exam_name} on your first try and start your
                professional career sooner!
              </p>
            </div>
          </div>

          <p className="text-lg text-gray-700 mb-8 text-poetsen">
            Choose your success package below and join thousands of satisfied
            students who've passed the {config.exam_name} with our help!
          </p>
        </div>

        {/*<h2 className="text-3xl text-poetsen text-gray-800">
          Select Your Path to Success
        </h2>
        <PricingCards isRenewal={false} />*/}

        <LoggedInPurchaseFlow />

        <div className="mt-10 bg-green-100 border-2 border-green-500 rounded-lg p-6">
          <img
            className="w-52 mx-auto mb-6"
            src="https://otp.nyc3.cdn.digitaloceanspaces.com/passExamGuarantee.png"
            alt="Pass your exam. Guaranteed."
          />
          <h3 className="text-2xl lg:text-3xl font-semibold text-yellow-600 mb-4 text-poetsen">
            <FontAwesomeIcon icon={faMedal} className="text-yellow-600 mr-2" />
            Our Success Guarantee
          </h3>
          <p className="text-lg text-green-700 text-poetsen">
            We're so confident in our program that if you complete our
            recommended study plan and don't pass the {config.exam_name}, we'll
            refund your purchase AND give you an additional 3 months of access
            for free! Now, that's a guarantee you can trust.
          </p>
          <p className="text-sm mt-4">
            *{" "}
            <Link
              className="text-body hover:text-gray-500 transition-colors duration-200"
              to="/terms-and-conditions"
              target="_blank"
            >
              Terms And conditions
            </Link>{" "}
            apply.
          </p>
        </div>

        <div className="mt-10 text-gray-800">
          <div className="text-poetsen text-body text-center text-4xl font-bold flex flex-col lg:flex-row justify-center items-center">
            Don't just take it from us...
            <div>
              <FontAwesomeIcon icon={faStar} className="text-yellow-500 ml-2" />
              <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
              <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
              <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
              <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
            </div>
          </div>

          <div className="bg-blue-100 mt-10 grid grid-cols-1 md:grid-cols-3 gap-4 max-w-7xl mx-auto  border border-2 border-primary rounded-xl shadow-md py-2 px-4">
            <div className=" max-w-2xl px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
              <div className="text-poetsen">
                <span className="text-primary text-5xl text-poetsen">"</span>
                {config.landing_page_testimonial_1}
                <p className="text-primary text-5xl text-poetsen text-right">
                  "
                </p>
                <img
                  className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary"
                  src={config.landing_page_testimonial_1_image}
                />
                <p className="mt-4">
                  {config.landing_page_testimonial_1_author}
                </p>
                <img
                  className="mb-4 mt-4 w-28 mx-auto"
                  src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png"
                />
              </div>
            </div>
            <div className=" max-w-2xl px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
              <div className="text-poetsen">
                <span className="text-primary text-5xl text-poetsen">"</span>
                {config.landing_page_testimonial_2}
                <p className="text-primary text-5xl text-poetsen text-right">
                  "
                </p>
                <img
                  className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary"
                  src={config.landing_page_testimonial_2_image}
                />
                <p className="mt-4">
                  {config.landing_page_testimonial_2_author}
                </p>
                <img
                  className="mb-4 mt-4 w-28 mx-auto"
                  src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png"
                />
              </div>
            </div>
            <div className=" max-w-2xl px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
              <div className="text-poetsen">
                <span className="text-primary text-5xl text-poetsen">"</span>
                {config.landing_page_testimonial_3}
                <p className="text-primary text-5xl text-poetsen text-right">
                  "
                </p>
                <img
                  className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary"
                  src={config.landing_page_testimonial_3_image}
                />
                <p className="mt-4">
                  {config.landing_page_testimonial_3_author}
                </p>
                <img
                  className="mb-4 mt-4 w-28 mx-auto"
                  src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10 bg-red-100 border-2 border-red-500 rounded-lg p-6">
          <h3 className="text-2xl lg:text-3xl font-semibold text-red-800 mb-4 text-poetsen">
            ⛔️ Limited Time Offer! ⛔️
          </h3>
          <p className="text-lg text-red-700 text-poetsen">
            Lock in our special launch pricing now! Prices will increase soon as
            more students experience our incredible results.
          </p>
        </div>

        <button
          onClick={() => {
            handleLogout();
          }}
          className="mt-2 flex items-center w-full text-primary border border-primary rounded hover:bg-gray-700 justify-center p-1"
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} className="mr-2" />
          Back to Home Page
        </button>
      </div>
    </div>
  );
}

export default CompletePurchasePage;
