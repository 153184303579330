import React from 'react';
import config from '../config/config';

function PrivacyPolicyPage() {

  return (
    <div className="w-full min-h-screen bg-gray-100 py-10 px-5">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="bg-primary text-white text-center py-4">
          <h1 className="text-2xl font-bold">Privacy Policy for {config.siteName}</h1>
          <p className="text-sm mt-1">Effective Date: June 19th, 2024</p>
        </div>
        <div className="p-6 space-y-6 text-gray-700">
          <section>
            <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
            <p>
              Welcome to {config.siteName} (“we,” “our,” “us”). We are committed to protecting your privacy and ensuring your personal information is handled in a safe and responsible manner. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to protect it.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">2. Information We Collect</h2>
            <ul className="list-disc pl-5 space-y-1">
              <li><strong>Personal Information:</strong> When you sign up for our services, we collect personal information such as your name, email address, and payment information.</li>
              <li><strong>Usage Data:</strong> We collect information about your interactions with our app, including the features you use and the time spent on the app.</li>
              <li><strong>Cookies:</strong> We use cookies to enhance your experience, gather general visitor information, and track visits to our website. You can choose to disable cookies through your browser settings.</li>
            </ul>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">3. How We Use Your Information</h2>
            <ul className="list-disc pl-5 space-y-1">
              <li><strong>To Provide Services:</strong> We use your personal information to provide and maintain our services, including processing transactions and managing your account.</li>
              <li><strong>To Communicate:</strong> We may use your email address to send you updates, promotional materials, and information about our services. You can opt out of these communications at any time.</li>
              <li><strong>To Improve Our Services:</strong> Usage data helps us understand how users interact with our app, allowing us to improve our services and user experience.</li>
              <li><strong>To Ensure Security:</strong> We use your information to detect, prevent, and address security and technical issues.</li>
            </ul>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">4. Data Sharing and Disclosure</h2>
            <ul className="list-disc pl-5 space-y-1">
              <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in providing our services, such as payment processors and email service providers. These providers are obligated to protect your information.</li>
              <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to valid requests by public authorities.</li>
            </ul>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">5. Data Security</h2>
            <p>
              We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">6. Your Rights</h2>
            <ul className="list-disc pl-5 space-y-1">
              <li><strong>Access:</strong> You have the right to access the personal information we hold about you.</li>
              <li><strong>Correction:</strong> You can request to correct or update your personal information.</li>
              <li><strong>Deletion:</strong> You can request to delete your account and personal information via the profile section of our app.</li>
              <li><strong>Opt-Out:</strong> You can opt-out of receiving promotional communications by following the unsubscribe link in emails.</li>
            </ul>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">7. Children's Privacy</h2>
            <p>
              Our services are not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">8. Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">9. Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@gaimnetwork.com" className="text-blue-600 underline">support@gaimnetwork.com</a>.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
