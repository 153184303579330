import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchBoardItems, deleteBoardItem, addBoardItem } from '../features/boards/boardItemSlice';
import { fetchBoards } from '../features/boards/boardSlice';
import { selectUser } from '../features/user/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPaperclip, faPlusCircle, faTrashCan, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard } from '../utils/commons';
import { showModal } from '../features/ui/uiSlice';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import config from '../config/config';

const BoardItem = () => {
    const { id: boardId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const items = useSelector(state => state.boardItems.items);
    const itemStatus = useSelector(state => state.boardItems.status);
    const boards = useSelector(state => state.boards.boards);
    const boardsStatus = useSelector(state => state.boards.status);
    const boardsError = useSelector(state => state.boards.error);
    const itemsError = useSelector(state => state.boardItems.error);
    const user = useSelector(selectUser);

    const [content, setContent] = useState('');
    const [itemType, setItemType] = useState('text'); // default to text
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [filterType, setFilterType] = useState('all'); // default to 'all'
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (boardsStatus === 'idle') {
            dispatch(fetchBoards({userId: user._id}));
        }
    }, [boardsStatus, dispatch]);

    useEffect(() => {
        if (itemStatus === 'idle' && boardsStatus === 'succeeded') {
            dispatch(fetchBoardItems({boardId, userId: user._id}));
        }
    }, [itemStatus, dispatch, boardId, boardsStatus]);

    const board = boards.find(b => b._id === boardId);
    const boardName = boardsStatus === 'succeeded' ? (board ? board.name : `${config.boards_title_singular} not found`) : 'Loading...';

    const filteredItems = items
        .filter(item => item.boardId === boardId)
        .filter(item => (filterType === 'all' ? true : item.itemType === filterType))
        .filter(item => item.content.toLowerCase().includes(searchTerm.toLowerCase()));

    const handleAddItem = async () => {
        if (content.trim()) {
            try {
                await dispatch(addBoardItem({ boardId, content, itemType, userId: user?._id })).unwrap();
                setContent(''); // clear the input
                setItemType('text'); // reset to default item type
                setIsFormVisible(false); // hide the form after adding the item
                dispatch(fetchBoardItems({boardId, userId: user._id})); // refresh the board items
            } catch (error) {
                console.error('Failed to add item:', error);
            }
        }
    };

    const handleDelete = async (itemId) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            try {
                await dispatch(deleteBoardItem(itemId)).unwrap();
                dispatch(fetchBoardItems({boardId, userId: user._id}));
            } catch (error) {
                console.error('Failed to delete item:', error);
            }
        }
    };

    if (boardsStatus === 'loading' || itemStatus === 'loading') {
        return <div>Loading...</div>;
    }

    if (boardsStatus === 'failed') {
        return <div>Error fetching {config.boards_title}: {boardsError}</div>;
    }

    if (itemStatus === 'failed') {
        return <div>Error fetching items: {itemsError}</div>;
    }

    const uniqueItemTypes = ['all', ...new Set(items.map(item => item.itemType))];

    const getBackgroundColor = (itemType) => {
        switch (itemType) {
            case 'text':
                return 'bg-[#FFF9C4]'; // Light Yellow
            case 'prompt':
                return 'bg-[#FFCDD2]'; // Light Pink
            case 'image':
                return 'bg-[#BBDEFB]'; // Light Blue
            default:
                return 'bg-white'; // Default background color
        }
    };

    return (
        <div className="container mx-auto p-4 min-h-96">
            <h1 className="text-3xl font-bold mb-4 text-primary text-poetsen">{boardName} Items - ({filteredItems.length})</h1>
            <div className='flex flex-wrap items-center justify-between mb-4'>
                <div className='flex flex-wrap items-center gap-5 justify-between w-full'>
                    <div className='flex gap-2'>
                        <button
                            onClick={() => navigate('/boards')}
                            className="bg-primary text-white px-4 py-2 rounded mb-4"
                        >
                            <FontAwesomeIcon icon={faPaperclip} className='mr-2' />Back to {config.boards_title}
                        </button>
                        <button
                            onClick={() => setIsFormVisible(!isFormVisible)}
                            className="bg-primary text-white px-4 py-2 rounded mb-4"
                        >
                            <FontAwesomeIcon icon={faPlusCircle} className='mr-2' />{isFormVisible ? 'Cancel' : 'Add New Item'}
                        </button>
                    </div>
                    
                    <div className="relative mb-4 flex flex-grow">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search items..."
                            className="border w-full rounded px-4 py-2 text-black"
                        />
                    </div>
                    <div className='flex gap-2'>
                        {uniqueItemTypes.map(type => (
                            <button
                                key={type}
                                onClick={() => setFilterType(type)}
                                className={`px-4 py-2 rounded mb-4 ${filterType === type ? 'bg-primary text-white' : 'bg-gray-200 text-black'}`}
                            >
                                {type === 'all' ? 'All' : type.charAt(0).toUpperCase() + type.slice(1)}
                            </button>
                        ))}
                    </div>
                    
                </div>
            </div>
            {isFormVisible && (
                <div className="mb-4">
                    <textarea
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        placeholder="Paste text, image URL, or prompt here..."
                        className="border rounded px-4 py-2 w-full mb-2 text-black"
                    />
                    <select
                        value={itemType}
                        onChange={(e) => setItemType(e.target.value)}
                        className="border rounded px-4 py-2 mb-2 w-full text-black"
                    >
                        <option value="text">Text</option>
                        <option value="image">Image</option>
                        <option value="prompt">Prompt</option>
                    </select>
                    <button
                        onClick={handleAddItem}
                        className="bg-primary text-white px-4 py-2 rounded w-full"
                    >
                        Add Item
                    </button>
                </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {filteredItems.map(item => (
                    <div key={item._id} className={`border text-gray-800 rounded-xl p-4 shadow relative h-64 max-h-64 overflow-auto slim-scrollbar shadow-lg ${getBackgroundColor(item.itemType)}`}>
                        {item.itemType === 'text' || item.itemType === 'prompt' ? (
                            <p><ReactMarkdown remarkPlugins={[remarkGfm]}>{truncateContent(item.content)}</ReactMarkdown></p>
                        ) : (
                            <div className='w-full flex flex-col justify-center items-center gap-2'>
                                <img className='max-h-48' src={item.content} alt="Board item" />
                                <p><ReactMarkdown remarkPlugins={[remarkGfm]}>{item.content}</ReactMarkdown></p>
                            </div>
                        )}
                        <div className='absolute bottom-2 right-2 gap-2 flex flex-row justify-center items-center'>
                            <button
                                onClick={()=>dispatch(showModal({ content: item.content, copy: true }))}
                                className="bg-primary text-white px-2 py-1 rounded opacity-50 hover:opacity-100"
                            >
                                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                            </button>
                            <button
                                onClick={() => copyToClipboard(item.content)}
                                className="bg-primary text-white px-2 py-1 rounded opacity-50 hover:opacity-100"
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                            <button
                                onClick={() => handleDelete(item._id)}
                                className="bg-red-500 text-white px-2 py-1 rounded opacity-50 hover:opacity-100"
                            >
                                <FontAwesomeIcon icon={faTrashCan} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const truncateContent = (content, maxLength = 500) => {
    if (!content) return 'No content available.';
    return content.length > maxLength ? content.slice(0, maxLength) + '...' : content;
};

export default BoardItem;
