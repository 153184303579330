import React, { useState, useEffect } from "react";
import LeadMagnetQuiz from "./LeadMagnetQuiz";
import FooterLandingPage from "../../../pages/Landingpage/FooterLandingPage";
import config from "../../../config/config";
import punchyHeadlines from "./data/punchyHeadlines";
import MetaTagsComponent from "../../MetaTagsComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowRight,
  faArrowUp,
  faCheck,
  faCheckCircle,
  faClipboardCheck,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";

const LeadMagnetQuizPage = () => {
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [quizResults, setQuizResults] = useState(null);
  const [currentHeadlineIndex, setCurrentHeadlineIndex] = useState(0);
  const [isLastHeadline, setIsLastHeadline] = useState(false);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    if (isLastHeadline) return; // Stop the effect if we're on the last headline

    const cycleInterval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentHeadlineIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex === punchyHeadlines.length - 1) {
            setIsLastHeadline(true);
            clearInterval(cycleInterval);
          }
          return nextIndex;
        });
        setFade(false);
      }, 500); // Wait for fade out before changing text
    }, 5000); // Change every 5 seconds

    return () => clearInterval(cycleInterval);
  }, [isLastHeadline]);

  const handleQuizComplete = (results) => {
    setQuizCompleted(true);
    setQuizResults(results);
    // You might want to do something with the results here, like sending them to an analytics service
  };

  const renderCompletionMessage = () => {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6 text-center">
        <h2 className="text-2xl font-bold mb-4 text-primary text-inter">
          Thank You for Completing the Quiz!
        </h2>
        <p className="text-lg mb-4">
          We've received your responses and will send your detailed results to
          the email address you provided.
        </p>
        <p className="text-md text-gray-600">
          Keep an eye on your inbox for more insights and personalized
          recommendations based on your quiz results.
        </p>
        {quizResults && quizResults.score && (
          <div className="mt-6 p-4 bg-blue-100 rounded-md">
            <p className="text-xl font-semibold text-blue-800">
              Your Score: {quizResults.score}
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mx-auto px-4 py-4 xl:py-8 bg-gradient-to-b from-blue-700 to-green-500 min-h-screen">
        <MetaTagsComponent
            title={`Take Our Free ${config.exam_name} Readiness Quiz`}
            description={`Find out if you're ready to conquer the ${config.exam_name} with our free readiness quiz. Get a personalized study plan and detailed analysis of your performance.`}
            imageUrl={config.leadMagnetQuizMetaTagImage}
            url={`${config.app_url}/readinessquiz`}
        />
      <div className=" max-w-3xl mx-auto flex flex-col justify-center">
        <img
          src={config.leadMagnetQuizHeaderImage}
          alt={`${config.exam_name} Readiness Quiz`}
          className="mb-2 mx-auto w-28 h-28 rounded-full shadow-xl"
        />
        <h1 className="text-3xl font-bold mb-4 text-center text-gray-100 text-inter">
          <FontAwesomeIcon icon={faClipboardCheck} className="mr-3" />
          Ready to Conquer the {config.exam_name}?
        </h1>

        {!quizCompleted ? (
          <>
            <button className="text-xl leading-none lg:text-2xl font-bold mb-4 mx-auto rounded-xl text-center text-gray-100 text-inter w-full cursor-default px-20 py-4 bg-green-500">
              Take Our Free Readiness Quiz!{" "}
              <FontAwesomeIcon icon={faArrowDown} className="ml-2" />
            </button>
            <p className="text-sm md:text-base text-left text-inter font-bold">
              Welcome to the ultimate {config.exam_name} Readiness Assessment
              Quiz. This is your first step towards mastering the{" "}
              {config.exam_name}. Answer a few questions, and we'll provide you
              with a detailed analysis of your performance and a personalized
              study plan to ensure you pass with flying colors!
            </p>
            <LeadMagnetQuiz onComplete={handleQuizComplete} />
          </>
        ) : (
          renderCompletionMessage()
        )}

        <div className="mt-8 w-full">
          <div className="flex items-center justify-start gap-4 lg:gap-8 w-full">
            <img
              src={config.leadMagnetQuizImage2}
              alt={`${config.exam_name} Prep and Readiness`}
              className="mb-2 mx-auto w-28 h-28 rounded-full shadow-xl"
            />
            <div className="lg:text-4xl mb-4 text-left text-inter font-bold w-full flex items-center">
              <p
                className={`w-full transition-opacity duration-500 ${
                  !isLastHeadline && fade ? "opacity-0" : "opacity-100"
                }`}
              >
                {punchyHeadlines[currentHeadlineIndex]}
              </p>
            </div>
          </div>

          <div className="lg:text-2xl flex flex-col mt-10 text-left text-center text-inter font-bold w-full flex items-center">
            <div className="text-3xl"><FontAwesomeIcon icon={faCheck} className='' /></div>
            <div>Why settle for guesswork? Our AI-driven analysis pinpoints exactly where you need to focus, so you can study smarter, not harder.</div>
            <div className="mt-6 text-lg px-10"><FontAwesomeIcon icon={faArrowUp} className="mr-2" />Complete your readiness quiz above to access your detailed analysis that's crafted ONLY FOR YOU!</div>
          </div>
        </div>
      </div>
      <div className="max-w-5xl mx-auto">
        <FooterLandingPage />
      </div>
    </div>
  );
};

export default LeadMagnetQuizPage;
