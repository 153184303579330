import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import FilterSection from './FilterSection';

const FilterBar = ({ filters, setFilters, categories, formats, tags }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleFilterChange = (filterType, value) => {
    const newFilters = {
      ...filters,
      [filterType]: filters[filterType]?.includes(value)
        ? filters[filterType].filter(item => item !== value)
        : [...(filters[filterType] || []), value]
    };
    setFilters(newFilters);
  };

  const clearFilters = () => {
    setFilters({});
    setIsOpen(false);
  };

  const closeFilters = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-primary text-white px-4 py-2 rounded flex items-center"
      >
        <FontAwesomeIcon icon={faFilter} className="mr-2" />
        Filter
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-xl z-10 p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-bold text-inter text-primary">Filters</h3>
            <button onClick={clearFilters} className="text-sm text-gray-500 hover:text-gray-700">
              Clear all
            </button>
          </div>
          <FilterSection 
            title="Categories" 
            items={categories} 
            filters={filters.categories} 
            onChange={(value) => handleFilterChange('categories', value)} 
          />
          <FilterSection 
            title="Formats" 
            items={formats} 
            filters={filters.formats} 
            onChange={(value) => handleFilterChange('formats', value)} 
          />
          <FilterSection 
            title="Tags" 
            items={tags} 
            filters={filters.tags} 
            onChange={(value) => handleFilterChange('tags', value)} 
          />
          <button
            onClick={closeFilters}
            className="mt-4 w-full bg-primary text-white px-4 py-2 rounded flex items-center justify-center"
          >
            <FontAwesomeIcon icon={faTimes} className="mr-2" />
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default FilterBar;