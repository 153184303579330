import config from "../config/config";
const apiURL = process.env.REACT_APP_API_URL;
const app_id = config.app_id;

export async function generateChat(
    newConversation,
    finalPrompt,
    toggleState,
    generatorID,
    userDynamicId,
    currentNFTChat,
  ) {
    try {
      const response = await fetch(`${apiURL}/chat/chatStreamBasic`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          //'stytch_session_token': stytch_session_token, // Assuming js-cookie is already installed and imported
        },
        body: JSON.stringify({
          dynamic_id: userDynamicId,
          messages: newConversation,
          //newMessage: finalPrompt,
          //pre_prompt: prePrompt,
          // convo_id: convoID, // Uncomment or handle as needed
          //servicesData: servicesData,
          model: toggleState === 'Best' ? 'gpt-4' : 'gpt-3.5-turbo-1106',
          tool_id: generatorID,
          currentNFTChat: currentNFTChat,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const jsonResponse = await response.json();
      return jsonResponse; // Return the JSON response to the caller
    } catch (error) {
      console.error('Error generating chat:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }

  export async function generateImage(
    dynamic_id, tool_id, prompt, stytch_session_token, servicesData
  ) {
    try {
      const response = await fetch(`${apiURL}/image/generateImage`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'stytch_session_token': stytch_session_token,
        },
        body: JSON.stringify({
          dynamic_id: dynamic_id,
          tool_id: tool_id,
          prompt: prompt,
          servicesData: servicesData,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const jsonResponse = await response.json();
      console.log('Image Response:', jsonResponse);
      return jsonResponse;
    } catch (error) {
      console.error('Error generating image:', error);
      throw error;
    }
  }

export async function postMessage(dynamic_id, promptToSubmit, threadId, assistantId) {
  console.log("postMessage dynamic_id:", dynamic_id, "promptToSubmit:", promptToSubmit, "threadId:", threadId, "assistantId:", assistantId);
  const sendPostMessage = async (prompt, updatedThreadId) => {
    const endpoint = `${apiURL}/thread/${updatedThreadId}/messages`;
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: prompt,
          role: "user",
          assistantId: assistantId,
          dynamic_id: dynamic_id,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("post message response: ", data);
      return data;
    } catch (error) {
      console.error("Error posting message:", error);
      return null;
    }
  };

  if (!threadId || threadId === "") {
    // Define and await the result of createThreadResponse
    const createThreadResponse = await (async (assistantId) => {
      const endpoint = `${apiURL}/thread`;
      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ assistantId: assistantId, dynamic_id: dynamic_id, app_id }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("create thread response: ", data.data.oai_threadId);
        return data.data.oai_threadId;
      } catch (error) {
        console.error("Error creating thread:", error);
        return null;
      }
    })(assistantId);

    if (createThreadResponse) {
      threadId = createThreadResponse;
    } else {
      console.error("Failed to create a new thread.");
      return;
    }
  }

  const finalResult = await sendPostMessage(promptToSubmit, threadId);
  //setResult(finalResult.data);
  console.log("finalResult:", finalResult);
  return finalResult;
};