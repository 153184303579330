import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import config from '../../config/config';
import BoardSelectorButton from '../BoardSelectorButton';

const ExplanationModal = ({ isOpen, onClose, content, isLoading, initialRandomBite, userId }) => {
  const bites = useSelector((state) => state.tools.items.bites);

  const [randomBite, setRandomBite] = useState(initialRandomBite);

  const selectRandomBite = () => {
    if (bites && bites.length > 0) {
      const randomIndex = Math.floor(Math.random() * bites.length);
      setRandomBite(bites[randomIndex]);
    } else {
      setRandomBite(null);
    }
  };

  useEffect(() => {
    if (isLoading && isOpen) {
        const interval = setInterval(() => {
            selectRandomBite();
        }
        , 5000);
        return () => clearInterval(interval);
    }
}, [isLoading, isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
      <div className="bg-body border-2 border-primary rounded-lg p-8 max-w-2xl w-full max-h-80vh flex flex-col relative lg:max-w-1/2 overflow-auto slim-scrollbar">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
        <div className="overflow-y-auto flex-grow mb-4">
          {isLoading ? (
            <div className="flex flex-col items-center justify-center h-full">
              <div className="animate-spin rounded-full h-16 w-16 border-b-4 border-gray-700 mx-auto mb-4 flex justify-center items-center">
                <img src={config.favicon} alt={`${config.quiz_page_title}`} className="w-12 h-12 rounded-full" />
            </div>
                {randomBite && <div className='flex flex-col mt-4 text-lg w-full text-center'>
                  <div className="text-poetsen text-2xl">Study Tip <FontAwesomeIcon icon={faPenToSquare} className='text-primary ml-2' /></div>
                  <div>{randomBite}</div>
                </div>}
            </div>
          ) : (
            <div className="prose max-w-none">
              <ReactMarkdown
                className="markdown-content"
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                >
                {content}
               </ReactMarkdown>
               <div className='w-full text-right'>
                  <BoardSelectorButton
                    itemContent={content}
                    itemType={"text"}
                    userId={userId}
                  />
                </div>
            </div>
          )}
        </div>
        <button
          onClick={onClose}
          className="bg-primary text-white px-4 py-2 rounded hover:bg-gray-700 transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ExplanationModal;