import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faArrowRight, faCrown, faRocket, faShieldAlt, faClock, faAward, faCertificate, faSquareCheck, faStar, faPersonChalkboard, faInfinity, faCalendarDays, faChartColumn, faPlusCircle, faLock } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectPackages } from '../../../features/packages/packageSlice';
import { setPackage } from '../../../features/packages/selectedPackageSlice';
import ScreenshotGalleryStories from '../../../components/ScreenshotGalleryStories';
import config from '../../../config/config';

const PricingCardsUpdated = ({ isRenewal = false, onPackageSelect }) => {
    const dispatch = useDispatch();
  const packages = useSelector(selectPackages).filter(pkg => 
    pkg.type === config.landing_page_package_type && pkg.isRenewal === isRenewal
  );
  const [currentIndex, setCurrentIndex] = useState(1);

  const screenshots = config.landing_page_screenshots;

  const stories = [
    {
      url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotChatSpace2.jpg',
      type: 'image', // or 'video'
      title: '24/7 AI Tutor',
      description: 'Unlock the power of personalized learning with our 24/7 AI Tutor. Get instant, accurate answers and explanations to all your NCLEX questions any time of day. It\'s like having a personal tutor in your pocket, ready to help you conquer your study goals whenever you need it.'
    },
    {
        url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotQuizSpace1.jpg',
        type: 'image', // or 'video'
        title: 'Unlimited Quizzes',
        description: 'Never run out of practice! With unlimited quizzes, you can test your knowledge as much as you want. Our ever-expanding question bank ensures you always have fresh, relevant material to keep you sharp and exam-ready.'
      },
      {
        url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotQuizResults2.jpg',
        type: 'image', // or 'video'
        title: 'Instant Quiz Feedback',
        description: 'Don\'t wait for results. Get instant feedback on your quizzes so you know exactly where you stand. Our real-time scoring helps you identify strengths and weaknesses immediately, allowing you to adjust your study plan on the fly.'
      },
      {
          url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotQuizReview1.jpg',
          type: 'image', // or 'video'
          title: 'Detailed Quiz Reviews',
          description: 'Turn mistakes into learning opportunities with detailed quiz reviews. Our comprehensive analysis highlights your errors and provides actionable insights to help you understand and correct them, making sure you\'re always improving.'
        },
        {
            url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotChatSpace2.jpg',
            type: 'image', // or 'video'
            title: '24/7 AI Tutor',
            description: 'Unlock the power of personalized learning with our 24/7 AI Tutor. Get instant, accurate answers and explanations to all your NCLEX questions any time of day. It\'s like having a personal tutor in your pocket, ready to help you conquer your study goals whenever you need it.'
          },
          {
              url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotQuizSpace1.jpg',
              type: 'image', // or 'video'
              title: 'Unlimited Quizzes',
              description: 'Never run out of practice! With unlimited quizzes, you can test your knowledge as much as you want. Our ever-expanding question bank ensures you always have fresh, relevant material to keep you sharp and exam-ready.'
            },
            {
                url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotChatSpace2.jpg',
                type: 'image', // or 'video'
                title: '24/7 AI Tutor',
                description: 'Unlock the power of personalized learning with our 24/7 AI Tutor. Get instant, accurate answers and explanations to all your NCLEX questions any time of day. It\'s like having a personal tutor in your pocket, ready to help you conquer your study goals whenever you need it.'
              },
              {
                  url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotQuizSpace1.jpg',
                  type: 'image', // or 'video'
                  title: 'Unlimited Quizzes',
                  description: 'Never run out of practice! With unlimited quizzes, you can test your knowledge as much as you want. Our ever-expanding question bank ensures you always have fresh, relevant material to keep you sharp and exam-ready.'
                },
  ];

  const selectPackage = (index) => {
    setCurrentIndex(index);
    const selectedPackage = packages[index];
    dispatch(setPackage(selectedPackage));
    localStorage.setItem('selectedPackage', JSON.stringify(selectedPackage));
    if (onPackageSelect) {
      onPackageSelect(selectedPackage);
    }
  };

  const PackageCard = ({ packageItem, index }) => {
    const isBestValue = index === packages.length - 1;
    const isMostPopular = index === 1;
    return (
      <motion.div
        key={packageItem._id}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
        className="bg-white rounded-3xl shadow-2xl overflow-hidden p-8 pt-4 relative text-inter"
      >
        {isBestValue && (
          <div className="absolute top-2 right-2 bg-gradient-to-r from-yellow-400 to-orange-500 text-white text-sm font-bold px-4 py-2 rounded-full flex items-center">
            <FontAwesomeIcon icon={faCrown} className="mr-2" />
            BEST VALUE
          </div>
        )}
        {isMostPopular && (
          <div className="absolute top-2 right-2 bg-gradient-to-r from-green-400 to-blue-500 text-white text-sm font-bold px-4 py-2 rounded-full flex items-center">
            <FontAwesomeIcon icon={faAward} className="mr-2" />
            POPULAR
          </div>
        )}
        {/*<h3 className="text-3xl font-bold text-inter text-blue-600 mb-4">{packageItem.name}</h3>*/}
        <p className="text-xl lg:text-3xl text-blue-600 font-bold">{config.siteName}</p>
        <p className="text-green-400 font-bold">
  <FontAwesomeIcon icon={faSquareCheck} className="mr-1 text-green-400" />
  All Access for {packageItem.subscription_months} {packageItem.subscription_months === 1 ? 'Month' : 'Months'}
</p>
        <div className='mb-4'>
        <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
                  <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
                  <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
                  <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
                  <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
                </div>
        {/*<div className="flex items-center justify-center mb-3">
            <ScreenshotGalleryStories stories={stories} />
        </div>*/}
        <motion.div className='grid grid-cols-4 text-blue-600 text-xs text-inter font-bold gap-4 mb-4'>
            <div className='flex flex-col text-center justify-center items-center gap-1'>
                Unlimited 
                <FontAwesomeIcon icon={faInfinity} className="text-xl text-blue-600" />
                Quizzes
            </div>
            <div className='flex flex-col text-center justify-center items-center gap-1'>
                24/7
                <FontAwesomeIcon icon={faPersonChalkboard} className="text-xl text-blue-600" />
                AI Tutor
            </div>
            <div className='flex flex-col text-center justify-center items-center gap-1'>
                Study 
                <FontAwesomeIcon icon={faCalendarDays} className="text-xl text-blue-600" />
                Plans
            </div>
            <div className='flex flex-col text-center justify-center items-center gap-1'>
                Quiz
                <FontAwesomeIcon icon={faChartColumn} className="text-xl text-blue-600" />
                Stats
            </div>
        </motion.div>
        <div className="flex items-center justify-between mb-2">
          <div>
            <p className="text-sm font-bold text-gray-700"><FontAwesomeIcon icon={faSquareCheck} className='mr-1 text-green-400' />One-Time Payment</p>
            <p className="text-sm font-bold text-gray-700 mb-1"><FontAwesomeIcon icon={faSquareCheck} className='mr-1 text-green-400' />Money-Back Guarantee</p>
            <p className="text-5xl font-bold text-green-400">${packageItem.usd_cost}</p>
            <p className="text-lg text-red-500 line-through">Reg. ${packageItem.usd_cost * 2}</p>
          </div>
        </div>

        <div className="text-sm text-gray-600 mt-4 mb-2 px-4 py-1 gradient-green-blue text-white rounded-full w-fit font-bold"><FontAwesomeIcon icon={faPlusCircle} className='mr-2' />Package Bonuses:</div>

        <ul className="space-y-1 mb-4 text-inter">
            <motion.li
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="flex items-start"
            >
              <FontAwesomeIcon icon={faCheck} className="mt-1 mr-3 text-green-500 text-xl" />
              <span className="text-gray-700 text-lg">{packageItem.flashcards === 999 ? 'Unlimited' : packageItem.flashcards} Gamified Flashcards</span>
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="flex items-start"
            >
              <FontAwesomeIcon icon={faCheck} className="mt-1 mr-3 text-green-500 text-xl" />
              <span className="text-gray-700 text-lg">{packageItem.notes === 999 ? 'Unlimited' : packageItem.notes} Savable Notes</span>
            </motion.li>
        </ul>
        
        
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => selectPackage(index)}
          className="w-full bg-gradient-to-r from-blue-600 to-red-600 hover:from-blue-700 hover:to-red-700 text-white font-bold py-4 px-8 rounded-full transition duration-300 ease-in-out text-xl"
        >
          Get Started Now
          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </motion.button>
        <p className="text-xs text-gray-600 mt-2 text-center"><FontAwesomeIcon icon={faLock} className='mr-2' />Secure checkout powered by Stripe</p>

        <div className="flex items-center justify-center mt-6 text-inter">
          <img src={config.landing_page_testimonial_2_image} alt="NCLEX Study Pro Testimonial" className="w-16 rounded-full" />
          <div className="ml-4">
            <p className="text-sm text-gray-600">"{config.landing_page_testimonial_2}"</p>
            <p className="text-sm text-gray-600 font-bold">{config.landing_page_testimonial_2_author}</p>
          </div>
        </div>

        <p className="text-sm text-gray-600 mt-8 mb-2">Additional Package Details:</p>
        <ul className="space-y-1 mb-4 text-inter">
          {packageItem?.features?.map((feature, idx) => (
            <motion.li 
              key={idx}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: idx * 0.1 }}
              className="flex items-start"
            >
              <FontAwesomeIcon icon={faCheck} className="mt-1 mr-3 text-green-500 text-xl" />
              <span className="text-gray-700">{feature}</span>
            </motion.li>
          ))}
        </ul>
      </motion.div>
    );
  };

        
  const DurationButton = ({ months, index }) => (<div className='flex flex-col'>
    <button
      onClick={() => selectPackage(index)}
      className={`w-16 h-16 rounded-full font-bold text-lg transition-all duration-300 ${
        index === currentIndex
          ? 'bg-blue-600 text-white shadow-lg'
          : 'bg-white text-blue-600 hover:bg-blue-100 border border-blue-200'
      } ${
        index === 1 && currentIndex !== 1 ? 'gradient-border-green-blue' : ''
      } ${
        index === 1 && currentIndex === 1 ? 'gradient-border-green-blue-active' : ''
      }`}
    >
      {months}
    </button>
    <div className='text-center text-green-500 text-sm font-bold'>
        {index !== currentIndex && <span>
    {packages[index].usd_cost - packages[currentIndex].usd_cost >= 0
      ? `+$${Math.floor(packages[index].usd_cost - packages[currentIndex].usd_cost)}`
      : `-$${Math.abs(Math.floor(packages[index].usd_cost - packages[currentIndex].usd_cost))}`}
  </span>}
    </div>
    </div>);

  return (
    <div className="max-w-4xl mx-auto lg:px-4 pt-4 pb-16 bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 rounded-3xl shadow-2xl">
      {/*<motion.h2 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-5xl font-bold text-inter text-center text-blue-800 mb-12"
      >
        Choose Your Perfect Package
      </motion.h2>

      <div className="flex items-center justify-center mb-3 pl-4 pt-2 bg-white rounded-t-3xl rounded-r-3xl">
            <ScreenshotGalleryStories stories={stories} />
        </div>*/}
      
      <div className="mb-4">
        <h3 className="px-10 text-sm text-inter lg:text-center text-blue-600 mb-2">How many months will you be preparing for your exam? (at least 3 is recommended)</h3>
        <div className="flex justify-center space-x-4 lg:space-x-8">
          {packages.map((pkg, index) => (
            <DurationButton key={pkg._id} months={pkg.subscription_months} index={index} />
          ))}
        </div>
      </div>
      
      <div className="relative">
        <AnimatePresence mode="wait">
          <PackageCard key={packages[currentIndex]._id} packageItem={packages[currentIndex]} index={currentIndex} />
        </AnimatePresence>
      </div>
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="bg-white rounded-3xl shadow-xl p-8 mt-12"
      >
        <h3 className="text-3xl font-bold text-inter text-blue-800 mb-6">Why Choose Our Ultimate Prep?</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="flex flex-col items-center text-center">
            <FontAwesomeIcon icon={faRocket} className="text-5xl text-blue-600 mb-4" />
            <h4 className="text-xl font-semibold text-blue-600 mb-2">Accelerated Learning</h4>
            <p className="text-gray-600">Our proven methods help you learn faster and retain more.</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <FontAwesomeIcon icon={faShieldAlt} className="text-5xl text-blue-600 mb-4" />
            <h4 className="text-xl font-semibold text-blue-600 mb-2">Guaranteed Results</h4>
            <p className="text-gray-600">We're so confident that you'll pass, we offer a money-back guarantee.</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <FontAwesomeIcon icon={faClock} className="text-5xl text-blue-600 mb-4" />
            <h4 className="text-xl font-semibold text-blue-600 mb-2">Flexible Learning</h4>
            <p className="text-gray-600">Study at your own pace with 24/7 access to materials.</p>
          </div>
        </div>
      </motion.div>
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7 }}
        className="mt-12 text-center"
      >
        <p className="text-xl text-gray-600 mb-4">Join thousands of successful students today!</p>
        <p className="text-lg text-blue-800 font-semibold">30-Day Money-Back Guarantee</p>
      </motion.div>
    </div>
  );
};

export default PricingCardsUpdated;