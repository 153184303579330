import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedProfile: null, // Initially, no profile is selected
};

export const selectedProfileSlice = createSlice({
  name: 'selectedProfile',
  initialState,
  reducers: {
    // Action to update the selected profile
    setSelectedProfile: (state, action) => {
      state.selectedProfile = action.payload;
    },
    // Action to clear the selected profile (optional, if you need to deselect)
    clearSelectedProfile: (state) => {
      state.selectedProfile = null;
    },
  },
});

// Export actions
export const { setSelectedProfile, clearSelectedProfile } = selectedProfileSlice.actions;

// Selector to get the selected profile from the state
export const selectSelectedProfile = (state) => state.selectedProfile.selectedProfile;

// Export reducer
export default selectedProfileSlice.reducer;
