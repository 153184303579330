import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import config from "../../../config/config";
import { downloadLeadMagnet } from "../../../api/leadMagnetApi";
import { useNavigate } from 'react-router-dom';

const ExitIntentPopup = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [agree, setAgree] = useState(true);
  const groupId = config.mailerLiteNewsletterGroupID;
  const leadMagnetId = config.leadMagnetId;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agree) {
      alert('You must agree to the privacy policy to continue.');
      return;
    }
    try {
      const response = await downloadLeadMagnet(leadMagnetId, email, groupId);
      //setMessage('Success! Check your email for your requested guide.');
      navigate(`/specialoffer/${leadMagnetId}`);
    } catch (error) {
      setMessage('Failed to download. Please try again later.');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="overflow-auto slim-scrollbar mt-20 md:mt-0 bg-white rounded-lg p-8 max-w-3xl w-full relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
        <h2 className="text-2xl text-inter font-bold mb-4 text-red-600">{config.exitIntentPopupTitle}</h2>
        <img src={config.leadMagnetImage} alt={config.exitIntentPopupTitle} className="w-full max-w-xl mx-auto mb-4 rounded shadow-xl" />
        <p className="mb-4 text-gray-800 text-inter font-bold">{config.exitIntentPopupDescription}</p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4 text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary transition duration-200"
            required
          />
          <button
            type="submit"
            className="w-full bg-red-600 text-gray-100 shake text-inter font-bold py-2 rounded-md hover:bg-primary-dark transition duration-200"
          >
            <FontAwesomeIcon icon={faDownload} className="mr-2" /> Get {config.leadMagnetName}
          </button>
          <div className="mt-4">
                <p className="text-xs text-gray-600 mt-2">
                  By submitting this form, you agree to receive your free {config.leadMagnetName} and related study materials from {config.siteName}. We'll also send you helpful {config.exam_name} prep tips and
                  updates. You can unsubscribe at any time using the link in our
                  emails. We respect your privacy and will never sell or share
                  your personal data. For more information on how we use your
                  data, please see our{" "}
                  <a
                    href={`${config.app_url}/privacy-policy`}
                    target="_blank"
                    className="text-blue-600 hover:underline"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
                {/*<label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                  <span className="ml-2 text-sm text-gray-600">
                    I agree to {config.siteName}'s <a href={`${config.app_url}/privacy-policy`} target="_blank" rel="noopener noreferrer" className="text-indigo-600 underline hover:text-black">privacy policy</a>.
                  </span>
                </label>*/}
              </div>
        </form>
        {message && <p className="mt-2 text-green-500 text-poetsen">{message}</p>}
        {/*<div className="text-red-600 bg-yellow-100 border-2 border-yellow-600 px-4 py-2 rounded-full text-inter font-bold mt-6 text-center">
          {config.leadMagnetHeroSubheader}
        </div>*/}
      </div>
    </div>
  );
};

export default ExitIntentPopup;