import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const PerformanceRadarChart = ({ showInfo }) => {
  const { categoryPerformance, loading, error } = useSelector((state) => state.userStats);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64 bg-gray-900">
        <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-blue-500" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center bg-gray-900 p-4">Error loading data</div>;
  }

  if (!categoryPerformance) {
    return <div className="text-gray-500 text-center bg-gray-900 p-4">No data available</div>;
  }

  const data = {
    labels: Object.keys(categoryPerformance),
    datasets: [
      {
        label: 'Performance',
        data: Object.values(categoryPerformance).map(cat => cat.percentage),
        borderColor: '#36A2EB',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
    ],
  };

  const options = {
    scales: {
      r: {
        angleLines: {
          display: false,
          color: 'rgba(255, 255, 255, 0.3)'
        },
        suggestedMin: 0,
        suggestedMax: 100,
        pointLabels: {
          color: 'white'
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.3)'
        },
        ticks: {
          color: 'white',
          backdropColor: 'transparent'
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      colors: {
        enabled: false
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.parsed.r.toFixed(2);
            return `${label}: ${value}%`;
          }
        }
      }
    },
  };

  return (
    <div className={`bg-gray-900 lg:p-4 rounded-lg shadow-md border-4 border-primary ${!showInfo ? 'h-72 max-h-72 w-full' : 'h-96 max-h-96 w-full lg:h-96 lg:max-h-96 overflow-auto'} flex flex-col items-center`}>
      {showInfo && <h2 className="text-sm text-center" style={{color: '#fff'}}>Performance Overview</h2>}
      <Radar data={data} options={options} />
      {showInfo && <div className="mt-4 text-white px-1 pt-2 leading-none">
        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
        <span className="text-xs">
          This radar chart provides a comprehensive view of your performance across different categories. 
          Each axis represents a category, and the distance from the center indicates your performance percentage. 
          Aim to expand the chart outwards in all directions for balanced improvement. 
          Focus on categories with shorter spokes to enhance your overall exam readiness.
        </span>
      </div>}
    </div>
  );
};

export default PerformanceRadarChart;