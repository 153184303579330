import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../../../features/user/userSlice';
import { setSession } from '../../../features/session/sessionSlice';
import { authUser } from '../../../api/userFunctions';
import PricingCards from './pricingCards';
import AuthForm from './AuthForm';
import { CheckoutForm, Return } from '../../../components/StripeCheckout';
import ProgressTracker from './ProgressTracker';
import { motion, AnimatePresence } from 'framer-motion';

const HomepagePurchaseFlow = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [topMessage, setTopMessage] = useState("💪 Stop stressing. We're here to help you pass your exam.");
  const dispatch = useDispatch();
    const navigate = useNavigate();
  const containerRef = useRef(null);
  const isInitialMount = useRef(true);

  const steps = ['Choose Package', 'Create Account', 'Checkout', 'Complete'];

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (containerRef.current && currentStep > 0) {
        containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [currentStep]);

  const handlePackageSelection = (packageItem) => {
    setSelectedPackage(packageItem);
    setCurrentStep(1);
    setTopMessage(`🔑 Proper preparation is the #1 key to passing. We've got you covered.`);
  };

  const handleAuthSuccess = async (sessionToken) => {
    try {
      const userDb = await authUser(sessionToken);
      dispatch(setUser(userDb.data));
      dispatch(setSession({
        stytch_session_token: sessionToken,
      }));
      setIsAuthenticated(true);
      if (userDb.data.subscription_id !== '6451434ac0267c72e2b0ddc9') {
        navigate('/dashboard');
      }
      setCurrentStep(2);
      setTopMessage(`🔥 You're one step away from prepping like a pro. Can you feel the success?`);
    } catch (error) {
      console.error('Auth success handling error:', error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  const handleCheckoutComplete = () => {
    setCurrentStep(3);
  };

  return (
    <div ref={containerRef} className="min-h-screen flex flex-col justify-start pb-12 pt-2 px-4 sm:px-6 lg:px-8">
      <ProgressTracker currentStep={currentStep} steps={steps} />
      <div className="text-xs md:text-base flex justify-center text-white mb-8 text-inter text-center">{topMessage}</div>
      <AnimatePresence mode="wait">
        {currentStep === 0 && (
          <motion.div
            key="pricing-cards"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-7xl mx-auto"
          >
            <PricingCards onPackageSelect={handlePackageSelection} />
          </motion.div>
        )}

        {currentStep === 1 && (
          <motion.div
            key="auth-form"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="max-w-md w-full mx-auto"
          >
            <div className="bg-white shadow-2xl rounded-lg overflow-hidden">
              <div className="px-4 py-5 sm:p-6">
                <AuthForm onAuthSuccess={handleAuthSuccess} />
              </div>
            </div>
            <button onClick={() => setCurrentStep(0)} className="block mx-auto mt-4 text-center text-primary font-bold text-sm hover:underline">Back To Packages</button>
          </motion.div>
        )}

        {currentStep === 2 && (
          <motion.div
            key="checkout"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-4xl mx-auto"
          >
            <CheckoutForm onCheckoutComplete={handleCheckoutComplete} selectedPackage={selectedPackage} />
            <button onClick={() => setCurrentStep(1)} className="block mx-auto mt-4 text-center text-primary font-bold text-sm hover:underline">Go Back</button>
          </motion.div>
        )}

        {currentStep === 3 && (
          <motion.div
            key="return"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-4xl mx-auto"
          >
            <Return />
            <button onClick={() => setCurrentStep(1)} className="block pulse mx-auto mt-6 text-center border-2 border-green-500 text-white px-6 py-2 hover:bg-green-500 rounded-full font-bold">Go To Your Dashboard 🎉</button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HomepagePurchaseFlow;