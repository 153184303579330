import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../features/user/userSlice';
import { setSession } from '../features/session/sessionSlice';
import { useNavigate } from 'react-router-dom';
import { useStytch, useStytchSession } from '@stytch/react';
import { authUser } from '../api/userFunctions';

const LoggingIn = () => {
  const dispatch = useDispatch();
  const stytchClient = useStytch();
  const { session } = useStytchSession();
  const navigate = useNavigate();

  useEffect(() => {
    //if (session) {
      //navigate('/dashboard');
    //} else {
      const getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
      };

      const token = getUrlParameter('token');
      const token_type = getUrlParameter('stytch_token_type');
      if (token && token_type === 'magic_links') {
        stytchClient.magicLinks.authenticate(token, { session_duration_minutes: 43830 })
          .then(async response => {
            const userDb = await authUser(response.session_token);
            /*dispatch(setUser({
              email: response.user.emails[0].email,
              stytch_user_id: response.user.user_id,
              user_id: userDb.data._id,
              username: userDb.data.username,
              name: userDb.data.name,
              pic: userDb.data.pic,
              api_keys: userDb.data.api_keys,
              // Add any other user details you need in your app
            }));*/
            dispatch(setUser(userDb?.data));
            dispatch(setSession({
              stytch_session_token: response.session_token,
            }));
            navigate('/dashboard');
          })
          .catch(error => {
            console.error('Stytch authentication error:', error);
            navigate('/login');
          });
      }

      if (token && token_type === 'oauth') {
        stytchClient.oauth.authenticate(token, { session_duration_minutes: 43830 })
          .then(async response => {
            console.log('oauth response:', response.session_token);
            //const userDb = await getUserByDynamicID(response.user.user_id);
            const userDb = await authUser(response.session_token);
            console.log('userDb:', userDb);
            dispatch(setUser(userDb.data));
            dispatch(setSession({
              stytch_session_token: response.session_token,
            }));
            navigate('/dashboard');
          })
          .catch(error => {
            console.error('Stytch authentication error:', error);
            navigate('/login');
          });
      }
    //}
  }, [dispatch]);

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <h1>Logging In...</h1>
    </div>
  );
}

export default LoggingIn;
