import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronLeft, faChevronRight, faPlay, faPause, faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';

const ScreenshotGalleryStories = ({ stories }) => {
  const [selectedStory, setSelectedStory] = useState(null);
  const [viewedStories, setViewedStories] = useState(new Set());
  const scrollContainerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (scrollContainerRef.current) {
        const scrollWidth = scrollContainerRef.current.scrollWidth;
        const clientWidth = scrollContainerRef.current.clientWidth;
        if (scrollWidth > clientWidth) {
          scrollContainerRef.current.style.justifyContent = 'flex-start';
        } else {
          scrollContainerRef.current.style.justifyContent = 'center';
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openLightbox = (index) => {
    setSelectedStory(index);
    setViewedStories(new Set([...viewedStories, index]));
    setIsPlaying(true);
  };

  const closeLightbox = () => {
    setSelectedStory(null);
    setIsPlaying(true);
    setIsMuted(true);
  };

  const navigateStory = (direction) => {
    setSelectedStory((prevIndex) => {
      const newIndex = prevIndex + direction;
      if (newIndex < 0) return stories.length - 1;
      if (newIndex >= stories.length) return 0;
      setViewedStories(new Set([...viewedStories, newIndex]));
      return newIndex;
    });
    setIsPlaying(true);
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (videoRef.current) {
      isPlaying ? videoRef.current.pause() : videoRef.current.play();
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
    }
  };

  return (
    <div className="container mx-auto bg-white text-inter">
      {/*<h2 className="font-bold text-inter text-center mb-6">
        Get The Tools To Help You Pass 👇
      </h2>*/}
      <div 
        ref={scrollContainerRef}
        className="flex overflow-x-auto pb-1 space-x-6 scrollbar-hide"
      >
        {stories.map((story, index) => (
          <div
            key={index}
            className="flex-shrink-0 cursor-pointer relative"
            onClick={() => openLightbox(index)}
          >
            <div className={`w-12 h-12 rounded-full ${
              viewedStories.has(index) 
                ? 'border-2 border-gray-300' 
                : 'gradient-border-green-blue'
            }`}>
              {story.type === 'video' ? (
                <video
                  src={story.url}
                  className="w-full h-full object-cover rounded-full"
                  muted
                  playsInline
                />
              ) : (
                <img
                  src={story.url}
                  alt={story.title}
                  className="w-full h-full object-cover rounded-full"
                />
              )}
            </div>
            <p className="text-xs mt-1 text-center truncate max-w-[80px] text-gray-700 text-inter">{story.title}</p>
          </div>
        ))}
      </div>
      {selectedStory !== null && (
        <div className="fixed inset-0 bg-black z-50 flex items-center justify-center">
          <div className="w-full h-full md:max-w-2xl md:max-h-screen relative">
            {stories[selectedStory].type === 'video' ? (
              <video
                ref={videoRef}
                src={stories[selectedStory].url}
                className="w-full h-full object-contain"
                autoPlay={isPlaying}
                loop
                muted={isMuted}
                playsInline
              />
            ) : (
              <img
                src={stories[selectedStory].url}
                alt={stories[selectedStory].title}
                className="w-full h-screen object-contain"
              />
            )}
            {/*<div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black to-transparent">
              <h3 className="text-inter font-bold text-xl text-white mb-2">{stories[selectedStory].title}</h3>
              <p className="text-white">{stories[selectedStory].description}</p>
            </div>*/}
            <button
              onClick={closeLightbox}
              className="absolute top-4 right-4 text-white text-2xl bg-black bg-opacity-50 rounded-full w-10 h-10 flex items-center justify-center focus:outline-none"
              aria-label="Close lightbox"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <button
              onClick={() => navigateStory(-1)}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-4xl bg-black bg-opacity-50 rounded-full w-12 h-12 flex items-center justify-center focus:outline-none"
              aria-label="Previous story"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
              onClick={() => navigateStory(1)}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-4xl bg-black bg-opacity-50 rounded-full w-12 h-12 flex items-center justify-center focus:outline-none"
              aria-label="Next story"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
            {stories[selectedStory].type === 'video' && (
              <div className="absolute bottom-20 left-4 space-x-4">
                <button
                  onClick={togglePlayPause}
                  className="text-white text-xl bg-black bg-opacity-50 rounded-full w-10 h-10 flex items-center justify-center focus:outline-none"
                  aria-label={isPlaying ? "Pause" : "Play"}
                >
                  <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                </button>
                <button
                  onClick={toggleMute}
                  className="text-white text-xl bg-black bg-opacity-50 rounded-full w-10 h-10 flex items-center justify-center focus:outline-none"
                  aria-label={isMuted ? "Unmute" : "Mute"}
                >
                  <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ScreenshotGalleryStories;