import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userStatsApi } from '../../api/userStatsApi';
import { updateFlashcard } from '../flashcards/flashcardsSlice';

export const updateXP = createAsyncThunk(
    'userStats/updateXP',
    async ({ userId, xpGained }, { rejectWithValue }) => {
      try {
        const response = await userStatsApi.updateXP(userId, xpGained);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  /*export const updateStreak = createAsyncThunk(
    'userStats/updateStreak',
    async (userId, { rejectWithValue }) => {
      try {
        const response = await userStatsApi.updateStreak(userId);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );*/

export const fetchUserStats = createAsyncThunk(
  'userStats/fetch',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await userStatsApi.getUserStats(userId);
      console.log('user stats response:', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCategoryPerformance = createAsyncThunk(
    'userStats/fetchCategoryPerformance',
    async (userId, { rejectWithValue }) => {
      try {
        const response = await userStatsApi.getCategoryPerformance(userId);
        console.log('fetchCategoryPerformance response:', response.data);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  export const fetchProgressOverTime = createAsyncThunk(
    'userStats/fetchProgressOverTime',
    async (userId, { rejectWithValue }) => {
      try {
        const response = await userStatsApi.getProgressOverTime(userId);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  export const fetchSubjectAreaPerformance = createAsyncThunk(
    'userStats/fetchSubjectAreaPerformance',
    async (userId, { rejectWithValue }) => {
      try {
        const response = await userStatsApi.getSubjectAreaPerformance(userId);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  export const fetchScoreDistribution = createAsyncThunk(
    'userStats/fetchScoreDistribution',
    async (userId, { rejectWithValue }) => {
      try {
        const response = await userStatsApi.getScoreDistribution(userId);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  export const fetchImprovementAreas = createAsyncThunk(
    'userStats/fetchImprovementAreas',
    async (userId, { rejectWithValue }) => {
      try {
        const response = await userStatsApi.getImprovementAreas(userId);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  export const fetchLearningCurve = createAsyncThunk(
    'userStats/fetchLearningCurve',
    async (userId, { rejectWithValue }) => {
      try {
        const response = await userStatsApi.getLearningCurve(userId);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

const userStatsSlice = createSlice({
  name: 'userStats',
  initialState: {
    data: null,
    categoryPerformance: null,
    progressOverTime: null,
    subjectAreaPerformance: null,
    scoreDistribution: null,
    improvementAreas: null,
    learningCurve: null,
    xp: 0,
    streak: 0,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserStats.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchUserStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCategoryPerformance.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategoryPerformance.fulfilled, (state, action) => {
        state.loading = false;
        state.categoryPerformance = action.payload;
      })
      .addCase(fetchCategoryPerformance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
        .addCase(fetchProgressOverTime.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchProgressOverTime.fulfilled, (state, action) => {
            state.loading = false;
            state.progressOverTime = action.payload;
        })
        .addCase(fetchProgressOverTime.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(fetchSubjectAreaPerformance.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchSubjectAreaPerformance.fulfilled, (state, action) => {
            state.loading = false;
            state.subjectAreaPerformance = action.payload;
        })
        .addCase(fetchSubjectAreaPerformance.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(fetchScoreDistribution.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchScoreDistribution.fulfilled, (state, action) => {
            state.loading = false;
            state.scoreDistribution = action.payload;
        })
        .addCase(fetchScoreDistribution.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(fetchImprovementAreas.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchImprovementAreas.fulfilled, (state, action) => {
            state.loading = false;
            state.improvementAreas = action.payload;
        })
        .addCase(fetchImprovementAreas.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(fetchLearningCurve.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchLearningCurve.fulfilled, (state, action) => {
            state.loading = false;
            state.learningCurve = action.payload;
        })
        .addCase(fetchLearningCurve.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(updateXP.fulfilled, (state, action) => {
            state.xp = action.payload.xp;
          })
          /*.addCase(updateStreak.fulfilled, (state, action) => {
            state.streak = action.payload.streak;
          })*/
          .addCase(updateFlashcard.fulfilled, (state, action) => {
            // Update XP when a flashcard is reviewed
            state.xp += 10; // Adjust XP gain as needed
          });
  },
});

export default userStatsSlice.reducer;