import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getLeadMagnetById } from "../api/leadMagnetApi";
import { Link } from "react-router-dom";
import LeadMagnetCard from "../components/LeadMagnetCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faCheck, faCheckCircle, faCircleCheck, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import config from "../config/config";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";

const GetYourGuide = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [leadMagnet, setLeadMagnet] = useState(null);

  useEffect(() => {
    if (!id) {
      alert("No Guide ID provided!", id);
      navigate("/");
      return;
    }

    const fetchLeadMagnet = async () => {
      try {
        const data = await getLeadMagnetById(id);
        if (!data) {
          navigate("/");
        } else {
          setLeadMagnet(data);
        }
      } catch (error) {
        console.error("Error fetching free guide:", error);
        navigate("/");
      }
    };

    fetchLeadMagnet();
  }, [id, navigate]);

  const images = [
    config.landing_page_testimonial_1_image,
    config.landing_page_testimonial_2_image,
    config.landing_page_testimonial_3_image,
    config.landing_page_testimonial_4_image,
    config.landing_page_testimonial_5_image,
    config.landing_page_testimonial_6_image,
    config.landing_page_testimonial_7_image,
  ];
  
  const OverlappingImages = ({ images }) => {
    return (
      <div className="flex items-center justify-center">
        {images.map((image, index) => (
          <div
            key={index}
            className="w-10 h-10 rounded-full border-2 border-gray-100 overflow-hidden"
            style={{
              position: "relative",
              zIndex: index,
              marginLeft: index !== 0 ? "-24px" : "0",
            }}
          >
            <img
              src={image}
              alt={`Profile ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
        <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 text-4xl ml-2" />
      </div>
    );
  };

  if (!leadMagnet) {
    return <p>Loading...</p>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-700 to-white flex flex-col items-center justify-center">
      <div className="container mx-auto p-4 pb-0 text-center">
        <h1 className="text-xl md:text-5xl text-gray-100 lg:mb-6 text-center font-bold text-inter">
          <span>{config.leadMagnetHeader}</span><FontAwesomeIcon icon={faCheckCircle} className="hidden lg:inline ml-5 border-4 border-gray-100 rounded-full bg-blue-400 text-green-blue" />
        </h1>
        <div className="hidden lg:block text-lg text-gray-700 bg-yellow-100 border-2 border-yellow-600 px-4 py-2 rounded-full max-w-2xl text-inter font-bold mx-auto">
          {config.leadMagnetHeroSubheader}
        </div>
      </div>
      <LeadMagnetCard leadMagnet={leadMagnet} />
      <div className="container mx-auto p-4 text-center">
      <div className="p-4 pt-0 flex justify-center items-center gap-2 text-blue-600 mb-2">
                    Join others just like you. <OverlappingImages images={images} />
                  </div>
        <p className="text-base font-bold text-gray-800">
          Simply provide your best email address above, and you will instantly
          receive{" "}
          <br /><span className="text-green-blue text-inter bg-blue-600 px-2 py-1 rounded-full">{leadMagnet.title}</span>{" "}<br />
          directly to your inbox. It's like magic! 
        </p>
        
        
        <div onClick={()=>navigate('/')} className="cursor-pointer rounded-full px-4 py-2 bg-blue-100 border-4 border-blue-600 text-blue-600 text-2xl mt-10 max-w-2xl text-inter font-bold mx-auto">
          <FontAwesomeIcon icon={faHandshake} className="mr-2" />{config.leadMagnetBottomHeadline}
        </div>
        <div className="mt-10">
          <img src="https://otp.nyc3.cdn.digitaloceanspaces.com/trustBadges.png" alt="Trust Badges" className="mx-auto w-96" />
        </div>

        <div className="flex justify-center mt-20">
          <div className="text-center">
            <a
              href="https://gaim.ai"
              alt="GAIM.AI"
              target="_blank"
              rel="noreferrer noopener"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              <img
                src="https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png"
                alt="GAIM.AI Logo"
                className="w-5 h-5 mr-2"
              />
              Powered By GAIM.AI
            </a>
          </div>

          
        </div>
        <p className="text-center text-xs text-gray-500 mt-8">
            We value your privacy. Your data will never be sold or used for
            spam.
          </p>
      </div>
    </div>
  );
};

export default GetYourGuide;
