import axios from 'axios';
import config from '../../config/config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const apiURL = process.env.REACT_APP_API_URL;

// Async thunk for fetching thread history
export const fetchThreadHistory = createAsyncThunk('threadHistory/fetchThreadHistory', async (userId) => {
  const response = await axios.get(`${apiURL}/thread/getThreadHistory/${userId}`);
  const sortedData = response.data.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
  return sortedData;
});

const threadHistorySlice = createSlice({
  name: 'threadHistory',
  initialState: {
    threads: [],
    currentThreadMessages: [],
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  },
  reducers: {
    setThreadHistory: (state, action) => {
      state.threads = action.payload;
    },
    setCurrentThreadMessages: (state, action) => {
      state.currentThreadMessages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchThreadHistory.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchThreadHistory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.threads = action.payload;
      })
      .addCase(fetchThreadHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setThreadHistory, setCurrentThreadMessages } = threadHistorySlice.actions;

export default threadHistorySlice.reducer;
