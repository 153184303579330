// VideoGallery.js
import React, { useState, useEffect } from 'react';
import VideoCard from './VideoCard';
import VideoModal from './VideoModal';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

const VideoGallery = ({ videos }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const openModal = (video) => {
    setSelectedVideo(video);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

  const handleNextVideo = (nextVideo) => {
    setSelectedVideo(nextVideo);
  };

  return (
    <div className="container mx-auto px-4 py-8" ref={ref}>
      <h2 className="text-4xl font-bold text-inter mb-8 text-center">Take A Look Inside <FontAwesomeIcon icon={faArrowDown} className='ml-2 bounce' /></h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {videos.map((video, index) => (
          <VideoCard
            key={video.id}
            video={video}
            onClick={() => openModal(video)}
            inView={inView}
            delay={index * 100}
          />
        ))}
      </div>
      {selectedVideo && (
        <VideoModal
          video={selectedVideo}
          onClose={closeModal}
          videos={videos}
          onNextVideo={handleNextVideo}
        />
      )}
    </div>
  );
};

export default VideoGallery;