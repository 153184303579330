import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getLeadMagnetById } from "../../api/leadMagnetApi";
import FooterLandingPage from "./FooterLandingPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCheckCircle,
  faArrowRight,
  faCopy,
  faPlusCircle,
  faMedal,
  faArrowDown,
  faXmarkCircle,
  faEnvelopeCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import config from "../../config/config";
import { copyToClipboard } from "../../utils/commons";
import { addToast } from "../../features/ui/uiSlice";
import ScreenshotGallery from "../../components/ScreenshotGallery";
import VideoGallery from "../../components/videoGallery/VideoGallery";
import HomepagePurchaseFlow from "../Homepage/components/HomepagePurchaseFlow";

const LandingPage = () => {
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [leadMagnet, setLeadMagnet] = useState(null);
  const dispatch = useDispatch();
  const screenshots = config.landing_page_screenshots;
  const videos = config.landing_page_demo_videos;

  useEffect(() => {
    if (!id) {
      return;
    }

    const fetchLeadMagnet = async () => {
      try {
        const data = await getLeadMagnetById(id);
        if (!data) {
          navigate("/");
        } else {
          setLeadMagnet(data);
        }
      } catch (error) {
        console.error("Error fetching free guide:", error);
        navigate("/");
      }
    };

    fetchLeadMagnet();
  }, [id, navigate]);
  const [timeLeft, setTimeLeft] = useState(900); // 15 minutes in seconds
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const plans = [
    { duration: "1-Month Intensive Prep", price: 49.99, originalPrice: 129.98, discountPrice: 44.99, flashcards: 75, notes: 5, persona: "🔥 For the Go-Getters" },
    { duration: "3 Months All-Access", freeMonths: "👍 1 FREE Month!", price: 99.99, originalPrice: 199.98, discountPrice: 89.99, flashcards: 150, notes: 10, persona: "🌟 For the Steady Planners", forThePriceOf: "3 months for the price of 2" },
    {
      duration: "6 Months All-Access",
      freeMonths: "😍 3 FREE Months!",
      price: 159.99,
      originalPrice: 349.98,
      discountPrice: 144.99,
      recommended: true,
      flashcards: 300, 
      notes: 20, 
      persona: "🤓 For the Thorough Learners",
      forThePriceOf: "6 months for the price of 3"
    },
    { duration: "12 Months All-Access", freeMonths: "🤯 8 FREE Months!", price: 219.99, originalPrice: 499.98, discountPrice: 199.99, flashcards: 'UNLIMITED', notes: 'UNLIMITED', persona: "💪 For the Serious Preppers", forThePriceOf: "12 months for the price of 4" },
  ];

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!leadMagnet) return;
    const firstTimer = setTimeout(() => {
      setIsVisible(true);
      const secondTimer = setTimeout(() => {
        setIsVisible(false);
      }, 2000); // Hide after 2 seconds
      return () => clearTimeout(secondTimer);
    }, 1000); // Show after 1 second

    return () => clearTimeout(firstTimer);
  }, [leadMagnet]);

  return (
    <div className="bg-black-main min-h-screen text-white font-sans">
      {leadMagnet && <header className="py-0 px-6">
        <div
          className={`${
            isVisible ? "h-28" : "h-0"
          } relative mb-3 overflow-hidden transition-all duration-500`}
        >
          {" "}
          {/* Set a fixed height */}
          <div
            className={`absolute w-full transition-all duration-500 ${
              isVisible ? "h-28" : "h-0"
            }`}
          >
            <img
              src="https://otp.nyc3.cdn.digitaloceanspaces.com/animatedDiscoBall.gif"
              alt="disco ball"
              className={`mx-auto transition-all duration-500 ${
                isVisible ? "h-24" : "h-0"
              }`}
            />
            
          </div>
          
        </div>
        {/*<div className="text-inter font-bold flex text-lg text-center text-green-500 leading-none pt-3">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-green-500 mr-2"
          />
          YES! {leadMagnet?.title} is on the way to your inbox!
        </div>*/}
      </header>}
      {leadMagnet && <div className="text-center text-inter text-gray-100 px-4">
          <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="mr-2" />If you don't see your {leadMagnet?.title || 'resource'} in your inbox, check your spam folder.
        </div>}
      <main className="container mx-auto px-4 py-8 text-inter">
      
        <section className="text-center mb-4">
           {/*<h1 className="text-4xl md:text-5xl font-bold mb-4 text-inter">
            Transform Your {config.exam_name} Prep - Conquer the Exam with
            Confidence!
          </h1>
          <p className="text-xl mb-6">
            Join other future{" "}
            <span className="lowercase">{config.graduate_name}</span>s just like you who have
            supercharged their {config.exam_name} preparation with{" "}
            {config.siteName}. Don't just dream of passing; make it a reality!
          </p>*/}

          <div className="bg-red-100 text-gray-100 text-4xl font-bold py-3 px-6 rounded border-b-8 border-red-500 inline-block mb-6 w-full max-w-2xl">
            <div className="mb-4 text-inter font-bold text-2xl text-red-500">
              Your Exclusive {config.exam_name} Offer Expires Soon!
            </div>
            <div className="flex justify-center items-center mb-6 rounded shadow-xl max-w-2xl mx-auto">
              <img
                src={config.leadMagnetLandingPageImage1}
                alt={`Pass your ${config.exam_name} guaranteed`}
                className="rounded-lg"
              />
            </div>
            <div className="mb-2">
              <img
                src="https://otp.nyc3.cdn.digitaloceanspaces.com/animatedHandsTimesUp.gif"
                alt="Sign up for exam success"
                className="w-24 h-24 mx-auto"
              />
            </div>
            <div className="flex justify-center items-center w-full text-inter font-bold text-5xl mb-2 text-gray-800">
              <FontAwesomeIcon
                icon={faClock}
                className="text-red-500 mr-2 pulse"
              />
              <span>{formatTime(timeLeft)}</span>
            </div>
            <div className="text-base md:text-xl font-bold text-gray-800 mb-4 mb-1">
              Skyrocket your chances of passing with our AI-powered study system. If you don't pass, we'll refund your purchase!
            </div>
            <div className="text-lg mb-2 text-inter font-bold text-gray-800">
              Use Code:
            </div>
            <div
              onClick={() => {
                copyToClipboard("TENOFFHURRY");
                dispatch(addToast("Copied to clipboard!", "success"));
              }}
              className="w-full max-w-xl cursor-pointer bg-red-500 text-gray-100 text-2xl font-bold py-3 px-6 rounded-full inline-block mb-2"
            >
              TENOFFHURRY
              <FontAwesomeIcon icon={faCopy} className="ml-2" />
            </div>
            <div className="text-lg text-inter font-bold text-gray-800">
              for 10% OFF any{" "}
              <span className="text-red-500">{config.siteName}</span> package 🤯
            </div>
            <ul class="text-sm text-inter text-left text-gray-700 mt-4 list-none px-4 md:px-6 lg:px-10 xl:px-16">
              <li class="flex items-center mb-2">
                <span class="min-w-6 min-h-6 flex items-center justify-center bg-blue-500 text-white rounded-full mr-2">
                  1
                </span>
                Click the button to copy your code and use it when you checkout.
              </li>
              <li class="flex items-center mb-2">
                <span class="min-w-6 min-h-6 flex items-center justify-center bg-blue-500 text-white rounded-full mr-2">
                  2
                </span>
                Choose your perfect package below. <FontAwesomeIcon icon={faArrowDown} className="text-blue-500 ml-2 text-xl" />
              </li>
              <li class="flex items-center mb-2">
                <span class="min-w-6 min-h-6 flex items-center justify-center bg-blue-500 text-white rounded-full mr-2">
                  3
                </span>
                Crush your {config.exam_name} with confidence!
              </li>
            </ul>
          </div>
        </section>
        <div id='packages'>
        <HomepagePurchaseFlow />
        </div>
        
        {/*<div className="mb-6">
            <div className="text-center text-gray-100 text-3xl font-bold mb-4 text-inter">
                All packages include:
            </div>
            <div className="flex flex-col md:flex-row md:flex-wrap md:justify-center md:items-center gap-2 text-lg font-bold text-inter text-center">
                <div className="border-2 border-gray-100 hover:border-green-400 cursor-pointer text-gray-100 rounded-lg shadow-lg px-4 py-2">
                    <h3><FontAwesomeIcon icon={faCheckCircle} className="text-green-400 mr-2" />Unlimited AI-generated Practice Questions</h3>
                </div>
                <div className="border-2 border-gray-100 hover:border-green-400 cursor-pointer text-gray-100 rounded-lg shadow-lg px-4 py-2">
                    <h3><FontAwesomeIcon icon={faCheckCircle} className="text-green-400 mr-2" />24/7 AI Tutor Access</h3>
                </div>
                <div className="border-2 border-gray-100 hover:border-green-400 cursor-pointer text-gray-100 rounded-lg shadow-lg px-4 py-2">
                    <h3><FontAwesomeIcon icon={faCheckCircle} className="text-green-400 mr-2" />Personalized Study Plans</h3>
                </div>
                <div className="border-2 border-gray-100 hover:border-green-400 cursor-pointer text-gray-100 rounded-lg shadow-lg px-4 py-2">
                    <h3><FontAwesomeIcon icon={faCheckCircle} className="text-green-400 mr-2" />Performance Analytics</h3>
                </div>
                <div className="border-2 border-gray-100 hover:border-green-400 cursor-pointer text-gray-100 rounded-lg shadow-lg px-4 py-2">
                    <h3><FontAwesomeIcon icon={faCheckCircle} className="text-green-400 mr-2" />Multi-Format Explanations</h3>
                </div>
                <div className="border-2 border-gray-100 hover:border-green-400 cursor-pointer text-gray-100 rounded-lg shadow-lg px-4 py-2">
                    <h3><FontAwesomeIcon icon={faCheckCircle} className="text-green-400 mr-2" />Gamified Flashcards</h3>
                </div>
                <div className="border-2 border-gray-100 hover:border-green-400 cursor-pointer text-gray-100 rounded-lg shadow-lg px-4 py-2">
                    <h3><FontAwesomeIcon icon={faCheckCircle} className="text-green-400 mr-2" />Saved Study Notes</h3>
                </div>
                <div className="border-2 border-gray-100 hover:border-green-400 cursor-pointer text-gray-100 rounded-lg shadow-lg px-4 py-2">
                    <h3><FontAwesomeIcon icon={faCheckCircle} className="text-green-400 mr-2" />One-Time Payment</h3>
                </div>
                <div className="border-2 border-gray-100 hover:border-green-400 cursor-pointer text-gray-100 rounded-lg shadow-lg px-4 py-2">
                    <h3><FontAwesomeIcon icon={faMedal} className="text-yellow-400 mr-2" />Money-Back Guarantee</h3>
                </div>
            </div>
        </div>
        <section id="packages" className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4 text-inter">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`bg-white text-blue-900 rounded-lg shadow-lg p-6 text-center ${
                plan.recommended ? "gradient-border-green-blue" : ""
              }`}
            >
              {plan.freeMonths && <p className="text-lg text-inter font-bold mb-2 text-red-500">
                {plan.freeMonths}
              </p>}
              <h3
                className={`text-2xl font-bold mb-4 ${
                  plan.recommended ? "text-gradient-green-blue" : ""
                }`}
              >
                {plan.duration}
              </h3>
              <div className="flex flex-col justify-center items-center mb-6 gap-2">
                <div className="bg-blue-100 text-blue-800 rounded-full px-3 py-1 text-sm font-semibold">
                    <FontAwesomeIcon icon={faPlusCircle} className="text-green-500 mr-2" />{plan.flashcards} Gamified Flashcards
                </div>
                <div className="bg-blue-100 text-blue-800 rounded-full px-3 py-1 text-sm font-semibold">
                    <FontAwesomeIcon icon={faPlusCircle} className="text-green-500 mr-2" />{plan.notes} Saved Notes
                </div>
                {index > 0 && (
                    <div className="bg-blue-100 text-blue-800 rounded-full px-3 py-1 text-sm font-semibold">
                        <FontAwesomeIcon icon={faPlusCircle} className="text-green-500 mr-2" />Priority Support
                    </div>
                )}
            </div>
              <p
                className={`text-2xl text-inter font-bold mb-2 leading-none ${
                  plan.recommended ? "text-green-500 " : ""
                }`}
              >
                
                With Discount: ${plan.discountPrice.toFixed(2)}
              </p>
              <p className="text-red-500 line-through font-bold text-inter mb-0">
                ${plan.price.toFixed(2)}
              </p>
              <p className="text-gray-500 line-through mb-4">
                Orig. ${plan.originalPrice.toFixed(2)}
              </p>
              <button
                onClick={() => navigate('/loginpurchase')}
                className={`w-full py-2 px-4 rounded-full text-white font-bold transition duration-300 hover:shadow-xl hover:scale-105 ${
                  plan.recommended ? "gradient-green-blue " : "bg-primary"
                }`}
              >
                GET THIS PLAN <FontAwesomeIcon icon={faArrowRight} className="ml-2 shake" />
              </button>
              {plan.forThePriceOf && <p className="text-sm text-gray-500 mt-4">
                Get {plan.forThePriceOf}!
              </p>}
              <h2 className="text-lg font-bold mb-2 mt-4 text-inter">
                {plan.persona}
              </h2>
              {plan.recommended && (
                <div className="mt-2 text-center text-lg text-inter font-bold text-gradient-green-blue">
                  Most Popular with {config.graduate_name}s!
                </div>
              )}
            </div>
          ))}
        </section>*/}
        <div className="w-full text-red-500 text-lg text-center text-inter font-bold">
          This offer is available for the next{" "}
          <span className="font-bold">{formatTime(timeLeft)}</span> only!
        </div>
        {/*<div>
          <img src="https://otp.nyc3.cdn.digitaloceanspaces.com/trustBadges.png" alt="Trust Badges" className="mx-auto w-96" />
        </div>*/}
        <VideoGallery videos={videos} />
        

        <section className="text-center mb-12 mt-12">
          <h2 className="text-3xl font-bold mb-6 text-inter">Why Act Immediately?</h2>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-blue-800 p-6 rounded-lg">
              <div className="text-5xl font-bold mb-2">💸</div>
              <h3 className="text-xl font-bold mb-2">Exclusive Discount</h3>
              <p>Save 10% with our limited-time offer. Invest in your future now before time runs out!</p>
            </div>
            <div className="bg-blue-800 p-6 rounded-lg">
                <div className="text-5xl font-bold mb-2">🤖</div>
              <h3 className="text-xl font-bold mb-2">AI-Powered Learning</h3>
              <p>Personalized study plans adapt to your needs, ensuring you focus on the right material.</p>
            </div>
            <div className="bg-blue-800 p-6 rounded-lg">
              <div className="text-5xl font-bold mb-2">🏆</div>
              <h3 className="text-xl font-bold mb-2">Pass Guarantee</h3>
              <p>Our system is proven to boost pass rates. We're so confident, we offer a money-back guarantee!</p>
            </div>
          </div>
        </section>

        <section className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-6 text-inter text-red-500">
            Failing the NCLEX sucks!<br />Don't let it happen to you.
          </h2>
          <p className="text-xl mb-6">
            Join other successful <span className="lowercase">{config.graduate_name}s</span> who <strong>passed their {config.exam_name}</strong> with our
            AI-powered study system.
          </p>
          <button
            onClick={() => {
              document.getElementById('packages').scrollIntoView({ behavior: 'smooth' });
            }}
            className="bg-green-500 hover:bg-green-600 shake text-white font-bold py-3 px-8 rounded-full text-lg transition duration-300 inline-flex items-center"
          >
            Claim Your Discount Now{" "}
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </button>
        </section>

        <section className="text-center mb-12 mt-12 flex flex-col justify-center items-center gap-10">
        <div className=" max-w-2xl px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
                <div className="text-inter font-bold">
                  <span className="text-primary text-5xl text-inter font-bold">"</span>
                  <span className="text-3xl font-semibold">{config.landing_page_testimonial_1}</span>
                  <p className="text-primary text-5xl text-inter font-bold text-right">
                    "
                  </p>
                  <img
                    className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary"
                    src={config.landing_page_testimonial_1_image}
                    alt={`Testimonial from ${config.landing_page_testimonial_1_author}`}
                  />
                  <p className="mt-4">
                    {config.landing_page_testimonial_1_author}
                  </p>
                  <img
                    className="mb-4 mt-4 w-28 mx-auto"
                    src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png"
                    alt="5-star review rating"
                  />
                </div>
              </div>
              <div className=" max-w-2xl   px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
                <div className="text-inter font-bold">
                  <span className="text-primary text-5xl text-inter font-bold">"</span>
                  <span className="text-3xl font-semibold">{config.landing_page_testimonial_2}</span>
                  <p className="text-primary text-5xl text-inter font-bold text-right">
                    "
                  </p>
                  <img
                    className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary"
                    src={config.landing_page_testimonial_2_image}
                    alt={`Testimonial from ${config.landing_page_testimonial_2_author}`}
                  />
                  <p className="mt-4">
                    {config.landing_page_testimonial_2_author}
                  </p>
                  <img
                    className="mb-4 mt-4 w-28 mx-auto"
                    src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png"
                    alt="5-star review rating"
                  />
                </div>
              </div>
              <div className=" max-w-2xl px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
                <div className="text-inter font-bold">
                  <span className="text-primary text-5xl text-inter font-bold">"</span>
                  <span className="text-3xl font-semibold">{config.landing_page_testimonial_3}</span>
                  <p className="text-primary text-5xl text-inter font-bold text-right">
                    "
                  </p>
                  <img
                    className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary"
                    src={config.landing_page_testimonial_3_image}
                    alt={`Testimonial from ${config.landing_page_testimonial_3_author}`}
                  />
                  <p className="mt-4">
                    {config.landing_page_testimonial_3_author}
                  </p>
                  <img
                    className="mb-4 mt-4 w-28 mx-auto"
                    src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png"
                    alt="5-star review rating"
                  />
                </div>
              </div>
        </section>

        <ScreenshotGallery screenshots={screenshots} />

        <section className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-6 text-inter">
            Don't Miss This Opportunity!
          </h2>
          <p className="text-xl mb-6">
            Join other successful <span className="lowercase">{config.graduate_name}s</span> who <strong>passed their {config.exam_name}</strong> with our
            AI-powered study system.
          </p>
          <button
            onClick={() => {
              document.getElementById('packages').scrollIntoView({ behavior: 'smooth' });
            }}
            className="bg-green-500 hover:bg-green-600 shake text-white font-bold py-3 px-8 rounded-full text-lg transition duration-300 inline-flex items-center"
          >
            Claim Your Discount Now{" "}
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </button>
        </section>

        <section className="bg-gradient-to-b from-blue-400 to-blue-900 p-6 rounded-lg mb-12 max-w-2xl mx-auto text-inter">
            <div className="text-5xl font-bold mb-2 text-center pulse">🏆</div>
          <h2 className="text-2xl font-bold mb-4 text-center text-inter">
            Money-Back Guarantee
          </h2>
          <p className="text-center text-xl">
            We're so confident in our system that <span className="font-bold text-green-400">if you don't pass your {config.exam_name},
            we'll refund your entire purchase.</span> With our state-of-the-art AI exam prep tools, awesome flashcards, and personalized study plans, we have zero doubt that you will successfully pass your exam!
          </p>
        </section>

        
        <div className="bg-blue-800 p-6 rounded-lg max-w-2xl mx-auto my-10">
                <img src={config.leadMagnetLandingPageImage4} alt='You will succeed' className="" />
              </div>

        <section className="mb-12">
          <h2 className="text-3xl font-bold mb-6 text-center text-inter">Everything You Need to Succeed</h2>
          <div className="grid md:grid-cols-3 gap-6">
            {[
              { icon: "🤖", title: "Unlimited AI-Generated Questions", description: "Never run out of fresh, relevant practice questions tailored to your needs." },
              { icon: "🎓", title: `Personal AI ${config.exam_name} Tutor`, description: "24/7 access to an AI tutor for instant answers and explanations, or even just chat about the exam." },
              { icon: "📈", title: "Adaptive Learning", description: "Our system adapts to your progress, focusing on areas that need improvement." },
              { icon: "📚", title: "Gamified Flashcards", description: "Automatically generated flashcards from your quiz results, making learning fun." },
              { icon: "📊", title: "Comprehensive Analytics", description: "In-depth performance tracking to optimize your study strategy." },
              { icon: "📝", title: "Multi-Format Explanations", description: "Get explanations tailored to your learning style - ELI5, stories, mnemonics, and more." },
            ].map((feature, index) => (
              <div key={index} className="bg-blue-800 p-6 rounded-lg text-center">
                <div className="text-4xl mb-2">{feature.icon}</div>
                <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-6 text-inter">
            Pass Your NCLEX!
          </h2>
          <p className="text-xl mb-6">
            The only thing standing between your and your dream life is your {config.exam_name}. Don't let it hold you back.
          </p>
          <button
            onClick={() => {
              document.getElementById('packages').scrollIntoView({ behavior: 'smooth' });
            }}
            className="bg-green-500 hover:bg-green-600 shake text-white font-bold py-3 px-8 rounded-full text-lg transition duration-300 inline-flex items-center"
          >
            Claim Your Discount Now{" "}
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </button>
        </section>

        <section className="mb-12">
          
          <div className="bg-blue-800 p-6 rounded-lg max-w-2xl mx-auto mb-10">
                <img src={config.leadMagnetLandingPageImage2} alt='Pass your Exam Now' className="" />
              </div>
          <div className="grid md:grid-cols-2 gap-6">
          <div className="text-3xl font-bold my-auto text-center text-inter">Overcome Your Fears and Smash Through Obstacles</div>
            {[
              { title: "Fear of Failure", content: `With ${config.siteName}, fear of failure becomes a thing of the past. Our adaptive learning and personalized feedback ensure you are always on the right track.` },
              { title: "Overwhelming Material", content: "Streamline your study with our AI-generated questions and targeted learning paths. Focus on what matters most." },
              { title: "Time Management", content: "Our comprehensive analytics help you manage your time effectively, ensuring you cover all the necessary material without the stress." },
              { title: "Financial Impact", content: `Invest in your future with our affordable plans and exclusive discount. Passing your ${config.exam_name} will open doors to a rewarding career.` },
              { title: "Lack of Support", content: `Our AI tutor is available 24/7 to answer your questions. You're never alone in your study journey.` },
            ].map((obstacle, index) => (
              <div key={index} className="bg-blue-800 p-6 rounded-lg">
                <h3 className="text-xl font-bold mb-2"><FontAwesomeIcon icon={faXmarkCircle} className="mr-2 text-red-500" />{obstacle.title}</h3>
                <p>{obstacle.content}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-6 text-inter">
            Don't Let Your Fears Hold You Back!
          </h2>
          <p className="text-xl mb-6">
            Join other successful <span className="lowercase">{config.graduate_name}s</span> just like you who <strong>passed their {config.exam_name}</strong> with our
            AI-powered study system. Your dream job is waiting.
          </p>
          <button
            onClick={() => {
              document.getElementById('packages').scrollIntoView({ behavior: 'smooth' });
            }}
            className="bg-green-500 hover:bg-green-600 shake text-white font-bold py-3 px-8 rounded-full text-lg transition duration-300 inline-flex items-center"
          >
            Claim Your Discount Now{" "}
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </button>

          <div className="bg-blue-800 p-6 rounded-lg max-w-2xl mx-auto my-10">
                <img src={config.leadMagnetLandingPageImage3} alt='Achieve your dreams' className="" />
              </div>
        </section>
        <FooterLandingPage />
      </main>

      
    </div>
  );
};

export default LandingPage;
