import React from 'react';

// Define the component and accept props
function IframeComponent({ src, title = "Default Title", width = "100%", height = "750rem" }) {
  return (
    <div>
      <iframe
        src={src} // Use the src prop for the iframe's src attribute
        title={title}
        width={width}
        height={height}
        frameBorder="0"
        allowFullScreen
        className='rounded-lg shadow-lg'
      ></iframe>
    </div>
  );
}

export default IframeComponent;
