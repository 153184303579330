import React, { useState, useEffect } from 'react';
import { selectUser } from '../features/user/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { closeModal, selectModalOpen } from '../features/userPrompts/userPromptsSlice';
import { fetchPrompts, deletePrompt } from '../features/userPrompts/userPromptsSlice';
import { selectAllPrompts } from '../features/userPrompts/userPromptsSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faSearch, faTag } from '@fortawesome/free-solid-svg-icons';
import { addToast } from '../features/ui/uiSlice';
import { setCurrentPrompt } from '../features/chat/chatSlice';


const UserPrompts = () => {
    const modal = useSelector((state) => state.userPrompts);
    console.log('toolType: ' + modal.toolType);
    let toolType = '';
    if (modal.toolType === 'image') {
        toolType = 'image';
    } else {
        toolType = 'text';
    }
    const currentTool = useSelector(selectCurrentTool);
    const [filter, setFilter] = useState(toolType);
    const [searchQuery, setSearchQuery] = useState('');
    const [expandedPromptIds, setExpandedPromptIds] = useState([]);
    const dispatch = useDispatch();
    const prompts = useSelector(selectAllPrompts);
    const user = useSelector(selectUser);
    

    const togglePromptExpansion = (id) => {
        setExpandedPromptIds((currentIds) =>
          currentIds.includes(id) ? currentIds.filter((curId) => curId !== id) : [...currentIds, id]
        );
      };
    
      const renderPromptText = (prompt) => {
        const isExpanded = expandedPromptIds.includes(prompt._id);
        const text = isExpanded ? prompt.prompt : `${prompt.prompt.substring(0, 300)}`;
        return (
          <p className='p-2 bg-primary text-inverted rounded'>
            {text}
            {prompt.prompt.length > 300 && (
              <button className="text-gray-800 ml-2" onClick={() => togglePromptExpansion(prompt._id)}>
                {isExpanded ? 'See less' : '...See more'}
              </button>
            )}
          </p>
        );
      };

  
    useEffect(() => {
      dispatch(fetchPrompts(user?._id));
    }, [dispatch]);
  
    const displayPrompts = prompts.filter(prompt => {
      if (filter !== 'all' && prompt.type !== filter) return false;
      if (searchQuery && !prompt.prompt.toLowerCase().includes(searchQuery.toLowerCase()) && !prompt.tags.join(' ').toLowerCase().includes(searchQuery.toLowerCase())) {
        return false;
      }
      return true;
    });
  
    const handleSearch = (event) => {
      setSearchQuery(event.target.value);
    };
  
    const handleFilter = (type) => {
      setFilter(type);
    };
  
    const handleCopy = (prompt) => {
      navigator.clipboard.writeText(prompt);
      dispatch(addToast('Prompt copied to clipboard.', 'success'));
    };

    const handleUse = (prompt) => {
        dispatch(setCurrentPrompt(prompt));
        dispatch(closeModal());
      };
  
    const handleDelete = (promptId) => {
      dispatch(deletePrompt({ userId: user?._id, promptId }));
      dispatch(addToast('Prompt deleted.', 'success'));
    };

  return (
    <div className="p-4 bg-primary">
      <h2 className="text-2xl text-inverted font-semibold mb-4"><FontAwesomeIcon icon={faClipboardList} className='mr-3' />Saved Prompts ({displayPrompts.length})</h2>
      <div className='flex flex-row justify-between items-center mb-4 rounded bg-gray-200 pl-3 pr-3'>
        <div className="relative w-2/5">
            <FontAwesomeIcon icon={faSearch} className="absolute left-2 top-2 text-gray-400" />
            <input
            type="text"
            className="pl-10 pr-4 py-2 w-full border rounded-md"
            placeholder="Search saved prompts..."
            onChange={handleSearch}
            />
        </div>
        <div className="p-2 pb-3 rounded w-2/5">
            <div className="text-sm mb-2">Filter by Type:</div>
            <button className={`px-4 py-2 mr-2 rounded border border-gray-500 ${filter === 'all' ? 'bg-primary text-inverted' : 'bg-gray-200'}`} onClick={() => handleFilter('all')}>All</button>
            <button className={`px-4 py-2 mr-2 rounded border border-gray-500 ${filter === 'text' ? 'bg-primary text-inverted' : 'bg-gray-200'}`} onClick={() => handleFilter('text')}>Text</button>
            <button className={`px-4 py-2 rounded border border-gray-500 ${filter === 'image' ? 'bg-primary text-inverted' : 'bg-gray-200'}`} onClick={() => handleFilter('image')}>Image</button>
        </div>
    </div>
      <div className="overflow-auto space-y-4 border border-gray-900 border-2 rounded" style={{maxHeight: '55vh'}}>
        {displayPrompts.map((prompt) => (
          <div key={prompt._id} className="p-4 m-4 border shadow-lg rounded-md bg-white">
            {renderPromptText(prompt)}
            <div className="flex flex-wrap gap-2 my-2 items-center">
                <FontAwesomeIcon icon={faTag} className='text-primary'/><span>Tags: </span>
              {prompt.tags.map((tag, index) => (
                <span key={index} className="px-2 py-1 bg-gray-200 rounded border border-gray-500">{tag}</span>
              ))}
            </div>
            <div className="flex space-x-2 justify-center">
              <button className="px-4 py-2 bg-primary text-inverted rounded-md" onClick={() => handleCopy(prompt.prompt)}>Copy</button>
              <button className="px-4 py-2 bg-primary text-inverted rounded-md" onClick={() => handleUse(prompt.prompt)}>Use</button>
              <button className="px-4 py-2 bg-red-500 text-white rounded-md" onClick={() => handleDelete(prompt._id)}>Delete</button>
            </div>
            <div className="text-sm text-gray-500 mt-2">Created: {new Date(prompt.date_saved).toLocaleString()}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserPrompts;
