import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentTool, setCurrentTool } from '../features/currentTool/currentToolSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChatComponent from '../components/ChatComponent';
import { close } from '../features/mobile/menuSlice';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import ToolsModal from '../components/ToolsModal';
import { openToolsModal } from '../features/tools/toolsModalSlice';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { setUser, selectUser } from '../features/user/userSlice';
import { addToast } from '../features/ui/uiSlice';
import axios from 'axios';
import { showModal } from '../features/ui/uiSlice';

const apiURL = process.env.REACT_APP_API_URL;

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  if (!user) navigate('/login');
  const fetchedTools = useSelector((state) => state.tools.items);
  const currentTool = useSelector(selectCurrentTool);
  if (!currentTool && fetchedTools?.length > 0) {
    dispatch(setCurrentTool(fetchedTools[0]));
  }
  
  // Favorites Begin
  const [isFavorite, setIsFavorite] = useState(
    user?.favorites?.generators?.includes(currentTool?._id)
  );

  useEffect(() => {
      setIsFavorite(user?.favorites?.generators?.includes(currentTool?._id));
  }, [user, currentTool]);

  const toggleFavorite = async () => {
    let updatedFavorites;
    const newFavoriteStatus = !isFavorite;
    setIsFavorite(newFavoriteStatus);
    try {
        await axios.post(`${apiURL}/favorites/update`, {
            dynamic_id: user?.dynamic_id,
            item_id: currentTool._id,
            type: 'generators',
            isFavorite: newFavoriteStatus,
        });
        dispatch(addToast(newFavoriteStatus ? 'Favorite Added!' : 'Favorite Removed!', 'success'));

        if (newFavoriteStatus) {
            updatedFavorites = {
                ...user.favorites,
                ['generators']: [...user.favorites['generators'], currentTool._id],
            };

        } else {
            updatedFavorites = {
                ...user.favorites,
                ['generators']: user.favorites['generators'].filter((id) => id !== currentTool._id),
            };
        }
        const updatedUser = {
            ...user,
            favorites: updatedFavorites,
        };
        dispatch(setUser(updatedUser));
      } catch (error) {
        setIsFavorite(!newFavoriteStatus);
        dispatch(addToast('Favorite NOT updated! Please try again.', 'error'));
      }
  };
  // Favorites End

  if (fetchedTools.length === 0 || !fetchedTools.tools.length > 0 || !fetchedTools.assistants > 0) return <span>Loading...</span>;

  return (
    <>
      <div className='flex flex-col min-w-0 md:flex-row w-full gap-2 h-95vh lg:pr-4'>
        <div className="xl:hidden flex-grow min-w-0">
          <div className='hidden flex flex-row items-center justify-center gap-2'>
            <button onClick={toggleFavorite} className="p-1 rounded border border-primary w-1/5 mb-2 hover:bg-gray-700 hover:text-gray-200">
                <FontAwesomeIcon icon={isFavorite ? solidStar : regularStar} className="text-yellow-400"/>
            </button>
            <button onClick={() => dispatch(showModal(currentTool.description))} className="p-1 rounded border border-primary w-1/5 mb-2 hover:bg-gray-700 hover:text-gray-200">
                <FontAwesomeIcon icon={faCircleInfo} className=""/>
            </button>
            <Link to={`/`} className="text-poetsen w-3/5 justify-center flex items-center px-1 py-1 border border-primary hover:bg-gray-700 hover:text-gray-200 focus:outline-none rounded mb-2" onClick={() => {dispatch(openToolsModal()); dispatch(close());}}>
                View All Tools
            </Link>
          </div>
          <ChatComponent />
        </div>
        <div className="hidden xl:flex flex-grow min-w-0">
          <ChatComponent />
        </div>
      </div>
      <ToolsModal />
    </>
  );
}

export default Home;

