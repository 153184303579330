// cacheSlice.js
import { createSlice } from '@reduxjs/toolkit';

const cacheSlice = createSlice({
  name: 'cache',
  initialState: {
    tools: { data: null, timestamp: null },
    packages: { data: null, timestamp: null },
    banners: { data: null, timestamp: null },
    boards: { data: null, timestamp: null },
    boardItems: { data: null, timestamp: null },
  },
  reducers: {
    setCacheData: (state, action) => {
      const { key, data } = action.payload;
      state[key] = { data, timestamp: Date.now() };
    },
  },
});

export const { setCacheData } = cacheSlice.actions;
export default cacheSlice.reducer;