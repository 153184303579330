import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../config/config';
import axios from 'axios';

const apiURL = process.env.REACT_APP_API_URL;
const app_id = config.app_id;

export const fetchLeadMagnets = createAsyncThunk(
  'bookshelf/fetchLeadMagnets',
  async ({ page, itemsPerPage }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiURL}/leadmagnets/getall/${app_id}?page=${page}&limit=${itemsPerPage}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchLeadMagnets = createAsyncThunk(
  'bookshelf/searchLeadMagnets',
  async (searchTerm, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiURL}/leadmagnets/search/${app_id}?term=${searchTerm}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const filterLeadMagnets = createAsyncThunk(
  'bookshelf/filterLeadMagnets',
  async (filters, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${apiURL}/leadmagnets/filter/${app_id}`, filters);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const toggleFavorite = createAsyncThunk(
    'bookshelf/toggleFavorite',
    async ({ itemId, userId }, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${apiURL}/leadmagnets/${itemId}/favorite`, { userId });
        return { itemId, favorites: response.data.favorites.bookshelf };
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  export const fetchFavorites = createAsyncThunk(
    'bookshelf/fetchFavorites',
    async (userId, { rejectWithValue }) => {
      try {
        const response = await axios.get(`${apiURL}/leadmagnets/favorites?userId=${userId}`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  const bookshelfSlice = createSlice({
    name: 'bookshelf',
    initialState: {
      items: [],
      status: 'idle',
      error: null,
      favorites: [],
      categories: [],
      formats: [],
      tags: [],
      totalPages: 1,
      currentPage: 1,
      showOnlyFavorites: false,
    },
    reducers: {
      setCurrentPage: (state, action) => {
        state.currentPage = action.payload;
      },
      updateItemFavoriteStatus: (state, action) => {
        const { itemId, isFavorite } = action.payload;
        const item = state.items.find(item => item._id === itemId);
        if (item) {
          item.isFavorite = isFavorite;
        }
      },
      setShowOnlyFavorites: (state, action) => {
        state.showOnlyFavorites = action.payload;
        state.filteredItems = action.payload
          ? state.items.filter(item => state.favorites.includes(item._id))
          : state.items;
      },
      applyFilters: (state, action) => {
        const { categories, formats, tags } = action.payload;
        state.filteredItems = state.items.filter(item => {
          const categoryMatch = !categories.length || categories.includes(item.category);
          const formatMatch = !formats.length || formats.includes(item.format);
          const tagMatch = !tags.length || item.tags.some(tag => tags.includes(tag));
          return categoryMatch && formatMatch && tagMatch;
        });
        if (state.showOnlyFavorites) {
          state.filteredItems = state.filteredItems.filter(item => state.favorites.includes(item._id));
        }
      },
    },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadMagnets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLeadMagnets.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload.items;
        state.filteredItems = state.showOnlyFavorites
          ? action.payload.items.filter(item => state.favorites.includes(item._id))
          : action.payload.items;
        state.totalPages = action.payload.totalPages;
        state.categories = action.payload.categories;
        state.formats = action.payload.formats;
        state.tags = action.payload.tags;
      })
      .addCase(fetchLeadMagnets.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(searchLeadMagnets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchLeadMagnets.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload.items;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(searchLeadMagnets.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(filterLeadMagnets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(filterLeadMagnets.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload.items;
        state.filteredItems = state.showOnlyFavorites
          ? action.payload.items.filter(item => state.favorites.includes(item._id))
          : action.payload.items;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(filterLeadMagnets.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(toggleFavorite.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(toggleFavorite.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.favorites = action.payload.favorites;
        state.items = state.items.map(item => ({
          ...item,
          isFavorite: state.favorites.includes(item._id)
        }));
        state.filteredItems = state.showOnlyFavorites
          ? state.items.filter(item => state.favorites.includes(item._id))
          : state.items;
      })
      .addCase(toggleFavorite.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        state.favorites = action.payload.favorites;
        state.filteredItems = state.showOnlyFavorites
          ? state.items.filter(item => state.favorites.includes(item._id))
          : state.items;
      });
  },
});

export const { setCurrentPage, updateItemFavoriteStatus, setShowOnlyFavorites, applyFilters } = bookshelfSlice.actions;

export default bookshelfSlice.reducer;