// src/components/Modal.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '../features/ui/uiSlice';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { addToast } from '../features/ui/uiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faX } from '@fortawesome/free-solid-svg-icons';

const Modal = () => {
  const { isVisible, content, copy } = useSelector((state) => state.ui.modal);
  const dispatch = useDispatch();

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-primary p-4 rounded flex flex-col w-full md:w-1/3">
        <div className='max-h-40rem overflow-auto slim-scrollbar'>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
        </div>
          {copy && (
            <button
              onClick={() => {
                navigator.clipboard.writeText(content);
                dispatch(hideModal());
                dispatch(addToast('Copied to Clipboard!', 'success'));
              }}
              className="bg-body hover:text-white hover:bg-gray-700 rounded p-2 mt-10"
            >
              <FontAwesomeIcon icon={faCopy} className='mr-2' />Copy to Clipboard
            </button>
          )}
          <button className='bg-body hover:text-white hover:bg-gray-700 rounded p-2 mt-2 ' onClick={() => dispatch(hideModal())}><FontAwesomeIcon icon={faX} className='mr-2' />Close</button>
      </div>
    </div>
  );
};

export default Modal;
