import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  session: null, // Initially, there is no session
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    // Action to set or update session information
    setSession: (state, action) => {
      state.session = action.payload;
    },
    // Action to delete (or clear) session information
    deleteSession: (state) => {
      state.session = null;
    },
  },
});

// Export actions for use in components
export const { setSession, deleteSession } = sessionSlice.actions;

// A selector to access the session state
export const selectSession = (state) => state.session.session;

export default sessionSlice.reducer;
