// VideoModal.js
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlay } from '@fortawesome/free-solid-svg-icons';

const VideoModal = ({ video, onClose, videos, onNextVideo }) => {
  const modalRef = useRef();
  const [isVideoEnded, setIsVideoEnded] = useState(false);

  const currentIndex = videos.findIndex(v => v.id === video.id);
  const nextVideo = videos[(currentIndex + 1) % videos.length];

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleVideoEnd = () => {
    setIsVideoEnded(true);
  };

  const handleNextVideo = () => {
    setIsVideoEnded(false);
    onNextVideo(nextVideo);
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      onClick={handleOutsideClick}
    >
      <div
        ref={modalRef}
        className="bg-white text-gray-800 rounded-lg p-4 w-full max-w-4xl max-h-[90vh] overflow-auto text-inter"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-inter text-primary">{video.title}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out"
          >
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </button>
        </div>
        <div className="relative pt-[56.25%] mb-4">
          <ReactPlayer
            url={video.url}
            width="100%"
            height="100%"
            controls
            playing
            onEnded={handleVideoEnd}
            className="absolute top-0 left-0"
          />
        </div>
        <p className="mt-4 text-gray-800 mb-6 font-bold text-xl text-center">{video.description}</p>
        
        <div className={`transition-opacity text-gray-800 duration-300 ${isVideoEnded ? 'opacity-100' : 'opacity-70'}`}>
          <h3 className={`text-xl font-bold text-inter mb-2 ${isVideoEnded ? 'text-primary' : 'text-gray-500'}`}>Next Up</h3>
          <div className={`flex items-center bg-gray-100 rounded-lg p-4 hover:bg-gray-200 transition duration-300 cursor-pointer border-2 ${isVideoEnded ? 'border-primary' : 'border-gray-100'}`} onClick={handleNextVideo}>
            <div className="relative w-32 h-20 mr-4">
              <img
                src={nextVideo.thumbnailUrl}
                alt={nextVideo.title}
                className="w-full h-full object-cover rounded"
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded">
                <FontAwesomeIcon icon={faPlay} className="text-white text-2xl" />
              </div>
            </div>
            <div className='text-left'>
              <h4 className="font-bold text-inter text-gray-800">{nextVideo.title}</h4>
              <p className="text-sm text-gray-600 truncate">{nextVideo.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;