import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getBlogPosts } from '../api/blogApi';
import BlogList from '../components/blog/BlogList';
import Search from '../components/blog/Search';
import CategoryFilter from '../components/blog/CategoryFilter';
import Pagination from '../components/blog/Pagination';
import config from '../config/config';
import Header from './Homepage/components/Header';
import Footer from './Homepage/components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpenReader } from '@fortawesome/free-solid-svg-icons';
import LeadMagnetCard from '../components/LeadMagnetCard';

const Home = () => {
  const leadMagnet = useSelector(state => state.tools.items.leadMagnetId);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await getBlogPosts();
        if (response.blogPosts && Array.isArray(response.blogPosts)) {
          setPosts(response.blogPosts); // Use the blogPosts array from the response
        } else {
          console.error('Expected an array of posts in response.blogPosts');
        }
      } catch (error) {
        console.error('Error setting posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (selectedCategory === '' || post.category === selectedCategory)
  );

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div className=''>
        <Header />
      <div className="container mx-auto p-4 min-h-screen">
        <h1 className='flex justify-center items-center text-3xl uppercase text-inter font-bold text-primary mb-2'>
          <FontAwesomeIcon icon={faBookOpenReader} className='mr-2' />{config.blog_title || 'Blog'}
        </h1>
        <Search onSearch={setSearchQuery} />
        <CategoryFilter
          categories={[...new Set(posts.map(post => post.category))]}
          selectedCategory={selectedCategory}
          onSelectCategory={setSelectedCategory}
        />
        {loading ? <p>Loading...</p> : <BlogList posts={currentPosts} />}
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(filteredPosts.length / postsPerPage)}
          onPageChange={setCurrentPage}
        />
        <div className='mt-20'>
          <LeadMagnetCard leadMagnet={leadMagnet} />
        </div>
      </div>
        <Footer />
    </div>
  );
};

export default Home;
