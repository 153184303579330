import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faAddressCard, faLink } from '@fortawesome/free-solid-svg-icons';
import AvatarSelector from './AvatarSelector';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUser, selectUser } from '../features/user/userSlice';
import { selectSession } from '../features/session/sessionSlice';
import { updateUserProfile } from '../api/userFunctions';
import { addToast } from '../features/ui/uiSlice';
import CurrentSubscriptionCard from './CurrentSubscriptionCard';

const SettingsForm = () => {
    const user = useSelector(selectUser);
    const session = useSelector(selectSession);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const features = [
        { id: 'personalInfo', icon: faUser, title: 'Personal Information' },
        { id: 'account', icon: faAddressCard, title: 'Plan Information' },
    ];

    const [selectedFeature, setSelectedFeature] = useState(features[0].id);
    const [formData, setFormData] = useState({
        name: user?.name,
        username: user?.username,
        emailAddress: user?.email,
    });
    const [isFormChanged, setIsFormChanged] = useState(false);

    useEffect(() => {
        setIsFormChanged(false);
    }, [selectedFeature]);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setIsFormChanged(true);
    };

    const handleCancel = () => {
        setFormData({
            name: user?.name,
            username: user?.username,
            emailAddress: user?.email,
        });
        navigate('/dashboard');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            ...user,
            name: formData.name,
            username: formData.username,
            email: formData.emailAddress,
        };
        const updateUser = await updateUserProfile(user?.dynamic_id, payload, session);
        dispatch(addToast('Your profile has been updated!', 'success'));
        setIsFormChanged(false);
        dispatch(setUser(updateUser.data));
    };

    const renderFormFields = () => {
        switch (selectedFeature) {
            case 'personalInfo':
                return (
                    <div className="space-y-6">
                        <div className="flex justify-center">
                            <AvatarSelector />
                        </div>
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-primary">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                className="px-2 py-1 mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor="username" className="block text-sm font-medium text-primary">Username</label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                value={formData.username}
                                onChange={handleInputChange}
                                className="px-2 py-1 mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor="emailAddress" className="block text-sm font-medium text-primary">Email Address</label>
                            <input
                                type="email"
                                id="emailAddress"
                                name="emailAddress"
                                value={formData.emailAddress}
                                disabled
                                className="px-2 py-1 mt-1 block w-full rounded-md border border-gray-300 bg-gray-100 shadow-sm sm:text-sm"
                            />
                        </div>
                    </div>
                );
            case 'account':
                return (
                    <div className="space-y-6">
                        <CurrentSubscriptionCard />
                        <div className="flex justify-center">
                            <Link
                                to="https://billing.stripe.com/p/login/28obKS4r73jf5DG9AA"
                                target="_blank"
                                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 flex items-center justify-center"
                            >
                                <FontAwesomeIcon icon={faLink} className="mr-2" />
                                Manage Stripe Subscription
                            </Link>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="bg-white shadow rounded-lg overflow-hidden text-gray-800 max-w-3xl mx-auto">
            <div className="px-4 py-5 sm:p-6">
                <h2 className="text-2xl font-bold text-inter text-gray-900 mb-6">Settings</h2>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    <div className="col-span-1">
                        <nav className="space-y-1" aria-label="Settings">
                            {features.map((feature) => (
                                <button
                                    key={feature.id}
                                    onClick={() => setSelectedFeature(feature.id)}
                                    className={`${
                                        selectedFeature === feature.id
                                            ? 'bg-blue-50 border-blue-500 text-blue-700'
                                            : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                    } flex items-center w-full px-3 py-2 text-sm font-medium text-left border-l-4`}
                                >
                                    <FontAwesomeIcon icon={feature.icon} className={`mr-3 h-6 w-6 ${
                                        selectedFeature === feature.id ? 'text-blue-500' : 'text-gray-400'
                                    }`} />
                                    <span className="truncate">{feature.title}</span>
                                </button>
                            ))}
                        </nav>
                    </div>
                    <div className="col-span-1 md:col-span-3">
                        <form onSubmit={handleSubmit}>
                            {renderFormFields()}
                            <div className="mt-6 flex items-center justify-end space-x-4">
                                <button
                                    type="button"
                                    onClick={handleCancel}
                                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={!isFormChanged}
                                    className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                                        isFormChanged
                                            ? 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                                            : 'bg-gray-300 cursor-not-allowed'
                                    }`}
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsForm;