import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config/config';

const apiURL = process.env.REACT_APP_API_URL;

const initialState = {
  items: [],
  status: 'idle',
  error: null,
};

export const fetchRecords = createAsyncThunk(
  'records/fetchRecords',
  async ({ model, page, pageSize }) => {
    const response = await axios.get(`${apiURL}/admin/records/${model}`, {
      params: { page, pageSize },
    });
    return response.data;
  }
);

export const addRecord = createAsyncThunk(
  'records/addRecord',
  async ({ model, recordData }) => {
    const response = await axios.post(`${apiURL}/admin/records/${model}`, recordData);
    return response.data;
  }
);

export const deleteRecord = createAsyncThunk(
  'records/deleteRecord',
  async ({ model, id }) => {
    await axios.delete(`${apiURL}/admin/records/${model}/${id}`);
    return id;
  }
);

const recordsSlice = createSlice({
  name: 'records',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecords.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRecords.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload.records;
      })
      .addCase(fetchRecords.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addRecord.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      .addCase(deleteRecord.fulfilled, (state, action) => {
        state.items = state.items.filter((record) => record._id !== action.payload);
      });
  },
});

export default recordsSlice.reducer;
