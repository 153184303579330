import HomepageLayout from "../layout/homepageLayout";
import Footer from "../components/Footer";
import Card from "../components/Card";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import PricingCardsMonthly from "../components/pricingCardsMonthly";
import PricingCards from "../components/pricingCards";
import FAQ from "../components/FAQ";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoltLightning,
  faCheck,
  faCheckDouble,
  faCheckSquare,
  faFire,
  faGraduationCap,
  faHandHoldingDollar,
  faMedal,
  faRightToBracket,
  faRobot,
  faStar,
  faThumbsDown,
  faWandMagicSparkles,
  faX,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import config from "../../../config/config";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchPackages } from "../../../features/packages/packageSlice";
import VideoPlayer from "../../../components/VideoPlayer";
import ExitIntentPopup from "../components/ExitIntentPopup";
import { useSelector } from "react-redux";
import {
  faCheckCircle,
  faHandshake,
} from "@fortawesome/free-regular-svg-icons";
import LeadMagnetCard from "../../../components/LeadMagnetCard";
import HomepagePurchaseFlow from "../components/HomepagePurchaseFlow";
import FeatureCard from "../components/FeatureCard";
import OverlappingImages from "../components/OverlappingImages";
import ComparisonTable from "../components/ComparisonTable";
import ChatSpaceExamples from "../components/ChatSpaceExamples";
import TestimonialSectionHome from "../components/TestimonialSectionHome";
import PersonalAITutorSection from "../components/PersonalAITutorSection";
import GuaranteeSectionHome from "../components/GuaranteeSectionHome";
import BottomCTAHome from "../components/BottomCTAHome";
import ScreenshotGallery from "../../../components/ScreenshotGallery";
import VideoGallery from "../../../components/videoGallery/VideoGallery";

const screenshots = config.landing_page_screenshots;

const videos = config.landing_page_demo_videos;

const images = [
  config.landing_page_testimonial_1_image,
  config.landing_page_testimonial_2_image,
  config.landing_page_testimonial_3_image,
  config.landing_page_testimonial_4_image,
  config.landing_page_testimonial_5_image,
  config.landing_page_testimonial_6_image,
  config.landing_page_testimonial_7_image,
];

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showExitPopup, setShowExitPopup] = useState(false);
  const [hasShownPopup, setHasShownPopup] = useState(false);
  const leadMagnet = useSelector((state) => state.tools.items.leadMagnetId);

  const handleMouseOut = useCallback(
    (e) => {
      if (!hasShownPopup && e.clientY <= 0) {
        setShowExitPopup(true);
        setHasShownPopup(true);
      }
    },
    [hasShownPopup]
  );

  const handleKeyDown = useCallback((e) => {
    if (e.key === "Escape") {
      setShowExitPopup(false);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchPackages());

    document.addEventListener("mouseout", handleMouseOut);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mouseout", handleMouseOut);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, handleMouseOut, handleKeyDown]);

  return (
    <>
      <div className="w-full text-right lg:hidden">
        <Link to={"/login"} className="text-primary text-inter">
          Members Login{" "}
          <FontAwesomeIcon icon={faRightToBracket} className="ml-1" />
        </Link>
      </div>
      <div className="flex flex-col px-1 lg:px-4 border-0 border-primary rounded bg-black-main">
        <Card>
          <div className="max-w-full text-center text-gray-100">
            <div className="grid lg:grid-cols-1 gap-4">
              <div className="grid grid-cols-1 mb-16 md:mb-20 lg:mb-4 md:my-auto order-1 lg:order-1 max-w-3xl mx-auto">
                <img
                  src="https://otp.nyc3.cdn.digitaloceanspaces.com/flexingNurseScribbleFrame.png"
                  alt="Successful Nurse Flexing"
                  className="w-full md:w-1/2 h-auto mx-auto"
                />
                <div className="px-6 md:px-0">
                  <div
                    className="md:text-5xl text-4xl text-gray-100 font-bold flex items-center justify-center mx-auto max-w-6xl text-inter font-bold relative h-24"
                    style={{
                      backgroundImage:
                        "url(https://otp.nyc3.cdn.digitaloceanspaces.com/redUnderline3.gif)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  >
                    {config.landing_page_hero_headline}
                  </div>
                  <div className="mx-auto md:mt-6 mb-6 h-16 text-center ">
                    <button
                      onClick={() => navigate("/loginpurchase")}
                      className="shake text-inter font-bold mt-2 py-2 md:px-6 px-10 border-none border-primary hover:bg-gray-700 gradient-red-blue focus:ring-blue-500 focus:ring-offset-blue-200 text-gray-100 w-full transition ease-in duration-200 text-center text-xl sm:text-3xl shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-full "
                    >
                      👉 {config.landing_page_hero_button_text}
                    </button>
                  </div>
                  <div className="md:text-xl text-sm font-semibold mt-1 mx-auto max-w-4xl text-inter">
                    
                    <p
                    dangerouslySetInnerHTML={{
                      __html: config.landing_page_hero_subheadline,
                    }}
                  ></p>
                  </div>
                  <div className="mt-4 italic text-gray-100">
                    <FontAwesomeIcon
                      icon={faMedal}
                      className="mr-2 text-yellow-600"
                    />
                    {config.landing_page_package_type === "Monthly" && (
                      <span>7-day money back guarantee.</span>
                    )}
                    {config.landing_page_package_type === "One-Time" && (
                      <span>Pass your exam or get a 100% refund!</span>
                    )}
                  </div>
                  <div className="p-4">
                    <OverlappingImages images={images} />
                  </div>
                </div>
              </div>{" "}
              {/*<div className={`order-2 lg:order-2`}>
                <div className="hidden lg:flex lg:flex-col items-center p-2 rounded-lg shadow-xl mb-2 text-primary">
                  <VideoPlayer
                    url={config.landing_page_hero_graphic_desktop}
                    width={500}
                  />
                </div>
                <div
                  className={`lg:hidden md:max-w-sm w-fit border border-4 border-gray-400 shadow-xl rounded-xl mx-auto p-1`}
                >
                  <img
                    src={config.landing_page_hero_graphic_mobile}
                    alt="AI-Powered Exam Prep"
                    className="rounded"
                    style={{ pointerEvents: "none" }}
                  />
                </div>
                <div className="text-primary text-2xl mt-4 max-w-2xl text-inter font-bold mx-auto">
                  <FontAwesomeIcon icon={faHandshake} className="mr-2" />
                  {config.leadMagnetBottomHeadline}
                </div>
              </div>*/}
            </div>

           
            <ScreenshotGallery screenshots={screenshots} />

            <div class="flex justify-center items-center text-center">
              <div class="mt-0">
                <div className="justify-center items-center text-inter font-bold text-center text-4xl font-bold mt-10 flex flex-col lg:flex-row gap-2 lg:gap-0 mb-8">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: config.landing_page_plans_headline,
                    }}
                  ></p>
                  <div>
                    <FontAwesomeIcon
                      icon={faHandHoldingDollar}
                      className="text-red-500 lg:ml-2"
                    />
                  </div>
                </div>
                {config.landing_page_package_type === "monthly" ? (
                  <>
                    <PricingCardsMonthly />
                  </>
                ) : (
                  <HomepagePurchaseFlow />
                )}
              </div>
            </div>

            <VideoGallery videos={videos} />

            

            <hr className="w-48 h-1 mx-auto bg-primary border-0 rounded my-10"></hr>

            <ChatSpaceExamples />

            <hr className="w-48 h-1 mx-auto bg-primary border-0 rounded my-20"></hr>

            {/* Comparison Table */}
            <section className="my-20">
              <h2 className="text-3xl text-inter font-bold mb-10">
                <FontAwesomeIcon
                  icon={faFire}
                  className="mr-2 text-orange-400"
                />
                Why You Should Choose {config.siteName}
              </h2>
              <ComparisonTable />
            </section>


            <div className="my-20">
              <TestimonialSectionHome />
            </div>

            <hr className="w-48 h-1 mx-auto bg-primary border-0 rounded my-20"></hr>

            <PersonalAITutorSection />

            <div className="py-2 mt-10 text-red-500 text-2xl lg:text-4xl max-w-4xl text-inter font-bold mx-auto">
              <FontAwesomeIcon icon={faThumbsDown} className="mr-2" />
              {config.landing_page_headline_4}
            </div>
            
            <GuaranteeSectionHome />

            {/* Key Features Section */}
            {config.keyFeatures.length > 0 && (
              <section className="my-20">
                <h2 className="text-2xl lg:text-3xl text-inter font-bold mb-10">
                  What makes {config.siteName} the perfect choice for you? 🧐
                </h2>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {config.keyFeatures.map((feature, index) => (
                    <FeatureCard
                      key={index}
                      title={feature.title}
                      icon={feature.icon}
                      description={feature.description}
                    />
                  ))}
                </div>
              </section>
            )}

            

<hr className="w-48 h-1 mx-auto bg-primary border-0 rounded my-20"></hr>

            <FAQ />
            {leadMagnet && (
              <div className="my-20 rounded border-4 p-4 border-red-600 border-dashed">
                <div className="text-lg text-gray-700 bg-yellow-100 border-2 border-yellow-600 px-4 py-2 rounded-full max-w-2xl text-inter font-bold mx-auto">
                  {config.leadMagnetHeroSubheader}
                </div>
                <LeadMagnetCard leadMagnet={leadMagnet} />
              </div>
            )}

            <div className="mt-10">
              <img
                src="https://otp.nyc3.cdn.digitaloceanspaces.com/trustBadges.png"
                alt="Trust Badges"
                className="mx-auto w-96"
              />
            </div>
            <div className="py-2 text-primary text-2xl mt-4 max-w-2xl text-inter font-bold mx-auto">
              <FontAwesomeIcon icon={faHandshake} className="mr-2" />
              {config.leadMagnetBottomHeadline}
            </div>

            <BottomCTAHome />
            <Footer />
          </div>
        </Card>
        {showExitPopup && (
          <ExitIntentPopup onClose={() => setShowExitPopup(false)} />
        )}
      </div>
    </>
  );
};

const Homepage = () => {
  return <HomepageLayout Component={Home} />;
};

export default Homepage;
