import { createSlice } from '@reduxjs/toolkit';

export const currentThemeSlice = createSlice({
  name: 'currentTheme',
  initialState: {
    value: null, // Initial state of the current tool is null
  },
  reducers: {
    // Action to update the current tool
    setCurrentTheme: (state, action) => {
      state.value = action.payload;
    },
    // Action to clear the current tool (optional)
    clearCurrentTheme: (state) => {
      state.value = null;
    },
  },
});

// Export the actions
export const { setCurrentTheme, clearCurrentTheme } = currentThemeSlice.actions;

// Export the selector to access the current tool state
export const selectCurrentTheme = (state) => state.currentTheme.value;

// Export the reducer
export default currentThemeSlice.reducer;
