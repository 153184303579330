import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { submitQuizResults } from '../../features/quiz/quizSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faChevronRight, faCheck, faTimes, faClipboard, faToggleOn, faToggleOff, faLock } from '@fortawesome/free-solid-svg-icons';

const QuizForm = ({ quiz, onComplete }) => {
  const dispatch = useDispatch();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [remainingTime, setRemainingTime] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [immediateFeedback, setImmediateFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [answeredQuestions, setAnsweredQuestions] = useState(new Set());

  useEffect(() => {
    if (quiz && quiz.timer) {
      setRemainingTime(quiz.timer);
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            handleQuizComplete();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [quiz]);

  if (!quiz) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const handleAnswerSelect = (questionIndex, answerIndex) => {
    if (immediateFeedback && answeredQuestions.has(questionIndex)) {
      return; // Prevent changing answer if immediate feedback is enabled and question is already answered
    }

    setUserAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[questionIndex] = answerIndex;
      return newAnswers;
    });

    if (immediateFeedback) {
      setAnsweredQuestions((prev) => new Set(prev).add(questionIndex));
      const question = quiz.questions[questionIndex];
      const isCorrect = answerIndex === Number(question.correctAnswer) - 1;
      setFeedbackMessage(isCorrect ? question.messageForCorrectAnswer : question.messageForIncorrectAnswer);
    }
  };

  const handleNextQuestion = () => {
    setFeedbackMessage('');
    if (currentQuestionIndex < quiz.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowResults(true);
    }
  };

  const handleQuizComplete = () => {
    const formattedResults = {
      userInput: quiz.questions.map((question, index) => ({
        isCorrect: userAnswers[index] === Number(question.correctAnswer) - 1,
        userAnswer: userAnswers[index],
        correctAnswer: Number(question.correctAnswer) - 1,
        question: question.question,
        explanation: question.explanation,
        category: question.questionCategory,
      })),
      remainingTime,
    };

    onComplete(formattedResults);
  };

  const renderQuestion = () => {
    const question = quiz.questions[currentQuestionIndex];
    const isAnswered = answeredQuestions.has(currentQuestionIndex);

    return (
      <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
        <h3 className="text-xl font-semibold mb-4 text-gray-900">{question.question}</h3>
        <div className="space-y-3">
          {question.answers.map((answer, answerIndex) => (
            <button
              key={answerIndex}
              onClick={() => handleAnswerSelect(currentQuestionIndex, answerIndex)}
              className={`w-full text-left p-3 rounded-md transition-colors duration-200 flex items-center justify-between
                ${userAnswers[currentQuestionIndex] === answerIndex
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 hover:bg-blue-200 text-gray-800'}
                ${immediateFeedback && isAnswered && userAnswers[currentQuestionIndex] !== answerIndex
                  ? 'opacity-50 cursor-not-allowed'
                  : ''}
              `}
              disabled={immediateFeedback && isAnswered && userAnswers[currentQuestionIndex] !== answerIndex}
            >
              <span>{answer}</span>
              {immediateFeedback && isAnswered && userAnswers[currentQuestionIndex] === answerIndex && (
                <FontAwesomeIcon icon={faLock} className="ml-2" />
              )}
            </button>
          ))}
        </div>
        {immediateFeedback && feedbackMessage && (
          <div className={`mt-4 p-3 rounded-md ${userAnswers[currentQuestionIndex] === Number(question.correctAnswer) - 1 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
            {feedbackMessage}
          </div>
        )}
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-4 text-primary text-inter"><FontAwesomeIcon icon={faClipboard} className='mr-2' />Quiz Results</h2>
        {quiz.questions.map((question, index) => (
          <div key={index} className="mb-4 p-4 bg-gray-100 rounded-md text-gray-800">
            <p className="font-semibold">{question.question}</p>
            <p className="mt-2">
              Your answer: {question.answers[userAnswers[index]]}
              {userAnswers[index] === Number(question.correctAnswer) - 1 ? (
                <FontAwesomeIcon icon={faCheck} className="text-green-500 ml-2" />
              ) : (
                <FontAwesomeIcon icon={faTimes} className="text-red-500 ml-2" />
              )}
            </p>
            {userAnswers[index] !== Number(question.correctAnswer) - 1 && (
              <p className="mt-1 text-green-600 text-inter font-bold">
                Correct answer: {question.answers[Number(question.correctAnswer) - 1]}
              </p>
            )}
            <div className='flex flex-col mt-2 items-center rounded border border-gray-500 p-4 pt-1 bg-blue-200 justify-center'>
                <div className='flex items-center text-inter font-bold'>
                    Explanation:
                </div>
            <div className="mt-2 text-sm text-gray-800">{question.explanation}</div>
            </div>
          </div>
        ))}
        <button
          onClick={handleQuizComplete}
          className="mt-6 w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-200"
        >
          Go to Quiz Overview
        </button>
      </div>
    );
  };

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <div className="mb-6 flex justify-between items-center">
        <div className="text-lg font-semibold">
          Question {currentQuestionIndex + 1} of {quiz.questions.length}
        </div>
        <div className="flex items-center">
          <button
            onClick={() => {
              setImmediateFeedback(!immediateFeedback);
              setAnsweredQuestions(new Set());
              setFeedbackMessage('');
            }}
            className="mr-4 flex items-center text-blue-500 hover:text-blue-600"
          >
            <FontAwesomeIcon icon={immediateFeedback ? faToggleOn : faToggleOff} className="mr-2" />
            {immediateFeedback ? 'Disable' : 'Enable'} Immediate Feedback
          </button>
          {remainingTime !== null && (
            <div className="flex items-center text-lg">
              <FontAwesomeIcon icon={faClock} className="mr-2 text-blue-500" />
              {remainingTime}s
            </div>
          )}
        </div>
      </div>
      <div className="mb-6 bg-gray-200 rounded-full h-2.5">
        <div
          className="bg-blue-500 h-2.5 rounded-full"
          style={{ width: `${((currentQuestionIndex + 1) / quiz.questions.length) * 100}%` }}
        ></div>
      </div>
      {showResults ? renderResults() : renderQuestion()}
      {!showResults && (
        <button
          onClick={handleNextQuestion}
          disabled={userAnswers[currentQuestionIndex] === undefined}
          className={`mt-6 w-full py-3 px-4 rounded-md transition-colors duration-200 flex justify-center items-center ${
            userAnswers[currentQuestionIndex] !== undefined
              ? 'bg-blue-500 text-white hover:bg-blue-600'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
        >
          {currentQuestionIndex === quiz.questions.length - 1 ? 'Finish Quiz' : 'Next Question'}
          <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
        </button>
      )}
    </div>
  );
};

export default QuizForm;