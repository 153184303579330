// components/BoardSelectorButton.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../features/user/userSlice';
import { fetchBoards, createBoard } from '../features/boards/boardSlice';
import { addBoardItem } from '../features/boards/boardItemSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { addToast } from '../features/ui/uiSlice';
import config from '../config/config';

const BoardSelectorButton = ({ itemContent, itemType, userId }) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const boards = useSelector(state => state.boards.boards);
    const [isOpen, setIsOpen] = useState(false);
    const [newBoardName, setNewBoardName] = useState('');
    const [selectedBoard, setSelectedBoard] = useState('');

    useEffect(() => {
        dispatch(fetchBoards({userId: user._id}));
    }, [dispatch]);

    const handleAddItemClick = () => {
        setIsOpen(true);
    };

    const handleCreateBoard = async () => {
        if (newBoardName.trim()) {
            const newBoard = await dispatch(createBoard({ name: newBoardName, userId })).unwrap();
            dispatch(addBoardItem({ boardId: newBoard._id, content: itemContent, itemType, userId }));
            setIsOpen(false);
            dispatch(addToast(`New ${config.boards_title_singular} created. Item Added to ${config.boards_title_singular}!`, 'success'));
            setNewBoardName('');
        }
    };

    const handleSelectBoard = async (boardId) => {
        dispatch(addBoardItem({ boardId, content: itemContent, itemType, userId }));
        setIsOpen(false);
        dispatch(addToast(`Item Added to ${config.boards_title_singular}!`, 'success'));
        setNewBoardName('');
    };

    return (
        <div className=''>
            <button className="bg-primary hover:bg-gray-700 text-white px-3 py-1 rounded" onClick={handleAddItemClick}>
                <FontAwesomeIcon icon={faPaperclip} />
            </button>
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div className="bg-body p-6 rounded shadow-lg w-full max-h-100 overflow-auto slim-scrollbar md:w-2/3 md:max-w-2/3 lg:w-1/3 lg:max-w-1/3">
                        <h2 className="text-xl lg:text-3xl mb-2 text-primary text-poetsen"><FontAwesomeIcon icon={faPaperclip} className='mr-2' />Select a {config.boards_title_singular}</h2>
                        <p className='text-sm lg:text-base'>Click on a {config.boards_title_singular} below to add the selection to that {config.boards_title_singular}, or type a new {config.boards_title_singular} name in the box to add the selection to a new {config.boards_title_singular}.</p>
                        <div className="my-4">
                            {boards.length === 0 && (
                                <p className="text-gray-600">No {config.boards_title_singular}s available. Create a new {config.boards_title_singular}.</p>
                            )}
                            {boards.map(board => (
                                <div key={board._id} className="mb-2">
                                    <button
                                        className="bg-body border border-primary hover:bg-gray-700 text-body hover:text-white px-4 py-2 rounded w-full text-left"
                                        onClick={() => handleSelectBoard(board._id)}
                                    >
                                        {board.name}
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="mb-4 flex gap-2 justify-between items-center">
                            <FontAwesomeIcon icon={faPlusCircle} className='text-primary text-2xl' />
                            <input
                                type="text"
                                placeholder="New Board Name"
                                value={newBoardName}
                                onChange={(e) => setNewBoardName(e.target.value)}
                                className="border text-black rounded px-4 py-2 w-full"
                            />
                        </div>
                        <div className="flex justify-between">
                            <button
                                className="bg-gray-500 text-white text-poetsen px-4 py-2 rounded mr-2 hover:bg-gray-700 hover:text-white"
                                onClick={() => setIsOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-primary text-poetsen text-body px-4 py-2 rounded hover:bg-gray-700 hover:text-white"
                                onClick={handleCreateBoard}
                                disabled={!newBoardName.trim()}
                            >
                                Create and Add
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BoardSelectorButton;
