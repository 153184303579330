import React from 'react';

const FilterSection = ({ title, items, filters, onChange }) => {
  return (
    <div className="mb-4 text-gray-800">
      <h4 className="text-sm font-bold text-inter mb-2">{title}</h4>
      {items.map(item => (
        <label key={item} className="flex items-center mb-1">
          <input
            type="checkbox"
            checked={filters?.includes(item)}
            onChange={() => onChange(item)}
            className="mr-2"
          />
          <span className="text-sm">{item}</span>
        </label>
      ))}
    </div>
  );
};

export default FilterSection;