import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/user/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PricingCardsUpdated from './Homepage/components/PricingCardsUpdated';
import ScreenshotGalleryStories from '../components/ScreenshotGalleryStories';
import config from '../config/config';

function PurchasePage() {
    const user = useSelector(selectUser);
    const userSubscriptionEndDate = new Date(user?.subscription_end_date);
    const formattedEndDate = userSubscriptionEndDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    const pricingCardsRef = useRef(null);
    const stories = [
        {
          url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/AITutorProductStory.png',
          type: 'image', // or 'video'
          title: 'AI Tutor',
          description: 'Unlock the power of personalized learning with our 24/7 AI Tutor. Get instant, accurate answers and explanations to all your NCLEX questions any time of day. It\'s like having a personal tutor in your pocket, ready to help you conquer your study goals whenever you need it.'
        },
        {
            url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/quizzesProductStory.png',
            type: 'image', // or 'video'
            title: 'Quizzes',
            description: 'Never run out of practice! With unlimited quizzes, you can test your knowledge as much as you want. Our ever-expanding question bank ensures you always have fresh, relevant material to keep you sharp and exam-ready.'
          },
          {
              url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/statsProductStory.png',
              type: 'image', // or 'video'
              title: 'Stats',
              description: 'Take control of your study strategy with advanced quiz analytics. Dive deep into graphs and charts that visualize your performance trends, pinpoint areas for improvement, and help you make data-driven decisions about where to focus your efforts.'
            },
            {
                url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/notesProductStory.png',
                type: 'image', // or 'video'
                title: 'Notes',
                description: 'Keep all your important study materials in one place with savable notes. Just like simplified Pinterest boards, you can save chat responses, incorrect answers, explanations, and more. Organize your study aids effortlessly and access them whenever you need.'
              },
          {
              url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/flashcardsProductStory.png',
              type: 'image', // or 'video'
              title: 'Flashcards',
              description: 'Studying has never been this fun! Our gamified flashcards are automatically populated with your incorrect quiz questions, turning your weaknesses into strengths. Compete with yourself, track your progress, and enjoy a more engaging way to review key concepts.'
            },
          {
            url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/feedbackProductStory.png',
            type: 'image', // or 'video'
            title: 'Feedback',
            description: 'Don\'t wait for results. Get instant feedback on your quizzes so you know exactly where you stand. Our real-time scoring helps you identify strengths and weaknesses immediately, allowing you to adjust your study plan on the fly.'
          },
          {
              url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/reviewsProductStory.png',
              type: 'image', // or 'video'
              title: 'Reviews',
              description: 'Turn mistakes into learning opportunities with detailed quiz reviews. Our comprehensive analysis highlights your errors and provides actionable insights to help you understand and correct them, making sure you\'re always improving.'
            },
                  {
                      url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/dashboardProductStory.png',
                      type: 'image', // or 'video'
                      title: 'Dash',
                      description: 'Navigate your NCLEX prep with ease using our personalized dashboard. Quickly access different app features and get snapshots of your current quiz performance. Everything you need to stay on top of your studies is just a click away.'
                    },
      ];
    const handlePackageSelection = () => {
        // Handle package selection
      };

    const handleScrollToPricingCards = () => {
        pricingCardsRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="w-full max-w-2xl mx-auto min-h-screen bg-gradient-to-b from-blue-600 to-white pb-10">
            <div className="flex items-center justify-center pl-4 pt-2 bg-white">
            <ScreenshotGalleryStories stories={stories} />
        </div>
        <div className="flex items-center justify-center mb-4 bg-gradient-to-r from-blue-400 to-green-400 text-white text-inter text-xs py-1">
          Exam prep powered by cutting-edge AI technology.
        </div>
            <div className='text-center text-inter font-bold px-1 mb-4 lg:text-xl'>
                Let's pick out your perfect {config.exam_name} package!
            </div>
            <div className='lg:px-10'>
            <PricingCardsUpdated onPackageSelect={handlePackageSelection} />
            </div>
        </div>
    );
} 

export default PurchasePage;
