import React, { useState } from "react";
import { downloadLeadMagnet } from "../api/leadMagnetApi";
import config from "../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const LeadMagnetCard = ({ leadMagnet }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [agree, setAgree] = useState(true);
  const groupId = config.mailerLiteNewsletterGroupID;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agree) {
      alert("You must agree to the privacy policy to continue.");
      return;
    }
    try {
      const response = await downloadLeadMagnet(leadMagnet._id, email, groupId);
      //setMessage("Success! Check your email for your requested guide.");
      navigate(`/specialoffer/${leadMagnet._id}`);
    } catch (error) {
      setMessage("Failed to download. Please try again later.");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && email) {
      handleSubmit(e);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white rounded-3xl shadow-md overflow-hidden md:max-w-5xl my-4">
      <div className="md:flex">
        <div className="hidden md:shrink-0 pt-6 md:pt-0 md:px-4 md:flex md:items-center md:justify-center">
          <img
            className="mx-auto shadow-xl object-contain max-w-80 md:h-auto rounded"
            src={config.leadMagnetSideImage}
            alt={leadMagnet.title}
          />
        </div>
        <div className="pt-2 md:pt-8 p-8">
          <div className="hidden md:block md:text-2xl text-gray-800 font-bold text-center text-inter leading-none py-2 px-4 border-3 border-red-600 rounded-full bg-red-100">
            {config.leadMagnetSubheader}
          </div>
          <div className="md:shrink-0 pt-0 md:px-4 md:flex md:items-center md:justify-center">
            <img
              className="my-4 mt-0 shadow-2xl object-contain h-auto max-h-72 w-full rounded-xl p-2"
              src={leadMagnet.image_url}
              alt={leadMagnet.title}
            />
          </div>
          <p className="hidden mt-1 leading-tight text-black text-xs md:text-base">
            {leadMagnet.description}
          </p>
          <p className="text-xs text-center md:text-base font-bold text-gray-800">
          Simply provide your best email address below, and you will instantly
          receive{" "}
          <span className="text-red-500 text-inter">{leadMagnet.title}</span>{" "}
          directly to your inbox.
        </p>
          {!message && (
            <form onSubmit={handleSubmit} className="mt-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                required
                className="mt-1 block text-black w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="you@example.com"
              />
              <button
                type="submit"
                className="shake font-bold text-inter text-lg mt-3 w-full inline-flex justify-center items-center py-2 pt-3 px-4 border border-transparent shadow-sm rounded-md text-gray-100 bg-red-600 hover:bg-gray-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <FontAwesomeIcon icon={faDownload} className="mr-2" />
                Get {leadMagnet.title}
              </button>
              <div className="mt-4">
                <p className="text-xs text-gray-600 mt-2">
                  By submitting this form, you agree to receive your free {leadMagnet.title} and related study materials from {config.siteName}. We'll also send you helpful {config.exam_name} prep tips and
                  updates. You can unsubscribe at any time using the link in our
                  emails. We respect your privacy and will never sell or share
                  your personal data. For more information on how we use your
                  data, please see our{" "}
                  <a
                    href={`${config.app_url}/privacy-policy`}
                    target="_blank"
                     rel="noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>

                <p className="md:hidden mt-4 leading-tight text-black text-xs md:text-base">
            {leadMagnet.description}
          </p>
              </div>
            </form>
          )}
          {message && (
            <p className="mt-2 text-green-700 text-poetsen">{message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadMagnetCard;
