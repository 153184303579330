import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  fetchAvailableGamesApi, 
  joinGameApi, 
  leaveGameApi,
  startGameApi, 
  submitAnswerApi, 
  createGameApi, 
  saveGameResultsApi 
} from '../../api/liveGameApi';

export const fetchAvailableGames = createAsyncThunk(
  'liveGame/fetchAvailableGames',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchAvailableGamesApi();
      console.log('game response:', response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const joinGame = createAsyncThunk(
  'liveGame/joinGame',
  async ({ gameId, userId }, { rejectWithValue }) => {
    try {
      const response = await joinGameApi(gameId, userId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const leaveGame = createAsyncThunk(
  'liveGame/leaveGame',
  async ({ gameId, userId }, { rejectWithValue }) => {
    try {
      const response = await leaveGameApi(gameId, userId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const startGame = createAsyncThunk(
  'liveGame/startGame',
  async (gameId, { rejectWithValue }) => {
    try {
      const response = await startGameApi(gameId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitAnswer = createAsyncThunk(
  'liveGame/submitAnswer',
  async ({ gameId, userId, answer }, { rejectWithValue }) => {
    try {
      const response = await submitAnswerApi(gameId, userId, answer);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createGame = createAsyncThunk(
  'liveGame/createGame',
  async (gameData, { rejectWithValue }) => {
    try {
      const response = await createGameApi(gameData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchGameResults = createAsyncThunk(
  'liveGame/fetchGameResults',
  async (gameId, { rejectWithValue }) => {
    try {
      const response = await saveGameResultsApi(gameId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const liveGameSlice = createSlice({
  name: 'liveGame',
  initialState: {
    availableGames: [],
    currentGame: null,
    gameResults: null,
    loading: false,
    error: null,
    chatMessages: [],
  },
  reducers: {
    updateGameState: (state, action) => {
        state.currentGame = { 
          ...state.currentGame, 
          ...action.payload,
        };
      },
    addChatMessage: (state, action) => {
      state.chatMessages.push(action.payload);
    },
    clearChatMessages: (state) => {
      state.chatMessages = [];
    },
    updateGameInList: (state, action) => {
        const index = state.availableGames.findIndex(game => game._id === action.payload._id);
        if (index !== -1) {
          state.availableGames[index] = {
            ...state.availableGames[index],
            ...action.payload,
          };
        }
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableGames.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAvailableGames.fulfilled, (state, action) => {
        state.loading = false;
        state.availableGames = action.payload;
      })
      .addCase(fetchAvailableGames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(joinGame.pending, (state) => {
        state.loading = true;
      })
      .addCase(joinGame.fulfilled, (state, action) => {
        state.loading = false;
        state.currentGame = action.payload;
        // Update the game in the available games list
        const index = state.availableGames.findIndex(game => game._id === action.payload._id);
        if (index !== -1) {
          state.availableGames[index] = {
            ...state.availableGames[index],
            ...action.payload,
          };
        }
      })
      .addCase(joinGame.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(leaveGame.fulfilled, (state) => {
        state.currentGame = null;
      })
      .addCase(startGame.fulfilled, (state, action) => {
        state.currentGame = action.payload;
        // Update the game in the available games list
        const index = state.availableGames.findIndex(game => game._id === action.payload._id);
        if (index !== -1) {
          state.availableGames[index] = action.payload;
        }
      })
      .addCase(submitAnswer.fulfilled, (state, action) => {
        state.currentGame = action.payload;
      })
      .addCase(createGame.pending, (state) => {
        state.loading = true;
      })
      .addCase(createGame.fulfilled, (state, action) => {
        state.loading = false;
        state.currentGame = action.payload;
      })
      .addCase(createGame.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchGameResults.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGameResults.fulfilled, (state, action) => {
        state.loading = false;
        state.gameResults = action.payload;
      })
      .addCase(fetchGameResults.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { updateGameState, addChatMessage, clearChatMessages, updateGameInList } = liveGameSlice.actions;

export default liveGameSlice.reducer;