import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faTimes, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ScreenshotGallery = ({ screenshots }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openLightbox = (index) => {
    setSelectedImage(index);
  };

  const closeLightbox = () => {
    setSelectedImage(null);
  };

  const navigateLightbox = (direction) => {
    setSelectedImage((prevIndex) => {
      const newIndex = prevIndex + direction;
      if (newIndex < 0) return screenshots.length - 1;
      if (newIndex >= screenshots.length) return 0;
      return newIndex;
    });
  };

  return (
    <div className="container mx-auto px-4 pb-10 xl:pb-20">
      <h2 className="text-3xl font-bold text-inter text-center mb-6 text-white hidden lg:block">Get The Tools To Help You Pass 👇</h2>
      <h2 className="text-3xl font-bold text-inter text-center mb-6 text-white lg:hidden">Get The Tools To<br />Help You Pass 👇</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {screenshots.map((screenshot, index) => (
          <div key={index} className="bg-white rounded-lg shadow-xl overflow-hidden">
            <div 
              className="relative cursor-pointer group h-48 overflow-hidden"
              onClick={() => openLightbox(index)}
            >
              <img
                src={screenshot.url}
                alt={screenshot.title}
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity duration-300">
                <FontAwesomeIcon icon={faExpand} className="text-white opacity-0 group-hover:opacity-100 text-2xl" />
              </div>
            </div>
            <div className="p-4">
              <h3 className="text-inter font-bold text-blue-500 text-xl mb-2">{screenshot.title}</h3>
              <p className="text-gray-600 text-sm text-inter font-semibold">{screenshot.description}</p>
            </div>
          </div>
        ))}
      </div>
      {selectedImage !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex flex-col items-center justify-center z-50">
          <div className="max-w-4xl w-full mx-4 relative">
            <img
              src={screenshots[selectedImage].url}
              alt={screenshots[selectedImage].title}
              className="w-full h-auto rounded-lg shadow-lg"
            />
            <button
              onClick={closeLightbox}
              className="absolute top-4 right-4 text-white text-2xl bg-black bg-opacity-50 rounded-full w-10 h-10 flex items-center justify-center focus:outline-none"
              aria-label="Close lightbox"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <button
              onClick={() => navigateLightbox(-1)}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-4xl bg-black bg-opacity-50 rounded-full w-12 h-12 flex items-center justify-center focus:outline-none"
              aria-label="Previous image"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
              onClick={() => navigateLightbox(1)}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-4xl bg-black bg-opacity-50 rounded-full w-12 h-12 flex items-center justify-center focus:outline-none"
              aria-label="Next image"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
            <div className="max-w-4xl w-full text-white p-4 rounded-b-lg">
              <h3 className="text-inter font-bold text-xl lg:text-3xl mb-2 text-primary">{screenshots[selectedImage].title}</h3>
              <p className="lg:text-xl text-inter font-semibold">{screenshots[selectedImage].description}</p>
            </div>
        </div>
      )}
    </div>
  );
};

export default ScreenshotGallery;