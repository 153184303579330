import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faChevronRight, faCheck, faTimes, faExclamationCircle, faTimesCircle, faToggleOn, faToggleOff, faLock, faChevronUp, faGlasses, faChartSimple, faCheckCircle, faEnvelopeCircleCheck, faFireAlt, faArrowAltCircleLeft, faInfoCircle, faInfo } from '@fortawesome/free-solid-svg-icons';
import readinessQuizData from './readinessQuizData';
import ReactMarkdown from 'react-markdown';
import config from '../../../config/config';
import { addToast } from '../../../features/ui/uiSlice';
import { downloadLeadMagnet } from '../../../api/leadMagnetApi';
import exampleAnalysis from './data/exampleAnalysis';

const LeadMagnetQuiz = ({ onComplete }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [remainingTime, setRemainingTime] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [immediateFeedback, setImmediateFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [answeredQuestions, setAnsweredQuestions] = useState(new Set());
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [generationComplete, setGenerationComplete] = useState(false);
  const [showExampleModal, setShowExampleModal] = useState(false);

  useEffect(() => {
    if (showResults) {
      const timer = setTimeout(() => {
        setGenerationComplete(true);
        dispatch(addToast(`Your detailed analysis and personalized study plan are ready for you!`, 'success'));
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showResults]);

  const LoadingAnimation = () => (
    <div className="mb-6 text-center">
      <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500 mr-2"></div>
      <p className="text-gray-600 font-semibold">
        Generating your personalized analysis...
      </p>
    </div>
  );

  const CompletedAnimation = () => (
    <div className="mb-6 text-center">
      <div className="inline-block animate-bounce">
        <FontAwesomeIcon icon={faCheck} className="text-green-500 text-4xl" />
      </div>
      <p className="text-green-500 font-semibold mt-2">
        Your personalized analysis is ready and waiting!
      </p>
      <p className="text-red-600 text-sm">
        (Your analysis will be permanently deleted if you leave this page without claiming it.)
      </p>
    </div>
  );

  const renderExampleModal = () => {
    if (!showExampleModal) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white rounded-lg w-full max-w-3xl max-h-[90vh] flex flex-col text-inter text-gray-700">
          <div className="sticky top-0 bg-white p-4 border-b border-gray-200 rounded-t-lg z-10">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-bold text-primary text-inter">Example Analysis</h2>
              <button
                onClick={() => setShowExampleModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <FontAwesomeIcon icon={faTimesCircle} size="lg" />
              </button>
            </div>
          </div>
          <div className="p-6 overflow-y-auto flex-grow">
            <div className="markdown-content max-w-none">
              <ReactMarkdown>{exampleAnalysis}</ReactMarkdown>
            </div>
          </div>
          <div className='w-full flex justify-center items-center py-2'>
            <button onClick={() => setShowExampleModal(false)} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-200">
              Close Example Analysis
            </button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (readinessQuizData[0].timer) {
      setRemainingTime(readinessQuizData[0].timer);
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            handleQuizComplete();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && email && !emailError) {
      handleQuizComplete();
    }
  };

  const isCorrectAnswer = (questionIndex, answerIndex) => {
    const question = readinessQuizData[questionIndex];
    const userAnswer = question.answers[answerIndex].trim().toLowerCase();
    const correctAnswer = question.answers[question.correctAnswer].trim().toLowerCase();
    return userAnswer === correctAnswer;
  };

  const handleAnswerSelect = (questionIndex, answerIndex) => {
    if (immediateFeedback && answeredQuestions.has(questionIndex)) {
      return;
    }

    setUserAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[questionIndex] = answerIndex;
      return newAnswers;
    });

    if (immediateFeedback) {
      setAnsweredQuestions((prev) => new Set(prev).add(questionIndex));
      const isCorrect = isCorrectAnswer(questionIndex, answerIndex);
      const currentQuestion = readinessQuizData[questionIndex];
      setFeedbackMessage(isCorrect ? currentQuestion.messageForCorrectAnswer : currentQuestion.messageForIncorrectAnswer);
    }
  };

  const handleNextQuestion = () => {
    setFeedbackMessage('');
    if (currentQuestionIndex < readinessQuizData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowResults(true);
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (newEmail && !validateEmail(newEmail)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const handleQuizComplete = async () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    const quizResults = {
      email,
      quizResults: readinessQuizData.map((question, index) => ({
        isCorrect: isCorrectAnswer(index, userAnswers[index]),
        userAnswer: question.answers[userAnswers[index]],
        correctAnswer: question.answers[question.correctAnswer],
        question: question.question,
        explanation: question.explanation,
        category: question.questionCategory,
      })),
      //remainingTime,
    };
    //console.log('quizResults:', quizResults);

    try {
        navigate(`/specialoffer/${config.leadMagnetQuizId}`);
        downloadLeadMagnet(config.leadMagnetQuizId, email, config.mailerLiteNewsletterGroupID, quizResults);
        //onComplete(response.data);
    } catch (error) {
      console.error('Error submitting quiz results:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const topSectionRef = useRef(null);

  const calculateScore = () => {
    const correctAnswers = userAnswers.filter((answer, index) => isCorrectAnswer(index, answer));
    return (correctAnswers.length / readinessQuizData.length) * 100;
  };

  const getPersonalizedMessage = (score) => {
    if (score < 40) {
      return "It's a tough road ahead, but don't worry! Our comprehensive analysis will pinpoint exactly where you need to focus, and our tailored study plan will guide you step-by-step to improve your score and boost your confidence. Let's turn those weaknesses into strengths!";
    } else if (score < 60) {
      return "You're on the right track, but there's still work to be done. Our in-depth analysis will help you understand the areas you need to improve. With a personalized study plan, we'll ensure you cover all the critical points to push you past the passing score. Success is within reach!";
    } else if (score < 85) {
      return "Great effort! You're getting close to passing, but we can make sure you ace the NCLEX. Our detailed analysis and customized study plan will help you fine-tune your knowledge and skills, so you can confidently tackle the exam and achieve your goal.";
    } else {
      return "Excellent job! You're almost there. To ensure you secure your passing score, our advanced analysis and personalized study plan will help you polish the last few areas and maintain your high performance. Let's make sure you pass with flying colors!";
    }
  };

  const renderQuestion = () => {
    const question = readinessQuizData[currentQuestionIndex];
    const isAnswered = answeredQuestions.has(currentQuestionIndex);

    return (
      <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
        <h3 className="text-xl font-semibold mb-4 text-gray-900">{question.question}</h3>
        <div className="space-y-3">
          {question.answers.map((answer, answerIndex) => (
            <button
              key={answerIndex}
              onClick={() => handleAnswerSelect(currentQuestionIndex, answerIndex)}
              className={`w-full text-left p-3 rounded-md transition-colors duration-200 flex items-center justify-between
                ${userAnswers[currentQuestionIndex] === answerIndex
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 hover:bg-blue-200 text-gray-800'}
                ${immediateFeedback && isAnswered && userAnswers[currentQuestionIndex] !== answerIndex
                  ? 'opacity-50 cursor-not-allowed'
                  : ''}
              `}
              disabled={immediateFeedback && isAnswered && userAnswers[currentQuestionIndex] !== answerIndex}
            >
              <span>{answer}</span>
              {immediateFeedback && isAnswered && userAnswers[currentQuestionIndex] === answerIndex && (
                <FontAwesomeIcon icon={faLock} className="ml-2" />
              )}
            </button>
          ))}
        </div>
        {immediateFeedback && feedbackMessage && (
          <div className={`mt-4 p-3 rounded-md ${userAnswers[currentQuestionIndex] === question.correctAnswer ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
            {feedbackMessage}
          </div>
        )}
      </div>
    );
  };

  const renderResults = () => {
    const score = calculateScore();
    const personalizedMessage = getPersonalizedMessage(score);

    return (
      <div className="bg-white rounded-lg shadow-lg p-2 md:p-6">
        <div ref={topSectionRef} className=' p-4 bg-blue-100 rounded-md'>
          <h2 className="text-2xl font-bold mb-4 text-primary text-inter"><FontAwesomeIcon icon={faChartSimple} className='mr-2' />Your Quiz Results</h2>
          <div className="mb-6">
            <p className="text-xl font-semibold text-red-500">Your Score: {score.toFixed(1)}%</p>
            <p className="text-md text-blue-700 mt-2">
              Correct Answers: {userAnswers.filter((answer, index) => isCorrectAnswer(index, answer)).length} out of {readinessQuizData.length}
            </p>
          </div>
          <h3 className="text-xl font-bold mb-2 text-primary text-inter"><FontAwesomeIcon icon={faGlasses} className='mr-2' />Personalized Analysis</h3>
          <p className="md:text-lg mb-6 text-gray-900">{personalizedMessage}</p>

          {/* Animated Loader with Transition */}
          <div className="transition-opacity duration-500 ease-in-out">
            {generationComplete ? <CompletedAnimation /> : <LoadingAnimation />}
          </div>

          <div className="mt-4 relative">
            <input
              type="email"
              placeholder="Enter your email to receive your free analysis"
              value={email}
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
              className={`w-full p-2 border rounded-md text-gray-800 ${
                emailError ? 'border-red-500' : ''
              }`}
              required
            />
            {emailError && (
              <p className="text-red-500 text-sm mt-1">
                <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
                {emailError}
              </p>
            )}
          </div>
          <button
            onClick={handleQuizComplete}
            disabled={!email || !!emailError}
            className={`lg:text-xl mt-6 w-full py-2 px-4 rounded-md transition-colors duration-200 ${
              email && !emailError
                ? 'bg-green-500 text-white hover:bg-blue-600'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Yes, Send My Free Analysis Now!
          </button>
          <div className='w-full flex justify-center items-center mt-2'>
            <button
              className='text-sm text-blue-600 hover:underline'
              onClick={() => setShowExampleModal(true)}
            >
              See Example Analysis
            </button>
          </div>
          <div className='text-gray-800 mt-6 lg:mt-10 text-inter'>
            <h3 className="text-xl font-bold mb-4 text-primary text-inter"><FontAwesomeIcon icon={faEnvelopeCircleCheck} className='mr-2' />Here's what you'll receive directly to your inbox:</h3>
            <p className="mb-2 text-gray-900"><FontAwesomeIcon icon={faCheckCircle} className='mr-2 text-green-500' />
                <span className='font-bold'>Deep Dive into Your Performance:</span> Understand your strengths and weaknesses with an exclusive AI-powered detailed breakdown of your quiz results.
            </p>
            <p className="mb-2 text-gray-900"><FontAwesomeIcon icon={faCheckCircle} className='mr-2 text-green-500' />
                <span className='font-bold'>Tailored Study Plan:</span> Get a customized study plan crafted specifically for your needs to maximize your {config.exam_name} prep efficiency and help you pass the {config.exam_name}.
            </p>
            <p className="mb-2 text-gray-900"><FontAwesomeIcon icon={faFireAlt} className='mr-2 text-red-500' />
                <span className='font-bold text-red-500'>LIMITED-TIME BONUS: </span><span className='font-bold'>{config.leadMagnetName}:</span> {config.leadMagnetDescription}
            </p>
            <p className="mb-2 text-gray-900">
                <span className='font-bold'>Did we mention that all of this is completely free?</span><FontAwesomeIcon icon={faArrowAltCircleLeft} className='ml-2 text-blue-500' />
                <p>Simply enter your email above and hit 'Yes, Send My Free Analysis Now!'</p>
            </p>
          </div>
          <p className="text-xs text-gray-600 mt-6">
            By submitting this form, you agree to receive your free personalized insights and related study materials from {config.siteName}. We'll also send you helpful {config.exam_name} prep tips and
            updates. You can unsubscribe at any time using the link in our emails. We respect your privacy and will never sell or share your personal data. For more information on how we use your data, please see our{" "}
            <a href={`${config.app_url}/privacy-policy`} target="_blank" rel="noreferrer" className="text-blue-600 hover:underline">
              Privacy Policy
            </a>.
          </p>
        </div>

        <h3 className="text-xl font-bold mt-8 mb-4 text-primary text-inter">Detailed Question Analysis</h3>
        {readinessQuizData.map((question, index) => (
          <div key={index} className="mb-4 p-4 bg-gray-100 rounded-md text-gray-800">
            <p className="font-semibold">{question.question}</p>
            <p className="mt-2">
              Your answer: {question.answers[userAnswers[index]]}
              {isCorrectAnswer(index, userAnswers[index]) ? (
                <FontAwesomeIcon icon={faCheck} className="text-green-500 ml-2" />
              ) : (
                <FontAwesomeIcon icon={faTimes} className="text-red-500 ml-2" />
              )}
            </p>
            {!isCorrectAnswer(index, userAnswers[index]) && (
              <p className="mt-1 text-green-600 text-inter font-bold">
                Correct answer: {question.answers[question.correctAnswer]}
              </p>
            )}
            <div className='flex flex-col mt-2 items-center rounded border border-gray-500 p-4 pt-1 bg-blue-200 justify-center'>
              <div className='flex items-center text-inter font-bold'>
                Explanation:
              </div>
              <div className="mt-2 text-sm text-gray-800">{question.explanation}</div>
            </div>
          </div>
        ))}

        <button
          onClick={() => topSectionRef.current.scrollIntoView({ behavior: 'smooth' })}
          className="mt-6 w-full py-3 px-4 rounded-md bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200 flex justify-center items-center"
        >
          Get Your Personalized Study Plan
          <FontAwesomeIcon icon={faChevronUp} className="ml-2" />
        </button>
      </div>
    );
  };

  return (
    <div className="max-w-3xl mx-auto md:px-4 py-8">
      <div className="mb-6 flex justify-between items-center">
        <div className="text-lg font-semibold">
          Question {currentQuestionIndex + 1} of {readinessQuizData.length}
        </div>
        <div className="flex items-center">
          {/*<button
            onClick={() => {
              setImmediateFeedback(!immediateFeedback);
              setAnsweredQuestions(new Set());
              setFeedbackMessage('');
            }}
            className="mr-4 flex items-center text-blue-500 hover:text-blue-600"
          >
            <FontAwesomeIcon icon={immediateFeedback ? faToggleOn : faToggleOff} className="mr-2" />
            {immediateFeedback ? 'Disable' : 'Enable'} Immediate Feedback
          </button>*/}
          {remainingTime !== null && (
            <div className="flex items-center text-lg">
              <FontAwesomeIcon icon={faClock} className="mr-2 text-blue-500" />
              {remainingTime}s
            </div>
          )}
        </div>
      </div>
      <div className="mb-6 bg-gray-200 rounded-full h-2.5">
        <div
          className="bg-green-500 h-2.5 rounded-full"
          style={{ width: `${((currentQuestionIndex + 1) / readinessQuizData.length) * 100}%` }}
        ></div>
      </div>
      {showResults ? renderResults() : renderQuestion()}
      {!showResults && (
        <button
          onClick={handleNextQuestion}
          disabled={userAnswers[currentQuestionIndex] === undefined}
          className={`mt-6 w-full py-3 px-4 rounded-md transition-colors duration-200 flex justify-center items-center ${
            userAnswers[currentQuestionIndex] !== undefined
              ? 'bg-blue-500 text-white hover:bg-blue-600'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
        >
          {currentQuestionIndex === readinessQuizData.length - 1 ? 'Finish Quiz' : 'Next Question'}
          <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
        </button>
      )}
      {renderExampleModal()}
    </div>
  );
};

export default LeadMagnetQuiz;