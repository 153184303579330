// src/features/modalPackages/modalPackagesSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const modalPackagesSlice = createSlice({
  name: 'modalPackages',
  initialState: {
    isOpen: false
  },
  reducers: {
    openModal: state => {
      state.isOpen = true;
    },
    closeModal: state => {
      state.isOpen = false;
    }
  }
});

export const { openModal, closeModal } = modalPackagesSlice.actions;

export const selectIsOpen = state => state.modalPackages.isOpen;

export default modalPackagesSlice.reducer;
