import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoltLightning, faInfoCircle, faQuestionCircle, faCog, faChartLine, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import config from '../../config/config';

const QuizGenerator = ({ onStartQuiz }) => {
  const [questionCount, setQuestionCount] = useState(5);
  const [quizType, setQuizType] = useState('general');
  const [selectedSection, setSelectedSection] = useState('');
  const userStats = useSelector((state) => state.userStats);
  const sectionOptions = config.quiz_sections;

  const handleStartQuiz = () => {
    onStartQuiz({
      questionCount,
      quizType,
      ...(quizType === 'section-specific' && { selectedSection }),
    });
  };

  const QuizTypeCard = ({ type, title, description, icon }) => (
    <div 
      className={`relative overflow-hidden rounded-lg p-6 cursor-pointer transition-all duration-300 ${
        quizType === type 
          ? 'bg-blue-600 text-white shadow-lg transform scale-105' 
          : 'bg-white text-gray-800 hover:bg-gray-100'
      }`}
      onClick={() => setQuizType(type)}
    >
      <FontAwesomeIcon icon={icon} className="absolute top-4 right-4 text-3xl opacity-20" />
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="text-sm">{description}</p>
    </div>
  );

  return (
    <div className="bg-gray-100 text-inter text-gray-800 rounded-xl shadow-2xl p-8 max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-blue-600">
        <FontAwesomeIcon icon={faBoltLightning} className="text-yellow-400 mr-2" />
        Generate Practice Quiz
      </h2>

      <div className="mb-8">
        <label className="block text-lg font-semibold mb-2" htmlFor="questionCount">
          Number of Questions
        </label>
        <div className="relative">
          <select
            id="questionCount"
            value={questionCount}
            onChange={(e) => setQuestionCount(Number(e.target.value))}
            className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-blue-500 transition duration-150 ease-in-out"
          >
            {[5, 10, 20, 30, 50, 75, 80].map(num => (
              <option key={num} value={num}>{num}</option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-4">Quiz Type</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <QuizTypeCard 
            type="general" 
            title="General Prep"
            description="Random questions from the entire question bank."
            icon={faCog}
          />
          <QuizTypeCard 
            type="section-specific" 
            title="Section-Specific"
            description="Focus on specific exam sections."
            icon={faQuestionCircle}
          />
          <QuizTypeCard 
            type="incorrect" 
            title="Incorrect Answers"
            description="Based on your previous mistakes."
            icon={faChartLine}
          />
        </div>
      </div>

      {quizType === 'section-specific' && (
        <div className="mb-8">
          <label className="block text-lg font-semibold mb-2" htmlFor="sectionSelect">
            Select Section
          </label>
          <div className="relative">
            <select
              id="sectionSelect"
              value={selectedSection}
              onChange={(e) => setSelectedSection(e.target.value)}
              className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-blue-500 transition duration-150 ease-in-out"
            >
              <option value="">Select a section</option>
              {sectionOptions.map((section, index) => (
                <option key={index} value={section}>{section}</option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <FontAwesomeIcon icon={faQuestionCircle} />
            </div>
          </div>
        </div>
      )}

      <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-8 rounded">
        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
        <span className="font-semibold">Quiz Type Description:</span>
        <p className="mt-2">
          {quizType === 'general' && 'Generate a set of random questions from the entire NCLEX question bank to mimic a real test experience.'}
          {quizType === 'section-specific' && 'Focus on specific exam sections such as Management of Care, Basic Care and Comfort, or Physiological Adaptation, to strengthen your knowledge in areas where you feel less confident.'}
          {quizType === 'incorrect' && 'Generate a quiz based on your previous incorrect answers to help you focus on areas where you need improvement.'}
        </p>
      </div>

      <button
        onClick={handleStartQuiz}
        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:shadow-outline"
        disabled={quizType === 'section-specific' && !selectedSection}
      >
        <FontAwesomeIcon icon={faBoltLightning} className="mr-2" />
        Generate a Fresh Quiz
      </button>

      {/*userStats.totalQuestions > 0 && (
        <div className="mt-6 bg-white rounded-lg p-4 shadow">
          <h3 className="text-lg font-semibold mb-2 text-gray-700">Your Current Statistics</h3>
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-600">Total Questions: {userStats.totalQuestions}</p>
              <p className="text-sm text-gray-600">Correct Answers: {userStats.correctAnswers}</p>
            </div>
            <div className="text-right">
              <p className="text-2xl font-bold text-blue-600">
                {((userStats.correctAnswers / userStats.totalQuestions) * 100).toFixed(2)}%
              </p>
              <p className="text-sm text-gray-600">Accuracy</p>
            </div>
          </div>
        </div>
      )*/}
    </div>
  );
};

export default QuizGenerator;