import React from 'react';
import { Bubble } from 'react-chartjs-2';
import { Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const ImprovementAreasBubbleChart = ({ showInfo }) => {
  const { improvementAreas, loading, error } = useSelector((state) => state.userStats);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64 bg-gray-900">
        <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-blue-500" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center bg-gray-900 p-4">Error loading data</div>;
  }

  if (!improvementAreas) {
    return <div className="text-gray-500 text-center bg-gray-900 p-4">No data available</div>;
  }

  const data = {
    datasets: Object.entries(improvementAreas).map(([category, stats], index) => ({
      label: category,
      data: [{
        x: stats.performance,
        y: stats.attempted,
        r: Math.sqrt(stats.attempted) * 2 // Adjust the multiplier to change bubble size
      }],
      backgroundColor: `hsla(${index * 137.5 % 360}, 70%, 60%, 0.7)`,
    }))
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { 
        position: 'top',
        labels: { color: 'white' }
      },
      title: { 
        display: true, 
        text: 'Improvement Areas',
        color: 'white',
        font: { size: 16, weight: 'bold' }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const performance = context.parsed.x.toFixed(2);
            const attempted = context.parsed.y;
            return `${label}: Performance: ${performance}%, Attempted: ${attempted}`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: { display: true, text: 'Questions Attempted', color: 'white' },
        ticks: { color: 'white' }
      },
      x: {
        beginAtZero: true,
        max: 100,
        title: { display: true, text: 'Performance (%)', color: 'white' },
        ticks: { color: 'white' }
      }
    }
  };

  return (
    <div className={`bg-gray-900 lg:p-4 rounded-lg shadow-md border-4 border-primary ${!showInfo ? 'h-52 max-h-52 w-full' : 'h-96 max-h-96 w-full lg:h-96 lg:max-h-96 overflow-auto'} flex flex-col items-center`}>
      <Bubble data={data} options={options} />
      {showInfo && <div className="mt-4 text-white pt-2 px-1 leading-none">
        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
        <span className="text-xs">
          This bubble chart visualizes your performance across different subject areas. 
          Each bubble represents a subject area, where:
          <ul className="list-disc list-inside mt-2">
            <li>The horizontal position shows your performance percentage</li>
            <li>The vertical position indicates the number of questions attempted</li>
            <li>The size of the bubble reflects the relative importance of the area</li>
          </ul>
          Focus on moving bubbles to the upper-right corner to improve your overall exam readiness.
          Larger bubbles in the lower-left quadrant may need immediate attention.
        </span>
      </div>}
    </div>
  );
};

export default ImprovementAreasBubbleChart;