import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../features/user/userSlice';
import { selectSession } from '../features/session/sessionSlice';
import { selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { generateImage } from '../api/aiFunctions';
import { selectCurrentPrompt, setResponse, setLoading, setError, setCurrentPrompt } from '../features/chat/chatSlice';
import { setThreadId, selectCurrentThreadId, updateThreadId, deleteThreadId } from '../features/threads/threadSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight, faDownload, faArrowRotateRight, faCopy, faSave } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import { copyToClipboard } from '../utils/commons';
import { addToast } from '../features/ui/uiSlice';
import '../styles/loader1.css';
import socket from '../utils/socket';
import SavePrompt from './SavePrompt';
import ModalPromptSave from './ModalPromptSave';

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
`;

const MessagesArea = styled.div`
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
`;

const Message = styled.div`
  background-color: ${props => props.isUser ? '#99abd6' : '#fff'};
  padding: 8px 10px;
  margin: 5px;
  border-radius: 8px;
  text-align: ${props => props.isUser ? 'right' : 'left'};
  color: #121212;
`;

const InputArea = styled.div`
  display: flex;
  padding: 10px;
  background-color: #eee;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  color: #121212;
`;

const SendButton = styled.button`
  padding: 10px 15px;
  background-color: #99abd6;
  color: #121212;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

const ImageChat = () => {
    const user = useSelector(selectUser);
    const session = useSelector(selectSession);
    const currentTool = useSelector(selectCurrentTool);
    const currentPrompt = useSelector((state) => state.chat.currentPrompt);
    // servicesData = useSelector((state) => state.services.data);
    const currentThreadId = useSelector(selectCurrentThreadId);
    const dispatch = useDispatch();
    const [messages, setMessages] = useState([]);
    const [messagesToSubmit, setMessagesToSubmit] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [submittedMessages, setSubmittedMessages] = useState([]);
    const [awaitingResponse, setAwaitingResponse] = useState(false);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const messagesEndRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCopy = async (text) => {
    const isCopied = await copyToClipboard(text);
    if (isCopied) {
        dispatch(addToast('Copied to clipboard!', 'success'));
    } else {
        dispatch(addToast('Failed to copy!', 'error'));
    }
  };

  const handleSend = async () => {
    
    if (inputValue.trim() !== '') {
      setLoading(true);
      const newMessage = { role: 'user', content: inputValue };
      setMessages(messages => [...messages, { id: messages.length + 1, text: inputValue, isUser: true }]);
      setMessagesToSubmit(messagesToSubmit => [...messagesToSubmit, newMessage]);
      setInputValue('');
      setAwaitingResponse(true);
    }
  };

  const handleSendRegenerate = async (messageText) => {
    if (messageText.trim() !== '') {
      setLoading(true);
      const newMessage = { role: 'user', content: messageText };
      setMessages(messages => [...messages, { id: messages.length + 1, text: messageText, isUser: true }]);
      setMessagesToSubmit(messagesToSubmit => [...messagesToSubmit, newMessage]);
      setInputValue('');
      setAwaitingResponse(true);
    }
  };

  useEffect(() => {
    const checkScroll = () => {
      if (messagesEndRef.current) { // Check if messagesEndRef.current is not null
        const isAtBottom = messagesEndRef.current.scrollHeight - messagesEndRef.current.scrollTop === messagesEndRef.current.clientHeight;
        setShowScrollButton(!isAtBottom);
      }
    };
  
    // Only add event listener if messagesEndRef.current is not null
    if (messagesEndRef.current) {
      messagesEndRef.current.addEventListener('scroll', checkScroll);
    }
  
    return () => {
      // Use if-check to ensure cleanup doesn't cause errors
      if (messagesEndRef.current) {
        messagesEndRef.current.removeEventListener('scroll', checkScroll);
      }
    };
  }, [messagesEndRef.current]); 
  
  const scrollToBottom = () => {
    messagesEndRef.current.scrollTo({
      top: messagesEndRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (currentPrompt) {
      const fetchChatResponse = async () => {
        const newMessage = { role: 'user', content: currentPrompt };
        setMessages(messages => [...messages, { id: messages.length + 1, text: currentPrompt, isUser: true }]);
        setMessagesToSubmit(messagesToSubmit => [...messagesToSubmit, newMessage]);
        //setInputValue('');
        dispatch(setCurrentPrompt(null));
        setAwaitingResponse(true);
        setLoading(true);
      };

      fetchChatResponse();
    }
  }, [currentPrompt, dispatch]); 
  
  const handleNewChat = () => {
    setMessages([]);
    setMessagesToSubmit([]);
    setSubmittedMessages([]);
    setAwaitingResponse(false);
    dispatch(setThreadId(null));
  };

  useEffect(() => {
    if (!currentThreadId) {
      handleNewChat();
    }
  }, [currentThreadId]);

  useEffect(() => {  
    if (messagesToSubmit.length > submittedMessages.length && awaitingResponse) {
      dispatch(setThreadId('imageGenerator'));
      const callGenerateChat = async () => {
        try {
            const lastNewMessage = messagesToSubmit[messagesToSubmit.length - 1];
          const chatResponse = await generateImage(
            user.dynamic_id,
            currentTool._id,
            lastNewMessage.content,
            session.stytch_session_token,
            //servicesData
          );
          console.log('Image Response:', chatResponse);
          if (chatResponse.success && Array.isArray(chatResponse.data)) {
            // Loop through each responseData item
            chatResponse.data.forEach((responseItem) => {
              // Add each responseData item as its own message
              setMessages(messages => [
                ...messages, 
                { 
                  id: messages.length + 1, 
                  text: responseItem.data, // URL of the generated image
                  isUser: false, 
                  service: responseItem.service // Name of the service
                }
              ]);
            });
          } else {
            // Handle cases where the chatResponse is not successful or doesn't contain the expected data
            console.log('No images received or an error occurred');
          }

          //setMessages(messages => [...messages, { id: messages.length + 1, text: chatResponse.gaim_url, isUser: false, service: chatResponse.service }]);
          setMessagesToSubmit(messagesToSubmit => [...messagesToSubmit, { role: 'assistant', content: chatResponse.gaim_url }]);
            setSubmittedMessages(submittedMessages => [...submittedMessages, lastNewMessage]);
            setAwaitingResponse(false);
            setLoading(false);
        } catch (error) {
          console.error('Failed to generate image:', error);
          setAwaitingResponse(false);
          setLoading(false);
        }
      };
  
      callGenerateChat();
    }
  }, [messagesToSubmit, currentTool, session, user]);
  
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };
  
  const downloadImage = async (url, filename) => {
    try {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok.');

        // Assuming the content is an image/jpeg; adjust if necessary
        const blob = await response.blob();
        const jpegBlob = new Blob([blob], { type: 'image/jpeg' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(jpegBlob);
        link.download = `${filename}.jpg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading image:', error);
    }
};

const handleRegenerate = (messageText, isUser) => {
  console.log('Regenerating message:', messageText, isUser)
  if (isUser) {
    // If the icon clicked is from a user's message, simply resend that message
    //const messageToRegenerate = messages.find(message => message.id === messageId);
    handleSendRegenerate(messageText);
    console.log('Resending message:', messageText)
  } else {
    // If the icon clicked is from an assistant's response, find the preceding user's message to regenerate
    /*for (let i = messageId - 1; i >= 0; i--) {
      const previousMessage = messages[i];
      if (previousMessage.isUser) {
        handleSend(previousMessage.text);
        break;
      }
    }*/
  }
};

const servicesMap = [
  { id: 'leonardo', label: 'Leonardo AI', image_url: 'https://byok.nyc3.digitaloceanspaces.com/images/leonardoLogo.jpg' },
  { id: 'openai', label: 'Open AI (DALLE)', image_url: 'https://byok.nyc3.digitaloceanspaces.com/images/openAILogo.jpg' },
  { id: 'stablediffusion', label: 'Stable Diffusion', image_url: 'https://byok.nyc3.digitaloceanspaces.com/images/stablediffusionLogo.jpg' }
];

  return (
    <ChatContainer>
      {loading && (
        <div className='rounded border p-2 flex pt-3 pb-4 flex-row justify-center items-center fade-colors-dark'>
          <div className="loader h-10 w-10">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className='ml-5 fade-colors-dark'>
            Generating Image...
          </div>
          
        </div>)
      }
      <MessagesArea ref={messagesEndRef}>
      {messages.map((message) => (
        <Message key={message.id} isUser={message.isUser}>
          {!message.isUser && currentTool?.image_data?.example_url ? (<>
            <div className='flex flex-row items-center'>
              <img 
                src={currentTool.image_data.example_url} 
                alt={currentTool.name} // Provide an appropriate alt attribute
                style={{ width: '40px', height: '40px', borderRadius: '50%', marginBottom: '8px' }} 
              />
              <FontAwesomeIcon icon={faArrowRight} className="mr-2 ml-2 mb-2" />
              {message.service && (
                <img 
                  src={servicesMap.find(service => service.id === message.service).image_url} 
                  alt={currentTool.name} // Provide an appropriate alt attribute
                  style={{ width: '40px', height: '40px', borderRadius: '50%', marginBottom: '8px' }} 
                />
              )}
              
            </div>
              
            <div className="flex flex-col items-center">
            <a href={message.text} target="_blank" rel="noreferrer">
              <img
                src={message.text}
                alt={currentTool.name}
                style={{ width: '400px', marginBottom: '8px' }}
              />
            </a>
            <button
              onClick={() => {
                const timestamp = new Date().getTime();
                downloadImage(message.text, timestamp);
              }}
              className="text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out"
            >
              <FontAwesomeIcon icon={faDownload} className="mr-1" />
              Download
            </button>
          </div>
          </>) : (<><div>
                <b>PROMPT:</b> <ReactMarkdown>{message.text}</ReactMarkdown>
            </div>
            <div className='border-t mt-5 text-right pt-2' style={{marginLeft: '80%'}}>
            <FontAwesomeIcon icon={faArrowRotateRight} onClick={() => handleRegenerate(message.text, message.isUser)} className='ml-2 cursor-pointer hover:text-gray-600' />
            <FontAwesomeIcon icon={faSave} onClick={() => setIsModalOpen(true)} className='ml-2 cursor-pointer hover:text-gray-600' />
            <ModalPromptSave isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <SavePrompt prompt={message.text} onClose={() => setIsModalOpen(false)} type={'image'} />
              </ModalPromptSave>
            <FontAwesomeIcon icon={faCopy} onClick={() => handleCopy(message.text)} className='ml-2 cursor-pointer hover:text-gray-600' />
          </div>
          </>)}
          
        </Message>
      ))}
      </MessagesArea>
      {showScrollButton && (
        <button onClick={scrollToBottom} className="relative mx-auto fixed bottom-20 p-2 bg-blue-500 w-1/2 text-white rounded-full shadow-lg">
            <FontAwesomeIcon icon={faArrowDown} />
        </button>
        )}
      <InputArea>
        <Input
          type="text"
          value={inputValue}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="Describe your image..."
        />
        <SendButton onClick={handleSend}>Send</SendButton>
      </InputArea>
    </ChatContainer>
  );
};

export default ImageChat;
