import { useEffect } from 'react';

const updateThemeVariables = (theme, config) => {
  const root = document.documentElement;

  if (theme === 'dark') {
    // Set variables for .theme-dark
    root.classList.add('theme-dark');
    root.style.setProperty('--primary-color', config.dark_primaryColor);
    root.style.setProperty('--secondary-color', config.dark_secondaryColor);
    root.style.setProperty('--background-color', config.dark_background_color);
    root.style.setProperty('--inverted-background-color', config.dark_inverted_background_color);
    root.style.setProperty('--text-color', config.dark_text_color);
    root.style.setProperty('--inverted-text-color', config.dark_inverted_text_color);
  } else {
    // Set variables for :root
    root.classList.remove('theme-dark');
    root.style.setProperty('--primary-color', config.light_primaryColor);
    root.style.setProperty('--secondary-color', config.light_secondaryColor);
    root.style.setProperty('--background-color', config.light_background_color);
    root.style.setProperty('--inverted-background-color', config.light_inverted_background_color);
    root.style.setProperty('--text-color', config.light_text_color);
    root.style.setProperty('--inverted-text-color', config.light_inverted_text_color);
  }

  // Set common variables
  root.style.setProperty('--success-color', config.successColor);
  root.style.setProperty('--information-color', config.informationColor);
  root.style.setProperty('--error-color', config.errorColor);
  root.style.setProperty('--warning-color', config.warningColor);
  root.style.setProperty('--white-color', config.whiteColor);
};

export const useThemeUpdate = (theme, config) => {
  useEffect(() => {
    updateThemeVariables(theme, config);
  }, [theme, config]);
};
