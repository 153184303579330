import axios from 'axios';
import config from '../../config/config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const apiURL = process.env.REACT_APP_API_URL;

// Async thunk for fetching tool data
export const fetchMerchItems = createAsyncThunk('merch/fetchMerchItems', async () => {
    const response = await axios.get(`${apiURL}/fetch/CryptoMerchItem`);
    console.log('crypto merch response.data: ', response.data.data);
    return response.data.data;
  });

  const merchItemsSlice = createSlice({
    name: 'merchItems',
    initialState: {
      items: [],
      status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchMerchItems.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchMerchItems.fulfilled, (state, action) => {
          state.status = 'succeeded';
          // Add any fetched items to the array
          state.items = action.payload;
        })
        .addCase(fetchMerchItems.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  
  export default merchItemsSlice.reducer;

  export const selectMerchItems = (state) => state.merchItems.items;