import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedProfile, selectSelectedProfile } from '../features/user/selectedProfileSlice';
import { selectSession } from '../features/session/sessionSlice';
import { addProfile, deleteProfile, modifyProfile } from '../api/userFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const ProfileManager = ({ user }) => {
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [editProfileId, setEditProfileId] = useState(null);
  const [newProfile, setNewProfile] = useState({ name: '', content: '' });
  const [editProfile, setEditProfile] = useState({ name: '', content: '' });
  const dispatch = useDispatch();
  const selectedProfile = useSelector(selectSelectedProfile);
  const session = useSelector(selectSession);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectProfile = (profile) => {
    dispatch(setSelectedProfile(profile));
    setIsCreatingNew(false);
    setEditProfileId(null);
  };

  const handleCreateProfile = async () => {
    await addProfile(user?.dynamic_id, newProfile, session); // Adjust to match your actual implementation
    setIsCreatingNew(false);
    setNewProfile({ name: '', content: '' });
    // Reload or update user profiles list here
  };

  const handleDeleteProfile = async (profileId) => {
    if (window.confirm('Are you sure you want to delete this profile?')) {
      await deleteProfile(user?.dynamic_id, profileId, session); // Adjust to match your actual implementation
      // Reload or update user profiles list here
      if (selectedProfile && selectedProfile._id === profileId) {
        dispatch(setSelectedProfile(null));
      }
    }
  };

  const handleUpdateProfile = async (profileId) => {
    await modifyProfile(user?.dynamic_id, profileId, editProfile, session); // Adjust to match your actual implementation
    setEditProfileId(null);
    setEditProfile({ name: '', content: '' });
    // Reload or update user profiles list here
  };

  return (
    <div className='w-full'>
        <button onClick={toggleDropdown} className="flex justify-between items-center w-full px-4 py-2 bg-primary text-inverted font-semibold mt-2">
          <span>Select or Create a Profile</span>
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
        </button>
      {/*<select onChange={(e) => handleSelectProfile(JSON.parse(e.target.value))} className="mt-3 mb-3 p-2 border rounded">
        <option value="">Select a Profile</option>
        {user?.profiles.map((profile) => (
          <option key={profile._id} value={JSON.stringify(profile)}>
            {profile.name}
          </option>
        ))}
        
        </select>*/}
        {isOpen && (<>
      <button type='button' className="p-2 border border-gray-500 mt-1 hover:text-green-600 hover:bg-gray-800 rounded" onClick={() => setIsCreatingNew(true)}>
        <FontAwesomeIcon icon={faPlus} className="mr-2 text-green-600" />
        Create New Profile
        </button>

      {isCreatingNew && (
        <div className="mt-3">
          <input
            type="text"
            placeholder="Name"
            className="p-2 border rounded w-full mb-2 text-black"
            value={newProfile.name}
            onChange={(e) => setNewProfile({ ...newProfile, name: e.target.value })}
          />
          <textarea
            placeholder="Content"
            className="p-2 border rounded w-full mb-2 text-black"
            value={newProfile.content}
            onChange={(e) => setNewProfile({ ...newProfile, content: e.target.value })}
          />
          <button onClick={handleCreateProfile} className="p-2 border rounded bg-primary text-inverted">Save Profile</button>
          <button type='button' className="p-2 border rounded bg-gray-500 text-inverted" onClick={() => setIsCreatingNew(false)}>Cancel</button>
        </div>
      )}

      {editProfileId && (
        <div className="mt-3">
          <input
            type="text"
            placeholder="Name"
            className="p-2 border rounded w-full mb-2 text-black"
            value={editProfile.name}
            onChange={(e) => setEditProfile({ ...editProfile, name: e.target.value })}
          />
          <textarea
            placeholder="Content"
            className="p-2 border rounded w-full mb-2 text-black"
            value={editProfile.content}
            onChange={(e) => setEditProfile({ ...editProfile, content: e.target.value })}
          />
          <button onClick={() => handleUpdateProfile(editProfileId)} className="p-2 border rounded bg-blue-500 text-white">Update Profile</button>
          <button onClick={() => setEditProfileId(null)} className="ml-2 p-2 border rounded bg-gray-500 text-white">Cancel</button>
        </div>
      )}

      {!isCreatingNew && user?.profiles.map((profile) => (
        <div key={profile._id} className="flex items-center justify-between mt-2">
          <span>{profile.name}</span>
          <div>
            <FontAwesomeIcon icon={faEdit} onClick={() => { setEditProfileId(profile._id); setEditProfile({ name: profile.name, content: profile.content }); }} className="cursor-pointer text-xl text-green-600 mr-2" />
            <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteProfile(profile._id)} className="cursor-pointer text-red-600 text-xl" />
          </div>
        </div>
      ))}
        </>)}
    </div>
  );
};

export default ProfileManager;
