import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AddBoardItemForm from './AddBoardItemForm';
import config from '../config/config';
import { createBoard } from '../features/boards/boardSlice';
import { selectUser } from '../features/user/userSlice';
import { faPaperclip, faPlusCircle, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DashAddBoardItem = () => {
    const dispatch = useDispatch();
    const boards = useSelector(state => state.boards.boards);
    const [selectedBoardId, setSelectedBoardId] = useState('');
    const user = useSelector(selectUser);
    const [newBoardName, setNewBoardName] = useState('');

    useEffect(() => {
        if (boards.length > 0) {
            setSelectedBoardId(boards[0]._id);
        }
    }, [boards]);

    const handleBoardChange = (event) => {
        setSelectedBoardId(event.target.value);
    };

    const handleCreateBoard = async () => {
        if (newBoardName.trim()) {
            await dispatch(createBoard({ name: newBoardName, userId: user?._id })).unwrap();
            setNewBoardName('');
        }
    };

    return (
        <div className="h-64 flex flex-col">
            {/*<h2 className="text-xl font-bold mb-4 flex items-center">
                <FontAwesomeIcon icon={faPaperclip} className="mr-2 text-blue-500" />
                Add New {config.boards_title_singular} Item
            </h2>*/}
            <div className="flex-grow overflow-y-auto">
                {boards?.length > 0 ? (
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="board" className="block text-sm font-medium text-gray-700 mb-1">
                                Select {config.boards_title_singular}
                            </label>
                            <select
                                value={selectedBoardId}
                                onChange={handleBoardChange}
                                className="w-full border text-gray-800 border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                            >
                                {boards.map(board => (
                                    <option key={board._id} value={board._id}>{board.name}</option>
                                ))}
                            </select>
                        </div>
                        {selectedBoardId && (
                            <AddBoardItemForm boardId={selectedBoardId} onFormToggle={() => {}} />
                        )}
                    </div>
                ) : (
                    <div className="text-center space-y-4">
                        <div className="text-gray-600">
                            <FontAwesomeIcon icon={faClipboardList} className="text-4xl mb-2" />
                            <p>No {config.boards_title} found.</p>
                            <p className="text-sm">Please create a new {config.boards_title_singular} first.</p>
                        </div>
                        <div className="flex items-center space-x-2">
                            <input
                                type="text"
                                placeholder={`New ${config.boards_title_singular} Name`}
                                value={newBoardName}
                                onChange={(e) => setNewBoardName(e.target.value)}
                                className="flex-grow border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                            />
                            <button
                                onClick={handleCreateBoard}
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                                Create
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DashAddBoardItem;