import axios from 'axios';
import config from '../config/config';

const apiURL = process.env.REACT_APP_API_URL;
const app_id = config.app_id;
const quiz_gen_oaiassistant_id = config.quiz_gen_oaiassistant_id;
const quiz_chat_oaiassistant_id = config.quiz_chat_oaiassistant_id;

export const quizApi = {
  generateQuiz: (quizOptions, userId) => {
    console.log('quizOptions:', quizOptions, 'userId:', userId, 'oaiassistant_id:', quiz_gen_oaiassistant_id)
    return axios.post(`${apiURL}/quiz/quiz/generate`, {
      ...quizOptions,
      app_id: app_id,
      quiz_gen_oaiassistant_id: quiz_gen_oaiassistant_id,
      userId: userId,
    });
  },

  fetchQuiz: (quizOptions, userId) => {
    console.log('quizOptions:', quizOptions, 'userId:', userId, 'oaiassistant_id:', quiz_gen_oaiassistant_id)
    return axios.post(`${apiURL}/quiz/quiz/fetch`, {
      ...quizOptions,
      app_id: app_id,
      quiz_gen_oaiassistant_id: quiz_gen_oaiassistant_id,
      userId: userId,
    });
  },

  submitQuizResults: (quizData) => {
    return axios.post(`${apiURL}/quiz/quiz/submit`, quizData);
  },

  postChatMessage: (messageData, userId) => {
    return axios.post(`${apiURL}/quiz/quiz/chat`, {
      ...messageData,
      app_id: app_id,
      quiz_chat_oaiassistant_id: quiz_chat_oaiassistant_id,
      userId: userId,
    });
  },
};


export const flashcardApi = {
  generateFlashcards: (userId) => {
    return axios.post(`${apiURL}/quiz/flashcard/generate/${userId}`, {
      app_id
    });
  },

  getFlashcards: (userId, category, sort) => {
    return axios.get(`${apiURL}/quiz/flashcard/${userId}`, {
      params: { category, sort, app_id }
    });
  },

  updateFlashcardMastery: (flashcardId, newMasteryLevel) => {
    return axios.put(`${apiURL}/quiz/flashcard/${flashcardId}/mastery`, {
      newMasteryLevel,
      app_id
    });
  },

  createCustomFlashcard: (userId, flashcardData) => {
    return axios.post(`${apiURL}/quiz/flashcard/custom/${userId}`, {
      ...flashcardData,
      app_id
    });
  },

  deleteFlashcard: (flashcardId) => {
    return axios.delete(`${apiURL}/quiz/flashcard/${flashcardId}`, {
      params: { app_id }
    });
  },

  addFlashcardPack: (userId, flashcardPackId) => 
    axios.post(`${apiURL}/quiz/flashcard/add-pack`, { userId, flashcardPackId }),
};