import React, { useState } from 'react';
import config from "../../../config/config";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpenReader, faRightToBracket, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import ThemeSwitcher from "../../../components/ThemeSwitcher";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="relative h-16 lg:h-24 flex items-center z-10 w-full mt-0">
      <div className="z-40 container mx-auto px-6 flex items-center justify-between">
        <Link to='/' className="text-3xl text-primary font-bold">
          <div className="uppercase text-3xl flex items-center h-24 mr-4 gap-5">
            <img
              className="h-10 lg:h-16 xl:h-24 mx-auto rounded-full"
              src={config.logoUrl}
              alt={config.siteTitle}
            />
            <div className="flex flex-col">
            <h1 className="text-inter font-bold text-lg lg:text-2xl xl:text-4xl text-primary">
              {config.siteName}
            </h1>
            <div className="text-xs lg:text-base text-body">{config.siteTagline}</div>
            </div>
          </div>
        </Link>
        <div className="flex items-center">
          <nav className="hidden lg:flex font-sen justify-end flex-row gap-4">
            <Link
              to={'/blog'}
              className="uppercase py-2 px-4 border border-gray-900 text-inter font-bold bg-primary hover:bg-gray-700 text-gray-100 focus:ring-gray-900 focus:ring-offset-gray-900 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
              style={{ minWidth: '180px' }}
            >
              <FontAwesomeIcon icon={faBookOpenReader} className="mr-2" />{config.blog_title}
            </Link>
            <Link
              to={'/login'}
              className="uppercase py-2 px-4 border border-gray-900 text-inter font-bold bg-primary hover:bg-gray-700 text-gray-100 focus:ring-gray-900 focus:ring-offset-gray-900 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
              style={{ minWidth: '180px' }}
            >
              <FontAwesomeIcon icon={faRightToBracket} className="mr-2" />Members Login
            </Link>
            <ThemeSwitcher />
          </nav>
          <div className="lg:hidden flex items-center">
            <button onClick={toggleMenu} className="text-3xl text-primary focus:outline-none">
              <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
            </button>
          </div>
        </div>
      </div>
      {menuOpen && (
        <div className="absolute top-full left-0 right-0 mx-2 bg-gray-100 border-2 border-primary shadow-md rounded-lg mt-2 transition-all duration-300 ease-in-out">
          <nav className="flex flex-col items-center gap-4 p-4">
            <Link
              to={'/login'}
              className="uppercase py-2 px-4 border border-gray-900 text-inter font-bold bg-primary hover:bg-gray-700 text-gray-100 focus:ring-gray-900 focus:ring-offset-gray-900 w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
              onClick={() => setMenuOpen(false)}
            >
              <FontAwesomeIcon icon={faRightToBracket} className="mr-2" />Members Login
            </Link>
            <Link
              to={'/blog'}
              className="uppercase py-2 px-4 border border-gray-900 text-inter font-bold bg-primary hover:bg-gray-700 text-gray-100 focus:ring-gray-900 focus:ring-offset-gray-900 w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
              onClick={() => setMenuOpen(false)}
            >
              <FontAwesomeIcon icon={faBookOpenReader} className="mr-2" />{config.blog_title}
            </Link>
            {/*<ThemeSwitcher />*/}
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
