import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FeatureCard = ({ title, icon, description }) => {
    return (
      <div className="flex flex-col items-center p-6 space-y-3 text-center bg-white rounded-xl shadow-lg dark:bg-gray-800 border border-primary">
        <div className="bg-primary text-gray-100 p-3 rounded-full">
          <FontAwesomeIcon icon={icon} size="2x" />
        </div>
        <h3 className="text-xl font-bold text-primary">{title}</h3>
        <p className="text-gray-600 dark:text-gray-300">{description}</p>
      </div>
    );
  };

export default FeatureCard;
