// src/features/modalSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const modaliframeSlice = createSlice({
  name: 'modalIFrame',
  initialState: {
    isOpen: false,
    url: ''
  },
  reducers: {
    openModalIFrame: (state, action) => {
      state.isOpen = true;
      state.url = action.payload.url;
    },
    closeModalIFrame: (state) => {
      state.isOpen = false;
      state.url = '';
    }
  }
});

export const { openModalIFrame, closeModalIFrame } = modaliframeSlice.actions;

export const selectModalIFrameState = (state) => state.modalIFrame;

export default modaliframeSlice.reducer;
