import React from 'react';

function Profile() {
  return (
    <div>
      <h1 className='text-success'>Profile</h1>
      <div className="inverted">
        This content has inverted colors based on the theme.
      </div>

    </div>
  );
}

export default Profile;
