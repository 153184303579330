import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SubjectAreaBarChart = ({ showInfo }) => {
  const { subjectAreaPerformance, loading, error } = useSelector((state) => state.userStats);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-blue-500" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">Error loading data</div>;
  }

  if (!subjectAreaPerformance) {
    return <div className="text-gray-500 text-center">No data available</div>;
  }

  const data = {
    labels: Object.keys(subjectAreaPerformance),
    datasets: [
      {
        label: 'Correct',
        data: Object.values(subjectAreaPerformance).map(area => area.correct),
        backgroundColor: 'rgba(132, 224, 132, 0.8)',
      },
      {
        label: 'Incorrect',
        data: Object.values(subjectAreaPerformance).map(area => area.incorrect),
        backgroundColor: 'rgba(255, 99, 132, 0.8)',
      }
    ]
  };

  const options = {
    responsive: true,
    scales: {
        x: { 
          stacked: true,
          ticks: {
            color: 'white' // This will make the x-axis labels white
          }
        },
        y: { 
          stacked: true,
          ticks: {
            color: 'white' // This will make the y-axis labels white as well
          }
        }
      },
    plugins: {
      legend: { position: 'top', labels: { color: 'white' }},
      title: { display: true, text: 'Subject Area Performance', color: 'white' },
    }
  };

  return (
    <div className={`bg-gray-900 lg:p-4 rounded-lg shadow-md border-4 border-primary ${!showInfo ? 'h-52 max-h-52 w-full' : 'h-96 max-h-96 w-full lg:h-96 lg:max-h-96 overflow-auto'} flex flex-col items-center`}>
      <Bar data={data} options={options} />
      {showInfo && <div className="mt-4 text-white leading-none pt-2 px-1 leading-none">
        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
        <span className="text-xs">
            This stacked bar chart illustrates your performance across different subject areas. Each bar 
            represents a subject, with the green portion showing correct answers and the red portion 
            showing incorrect answers. Longer bars indicate more questions attempted in that subject. 
            Use this chart to:
            <ul className="list-disc list-inside mt-2">
            <li>Identify your strongest and weakest subject areas</li>
            <li>Focus your study efforts on subjects with larger red portions</li>
            <li>Ensure you're balancing your practice across all relevant subjects</li>
            </ul>
            Aim to increase the green portion in each bar as you progress in your exam preparation.
        </span>
      </div>}
    </div>
  );
};

export default SubjectAreaBarChart;