import { createSlice } from '@reduxjs/toolkit';

export const threadSlice = createSlice({
  name: 'thread',
  initialState: {
    currentThreadId: null,
  },
  reducers: {
    // Action to set the current thread ID
    setThreadId: (state, action) => {
      state.currentThreadId = action.payload;
    },
    // Action to update the current thread ID
    updateThreadId: (state, action) => {
      state.currentThreadId = action.payload;
    },
    // Action to delete (or reset) the current thread ID
    deleteThreadId: (state) => {
      state.currentThreadId = null;
    },
  },
});

// Export actions
export const { setThreadId, updateThreadId, deleteThreadId } = threadSlice.actions;

// Export reducer
export default threadSlice.reducer;

export const selectCurrentThreadId = (state) => state.thread.currentThreadId;
