import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/user/userSlice';
import SocialShare from './SocialShare';
import ExplanationModal from './ExplanationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale, faBookOpen, faBrain, faChartSimple, faCheckCircle, faChild, faExclamationTriangle, faHistory, faListAlt, faQuestionCircle, faStepForward, faTimes, faTools, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { quizApi } from '../../api/quizApi';
import BoardSelectorButton from '../BoardSelectorButton';

const QuizResults = () => {
  const results = useSelector((state) => state.quiz.lastResults);
  const user = useSelector(selectUser);
  const bites = useSelector((state) => state.tools.items.bites);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [randomBite, setRandomBite] = useState(() => {
    if (bites && bites.length > 0) {
      return bites[Math.floor(Math.random() * bites.length)];
    }
    return null;
  });

  if (!results) return <div className="text-center text-gray-600">No results available.</div>;

  const { numberOfCorrectAnswers, numberOfQuestions, totalPoints, userInput } = results;

  if (!numberOfCorrectAnswers || !numberOfQuestions || !totalPoints) {
    return <div className="text-center text-gray-600">Insufficient data to display results.</div>;
  }

  const score = (numberOfCorrectAnswers / numberOfQuestions) * 100;

  const handleExplainClick = async (question, explanation, explainType) => {
    setRandomBite(bites[Math.floor(Math.random() * bites.length)]);
    setIsModalOpen(true);
    setIsLoading(true);

    try {
      const response = await quizApi.postChatMessage({
        submitted_request: {
          quiz_question: question,
          quiz_explanation: explanation,
          explain_request: explainType
        }
      }, user?._id);
      setModalContent(response.data);
    } catch (error) {
      console.error('Error fetching explanation:', error);
      setModalContent('Failed to fetch explanation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderExplainButtons = (answer) => {
    const buttons = [
      { icon: faChild, text: "Like I'm 5" },
      { icon: faListAlt, text: "In Detail" },
      { icon: faBookOpen, text: "With A Story" },
      { icon: faBalanceScale, text: "With An Analogy" },
      { icon: faStepForward, text: "Step-By-Step" },
      { icon: faExclamationTriangle, text: "Misconceptions" },
      { icon: faHistory, text: "Historical Context" },
      { icon: faTools, text: "Practical Application" },
      { icon: faBrain, text: "Mnemonic Device" },
      { icon: faUserSecret, text: "Debunk Myths" }
    ];

    return (
      <div className="flex flex-wrap gap-2 mt-4">
        {buttons.map((button, index) => (
          <button
            key={index}
            className="bg-blue-500 hover:bg-blue-600 text-white text-sm py-1 px-2 rounded transition duration-300 ease-in-out flex items-center"
            onClick={() => handleExplainClick(answer.question, answer.explanation, button.text)}
          >
            <FontAwesomeIcon icon={button.icon} className="mr-1" />
            {button.text}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="bg-gradient-to-r from-blue-500 to-blue-600 text-white p-6">
        <h2 className="text-3xl font-bold mb-2 text-inter">
          <FontAwesomeIcon icon={faChartSimple} className="mr-2" />
          Quiz Results Overview
        </h2>
        <p className="text-xl">
          You got <span className="font-bold">{numberOfCorrectAnswers}</span> out of <span className="font-bold">{numberOfQuestions}</span> questions correct.
        </p>
        <p className="text-xl">
          Your score: <span className="font-bold">{score.toFixed(2)}%</span> ({totalPoints} points)
        </p>
      </div>

      <div className="p-6">
        <div className="mb-8">
          <h3 className="text-xl font-bold mb-4 text-gray-700 text-inter">Question Overview:</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {userInput.map((answer, index) => (
              <div
                key={index}
                className={`p-4 rounded-lg flex items-center text-inter ${
                  answer.isCorrect ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}
              >
                <FontAwesomeIcon
                  icon={answer.isCorrect ? faCheckCircle : faTimes}
                  className={`mr-3 text-2xl ${answer.isCorrect ? 'text-green-500' : 'text-red-500'}`}
                />
                <span>Question {index + 1}: {answer.isCorrect ? 'Correct' : 'Incorrect'}</span>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-bold mb-4 text-gray-700 text-inter">
            <FontAwesomeIcon icon={faBrain} className="mr-2 text-pink-500" />
            Areas for Improvement:
          </h3>
          {userInput.filter(answer => !answer.isCorrect).map((answer, index) => (
            <div key={index} className="mb-6 bg-gray-50 rounded-lg p-6 shadow-sm">
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-xl font-semibold text-blue-600"><FontAwesomeIcon icon={faQuestionCircle} className='mr-2' />Question {userInput.indexOf(answer) + 1}</h4>
                <BoardSelectorButton
                  itemContent={`Question: ${answer.question} Explanation: ${answer.explanation}`}
                  itemType="text"
                  userId={user?._id}
                />
              </div>
              <p className="mb-2 text-gray-700"><strong>Question:</strong> {answer.question}</p>
              <p className="text-gray-600"><strong>Explanation:</strong> {answer.explanation}</p>
              {renderExplainButtons(answer)}
            </div>
          ))}
        </div>
      </div>

      <div className="bg-gray-100 p-6">
        <SocialShare score={score} totalQuestions={numberOfQuestions} />
      </div>

      <ExplanationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={modalContent}
        isLoading={isLoading}
        initialRandomBite={randomBite}
        userId={user?._id}
      />
    </div>
  );
};

export default QuizResults;