import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/user/userSlice";
import {
  fetchFlashcards,
  selectAllFlashcards,
  selectFlashcardsStatus,
  addFlashcardPack
} from "../../features/flashcards/flashcardsSlice";
import FlashcardItem from "./FlashcardItem";
import FlashcardCreationForm from "./FlashcardCreationForm";
import FlashcardGameElement from "./FlashcardGameElement";
import FlashcardProgressTracker from "./FlashcardProgressTracker";
import FlashcardPackModal from "./FlashcardPackModal";
import config from "../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faArrowLeft,
  faArrowRight,
  faInfoCircle,
  faThumbTack,
  faFilter,
  faSort,
  faPlus,
  faBookOpen
} from "@fortawesome/free-solid-svg-icons";

const FlashcardPage = () => {
  const dispatch = useDispatch();
  const flashcards = useSelector(selectAllFlashcards);
  const flashcardpacks = useSelector((state) => [
    ...(state.tools.items.flashcardpacks ?? [])
  ].sort((a, b) => a.name.localeCompare(b.name)));
  const status = useSelector(selectFlashcardsStatus);
  const user = useSelector(selectUser);
  const [showCreationForm, setShowCreationForm] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [category, setCategory] = useState("");
  const [sort, setSort] = useState("");
  const [showPackModal, setShowPackModal] = useState(false);

  useEffect(() => {
    if (user && user._id) {
      dispatch(fetchFlashcards({ userId: user._id, category, sort }));
    }
  }, [dispatch, user, category, sort]);

  const handleAddFlashcardPack = async (flashcardPackId) => {
    if (user && user._id) {
      await dispatch(addFlashcardPack({ userId: user._id, flashcardPackId }));
      setShowPackModal(false);
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % flashcards.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + flashcards.length) % flashcards.length);
  };

  if (status === "loading") {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl text-inter font-bold mb-2 text-primary">
        <FontAwesomeIcon icon={faThumbTack} className="mr-2" />{config.siteName} Flashcards
      </h1>
      <p className="text-body text-sm lg:text-base mb-4 p-2">
        <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
        Your incorrect quiz answers are automatically transformed into personalized flashcards, seamlessly adding them to your study stack. 
        Boost your mastery with each review and track your progress. Add pre-made packs covering all NCLEX topics and sections to ensure comprehensive prep. 
        Keep your study streak alive by practicing daily. Study smarter, not harder!
      </p>

      <button 
        onClick={() => setShowPackModal(true)}
        className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 mb-4"
      >
        <FontAwesomeIcon icon={faBookOpen} className="mr-2" />
        Add Flashcard Packs
      </button>
     
      {flashcards.length === 0 ? (
        <div className="bg-white shadow-lg rounded-lg p-8 text-center">
          <p className="text-xl text-inter text-gray-800 mb-6">No flashcards available yet. Create your first flashcard or add a pre-made pack to get started!</p>
          <button 
            onClick={() => setShowCreationForm(!showCreationForm)}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            {showCreationForm ? (
              <>
                <FontAwesomeIcon icon={faAngleDoubleUp} className="mr-2" />
                Hide Creation Form
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Create New Flashcard
              </>
            )}
          </button>
          {showCreationForm && <FlashcardCreationForm />}
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="lg:col-span-2">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-6">
                <div className="p-6">
                  {flashcards.length > 0 && (
                    <FlashcardItem
                      flashcard={flashcards[currentIndex]}
                    />
                  )}
                  <div className="flex justify-between mt-6">
                    <button 
                      onClick={handlePrevious}
                      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                    >
                      <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                      Previous
                    </button>
                    <button 
                      onClick={handleNext}
                      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                    >
                      Next
                      <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <button 
                  onClick={() => setShowCreationForm(!showCreationForm)}
                  className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  {showCreationForm ? (
                    <>
                      <FontAwesomeIcon icon={faAngleDoubleUp} className="mr-2" />
                      Hide Creation Form
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faPlus} className="mr-2" />
                      Create New Flashcard
                    </>
                  )}
                </button>
                {showCreationForm && (
                  <div className="mt-4 bg-white shadow-lg rounded-lg overflow-hidden">
                    <FlashcardCreationForm />
                  </div>
                )}
              </div>
            </div>
            <div className="space-y-6">
              <FlashcardGameElement />
              <FlashcardProgressTracker />
            </div>
          </div>
        </>
      )}
      <FlashcardPackModal 
        isOpen={showPackModal}
        onClose={() => setShowPackModal(false)}
        flashcardPacks={flashcardpacks}
        onAddPack={handleAddFlashcardPack}
      />
    </div>
  );
};

export default FlashcardPage;