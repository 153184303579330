import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="flex justify-center items-center mt-8">
      {[...Array(totalPages).keys()].map(page => (
        <button
          key={page + 1}
          onClick={() => onPageChange(page + 1)}
          className={`mx-1 px-3 py-1 rounded ${
            currentPage === page + 1 ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700'
          }`}
        >
          {page + 1}
        </button>
      ))}
    </div>
  );
};

export default Pagination;