import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setUser } from '../features/user/userSlice';
import { setSession } from '../features/session/sessionSlice';
import { authUser } from '../api/userFunctions';
import PricingCards from './Homepage/components/pricingCards';
import AuthForm from './Homepage/components/AuthForm';
import { CheckoutForm, Return } from '../components/StripeCheckout';
import ProgressTracker from './Homepage/components/ProgressTracker';
import { motion, AnimatePresence } from 'framer-motion';
import Footer from './Homepage/components/Footer';
import config from '../config/config';

const LoginPurchase = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [topMessage, setTopMessage] = useState("💪 Stop stressing. We're here to help you pass your exam.");
  const [defaultToLogin, setDefaultToLogin] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const steps = ['Create Account', 'Choose Package', 'Checkout', 'Complete'];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    console.log('searchParams:', searchParams);
    const loggedIn = searchParams.get('loggedIn');
    console.log('loggedIn:', loggedIn);
    setDefaultToLogin(loggedIn);
  }, [location]);

  const handleAuthSuccess = async (sessionToken) => {
    try {
      const userDb = await authUser(sessionToken);
      dispatch(setUser(userDb.data));
      dispatch(setSession({
        stytch_session_token: sessionToken,
      }));
      setIsAuthenticated(true);
      if (userDb.data.subscription_id !== '6451434ac0267c72e2b0ddc9') {
        navigate('/dashboard');
      }
      setCurrentStep(1);
      setTopMessage(`🔑 Proper preparation is the #1 key to passing. We've got you covered.`);
    } catch (error) {
      console.error('Auth success handling error:', error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  const handlePackageSelection = () => {
    setCurrentStep(2);
    setTopMessage(`🔥 You're one step away from prepping like a pro. Can you feel the success?`);
  };

  const handleCheckoutComplete = () => {
    setCurrentStep(3);
  };

  return (
    <div className="min-h-screen flex flex-col justify-start pb-12 px-4 sm:px-6 lg:px-8 bg-black-main text-inter">
      <div className="text-xs md:text-base flex justify-center text-white mb-8 text-inter text-center">
        {topMessage}
      </div>
      <div className='flex mx-auto gap-4 justify-center items-center mb-8'>
        <div>
          <img src={config.logoUrl} alt="logo" className="block mx-auto w-20" />
        </div>
        <div className='flex flex-col justify-center items-start'>
          <h1 className="text-2xl md:text-3xl font-bold text-primary text-left text-inter">NCLEX Study Pro</h1>
          <h2 className="text-sm md:text-base text-gray-100 text-left">Pass your NCLEX. Guaranteed.</h2>
        </div>
        
      </div>
      <ProgressTracker currentStep={currentStep} steps={steps} />
      
      <AnimatePresence mode="wait">
        {currentStep === 0 && (
          <motion.div
            key="auth-form"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="max-w-md w-full mx-auto"
          >
            <div className="bg-white shadow-2xl rounded-lg overflow-hidden">
              <div className="px-4 py-5 sm:p-6">
                <AuthForm onAuthSuccess={handleAuthSuccess} defaultToLogin={defaultToLogin} />
              </div>
            </div>
            <button onClick={() => navigate('/')} className="block mx-auto mt-4 text-center text-primary font-bold text-sm hover:underline">Back To Home</button>
          </motion.div>
        )}

        {currentStep === 1 && (
          <motion.div
            key="pricing-cards"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-7xl mx-auto"
          >
            <PricingCards onPackageSelect={handlePackageSelection} />
          </motion.div>
        )}

        {currentStep === 2 && (
          <motion.div
            key="checkout"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-4xl mx-auto"
          >
            <CheckoutForm onCheckoutComplete={handleCheckoutComplete} />
            <button onClick={() => setCurrentStep(1)} className="block mx-auto mt-4 text-center text-primary font-bold text-sm hover:underline">Back To Packages</button>
          </motion.div>
        )}

        {currentStep === 3 && (
          <motion.div
            key="return"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-4xl mx-auto"
          >
            <Return />
            <button onClick={() => setCurrentStep(1)} className="block pulse mx-auto mt-6 text-center border-2 border-green-500 text-white px-6 py-2 hover:bg-green-500 rounded-full font-bold">Go To Your Dashboard 🎉</button>
          </motion.div>
        )}
      </AnimatePresence>
      <Footer />
    </div>
  );
};

export default LoginPurchase;