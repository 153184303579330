import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../../features/user/userSlice';
import { fetchUserStats } from '../../../features/quiz/userStatsSlice';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faCheckCircle, faTimesCircle, faPen, faChartColumn, faSpinner, faChartBar } from '@fortawesome/free-solid-svg-icons';

const DashboardSummary = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { data: userStats, loading, error } = useSelector((state) => state.userStats);

  useEffect(() => {
    if (user && user?._id && !error) {
      dispatch(fetchUserStats(user?._id));
    }
  }, [dispatch, user?._id, error]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-blue-500" />
      </div>
    );
  }

  if (!userStats || error) {
    return (
      <div className="text-center text-gray-600">
        <FontAwesomeIcon icon={faChartBar} className="text-4xl mb-4 text-blue-500" />
        <p className="mb-4">No data available yet.</p>
        <p>Take some quizzes to start generating statistics.</p>
      </div>
    );
  }

  const statItems = [
    { icon: faChartLine, label: 'Average Score', value: `${userStats.averageScore.toFixed(2)}%` },
    { icon: faCheckCircle, label: 'Correct Answers', value: userStats.correctAnswers },
    { icon: faTimesCircle, label: 'Incorrect Answers', value: userStats.incorrectAnswers },
    { icon: faPen, label: 'Quizzes Taken', value: userStats.totalQuizzes },
  ];

  return (
    <div>
      <div className="grid grid-cols-2 gap-4 mb-6">
        {statItems.map((item, index) => (
          <div key={index} className="bg-gray-100 p-4 rounded-lg">
            <FontAwesomeIcon icon={item.icon} className="text-blue-500 text-2xl mb-2" />
            <p className="text-sm text-gray-600">{item.label}</p>
            <p className="text-lg font-semibold">{item.value}</p>
          </div>
        ))}
      </div>
      <Link
        to="/performance-analytics"
        className="w-full text-inter font-bold text-sm py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 flex items-center justify-center"
      >
        <FontAwesomeIcon icon={faChartColumn} className="mr-2" />
        View Detailed Analytics
      </Link>
    </div>
  );
};

export default DashboardSummary;