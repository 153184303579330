import { createSlice } from '@reduxjs/toolkit';

export const currentProfileSlice = createSlice({
  name: 'currentProfile',
  initialState: {
    value: null, // Initial state of the current tool is null
  },
  reducers: {
    // Action to update the current tool
    setCurrentProfile: (state, action) => {
      state.value = action.payload;
    },
    // Action to clear the current tool (optional)
    clearCurrentProfile: (state) => {
      state.value = null;
    },
  },
});

// Export the actions
export const { setCurrentProfile, clearCurrentProfile } = currentProfileSlice.actions;

// Export the selector to access the current tool state
export const selectCurrentProfile = (state) => state.currentProfile.value;

// Export the reducer
export default currentProfileSlice.reducer;
