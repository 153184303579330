import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentTool,
  selectCurrentTool,
} from "../features/currentTool/currentToolSlice";
import { close } from "../features/mobile/menuSlice";
import { setThreadId } from "../features/threads/threadSlice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faCommentDots,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

const DashboardThreadHistoryCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const threads = useSelector((state) => state.threadHistory.threads);
  const tools = useSelector((state) =>
    [
      ...(state.tools.items.tools ?? []),
      ...(state.tools.items.assistants ?? []),
    ].sort((a, b) => a.name.localeCompare(b.name))
  );

  const truncateContent = (content, maxLength = 60) => {
    if (!content) return "No content available.";
    return content.length > maxLength
      ? content.slice(0, maxLength) + "..."
      : content;
  };

  const handleThreadClick = (thread) => {
    console.log("Thread clicked", thread);
    dispatch(setThreadId(thread.oai_threadId));
    dispatch(close());
    dispatch(
      setCurrentTool(
        tools.find(
          (tool) => tool.oai_assistantId === thread.oai_assistantId
        )
      )
    );
    navigate("/chat");
  };

  return (
    <div className="max-h-96 flex flex-col">
      {/*<h2 className="text-xl font-bold mb-4 flex items-center">
        <FontAwesomeIcon icon={faCommentDots} className="mr-2 text-blue-500" />
        Recent Threads
      </h2>*/}
      {threads?.length > 0 ? (
        <div className="overflow-y-auto flex-grow">
          {threads.slice(0, 5).map((thread) => (
            <div
              key={thread._id}
              className="mb-2 p-3 bg-white rounded-lg shadow hover:shadow-md transition-shadow duration-200 cursor-pointer"
              onClick={() => handleThreadClick(thread)}
            >
              <div className="flex items-start mb-1">
                <FontAwesomeIcon icon={faMessage} className="mr-2 mt-1 text-gray-500" />
                <p className="text-sm text-gray-700">{truncateContent(thread.messages[0].content)}</p>
              </div>
              <div className="flex items-center justify-end text-xs text-gray-500">
                <FontAwesomeIcon icon={faClock} className="mr-1" />
                {new Date(thread.lastUpdated).toLocaleString("en-US", { 
                  month: 'short', 
                  day: 'numeric', 
                  hour: '2-digit', 
                  minute: '2-digit' 
                })}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex-grow flex flex-col items-center justify-center text-gray-500 text-center">
          <FontAwesomeIcon icon={faCommentDots} className="text-4xl text-blue-500 mb-2" />
          <p>No recent threads found.</p>
          <p className="text-sm">Visit the Chat Space to start a new conversation.</p>
        </div>
      )}
    </div>
  );
};

export default DashboardThreadHistoryCard;