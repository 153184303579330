import axios from 'axios';
import config from '../../config/config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const apiURL = process.env.REACT_APP_API_URL;
const path = config.path;

// Async thunk for fetching packages data
export const fetchPackages = createAsyncThunk('packages/fetchPackages', async () => {
    const response = await axios.get(`${apiURL}/fetch/Package`);
    console.log('packages response: ', response.data.data);
    const sortedData = response.data.data.sort((a, b) => a.usd_cost - b.usd_cost);
    return sortedData;
  });

const packageSlice = createSlice({
  name: 'packages',
  initialState: {
    items: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchPackages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default packageSlice.reducer;

export const selectPackages = (state) => state.packages.items;
