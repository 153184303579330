import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsComponentModalOpen, closeComponentModal } from '../features/modals/modalcomponentSlice';
import ProfileManagerComponent from './ProfileManagerComponent';

const ModalComponent = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(selectIsComponentModalOpen);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
            <div className="w-full bg-body max-w-5xl p-6 rounded-lg relative">
                <button
                    onClick={() => dispatch(closeComponentModal())}
                    className="absolute top-2 right-2 text-3xl hover:text-red-400"
                >
                    &times;
                </button>
                <ProfileManagerComponent />
                <div className="mt-4 flex justify-end">
                    <button
                        onClick={() => dispatch(closeComponentModal())}
                        className="px-4 py-2 bg-primary hover:text-white rounded hover:bg-gray-700"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalComponent;
