import axios from 'axios';
import config from '../config/config';

const apiURL = process.env.REACT_APP_API_URL;
const app_id = config.app_id;

const api = axios.create({
  baseURL: apiURL
});

export const getBlogPosts = async () => {
    try {
      const response = await api.get(`/blogposts/${app_id}`);
      return response.data; // Return the actual data instead of the whole response
    } catch (error) {
      console.error('Error fetching blog posts:', error);
      throw error;
    }
  };
  
  export const getBlogPostById = async (id) => {
    try {
      const response = await api.get(`/blogposts/${app_id}/${id}`);
      return response.data; // Return the actual data instead of the whole response
    } catch (error) {
      console.error(`Error fetching blog post with id ${id}:`, error);
      throw error;
    }
  };

  export const getBlogPostBySlug = async (slug) => {
    try {
      const response = await api.get(`/blogposts/slug/${app_id}/${slug}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching blog post with slug ${slug}:`, error);
      throw error;
    }
  };