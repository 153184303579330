import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faThumbTack, faBolt } from '@fortawesome/free-solid-svg-icons';

const FlashcardProgressTracker = () => {
  const flashcards = useSelector(state => state.flashcards.items);
  const userStats = useSelector(state => state.userStats);
  const [chartData, setChartData] = useState([]);

  const calculateChartData = useCallback(() => {
    const masteryLevels = flashcards.reduce((acc, card) => {
      acc[card.mastery_level] = (acc[card.mastery_level] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(masteryLevels).map(([level, count]) => ({
      name: `Level ${level}`,
      value: count
    }));
  }, [flashcards]);

  useEffect(() => {
    const newChartData = calculateChartData();
    setChartData(newChartData);
  }, [calculateChartData]);

  const COLORS = ['#FF6B6B', '#e8bd12', '#6BCB77', '#4D96FF', '#9B89B3', '#2C3639'];

  return (
    <div className="bg-white rounded-lg shadow-md p-6 text-inter">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        <FontAwesomeIcon icon={faChartPie} className="mr-2 text-blue-500" />
        Flashcard Mastery Progress
      </h2>
      <div className='font-bold' style={{ height: '300px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="flex justify-between items-center mt-6">
        <div className="text-gray-600">
          <FontAwesomeIcon icon={faThumbTack} className="mr-2 text-blue-500" />
          {flashcards.length} Current Flashcards
        </div>
        <div className="text-gray-600">
          <FontAwesomeIcon icon={faBolt} className="mr-2 text-yellow-500" />
          XP: {userStats.xp}
        </div>
      </div>
    </div>
  );
};

export default FlashcardProgressTracker;