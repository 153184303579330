// src/redux/jsonModalSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  jsonData: {},
  apiEndpoint: '',
  type: '',
};

const jsonModalSlice = createSlice({
  name: 'jsonModal',
  initialState,
  reducers: {
    openModal(state, action) {
      state.isOpen = true;
      state.jsonData = action.payload.jsonData;
      state.apiEndpoint = action.payload.apiEndpoint;
    state.type = action.payload.type;
    },
    closeModal(state) {
      state.isOpen = false;
      state.jsonData = {};
      state.apiEndpoint = '';
      state.type = '';
    },
  },
});

export const { openModal, closeModal } = jsonModalSlice.actions;

export default jsonModalSlice.reducer;
