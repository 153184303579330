import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/user/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle, faClock, faRocket, faMedal } from '@fortawesome/free-solid-svg-icons';
import PricingCards from './Homepage/components/pricingCards';
import config from '../config/config';

function RenewPage() {
    const user = useSelector(selectUser);
    const userSubscriptionEndDate = new Date(user?.subscription_end_date);
    const formattedEndDate = userSubscriptionEndDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    const pricingCardsRef = useRef(null);

    const handleScrollToPricingCards = () => {
        pricingCardsRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="w-full min-h-screen bg-gradient-to-b from-red-500 to-white py-10 px-5">
            <div className="max-w-5xl mx-auto text-center">
                <div className="bg-white shadow-xl rounded-lg p-8 mb-10">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="text-yellow-500 text-5xl mb-4" />
                    <h1 className="text-3xl font-bold text-gray-800 mb-4 text-poetsen">Your Subscription Has Expired</h1>
                    <p className="text-xl text-gray-600 mb-6">
                        Your {config.siteName} subscription ended on <span className="font-semibold">{formattedEndDate}</span>.
                    </p>
                    <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6">
                        <p className="text-blue-700 text-poetsen">
                            😫 Don't miss out on your important exam prep! Renew now to continue your journey to success.
                        </p>
                    </div>
                </div>

                <div className="bg-white shadow-xl rounded-lg p-8 mb-10 text-gray-800">
                    <h2 className="text-2xl font-bold text-gray-800 mb-6 text-poetsen">Why Renew Your Subscription?</h2>
                    <div className="grid md:grid-cols-2 gap-6">
                        <div className="flex items-start">
                            <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 text-2xl mr-4 mt-1" />
                            <div>
                                <h3 className="text-lg font-semibold mb-2 text-poetsen">Continuous Learning</h3>
                                <p className="text-gray-600">Stay on top of your exam prep with our always-updated question bank and AI tutor.</p>
                            </div>
                        </div>
                        <div className="flex items-start">
                            <FontAwesomeIcon icon={faClock} className="text-blue-500 text-2xl mr-4 mt-1" />
                            <div>
                                <h3 className="text-lg font-semibold mb-2 text-poetsen">Save Time</h3>
                                <p className="text-gray-600">Our adaptive learning system focuses on your weak areas, optimizing your study time.</p>
                            </div>
                        </div>
                        <div className="flex items-start">
                            <FontAwesomeIcon icon={faRocket} className="text-purple-500 text-2xl mr-4 mt-1" />
                            <div>
                                <h3 className="text-lg font-semibold mb-2 text-poetsen">Boost Confidence</h3>
                                <p className="text-gray-600">Practice with our AI-generated questions to feel fully prepared on exam day.</p>
                            </div>
                        </div>
                        <div className="flex items-start">
                            <FontAwesomeIcon icon={faMedal} className="text-yellow-500 text-2xl mr-4 mt-1" />
                            <div>
                                <h3 className="text-lg font-semibold mb-2 text-poetsen">Pass Guarantee</h3>
                                <p className="text-gray-600">Renew with confidence - our pass guarantee ensures your success.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={pricingCardsRef} className="bg-white shadow-xl rounded-lg p-8 mb-10">
                    <h2 className="text-2xl lg:text-3xl text-gray-800 mb-2 text-poetsen">Choose Your Renewal Plan and Save 10%</h2>
                    <PricingCards isRenewal={true} />
                </div>

                <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg p-8">
                    <h2 className="text-2xl text-poetsen mb-4">Don't Let Your Exam Prep Slip Away!</h2>
                    <p className="text-lg mb-6">
                        Renewing your subscription means continued access to our cutting-edge AI tutor, unlimited practice questions, and comprehensive study materials.
                    </p>
                    <button
                        className="cursor-pointer bg-white text-blue-600 py-3 px-8 rounded-full hover:bg-green-200 transition duration-300"
                        onClick={handleScrollToPricingCards}
                    >
                        Renew Now and Save 10%!
                    </button>
                </div>
            </div>
        </div>
    );
}

export default RenewPage;
