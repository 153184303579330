import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../features/user/userSlice';
import { fetchUserStats } from '../../features/quiz/userStatsSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faQuestion, faCheckCircle, faTimesCircle, faClipboardList, faTrophy } from '@fortawesome/free-solid-svg-icons';

const UserStatistics = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { data: stats, loading, error } = useSelector((state) => state.userStats);

  useEffect(() => {
    if (user && user._id) {
      dispatch(fetchUserStats(user?._id));
    }
  }, [dispatch, user]);

  const StatCard = ({ title, value, icon, color }) => (
    <div className={`bg-white rounded-lg shadow-md p-6 flex items-center ${color}`}>
      <div className="mr-4">
        <FontAwesomeIcon icon={icon} className="text-3xl" />
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-1">{title}</h3>
        <p className="text-2xl font-bold">{value}</p>
      </div>
    </div>
  );

  const PercentageBar = ({ percentage }) => (
    <div className="relative pt-1">
      <div className="flex mb-2 items-center justify-between">
        <div>
          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
            All Quizzes
          </span>
        </div>
        <div className="text-right">
          <span className="text-xs font-semibold inline-block text-blue-600">
            {percentage.toFixed(2)}%
          </span>
        </div>
      </div>
      <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
        <div style={{ width: `${percentage}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="bg-gray-100 rounded-xl shadow-2xl p-8 max-w-4xl mx-auto mt-8">
        <h2 className="text-3xl font-bold mb-8 text-gray-400 text-inter">
          <FontAwesomeIcon icon={faChartSimple} className="mr-2" />
          Your Overall Quiz Statistics
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="bg-white rounded-lg shadow-md p-6 h-32"></div>
          ))}
        </div>
      </div>
    );
  }

  if (!stats || error) {
    return (
      <div className="bg-gray-100 rounded-xl shadow-2xl p-8 max-w-4xl mx-auto mt-8">
        <h2 className="text-3xl font-bold mb-8 text-blue-600 text-inter">
          <FontAwesomeIcon icon={faChartSimple} className="mr-2" />
          Your Overall Quiz Statistics
        </h2>
        <p className="text-lg text-gray-600">
          No quiz statistics available. Take some quizzes to start generating statistics.
        </p>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 text-gray-800 rounded-xl shadow-2xl p-8 max-w-4xl mx-auto mt-8">
      <h2 className="text-3xl font-bold mb-8 text-blue-600 text-inter">
        <FontAwesomeIcon icon={faChartSimple} className="mr-2" />
        Your Overall Quiz Statistics
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-inter">
        <StatCard title="Total Questions" value={stats.totalQuestions} icon={faQuestion} color="text-blue-600" />
        <StatCard title="Correct Answers" value={stats.correctAnswers} icon={faCheckCircle} color="text-green-600" />
        <StatCard title="Incorrect Answers" value={stats.incorrectAnswers} icon={faTimesCircle} color="text-red-600" />
        <StatCard title="Total Quizzes" value={stats.totalQuizzes} icon={faClipboardList} color="text-purple-600" />
        <div className="bg-white rounded-lg shadow-md p-6 col-span-1 md:col-span-2 lg:col-span-3">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold">Average Score</h3>
            <FontAwesomeIcon icon={faTrophy} className="text-3xl text-yellow-500" />
          </div>
          <PercentageBar percentage={stats.averageScore} />
        </div>
      </div>
    </div>
  );
};

export default UserStatistics;