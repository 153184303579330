import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    currentPrompt: '',
    response: null,
    loading: false,
    error: null,
  },
  reducers: {
    setCurrentPrompt: (state, action) => {
      state.currentPrompt = action.payload;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setCurrentPrompt, setResponse, setLoading, setError } = chatSlice.actions;

export default chatSlice.reducer;