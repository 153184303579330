// src/components/PackageModal.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal, selectIsOpen } from '../features/packages/modalPackagesSlice';
import { selectPackages } from '../features/packages/packageSlice';
import PurchaseCreditsComponent from './PurchaseCreditsComponent';
import PurchaseCreditsComponentMobile from './PurchaseCreditsComponentMobile';

const PackageModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsOpen);
  const packages = useSelector(selectPackages);

  return (
    <div className={`fixed inset-0 bg-gray-600 bg-opacity-70 overflow-y-auto h-full w-full ${!isOpen && 'hidden'}`}>
      <div className="relative top-0 lg:top-5 md:top-20 mx-auto p-5 border w-full md:w-1/3 shadow-lg rounded-md bg-yellow-400">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">AI Credits Packages</h3>
          <div className='mt-2'>
            <p className="hidden lg:block text-sm text-gray-700">AI credits are required to use and interact with all of the AI tools in this app. You can purchase AI credits as you go to enjoy all of the awesome fun. Credit usage is based on the length of messages both from you to a tool, as well as from a tool back to you.</p>
            <p className="lg:hidden text-sm text-gray-700">AI credits are required to use and interact with all of the AI tools in this app. You can purchase AI credits as you go to enjoy all of the awesome fun. <span className='font-bold'>You can purchase with crypto by using this app on a laptop or desktop computer.</span></p>
          </div>
          <div className="mt-2">
            {packages.map(packageItem => (
              <div key={packageItem._id} className="flex flex-row items-center p-2 border rounded m-2 bg-white hover:bg-gray-200">
                <div className='w-1/6 hidden md:block md:pl-3'>
                    <img
                    src="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AndyBaseAvatar.png"
                    alt="Avatar"
                    className={`w-16 h-12`}
                    /> 
                </div>
                <div className='w-1/2'>
                    <p className="text-lg text-gray-500 leading-none">{packageItem.name}</p>
                </div>
                {/*<div className='hidden lg:block w-1/2 md:w-1/3'>
                    <PurchaseCreditsComponent packageItem={packageItem} />
                </div>
                <div className='lg:hidden w-1/2 md:w-1/3'>
                    <PurchaseCreditsComponentMobile packageItem={packageItem} />
            </div>*/}
                
                
              </div>
            ))}
          </div>
          <div className="items-center px-4 py-3">
            <button onClick={() => dispatch(closeModal())} className="px-4 py-2 bg-blue-500 border text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageModal;
