import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { selectUser } from '../features/user/userSlice';
import { setThreadId, selectCurrentThreadId, updateThreadId, deleteThreadId } from '../features/threads/threadSlice';
import styled from 'styled-components';
import CurrentToolCard from './CurrentToolCard';
import SuggestedPrompts from './SuggestedPrompts';
import { openModal } from '../features/userPrompts/userPromptsSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ProfileManager from './ProfileManager';


const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%; /* Full viewport height */
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow-y: auto; /* Enables vertical scrolling when necessary */
  padding: 20px;
`;

const ChatSidebar = () => {
    const dispatch = useDispatch();
    const currentTool = useSelector(selectCurrentTool);
    const user = useSelector(selectUser);
    const { data, status, error } = useSelector((state) => state.BYOKUserAPIKeys);
    //const serviceStatus = useSelector(state => state.services.data);
    const currentThreadId = useSelector((state) => state.thread.currentThreadId);
    let checkboxOptions = [];
    if (currentTool.type === 'image') {
      checkboxOptions = [
        { id: 'leonardo', label: 'Leonardo AI', image_url: 'https://byok.nyc3.digitaloceanspaces.com/images/leonardoLogo.jpg' },
        { id: 'openai', label: 'Open AI (DALLE)', image_url: 'https://byok.nyc3.digitaloceanspaces.com/images/openAILogo.jpg' },
        { id: 'stablediffusion', label: 'Stable Diffusion', image_url: 'https://byok.nyc3.digitaloceanspaces.com/images/stablediffusionLogo.jpg' }
        // Add more options as needed
      ];
    } else if (currentTool.type === 'character-famous') {
      checkboxOptions = [
        { id: 'openai', label: 'Open AI (ChatGPT)', image_url: 'https://byok.nyc3.digitaloceanspaces.com/images/openAILogo.jpg' }
        // Add more options as needed
      ];
    } else {
      checkboxOptions = [
        { id: 'openai', label: 'Open AI (ChatGPT)', image_url: 'https://byok.nyc3.digitaloceanspaces.com/images/openAILogo.jpg' },
        { id: 'perplexity', label: 'Perplexity AI', image_url: 'https://byok.nyc3.digitaloceanspaces.com/images/perplexityLogo.jpg' }
        // Add more options as needed
      ];
    }
    
    const itemsArray = Object.entries(data);
    //const serviceStatusArray = Object.entries(serviceStatus);
      /*const updatedCheckboxOptions = checkboxOptions.map(option => {
        const isAvailable = itemsArray.find(item => item[0] === option.id && item[1] === true);
        //const isChecked = serviceStatusArray.find(item => item[0] === option.id);
        return isAvailable ? { ...option, isChecked: isChecked[1], isAvailable: isAvailable[1] } : null;
      })
      .filter(option => option !== null);*/

  return (
    <SidebarContainer>
      <div className='text-center text-lg mb-4'>CURRENT TOOL:</div>
      <CurrentToolCard />
      <div className='text-center text-lg mb-1 mt-1 flex flex-row gap-2'>
        {currentThreadId && (
            <button
            className="w-2/4 px-4 py-2 border border-primary mt-2 rounded hover:bg-gray-200 hover:text-black transition-colors"
            onClick={() => dispatch(setThreadId(null))}
          >
            <FontAwesomeIcon icon={faPlusCircle} className='mr-2' /> New Chat
          </button>
        )}
        
        <button
            className={`${
              currentThreadId === null ? "w-full" : "w-2/4"
            } px-4 py-2 border border-primary mt-2 rounded hover:bg-gray-200 hover:text-black transition-colors`}
            onClick={() => dispatch(openModal(currentTool.type))}
          >
          <FontAwesomeIcon icon={faClipboardList} className='mr-2' /> Open Saved Prompts
        </button>
      </div>
      <SuggestedPrompts />
      <ProfileManager user={user} />
    </SidebarContainer>
  );
};

export default ChatSidebar;
