import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const FileUpload = ({ uploadUrl, onSuccess }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      onSuccess(response.data.url);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div className='flex flex-col gap-2 my-3'>
      <input type="file" onChange={handleFileChange} />
      <button disabled={!file} className='flex flex-row text-center p-2 bg-primary rounded w-full justify-center items-center disabled:bg-gray-400 disabled:text-gray-500' onClick={handleUpload}><FontAwesomeIcon icon={faUpload} className='mr-2' />Upload</button>
    </div>
  );
};

export default FileUpload;
