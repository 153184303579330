import React from 'react';
import config from '../../../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const TestimonialSectionHome = () => {
    return (<>
    <div className="text-inter font-bold text-center text-4xl font-bold flex flex-col lg:flex-row justify-center items-center">
                Don't just take it from us...
                <div>
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-yellow-400 ml-2"
                  />
                  <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
                  <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
                  <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
                  <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
                </div>
              </div>

              <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-4 max-w-7xl mx-auto  border border-2 border-primary rounded-xl shadow-md py-2 px-4">
                <div className=" max-w-2xl   px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
                  <div className="text-inter font-bold">
                    <span className="text-primary text-5xl text-inter font-bold">
                      "
                    </span>
                    {config.landing_page_testimonial_1}
                    <p className="text-primary text-5xl text-inter font-bold text-right">
                      "
                    </p>
                    <img
                      className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary"
                      src={config.landing_page_testimonial_1_image}
                    />
                    <p className="mt-4">
                      {config.landing_page_testimonial_1_author}
                    </p>
                    <img
                      className="mb-4 mt-4 w-28 mx-auto"
                      src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png"
                    />
                  </div>
                </div>
                <div className=" max-w-2xl   px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
                  <div className="text-inter font-bold">
                    <span className="text-primary text-5xl text-inter font-bold">
                      "
                    </span>
                    {config.landing_page_testimonial_2}
                    <p className="text-primary text-5xl text-inter font-bold text-right">
                      "
                    </p>
                    <img
                      className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary"
                      src={config.landing_page_testimonial_2_image}
                    />
                    <p className="mt-4">
                      {config.landing_page_testimonial_2_author}
                    </p>
                    <img
                      className="mb-4 mt-4 w-28 mx-auto"
                      src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png"
                    />
                  </div>
                </div>
                <div className=" max-w-2xl px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
                  <div className="text-inter font-bold">
                    <span className="text-primary text-5xl text-inter font-bold">
                      "
                    </span>
                    {config.landing_page_testimonial_3}
                    <p className="text-primary text-5xl text-inter font-bold text-right">
                      "
                    </p>
                    <img
                      className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary"
                      src={config.landing_page_testimonial_3_image}
                    />
                    <p className="mt-4">
                      {config.landing_page_testimonial_3_author}
                    </p>
                    <img
                      className="mb-4 mt-4 w-28 mx-auto"
                      src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png"
                    />
                  </div>
                </div>
              </div>
    </>);
  };

export default TestimonialSectionHome;
