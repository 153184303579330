import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBlogPostBySlug } from '../api/blogApi';
import BlogPost from '../components/blog/BlogPost';
import Header from './Homepage/components/Header';
import Footer from './Homepage/components/Footer';

const Post = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const fetchedPost = await getBlogPostBySlug(slug);
        setPost(fetchedPost);
      } catch (error) {
        console.error('Error setting post:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPost();
  }, [slug]);

  return (
    <div>
      <div className="container mx-auto p-4 min-h-screen">
        <Header />
        {loading ? <p>Loading...</p> : post && <BlogPost post={post} />}
        <Footer />
      </div>
    </div>
  );
};

export default Post;