import { faFacebook, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import config from '../../config/config';

const SocialShare = ({ score, totalQuestions }) => {
  const shareMessage = `I just scored ${score.toFixed(2)}% on my NCLEX practice quiz (${totalQuestions} questions)! Try it out at NCLEX Study Pro!`;
  const shareUrl = config.app_url;

  const shareOnTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareMessage)}&url=${encodeURIComponent(shareUrl)}`;
    window.open(twitterUrl, '_blank');
  };

  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&t=${encodeURIComponent(shareMessage)}`;
    window.open(facebookUrl, '_blank');
  };

  const shareOnLinkedIn = () => {
    const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent('NCLEX Study Pro Quiz Results')}&summary=${encodeURIComponent(shareMessage)}`;
    window.open(linkedInUrl, '_blank');
  };

  return (
    <div className="mt-4">
      <h3 className="text-xl font-bold mb-2">Share your results:</h3>
      <div className="flex space-x-4">
        <button onClick={shareOnTwitter} className="text-blue-400 hover:text-blue-600">
            <FontAwesomeIcon icon={faXTwitter} className='text-xl' />
        </button>
        <button onClick={shareOnFacebook} className="text-blue-600 hover:text-blue-800">
            <FontAwesomeIcon icon={faFacebook} className='text-xl' />
        </button>
        <button onClick={shareOnLinkedIn} className="text-blue-700 hover:text-blue-900">
          <FontAwesomeIcon icon={faLinkedin} className='text-xl' />
        </button>
      </div>
    </div>
  );
};

export default SocialShare;