import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ScoreDistributionHistogram = ({ showInfo }) => {
  const { scoreDistribution, loading, error } = useSelector((state) => state.userStats);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64 bg-gray-900">
        <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-blue-500" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center bg-gray-900 p-4">Error loading data</div>;
  }

  if (!scoreDistribution) {
    return <div className="text-gray-500 text-center bg-gray-900 p-4">No data available</div>;
  }

  const data = {
    labels: Object.keys(scoreDistribution).map(key => `${key}-${parseInt(key) + 9}`),
    datasets: [{
      label: 'Number of Quizzes',
      data: Object.values(scoreDistribution),
      backgroundColor: 'rgba(132, 224, 132, 0.8)',
    }]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { 
        position: 'top',
        labels: { color: 'white' }
      },
      title: { 
        display: true, 
        text: 'Score Distribution',
        color: 'white',
        font: { size: 16, weight: 'bold' }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: { display: true, text: 'Number of Quizzes', color: 'white' },
        ticks: { color: 'white' }
      },
      x: {
        title: { display: true, text: 'Score Range', color: 'white' },
        ticks: { color: 'white' }
      }
    }
  };

  return (
    <div className={`bg-gray-900 lg:p-4 rounded-lg shadow-md border-4 border-primary ${!showInfo ? 'h-52 max-h-52 w-full' : 'h-96 max-h-96 w-full lg:h-96 lg:max-h-96 overflow-auto'} flex flex-col items-center`}>
      <Bar data={data} options={options} />
      {showInfo && <div className="mt-4 text-white pt-2 px-1 leading-none">
        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
        <span className="text-xs">
          This histogram shows the distribution of your quiz scores. It helps you understand 
          your performance patterns and identify which score ranges are most common for you. 
          Aim to shift the distribution towards higher scores as you prepare for your exam. 
          A right-skewed distribution indicates consistent high performance.
        </span>
      </div>}
    </div>
  );
};

export default ScoreDistributionHistogram;