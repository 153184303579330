import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import config from '../../../config/config';

const ComparisonTable = () => {
  return (
    <div className="overflow-x-auto rounded">
      <table className="lg:w-full text-sm lg:text-lg text-left text-gray-500 overflow-auto">
        <thead className="text-gray-100 uppercase bg-primary text-inter font-bold border-b border-gray-900">
          <tr>
            <th scope="col" className="pl-2 lg:px-6 py-3">
              Feature
            </th>
            <th scope="col" className="text-center lg:px-6 py-3">
              {config.siteName}
            </th>
            <th scope="col" className="text-center lg:px-6 py-3">
              Competitors
            </th>
          </tr>
        </thead>
        <tbody>
          {config.comparisonTableRows.map((row, index) => (
            <tr key={index} className="bg-white border-b dark:bg-gray-800">
              <th
                scope="row"
                className="px-1 lg:px-6 py-4 font-bold text-primary whitespace-nowrap"
              >
                {row.feature}
              </th>
              <td className="text-center px-1 lg:px-6 py-4 text-gray-100 bg-primary font-bold">
                <FontAwesomeIcon icon={faCheckSquare} className="mr-2" />
                {row.nclexStudyPro}
              </td>
              <td className="text-center lg:px-6 py-4">{row.competitors}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ComparisonTable;
