// useCachedFetch.js
import { useSelector, useDispatch } from 'react-redux';
import { setCacheData } from '../features/cacheSlice';

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

export function useCachedFetch(key, fetchAction) {
  const dispatch = useDispatch();
  const cachedData = useSelector(state => state.cache[key]);

  const fetchData = async () => {
    const now = Date.now();
    if (cachedData.data && now - cachedData.timestamp < CACHE_DURATION) {
      // Data is still fresh, no need to fetch
      return cachedData.data;
    }

    // Data is stale or doesn't exist, fetch new data
    const data = await dispatch(fetchAction()).unwrap();
    dispatch(setCacheData({ key, data }));
    return data;
  };

  return fetchData;
}