import { 
  faBoltLightning, 
  faHandHoldingDollar, 
  faMedal, 
  faRobot, 
  faStar, 
  faWandMagicSparkles,
  faChartLine,
  faBookOpen,
  faGraduationCap,
  faClipboardCheck
} from "@fortawesome/free-solid-svg-icons";

const config = {
  //App Details
  siteTitle: "NCLEX Study Pro | Pass the NCLEX with AI-Powered Exam Prep!",
  siteName: "NCLEX Study Pro",
  siteTagline: "Pass Your NCLEX. Guaranteed.",
  siteDescription: "NCLEX Study Pro is your ultimate NCLEX study guide, offering AI-powered tools, practice tests, and personalized learning plans to help you ace the NCLEX exam. Achieve success with our comprehensive study resources, tailored to your needs.",
  siteKeywords: "NCLEX Study Pro, NCLEX study guide, NCLEX prep, NCLEX exam preparation, AI-powered NCLEX prep, NCLEX practice tests, personalized NCLEX study plan, ultimate NCLEX guide, NCLEX exam success, NCLEX study resources",
  path: "nclexstudypro",
  logoUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/NCLEX_Study_Pro_blue.png",
  favicon: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/NCLEX_Study_Pro_Favicon_blue.png",
  homeMetaTagImage: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/Nursing_Images/NCLEXHomeFBMetaTagImage.jpg",
  app_id: "6674797b7a3ff16c14f8eaa4",
  app_url: "https://nclexstudypro.com",
  defaultToolId: '6670e1957a3ff16c14f8ea96',
  app_auth_type: 'package_required', // 'package_required' | 'none'
  exam_name: "NCLEX",
  graduate_name: "Nurse",
  graduate_name_adj: "Nursing", //i.e. Nursing Student, Nursing Graduate

  //Styles
  light_primaryColor: "#1E90FF",
  light_secondaryColor: "#0462dd",
  light_background_color: "#F3F4F6",
  light_inverted_background_color: "#1E1E1E",
  light_text_color: "#1E1E1E",
  light_inverted_text_color: "#F3F4F6",
  dark_primaryColor: "#1E90FF",
  dark_secondaryColor: "#0462dd",
  dark_background_color: "#1E1E1E",
  dark_inverted_background_color: "#F3F4F6",
  dark_text_color: "#F3F4F6",
  dark_inverted_text_color: "#1E1E1E",
  successColor: "#04e762",
  informationColor: "#008bf8",
  errorColor: "#dc0073",
  warningColor: "#f5b700",
  whiteColor: "#F3F4F6",

  headerFont: "Inter",
  bodyFont: "Open Sans",

  //Social Media
  twitter: "nclexstudypro",
  facebook: "nclexstudypro",
  instagram: "nclexstudypro",
  pinterest: "aiexampro/nclex-questions-and-study-guides/",
  linkedin: "", // full URL
  youtube: "@NCLEXStudyPro",
  telegram: "",
  discord: "", // full URL
  tiktok: "",
  medium: "",
  website_1: "https://gaimnetwork.com", // full URL
  website_1_title: "GAIM Network",
  website_2: "", // full URL
  website_2_title: "",
  website_3: "https://gaim.ai", // full URL
  website_3_title: "GAIM.AI",
  email: "support@nclexstudypro.com",
  loginTemplateId: "nclex_study_pro_login",
  signupTemplateId: "nclex_study_pro_signup",
  mailerLiteGroupID: '125321754373522629',
  mailerLiteNewsletterGroupID: '125616603776681653',
  mailerLiteAbandonedCartGroupID: '126072575909430371',

  //components
  bookshelf: true,
  blog: true,
  blog_title: "NCLEX Study Blog",
  quizzes: true,
  quiz_page_title: "NCLEX Pro Practice Quiz Space",
  quiz_gen_oaiassistant_id: 'asst_05hS9AaHV5Zlo7upoY64gLAG',
  quiz_chat_oaiassistant_id: 'asst_u3PGGwmiPRQxKNISteHNFHdm',
  quiz_sections: [
    'Basic Care and Comfort',
    'Health Promotion and Maintenance',
    'Management of Care',
    'Pharmacological and Parenteral Therapies',
    'Physiological Adaptation',
    'Psychosocial Integrity',
    'Reduction of Risk Potential',
    'Safety and Infection Control'
  ],
  flashcards: true,
  shopUrl: "", // full URL
  shopUrl_title: "",
  chat_space: true,
  chat_space_title: "Chat Space",
  chat_space_get_started_desktop: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/GetStartedDesktopNCLEXMP4.mp4",
  chat_space_get_started_mobile: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/getStartedMobileNCLEX.gif",
  boards: true,
  boards_title: "Notes",
  boards_title_singular: "Note",
  recent_threads_dash: true,
  recent_threads_dash_title: "Recent Threads",
  add_board_item_dash: true,
  profiles: false,
  profile_dash: false,
  thread_history_chat_space: true,
  thread_history_chat_space_title: "Past Threads",
  menu_options_chat_space: true,
  menu_options_chat_space_title: "Menu Options",

  //landing page
  landing_page_package_type: "One-Time", // "Monthly" | "One-Time"
  landing_page_hero_headline: "Fight NCLEX Failure!",
  landing_page_hero_subheadline: "Unlimited quiz questions, 24/7 AI tutoring,<br>and gamified flashcards.",
  landing_page_hero_button_text: "Pass Your NCLEX. Guaranteed.",
  landing_page_hero_graphic_desktop: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/NCLEX_Desktop_Hero_Vid_200X.mp4",
  landing_page_hero_graphic_mobile: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/NCLEXMobileAITutor.gif",
  landing_page_headline_2: "<span class='text-poetsen'>It's like having <span class='text-primary font-bold uppercase text-poetsen'>the world's smartest tutor</span> in your pocket.</span>",
  landing_page_subheadline_2: "Get personalized guidance, unlimited questions, and more - all powered by 🤖 advanced artificial intelligence.",
  landing_page_headline_3: "Meet your personal AI NCLEX tutor, available 24/7 to answer questions, explain concepts, and guide your study plan.",
  landing_page_headline_3_features: [
    "Instant answers to your NCLEX-related questions",
    "Personalized study plans based on your performance",
    "In-depth explanations of complex topics",
    "Custom quizzes generated on-demand",
    "Progress tracking and performance analysis"
  ],
  landing_page_subheadline_3: "",
  landing_page_headline_4: "Don't let the NCLEX exam stand between you and your dream career. Get the tools you need to pass!",
  landing_page_testimonial_1: "NCLEX Study Pro's AI tutor and unlimited questions helped me pass on my first try! It's like having a personal nurse in my pocket.",
  landing_page_testimonial_1_author: "Alyssa J., Recent BSN Graduate",
  landing_page_testimonial_1_image: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/Nursing_Images/profilepicture1000x1000_5.jpg",
  landing_page_testimonial_2: "The adaptive learning feature focused my studies exactly where I needed it. I felt completely prepared on exam day!",
  landing_page_testimonial_2_author: "Kraig G., RN, Critical Care",
  landing_page_testimonial_2_image: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/Nursing_Images/NCLEX_testimonial_new_2.jpg",
  landing_page_testimonial_3: "I love how the app explains concepts in different ways. The 'Explain Like I'm 5' feature was super helpful for me!",
  landing_page_testimonial_3_author: "Shonna S., Nursing Student",
  landing_page_testimonial_3_image: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/Nursing_Images/NCLEX_testimonial_new_1.jpg",
  landing_page_plans_headline: "Unlimited Exam Prep Without the <span class='text-red-500 font-bold uppercase'>$1000</span> Price Tag",
  landing_page_testimonial_4_image: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXTestimonial4.jpg",
  landing_page_testimonial_5_image: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXTestimonial5.jpg",
  landing_page_testimonial_6_image: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXTestimonial6.jpg",
  landing_page_testimonial_7_image: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXTestimonial7.jpg",

  landing_page_example_1_request: "Ask Me A Random Question",
  landing_page_example_1_response: `
### 📚 NCLEX Study Pro 🩺
Here's a random NCLEX question to test your knowledge:

**Category: Safety and Infection Control**

![Busy Exam Room](https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/busyExamRoom.jpg)

**Question:** The nurse is providing lunch to a 68-year-old male patient with a history of stroke which has affected his swallowing reflex. As the patient begins to eat, he suddenly starts choking on a piece of food but is coughing loudly and forcefully. Observing this, what should the nurse do?

**Options:**
1. Assist the patient to stand up and perform the abdominal thrust maneuver immediately.
2. Lay the patient down and prepare to perform back blows and chest thrusts.
3. Exit the room quickly to summon for additional help from the healthcare team.
4. Stay with the patient, encourage him to keep coughing, and monitor him closely.
5. Provide a drink of water to help the patient swallow the obstructing food.
`,
  landing_page_example_1_next_action_options: [
  "Option 1",
  "Option 2",
  "Option 3",
  "Option 4",
  "Option 5",
],
  landing_page_example_2_request: "Ask Me A Random Question",
  landing_page_example_2_response: `
### 📚 NCLEX Study Pro 🩺

Here's a random NCLEX question to test your knowledge!

**Category: Reduction of Risk Potential**

**Question:**  
After insertion of a chest tube for a pneumothorax, a client becomes hypotensive with neck vein distention, tracheal shift, absent breath sounds, and diaphoresis. Nurse Amanda suspects a tension pneumothorax has occurred. What cause of tension pneumothorax should the nurse check for?

A. Infection of the lung  
B. Kinked or obstructed chest tube  
C. Excessive water in the water-seal chamber  
D. Excessive chest tube drainage  

![Nurse reading medical chart in the hallway.](https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/nurseReadingMedicalChartInHallways.jpg)

Choose your answer from the options below.
`,
landing_page_example_2_next_action_options: [
  "A. Infection of the lung",
  "B. Kinked or obstructed chest tube",
  "C. Excessive water in the water-seal chamber",
  "D. Excessive chest tube drainage"
],
keyFeatures: [
  {
    title: "Unlimited AI-Generated Questions",
    icon: faRobot,
    description: "Never run out of fresh, relevant practice questions tailored to your needs"
  },
  {
    title: "Personal AI NCLEX Tutor",
    icon: faGraduationCap,
    description: "24/7 access to an AI tutor for instant answers and explanations"
  },
  {
    title: "Adaptive Learning",
    icon: faChartLine,
    description: "Our system adapts to your progress, focusing on areas that need improvement"
  },
  {
    title: "Gamified Flashcards",
    icon: faBookOpen,
    description: "Automatically generated flashcards from your quiz results, making learning fun"
  },
  {
    title: "Comprehensive Analytics",
    icon: faChartLine,
    description: "In-depth performance tracking to optimize your study strategy"
  },
  {
    title: "Multi-Format Explanations",
    icon: faClipboardCheck,
    description: "Get explanations tailored to your learning style - ELI5, stories, mnemonics, and more"
  }
],
comparisonTableRows: [
  {
    feature: "Practice Questions",
    nclexStudyPro: "Unlimited AI-generated questions",
    competitors: "Limited question banks (2,000-6,000 questions)"
  },
  {
    feature: "Personal Tutor",
    nclexStudyPro: "24/7 AI tutor with instant responses",
    competitors: "Limited or no personalized tutoring"
  },
  {
    feature: "Adaptive Learning",
    nclexStudyPro: "Continuously adapts to your performance",
    competitors: "Basic or limited adaptive features"
  },
  {
    feature: "Explanations",
    nclexStudyPro: "Multi-format explanations (ELI5, stories, mnemonics)",
    competitors: "Standard text explanations"
  },
  {
    feature: "Flashcards",
    nclexStudyPro: "Auto-generated + custom flashcards with gamification",
    competitors: "Basic flashcards or none"
  },
  {
    feature: "Analytics",
    nclexStudyPro: "Comprehensive performance tracking and insights",
    competitors: "Basic progress tracking"
  },
  {
    feature: "Study Plan",
    nclexStudyPro: "AI-generated personalized study plans",
    competitors: "Generic study plans"
  },
  {
    feature: "Content Updates",
    nclexStudyPro: "Real-time updates with AI-generated content",
    competitors: "Periodic manual updates"
  }
],
landing_page_demo_videos: [
  {
    id: 1,
    title: "Quiz Space",
    description: "Unlimited NCLEX Practice Questions",
    thumbnailUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotQuizSpaceRaw1.jpg",
    url: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/videos/QuizSpaceDemo_7-21-2024.mp4",
    duration: "1:14",
    featured: true,
  },
  {
    id: 2,
    title: "Chat Space",
    description: "Your 24/7 AI Study Buddy",
    thumbnailUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotChatSpaceRaw1.jpg",
    url: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/videos/ChatDemo_7-23-2024.mp4",
    duration: "1:18",
  },
  {
    id: 3,
    title: "Gamified Flashcards",
    description: "Make Studying Fun Again",
    thumbnailUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotFlashcardsRaw1.jpg",
    url: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/videos/FlashcardsDemo_7-22-2024.mp4",
    duration: "0:52",
    featured: true,
  },
  {
    id: 4,
    title: "Quiz Performance Analytics",
    description: "Turn Your Weaknesses into Strengths",
    thumbnailUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotAnalyticsRaw1.jpg",
    url: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/videos/AnalyticsDemo_7-23-2024.mp4",
    duration: "0:28",
    new: true,
  },
  {
    id: 5,
    title: "User Dashboard",
    description: "All Your Important Stuff in One Place",
    thumbnailUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotDashboardRaw1.jpg",
    url: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/videos/DashboardDemo_7-23-2024.mp4",
    duration: "0:18",
  },
],
landing_page_screenshots: [
  {
    "url": "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotChatSpace2.jpg",
    "title": "24/7 AI Tutor",
    "description": "Unlock the power of personalized learning with our 24/7 AI Tutor. Get instant, accurate answers and explanations to all your NCLEX questions any time of day. It's like having a personal tutor in your pocket, ready to help you conquer your study goals whenever you need it."
  },
  {
    "url": "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotQuizSpace1.jpg",
    "title": "Unlimited Quizzes",
    "description": "Never run out of practice! With unlimited quizzes, you can test your knowledge as much as you want. Our ever-expanding question bank ensures you always have fresh, relevant material to keep you sharp and exam-ready."
  },
  {
    "url": "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotQuizResults2.jpg",
    "title": "Instant Quiz Feedback",
    "description": "Don't wait for results. Get instant feedback on your quizzes so you know exactly where you stand. Our real-time scoring helps you identify strengths and weaknesses immediately, allowing you to adjust your study plan on the fly."
  },
  {
    "url": "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotQuizReview1.jpg",
    "title": "Detailed Quiz Reviews",
    "description": "Turn mistakes into learning opportunities with detailed quiz reviews. Our comprehensive analysis highlights your errors and provides actionable insights to help you understand and correct them, making sure you're always improving."
  },
  {
    "url": "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotExplanation1.jpg",
    "title": "Multiple AI-Powered Explanations",
    "description": "Benefit from multiple perspectives with our AI-powered explanations. Choose from \"Explain Like I'm 5\" for simple breakdowns, \"Explain with a Story\" for memorable narratives, or \"Common Misconceptions\" to clear up any confusion. Tailor your learning experience to what works best for you."
  },
  {
    "url": "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotFlashcards1.jpg",
    "title": "Gamified Flashcards",
    "description": "Studying has never been this fun! Our gamified flashcards are automatically populated with your incorrect quiz questions, turning your weaknesses into strengths. Compete with yourself, track your progress, and enjoy a more engaging way to review key concepts."
  },
  {
    "url": "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotAnalytics1.jpg",
    "title": "Advanced Quiz Analytics",
    "description": "Take control of your study strategy with advanced quiz analytics. Dive deep into graphs and charts that visualize your performance trends, pinpoint areas for improvement, and help you make data-driven decisions about where to focus your efforts."
  },
  {
    "url": "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotNotes1.jpg",
    "title": "Savable Notes",
    "description": "Keep all your important study materials in one place with savable notes. Just like simplified Pinterest boards, you can save chat responses, incorrect answers, explanations, and more. Organize your study aids effortlessly and access them whenever you need."
  },
  {
    "url": "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXScreenshotDashboard1.jpg",
    "title": "Personalized Dashboard",
    "description": "Navigate your NCLEX prep with ease using our personalized dashboard. Quickly access different app features and get snapshots of your current quiz performance. Everything you need to stay on top of your studies is just a click away."
  }
],
exitIntentPopupTitle: "🚨 Take this with you to help!",
exitIntentPopupDescription: "Get instant access to a free downloadable and printable PDF with over 200 NCLEX questions and answers to skyrocket your exam success.",
leadMagnetHeader: "Pass the NCLEX on Your First Try!",
leadMagnetHeroSubheader: "⚠️ LESS THAN HALF of repeat test takers pass the NCLEX exam!",
leadMagnetSubheader: "🚨 Fight Failure with FREE PREP",
leadMagnetName: "202 FREE NCLEX Flashcards",
leadMagnetDescription: "Get instant access to a free downloadable and printable PDF with over 200 NCLEX questions and answers to skyrocket your exam success.",
leadMagnetImage: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/lead_magnets/202%20NCLEX%20Flashcards%20(1920%20x%201080%20px).png",
leadMagnetSideImage: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/NCLEXStudentCelebrating.jpg",
leadMagnetBottomHeadline: "Get HIRED at top hospitals nationwide!",
leadMagnetId: "668201ac31a65951e0e371e6",
bottomLeadMagnetSideImage: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/nurseElderlyPatientInLobby.jpg",
leadMagnetQuizId: "6691b535d156bf7e0e928730",
leadMagnetQuizHeaderImage: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/Nursing_Images/smilingNurse500x500.jpg",
leadMagnetQuizImage2: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/Nursing_Images/smilingNurse2-500x500.jpg",
leadMagnetQuizMetaTagImage: "https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/Nursing_Images/NCLEXReadinessQuizFBMetaTagImage.jpg",
faq_custom_title_1: "Is NCLEX Study Pro for the NCLEX-RN or the NCLEX-PN?",
faq_custom_content_1: "NCLEX Study Pro is based on the NCLEX-RN Exam, but is designed to help both RN and PN students prepare for the NCLEX exam. Our AI-powered platform offers personalized study plans, unlimited practice questions, and a personal tutor to help you succeed.",
faq_custom_title_2: "Will the AI tutor really help me learn and understand the material?",
faq_custom_content_2: "Yes! Our AI tutor is designed to provide instant answers to your questions, explain concepts in multiple ways, and offer personalized study plans based on your performance. It's like having a personal NCLEX expert available 24/7 to guide your study plan and help you succeed.",
faq_custom_title_3: null,
faq_custom_content_3: null,
faq_custom_title_4: null,
faq_custom_content_4: null,

// Lead Magnet Squeeze Landing Page
leadMagnetLandingPageImage1: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/Nursing_Images/NCLEXNursePhotos3.jpg',
leadMagnetLandingPageImage2: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/Nursing_Images/NCLEXNursePhotos6.jpg',
leadMagnetLandingPageImage3: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/Nursing_Images/NCLEXNursePhotos2.jpg',
leadMagnetLandingPageImage4: 'https://otp.nyc3.cdn.digitaloceanspaces.com/NCLEX/images/Nursing_Images/NCLEXNursePhotos8.jpg',


updatePoetsenClass: (font) => {
  const style = document.createElement('style');
  style.innerHTML = `
    .text-poetsen {
      font-family: ${font};
      letter-spacing: -0.04em;
      font-weight: bold;
    }
  `;
  document.head.appendChild(style);
  },

updateBodyFont: (font) => {
const style = document.createElement('style');
style.innerHTML = `
  body {
    font-family: ${font};
  }
`;
document.head.appendChild(style);
}

};

export default config;