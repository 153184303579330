import React, { useState, useMemo } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faPlus, faTimes, faSearch, faFilter } from "@fortawesome/free-solid-svg-icons";

const FlashcardPackModal = ({ isOpen, onClose, flashcardPacks, onAddPack }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const categories = useMemo(() => {
    const uniqueCategories = new Set(flashcardPacks.map(pack => pack.category));
    return ['All', ...Array.from(uniqueCategories)].sort();
  }, [flashcardPacks]);

  const filteredPacks = useMemo(() => {
    return flashcardPacks.filter(pack => {
      const matchesSearch = pack.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            pack.category.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory = selectedCategory === 'All' || selectedCategory === '' || pack.category === selectedCategory;
      return matchesSearch && matchesCategory;
    });
  }, [flashcardPacks, searchQuery, selectedCategory]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 text-inter text-gray-800">
      <div className="bg-white rounded-lg p-8 max-w-2xl w-full max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-2xl font-bold text-primary"><FontAwesomeIcon icon={faBookOpen} className='mr-2' />Add Flashcard Packs</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
        </div>
        <p className="text-gray-600 mb-4 text-xs md:text-base">Select a pre-made flashcard pack to add to your stack.</p>
        
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <div className="relative flex-grow">
            <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search packs..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div className="relative">
            <FontAwesomeIcon icon={faFilter} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="w-full md:w-48 pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary appearance-none"
            >
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="space-y-4">
          {filteredPacks.map((pack) => (
            <div key={pack._id} className="border p-4 rounded-lg shadow-xl flex flex-col md:flex-row gap-2 md:gap-0 md:justify-between md:items-center">
              <div>
                <h3 className="text-lg font-semibold">{pack.name}</h3>
                <p className="text-gray-600">Category: {pack.category}</p>
                <div className="text-sm text-gray-500 border border-primary px-4 py-1 mt-2 rounded-full w-fit">{pack.flashcards.length} cards</div>
              </div>
              <button
                onClick={() => onAddPack(pack._id)}
                className="bg-primary hover:bg-green-500 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add Pack
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FlashcardPackModal;