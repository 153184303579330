import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFlashcard, deleteFlashcard } from '../../features/flashcards/flashcardsSlice';
import { updateXP, fetchUserStats } from '../../features/quiz/userStatsSlice';
import { selectUser } from '../../features/user/userSlice';
import { motion } from 'framer-motion';
import { playFlipSound, playMasterySound } from '../../utils/soundEffects';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { addToast } from '../../features/ui/uiSlice';

const FlashcardItem = ({ flashcard }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [mastery, setMastery] = useState(flashcard.mastery_level);
  const [hasFlippedThisSession, setHasFlippedThisSession] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const cardRef = useRef(null);
  const user = useSelector(selectUser);
  const userStats = useSelector(state => state.userStats);
  const userId = user ? user._id : null;

  const MAX_MASTERY_LEVEL = 5;

  useEffect(() => {
    setMastery(flashcard.mastery_level);
    setHasFlippedThisSession(false);
  }, [flashcard]);

  useEffect(() => {
    if (isFlipped) {
        setIsFlipped(false);
    }
  }, [flashcard._id]);

  const handleFlip = (e) => {
    // Prevent flipping when clicking on mastery buttons or delete button
    if (e.target.closest('.mastery-buttons') || e.target.closest('.delete-button')) {
      return;
    }
    setIsFlipped(!isFlipped);
    playFlipSound();

    if (!isFlipped && !hasFlippedThisSession && mastery < MAX_MASTERY_LEVEL) {
      const newMasteryLevel = Math.min(mastery + 1, MAX_MASTERY_LEVEL);
      handleMasteryUpdate(newMasteryLevel);
      setHasFlippedThisSession(true);
    }
  };

  const handleMasteryUpdate = (newLevel) => {
    if (newLevel <= MAX_MASTERY_LEVEL && newLevel !== mastery) {
      setMastery(newLevel);
      playMasterySound();
      dispatch(updateFlashcard({ flashcardId: flashcard._id, newMasteryLevel: newLevel }))
      .then((action) => {
        if (updateFlashcard.fulfilled.match(action)) {
          const { streak } = action.payload;
          if (newLevel < MAX_MASTERY_LEVEL) {
            dispatch(updateXP({ userId, xpGained: 10 }));
          }
          if (streak !== userStats.data.streak) {
            dispatch(fetchUserStats(userId));
          }
        }
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleFlip(e);
    }
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation(); // Prevent card flip
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = (e) => {
    e.stopPropagation(); // Prevent card flip
    dispatch(deleteFlashcard(flashcard._id))
      .then(() => {
        dispatch(addToast(`Flashcard deleted`, 'success'));
        setShowDeleteConfirmation(false);
      })
      .catch((error) => {
        console.error('Failed to delete flashcard:', error);
        dispatch(addToast(`Failed to delete flashcard`, 'error'));
      });
  };

  const handleCancelDelete = (e) => {
    e.stopPropagation(); // Prevent card flip
    setShowDeleteConfirmation(false);
  };

  if (!user) {
    return <span>Not logged in.</span>;
  }

  return (
    <motion.div
      ref={cardRef}
      className="bg-white shadow-lg rounded-lg overflow-hidden border-2 border-primary relative"
      role="button"
      tabIndex="0"
      aria-label={`Flashcard: ${flashcard.question}`}
      onKeyDown={handleKeyDown}
      onClick={handleFlip}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <motion.div
        className="p-6 cursor-pointer"
        animate={{ rotateY: isFlipped ? 360 : 0 }}
        transition={{ duration: 0.6 }}
      >
        <motion.div
          animate={{ opacity: isFlipped ? 0 : 1 }}
          transition={{ duration: 0.3 }}
          style={{ display: !isFlipped ? 'block' : 'none' }}
        >
          <div className="font-bold text-xl mb-2 text-gray-900 text-inter">{flashcard.question}</div>
        </motion.div>
        <motion.div
          animate={{ opacity: isFlipped ? 1 : 0 }}
          transition={{ duration: 0.3 }}
          style={{ display: isFlipped ? 'block' : 'none' }}
        >
          <div className="font-bold text-xl text-primary text-inter">{flashcard.explanation ? flashcard.explanation : flashcard.answer}</div>
        </motion.div>
      </motion.div>
      <div className="px-6 py-4">
        <div className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 border border-primary w-full text-center lg:w-fit text-inter">
          {flashcard.category}
        </div>
        <div className="mt-4 flex flex-col lg:flex-row gap-2 justify-between items-center lg:items-end" role="group" aria-label="Mastery Level and Actions">
          <div className="mastery-buttons">
            <p className="text-sm text-gray-600 mb-2 text-poetsen text-primary">Mastery Level:</p>
            {[0, 1, 2, 3, 4, 5].map((level) => (
              <button
                key={level}
                className={`mr-1 px-2 py-1 rounded ${
                  mastery >= level ? 'bg-primary text-white' : 'bg-gray-200 text-gray-500'
                }`}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent card flip
                  handleMasteryUpdate(level);
                }}
                aria-pressed={mastery >= level}
                aria-label={`Set mastery level ${level}`}
              >
                {level}
              </button>
            ))}
          </div>
          <div className="delete-button">
            {!showDeleteConfirmation ? (
              <button
                onClick={handleDeleteClick}
                className="bg-red-400 hover:bg-red-700 text-white py-2 px-4 rounded"
                aria-label="Delete flashcard"
              >
                <FontAwesomeIcon icon={faTrashCan} className='text-body mr-2' />Remove From Stack
              </button>
            ) : (
              <div className="flex space-x-2">
                <button
                  onClick={handleConfirmDelete}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  aria-label="Confirm delete flashcard"
                >
                  Confirm
                </button>
                <button
                  onClick={handleCancelDelete}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                  aria-label="Cancel delete flashcard"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default FlashcardItem;