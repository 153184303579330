import React from 'react';
import config from '../config/config';

function TermsAndConditionsPage() {

  return (
    <div className="w-full min-h-screen bg-gray-100 py-10 px-5">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="bg-primary text-white text-center py-4">
          <h1 className="text-2xl font-bold">Terms and Conditions for {config.siteName}</h1>
          <p className="text-sm mt-1">Effective Date: June 19th, 2024</p>
        </div>
        <div className="p-6 space-y-6 text-gray-700">
          <section>
            <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
            <p>
              These Terms and Conditions ("Terms") govern your use of {config.siteName} (“we,” “our,” “us”) and the services we provide. By accessing or using our app, you agree to comply with and be bound by these Terms.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">2. Use of Services</h2>
            <ul className="list-disc pl-5 space-y-1">
              <li><strong>Eligibility:</strong> You must be at least 13 years old to use our services.</li>
              <li><strong>Account Responsibility:</strong> You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</li>
              <li><strong>Prohibited Conduct:</strong> You agree not to use our services for any unlawful purpose or in violation of any applicable laws.</li>
            </ul>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">3. Subscription Plans and Credits</h2>
            <ul className="list-disc pl-5 space-y-1">
              <li><strong>Subscriptions:</strong> We offer monthly and yearly subscription plans. Subscriptions entitle you to a set amount of credits that refresh monthly.</li>
              <li><strong>Cancellation:</strong> You can cancel your subscription at any time. If you cancel, you will not receive a refund for any unused portion of your subscription term.</li>
              <li><strong>Account Deletion:</strong> You can delete your account through the profile section of our app.</li>
            </ul>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">4. Intellectual Property</h2>
            <p>
              All content and materials available on our app, including but not limited to text, graphics, logos, and software, are the property of GAIM Network or our licensors and are protected by intellectual property laws. You may not use any content without our express written permission.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">5. Disclaimer of Warranties</h2>
            <p>
              Our services are provided “as is” and “as available” without any warranties of any kind, either express or implied. We do not guarantee that our services will be uninterrupted or error-free.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">6. Limitation of Liability</h2>
            <p>
              In no event shall GAIM Network, its directors, employees, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our services. Your sole remedy for dissatisfaction with our services is to stop using them.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">7. User Content</h2>
            <p>
              You are solely responsible for the content you submit or make available through our app. We do not endorse and are not responsible for any user content.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">8. Governing Law</h2>
            <p>
              These Terms shall be governed by and construed in accordance with the laws of the state of Indiana, without regard to its conflict of law provisions.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">9. Changes to These Terms</h2>
            <p>
              We may modify these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. By continuing to use our services after the changes become effective, you agree to be bound by the revised Terms.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">10. Contact Us</h2>
            <p>
              If you have any questions about these Terms, please contact us at <a href="mailto:support@gaimnetwork.com" className="text-blue-600 underline">support@gaimnetwork.com</a>.
            </p>
          </section>
          <div class="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-8">
        <h2 class="text-2xl font-bold text-blue-600 mb-6">Money-Back Guarantee Terms and Conditions</h2>

        <div class="mb-6">
            <h3 class="text-xl font-semibold text-gray-700">Eligibility Criteria:</h3>
            <ul class="list-disc list-inside pl-4 mt-2">
                <li>The Money-Back Guarantee applies to users who purchase a 3-month, 6-month, or 12-month package.</li>
                <li>Users must attempt the professional certification exam within 30 days of the end of their subscription period.</li>
            </ul>
        </div>

        <div class="mb-6">
            <h3 class="text-xl font-semibold text-gray-700">Usage Requirements:</h3>
            <ul class="list-disc list-inside pl-4 mt-2">
                <li>To qualify for the Money-Back Guarantee, users must actively use the app for a minimum of:</li>
                <ul class="list-disc list-inside pl-8 mt-2">
                    <li>3-month package: 60 hours + 300 quiz questions answered</li>
                    <li>6-month package: 120 hours + 450 quiz questions answered</li>
                    <li>12-month package: 240 hours + 600 quiz questions answered</li>
                </ul>
                <li>Users must achieve an average score of 70% or higher on the practice tests.</li>
            </ul>
        </div>

        <div class="mb-6">
            <h3 class="text-xl font-semibold text-gray-700">Proof of Exam Failure:</h3>
            <ul class="list-disc list-inside pl-4 mt-2">
                <li>Users must provide official documentation of their exam results, showing a non-passing score.</li>
                <li>The documentation must be submitted within 30 days of receiving the exam results.</li>
            </ul>
        </div>

        <div class="mb-6">
            <h3 class="text-xl font-semibold text-gray-700">Requesting a Refund:</h3>
            <ul class="list-disc list-inside pl-4 mt-2">
                <li>To request a refund, users must contact our customer support team via email at <a href="mailto:support@gaimnetwork.com" class="text-blue-600 underline">support@gaimnetwork.com</a> within 30 days of receiving their exam results.</li>
                <li>The email must include the user’s full name, registered email address, purchase details, and official exam results.</li>
            </ul>
        </div>

        <div class="mb-6">
            <h3 class="text-xl font-semibold text-gray-700">Refund Process:</h3>
            <ul class="list-disc list-inside pl-4 mt-2">
                <li>Upon verifying eligibility, refunds will be processed within 30 days.</li>
                <li>Refunds will be issued to the original method of payment.</li>
            </ul>
        </div>

        <div class="mb-6">
            <h3 class="text-xl font-semibold text-gray-700">Exclusions:</h3>
            <ul class="list-disc list-inside pl-4 mt-2">
                <li>The Money-Back Guarantee does not apply to users who have violated our terms of service.</li>
                <li>Refunds will not be granted to users who do not meet the usage requirements outlined above.</li>
                <li>This guarantee is void if the user does not attempt the exam within 30 days of their subscription end date.</li>
            </ul>
        </div>

        <div class="mb-6">
            <h3 class="text-xl font-semibold text-gray-700">Modifications:</h3>
            <ul class="list-disc list-inside pl-4 mt-2">
                <li>{config.siteName} reserves the right to modify or terminate the Money-Back Guarantee at any time without prior notice.</li>
                <li>Any changes will not affect users currently within an active subscription period.</li>
            </ul>
        </div>

        <p class="mt-6">By using our app and purchasing a subscription package, you agree to comply with these terms and conditions. If you have any questions or need further assistance, please contact our customer support team.</p>
    </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
