import React, { useState } from 'react';
import { useStytch } from '@stytch/react';
import { useDispatch } from 'react-redux';
import { setUser } from '../features/user/userSlice';
import { setSession } from '../features/session/sessionSlice';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import config from '../config/config';
import { authUser } from '../api/userFunctions';

const StandaloneAuthForm = () => {
    const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const stytchClient = useStytch();
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    return password.length >= 14;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    if (!validatePassword(password)) {
      setError('Password must be at least 14 characters long.');
      return;
    }

    setIsLoading(true);
    try {
      let response;
      if (isSignUp) {
        response = await stytchClient.passwords.create({
          email,
          password,
          session_duration_minutes: 43830
        });
      } else {
        response = await stytchClient.passwords.authenticate({
          email,
          password,
          session_duration_minutes: 43830
        });
      }
      const userDb = await authUser(response.session_token);
      dispatch(setUser(userDb.data));
      dispatch(setSession({
        stytch_session_token: response.session_token,
      }));
      console.log('Authentication successful, navigating to dashboard');
      navigate('/dashboard');
    } catch (err) {
      console.error('Authentication error:', err);
      // ... (error handling remains the same)
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleAuth = () => {
    stytchClient.oauth.google.start({
      login_redirect_url: `${window.location.origin}/loggingin`,
      signup_redirect_url: `${window.location.origin}/loggingin`,
    });
  };

  return (
    <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-lg shadow-md">
        <div className='flex justify-center gap-4 items-center'>
            <div>
            <img src={config.logoUrl} alt='Logo' className='w-16 h-16 rounded-full border-2 border-primary' />
            </div>
            <div>
            <span className='mt-3 mb-2 text-inter font-bold text-primary text-xl lg:text-2xl'>{config.siteName}</span>
            </div>
        </div>
      <h2 className="mt-6 text-center text-3xl font-bold text-inter text-gray-900">
        {isSignUp ? 'Create an account' : 'Sign in to your account'}
      </h2>
      <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email address
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FontAwesomeIcon icon={faEnvelope} className="text-gray-400" />
            </div>
            <input
              id="email"
              name="email"
              type="email"
              required
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 py-2 text-gray-900 sm:text-sm border-gray-300 rounded-md"
              placeholder="you@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Password
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FontAwesomeIcon icon={faLock} className="text-gray-400" />
            </div>
            <input
              id="password"
              name="password"
              type="password"
              required
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 py-2 text-gray-900 sm:text-sm border-gray-300 rounded-md"
              placeholder="••••••••••••••"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        {error && (
          <div className="text-red-600 text-sm mt-2">
            {error}
          </div>
        )}

        <div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent transition ease-in duration-200 rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : (isSignUp ? 'Create Account' : 'Sign in')}
          </button>
        </div>
      </form>

      <div className="mt-6">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">Or continue with</span>
          </div>
        </div>

        <div className="mt-6">
          <button
            onClick={handleGoogleAuth}
            className="w-full flex justify-center items-center py-2 px-4 border border-transparent transition ease-in duration-200 rounded-md shadow-sm text-sm font-medium text-white bg-gray-900 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <FontAwesomeIcon icon={faGoogle} className="mr-2" />
            {isSignUp ? 'Create Account' : 'Sign in'} with Google
          </button>
        </div>
      </div>

      <div className="mt-6 text-center">
        <button
          onClick={() => setIsSignUp(!isSignUp)}
          className="text-sm text-indigo-600 hover:text-indigo-500"
        >
          {isSignUp ? 'Already have an account? Sign in' : 'Need an account? Sign up'}
        </button>
      </div>
    </div>
  );
};

export default StandaloneAuthForm;