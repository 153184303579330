import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../../../features/user/userSlice';
import { setSession } from '../../../features/session/sessionSlice';
import { authUser } from '../../../api/userFunctions';
import PricingCards from './pricingCards';
import { CheckoutForm, Return } from '../../../components/StripeCheckout';
import ProgressTracker from './ProgressTracker';
import { motion, AnimatePresence } from 'framer-motion';
import { useStytch } from '@stytch/react';
import Footer from './Footer';

const GooglePurchaseFlow = () => {
  const [currentStep, setCurrentStep] = useState(1); // Start at step 1 (Create Account)
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [topMessage, setTopMessage] = useState("🎉 Account created successfully! Let's choose your package.");
  const dispatch = useDispatch();
  const stytchClient = useStytch();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const isInitialMount = useRef(true);

  const steps = ['Create Account', 'Choose Package', 'Checkout', 'Complete'];

  useEffect(() => {
    const packageStored = localStorage.getItem('selectedPackage');
    if (packageStored) {
      setSelectedPackage(packageStored);
      setCurrentStep(2); // Move to checkout step
      setTopMessage(`🔥 You're one step away from prepping like a pro. Can you feel the success?`);
      localStorage.removeItem('selectedPackage');
    } else {
      setCurrentStep(1); // Move to package selection step
    }
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (containerRef.current && currentStep > 1) {
        containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [currentStep]);

  const handlePackageSelection = (packageItem) => {
    setSelectedPackage(packageItem);
    setCurrentStep(2);
    setTopMessage(`🔥 You're one step away from prepping like a pro. Can you feel the success?`);
  };

  const handleCheckoutComplete = () => {
    setCurrentStep(3);
    setTopMessage(`🎉 Congratulations! You're all set to ace your exam.`);
  };

  return (
    <div ref={containerRef} className="min-h-screen flex flex-col justify-start pb-12 pt-2 px-4 sm:px-6 lg:px-8">
      <ProgressTracker currentStep={currentStep} steps={steps} />
      
      <div className="text-xs md:text-base flex justify-center text-white mb-8 text-inter text-center">{topMessage}</div>

      <AnimatePresence mode="wait">
        {currentStep === 1 && (
          <motion.div
            key="pricing-cards"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-7xl mx-auto"
          >
            <PricingCards onPackageSelect={handlePackageSelection} />
          </motion.div>
        )}

        {currentStep === 2 && (
          <motion.div
            key="checkout"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-4xl mx-auto"
          >
            <CheckoutForm onCheckoutComplete={handleCheckoutComplete} selectedPackage={selectedPackage} />
            <button onClick={() => setCurrentStep(1)} className="block mx-auto mt-4 text-center text-primary font-bold text-sm hover:underline">Back To Packages</button>
          </motion.div>
        )}

        {currentStep === 3 && (
          <motion.div
            key="return"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-4xl mx-auto"
          >
            <Return />
            <button onClick={() => navigate('/dashboard')} className="block pulse mx-auto mt-6 text-center border-2 border-green-500 text-white px-6 py-2 hover:bg-green-500 rounded-full font-bold">Go To Your Dashboard 🎉</button>
          </motion.div>
        )}
      </AnimatePresence>
      <Footer />
    </div>
  );
};

export default GooglePurchaseFlow;