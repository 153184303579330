import React, { useEffect, useRef } from 'react';
import AppRouter from './AppRouter';
import config from './config/config';
import { useDispatch, useSelector } from 'react-redux';
import { selectSession, deleteSession } from './features/session/sessionSlice';
import { selectUser } from './features/user/userSlice';
import { authUser } from './api/userFunctions';
import { setUser } from './features/user/userSlice';
import { setSession } from './features/session/sessionSlice';
import { fetchTools } from './features/tools/toolSlice';
import { fetchBoards } from './features/boards/boardSlice';
import { fetchBoardItems } from './features/boards/boardItemSlice';
import { fetchBanners } from './features/games/bannersSlice';
import { fetchPackages } from './features/packages/packageSlice';
import { fetchUserStats } from './features/quiz/userStatsSlice';
import { useThemeUpdate } from './utils/themeUpdate';
import { useCachedFetch } from './hooks/useCachedFetch';
import { logout } from './features/user/userSlice';
import { fullLogout } from './api/userFunctions';

const app_id = config.app_id;

function App() {
  const dispatch = useDispatch();
  const session = useSelector(selectSession);
  const user = useSelector(selectUser);
  const authAttempted = useRef(false);
  const theme = useSelector((state) => state.currentTheme.value);

  useEffect(() => {
    const verifyAuth = async () => {
      if (session?.stytch_session_token && !user && !authAttempted.current) {
        authAttempted.current = true;
        try {
          const authResponse = await authUser(session.stytch_session_token);
          if (authResponse.success) {
            dispatch(setUser(authResponse.data));
            dispatch(setSession({
              stytch_session_token: session.stytch_session_token,
            }));
            dispatch(fetchUserStats(authResponse.data._id));
          } else {
            throw new Error('Authentication failed');
          }
        } catch (error) {
          console.error('Authentication error:', error);
          dispatch(logout());
          dispatch(deleteSession());
        }
      }
    };

    verifyAuth();
  }, [dispatch, session, user]);

  const fetchCachedTools = useCachedFetch('tools', fetchTools);
  const fetchCachedPackages = useCachedFetch('packages', fetchPackages);
  const fetchCachedBanners = useCachedFetch('banners', fetchBanners);
  const fetchCachedBoards = useCachedFetch('boards', () => fetchBoards({userId: user._id}));
  const fetchCachedBoardItems = useCachedFetch('boardItems', () => fetchBoardItems({userId: user._id}));

  // Set document title
  useEffect(() => {
    document.title = config.siteTitle;    
    config.updateBodyFont(config.bodyFont);
    config.updatePoetsenClass(config.headerFont);

    /*const meta = document.createElement('meta');
    meta.httpEquiv = 'Content-Security-Policy';
    //meta.content = `default-src 'self'; script-src 'self' ${process.env.REACT_APP_API_URL}; style-src 'self' 'unsafe-inline'; connect-src 'self' ${process.env.REACT_APP_API_URL}; font-src 'self'; frame-src 'none';`;
    meta.content = `
      default-src 'self';
      script-src 'self' ${process.env.REACT_APP_API_URL} https://connect.facebook.net https://m.stripe.network;
      style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
      img-src 'self' data: https://otp.nyc3.cdn.digitaloceanspaces.com https://gaim.nyc3.cdn.digitaloceanspaces.com https://www.facebook.com;
      connect-src 'self' ${process.env.REACT_APP_API_URL} ws://localhost:8080 https://web.stytch.com;
      font-src 'self' https://fonts.gstatic.com;
      frame-src 'self' https://js.stripe.com;
      media-src 'self' https://otp.nyc3.cdn.digitaloceanspaces.com;
    `;
    // Append the meta tag to the head
    document.head.appendChild(meta);

    // Clean up by removing the meta tag on component unmount
    return () => {
      document.head.removeChild(meta);
    };*/
  }, []);

  // Fetch initial data when route changes
  useEffect(() => {
    fetchCachedTools();
    fetchCachedPackages();
    fetchCachedBanners();
    fetchCachedBoards();
    fetchCachedBoardItems();
  }, [dispatch]);

  // Update theme variables
  useThemeUpdate(theme, config);

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;



//import { selectUser, setUser, resetUser } from './features/user/userSlice';
//import { fetchMerchItems } from './features/merch/merchSlice';
//import { fetchPopularNFTs } from './features/games/popularNFTsSlice';
//import { fetchMiniGames } from './features/games/gamesSlice';
//import { useIsMobileOrTablet, useIsLaptopOrDesktop } from './utils/useScreenSize';
//import { useActiveWallet } from 'thirdweb/react';
//import { getUserByDynamicID, get_user } from './api/userFunctions';
//import { useUser, useLogout, useAddress } from '@thirdweb-dev/react';

// Handle user state based on active wallet changes
  /*useEffect(() => {
    const handleUserUpdate = async () => {
      if (userAddress) {
        const userData = await get_user(userAddress);
        console.log('User Data:', userData);
        dispatch(setUser(userData));
      } else {
        dispatch(setUser(null));
        deleteCookiesStartingWith('thirdweb_auth_token_');
      }
    };

    handleUserUpdate();
  }, [userWallet, dispatch]);*/

  //dispatch(fetchMerchItems());
    //dispatch(fetchMiniGames());
    //dispatch(fetchPopularNFTs());
  //const { logout, isLoading } = useLogout();
  //const activeWallet = useActiveWallet();
  //const userAddress = activeWallet?.getAccount().address;
  /*const isLaptopOrDesktop = useIsLaptopOrDesktop();
    const userWalletDesktop = useUser();  // Assuming this hook can be safely called regardless of device
    const userWalletMobile = useActiveWallet();  // Assuming this too

    let userAddress, userWallet;

    if (isLaptopOrDesktop) {
        // Access data for desktop
        userAddress = userWalletDesktop?.user?.address;
        userWallet = userWalletDesktop?.user?.address;
    } else {
        // Access data for mobile
        // Make sure getAccount is safe to call here; you might need additional checks
        userAddress = userWalletMobile?.getAccount()?.address;
        userWallet = userWalletMobile?.getAccount()?.address;
    }*/

        /*function deleteCookiesStartingWith(prefix) {
  const cookies = document.cookie.split(';');
  cookies.forEach(cookie => {
      let [cookieName] = cookie.split('=');
      cookieName = cookieName.trim(); // Trim whitespace
      if (cookieName.startsWith(prefix)) {
          document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
          console.log(cookieName + ' cookie has been deleted.');
      }
  });
}*/