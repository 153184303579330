import React, { useCallback, useState, useEffect } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setUser } from '../features/user/userSlice';
import config from "../config/config";
import { getSelectedPackage } from "../features/packages/selectedPackageSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const apiURL = process.env.REACT_APP_API_URL;

const stripePromise = loadStripe("pk_live_51Mo9vdG2rariuf0Fkr6Mf17iBmftq63eaH9Ct7BuhIYmNBHtq3pLZCv4G9IJ95U6dua1bA39C0rmeh1lXgbrb2eu003V4DGWcT");
//pk_test_51Mo9vdG2rariuf0FfTILS1TfHEOKABhpyNRwl68r0gZhyz4QZ5Lxs2s2eSp09vQEYWYvqsJcPisirJfyGoZVKTds00N8MLHOeP
//pk_live_51Mo9vdG2rariuf0Fkr6Mf17iBmftq63eaH9Ct7BuhIYmNBHtq3pLZCv4G9IJ95U6dua1bA39C0rmeh1lXgbrb2eu003V4DGWcT
export const CheckoutForm = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const packageItem = useSelector(getSelectedPackage) || {};
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      if (!user) {
          navigate('/login');
      }
  }, [user, navigate]);

  const fetchClientSecret = useCallback(() => {
      if (!packageItem || !user || !packageItem.stripe_price_id || !user?.dynamic_id) {
          alert('There was an error. Please try again later.');
          navigate('/');
          return Promise.reject('Missing required data');
      }
  
      return fetch(`${apiURL}/create-checkout-session`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify({
              price_id: packageItem.stripe_price_id, 
              quantity: 1, 
              user_id: user?._id,
              return_url: `${window.location.origin}`,
              app_id: config.app_id
          })
      })
      .then((res) => {
          if (!res.ok) {
              throw new Error('Network response was not ok');
          }
          return res.json();
      })
      .then((data) => {
          if (data.clientSecret) {
              setClientSecret(data.clientSecret);
          } else {
              throw new Error('Client secret not found in response');
          }
      })
      .catch((error) => {
          console.error('Error fetching client secret:', error);
          alert('There was an error. Please try again later.');
          navigate('/');
      })
      .finally(() => {
          setLoading(false);
      });
  }, [packageItem, user, navigate]);

  useEffect(() => {
      fetchClientSecret();
  }, [fetchClientSecret]);

  const options = { clientSecret };

  if (loading) {
      return <div>Loading...</div>;
  }

  return (
      <>
          <div id="checkout" className="mx-auto md:w-3/4 border-primary p-4 rounded">
              {clientSecret && (
                  <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                      <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
              )}
          </div>
      </>
  );
};


export const Return = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const navigate = useNavigate();
  const packageItem = useSelector(getSelectedPackage) || {};

  useEffect(() => {
    if (packageItem && user && packageItem.stripe_price_id && user?._id && status === 'complete') {
      const subscriptionEndDate = moment().add(packageItem.subscription_months, 'months').toISOString();
      try {
        dispatch(setUser({ 
          ...user, 
          subscription_id: packageItem._id, 
          subscription_end_date: subscriptionEndDate 
        }));
      } catch (error) {
        console.error('Error dispatching setUser:', error);
      }
    }
  }, [status, dispatch]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(`${apiURL}/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      })
      .catch((error) => {
        console.error('Error fetching session status:', error);
      });
  }, []);

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <div className="w-full mt-20 md:w-3/4 mx-auto p-10 bg-black-main text-inter">
          <div className="flex justify-center items-center my-10">
            <img src="https://otp.nyc3.cdn.digitaloceanspaces.com/purchaseSuccess.png" alt="Purchase successful" className="w-52 h-52" />
          </div>
          <div className="flex flex-col text-center lg:flex-row items-center justify-center gap-3 lg:gap-10">
            <img
              src={config.favicon}
              alt="Avatar"
              className="w-24 h-24"
            />
            <h1 className="text-5xl text-green-400 font-bold text-inter">Thank you for your purchase!</h1>
          </div>
          <div className="flex flex-col items-center justify-center mt-10 text-center text-white">
            <p>
              A confirmation email will be sent to {customerEmail}.
            </p>
            <p>
              If you have any questions, please email <a href={`mailto:${config.email}`}>{config.email}</a>.
            </p>
            <button className="bg-primary hover:bg-gray-700 hover:text-white py-3 px-5 rounded mt-10" onClick={() => navigate('/dashboard')}>Go To Your Dashboard</button>
          </div>
        </div>
      </section>
    )
  }

  return null;
}