import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from 'react-redux';
import { selectPackages } from '../../../features/packages/packageSlice';
import { setPackage } from '../../../features/packages/selectedPackageSlice';
import { useNavigate } from 'react-router-dom';
import config from '../../../config/config';

const PricingCardsOGFlow = ({ isRenewal = false, isFrontPage = false }) => {
  const dispatch = useDispatch();
  const packages = useSelector(selectPackages);
  const navigate = useNavigate();

  return (
    <div>
      <div className={`pt-2 lg:pt-10 grid grid-cols-1 sm:grid-cols-2 gap-4 mx-auto`}>
        {packages
          .filter(packageItem => 
            packageItem.type === config.landing_page_package_type && 
            packageItem.isRenewal === isRenewal
          )
          .map(packageItem => (
            <div key={packageItem._id} className="flex flex-col gap-4 justify-between items-center p-6 border-4 border-primary rounded-xl m-2 bg-white hover:border-green-400">
              <div className='md:pl-3'>
                <img
                  src={packageItem.image_url}
                  alt="Purchase AI Credits"
                  className={`w-32 h-auto rounded-full`}
                />
              </div>
              <div className='flex flex-col gap-2'>
                <p className="text-2xl mb-2 text-primary leading-none text-inter font-bold">{packageItem.name}</p>
                {packageItem?.features?.map((feature, index) => (
                  <p key={index} className="text-gray-500 leading-none text-inter font-bold">
                    <FontAwesomeIcon icon={faPlusCircle} className='mr-2 text-green-400' />
                    {feature}
                  </p>
                ))}
              </div>
              <div className='flex flex-col text-gray-700'>
                <div className='font-bold'>One-time payment. No recurring fees.</div>
                <div className='mt-4 text-inter font-bold text-primary text-xl'>Special Launch Price:</div>
                <span className='text-primary text-2xl text-inter font-bold'>${packageItem.usd_cost}</span> 
                <span className='text-red-500 line-through'>Reg. ${packageItem.usd_cost * 2}</span>
              </div>
              <div className='flex flex-col gap-3'>
              <button
                onClick={() => 
                  !isFrontPage 
                    ? (dispatch(setPackage(packageItem)), navigate('/checkout'))
                    : navigate('/loginpurchase')
                }
                className='w-full border bg-primary hover:bg-gray-700 text-gray-100 text-inter font-bold py-2 px-6 rounded mt-4'
              >
                <FontAwesomeIcon icon={faPlusCircle} className='mr-2 text-green-400' />
                {isRenewal ? 'Renew Now!' : 'Get Started!'}
              </button>
              </div>
            </div>
          ))}
      </div>
      <img 
        className="h-24 mt-6 mx-auto" 
        src="https://otp.nyc3.cdn.digitaloceanspaces.com/trustBadges.png" 
        alt="Payment options"
      />
    </div>
  );
};

export default PricingCardsOGFlow;
