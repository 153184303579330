import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../features/user/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBookmark,
  faHeart,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import {
  fetchLeadMagnets,
  searchLeadMagnets,
  filterLeadMagnets,
  fetchFavorites,
  toggleFavorite,
  setCurrentPage,
  updateItemFavoriteStatus,
  setShowOnlyFavorites,
  applyFilters,
} from "../../features/bookshelf/bookshelfSlice";
import { addToast } from "../../features/ui/uiSlice";

// Import the separate components
import SearchBar from "./SearchBar";
import FilterBar from "./FilterBar";
import Pagination from "./Pagination";

const Bookshelf = () => {
  const dispatch = useDispatch();
  const leadMagnets = useSelector((state) => state.bookshelf.items);
  const filteredItems = useSelector((state) => state.bookshelf.filteredItems);
  const favorites = useSelector((state) => state.bookshelf.favorites);
  const showOnlyFavorites = useSelector(
    (state) => state.bookshelf.showOnlyFavorites
  );
  const user = useSelector(selectUser);
  const userId = user ? user._id : null;
  const categories = useSelector((state) => state.bookshelf.categories);
  const formats = useSelector((state) => state.bookshelf.formats);
  const tags = useSelector((state) => state.bookshelf.tags);
  const totalPages = useSelector((state) => state.bookshelf.totalPages);
  const currentPage = useSelector((state) => state.bookshelf.currentPage);
  const status = useSelector((state) => state.bookshelf.status);
  const error = useSelector((state) => state.bookshelf.error);
  const bookshelfRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({});
  const itemsPerPage = 12;

  useEffect(() => {
    if (userId) {
      dispatch(fetchLeadMagnets({ page: currentPage, itemsPerPage }));
      dispatch(fetchFavorites(userId));
    }
  }, [dispatch, currentPage, userId]);

  useEffect(() => {
    const scrollToTop = () => {
      if (bookshelfRef.current) {
        bookshelfRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      } else {
        console.warn('Bookshelf ref not available for scrolling');
      }
    };

    // Delay the scroll to ensure the DOM has updated
    const timeoutId = setTimeout(scrollToTop, 100);

    return () => clearTimeout(timeoutId);
  }, [currentPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(searchLeadMagnets(searchTerm)).then(() => {
      dispatch(applyFilters());
      dispatch(setCurrentPage(1)); // Reset to first page after search
    });
  };

  const handleFilter = (newFilters) => {
    setFilters(newFilters);
    dispatch(filterLeadMagnets(newFilters));
    dispatch(setCurrentPage(1)); // Reset to first page after applying filters
  };

  const handlePageChange = (newPage) => {
    if (newPage !== currentPage) {
      console.log(`Changing page from ${currentPage} to ${newPage}`);
      dispatch(setCurrentPage(newPage));
    }
  };

  const handleToggleFavoritesFilter = () => {
    dispatch(setShowOnlyFavorites(!showOnlyFavorites));
    dispatch(setCurrentPage(1)); // Reset to first page when toggling favorites
  };

  const handleToggleFavorite = useCallback(
    (itemId) => {
      if (!userId) {
        dispatch(addToast("Please log in to favorite items.", "error"));
        return;
      }
      dispatch(
        updateItemFavoriteStatus({
          itemId,
          isFavorite: !favorites.includes(itemId),
        })
      );
      dispatch(toggleFavorite({ itemId, userId }))
        .unwrap()
        .then(() => {
          dispatch(addToast("Favorite updated successfully!", "success"));
        })
        .catch((error) => {
          dispatch(
            updateItemFavoriteStatus({
              itemId,
              isFavorite: favorites.includes(itemId),
            })
          );
          dispatch(
            addToast("Failed to update favorite. Please try again.", "error")
          );
        });
    },
    [dispatch, favorites, userId]
  );

  if (status === "loading" && filteredItems.length === 0) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (status === "failed") {
    return (
      <div className="text-center mt-8 text-red-500">
        Error:{" "}
        {error
          ? error.message || "An unknown error occurred"
          : "An unknown error occurred"}
      </div>
    );
  }

  if (!userId) {
    return (
      <div className="text-center mt-8">
        Please log in to view your bookshelf.
      </div>
    );
  }

  return (
    <div ref={bookshelfRef} className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-primary text-inter mb-2">
        <FontAwesomeIcon icon={faBook} className="mr-2" />
        Your Digital Bookshelf
      </h1>
      <p className="text-body text-sm lg:text-base mb-4 p-2">
        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
        Welcome to Your Digital Bookshelf! Imagine having a library at your
        fingertips, filled with essential resources to supercharge your NCLEX
        prep journey. Instantly access to a treasure trove of 
        assets, including PDFs, Google Slides, PowerPoint files, video links,
        and more. Dive into your personalized collection today and unlock a
        world of knowledge that's always just a click away!
      </p>

      <div className="mb-6 flex flex-col md:flex-row justify-between items-center gap-2">
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleSearch={handleSearch}
        />
        <div className="flex items-center gap-2">
          <FilterBar
            filters={filters}
            setFilters={handleFilter}
            categories={categories}
            formats={formats}
            tags={tags}
          />
          <button
            onClick={handleToggleFavoritesFilter}
            className={`px-4 py-2 rounded ${
              showOnlyFavorites
                ? "bg-red-500 text-white"
                : "bg-primary text-white"
            } transition-colors duration-300`}
          >
            {showOnlyFavorites ? (
              <FontAwesomeIcon icon={faBookmark} />
            ) : (
              <FontAwesomeIcon icon={faBookmark} />
            )}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {filteredItems?.map((item) => (
          <BookshelfItem
            key={item._id}
            item={item}
            isFavorite={favorites.includes(item._id)}
            onToggleFavorite={() => handleToggleFavorite(item._id)}
          />
        ))}
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

const BookshelfItem = React.memo(({ item, isFavorite, onToggleFavorite }) => {
  const handleItemClick = () => {
    if (
      item.type === "download" ||
      item.type === "video" ||
      item.type === "link"
    ) {
      window.open(item.url, "_blank");
    }
  };

  return (
    <div
      className="relative bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 cursor-pointer"
      onClick={handleItemClick}
    >
      <div className="aspect-w-3 aspect-h-4">
        {item.format === "Video" ? (
          <ReactPlayer
            url={item.url}
            width="100%"
            height="100%"
            light={item.image_url}
            playIcon={<FontAwesomeIcon icon="play-circle" size="3x" />}
          />
        ) : (
          <img
            src={item.image_url}
            alt={item.title}
            className="object-cover w-full h-full"
          />
        )}
      </div>
      <div className="p-4">
        <h3 className="text-lg font-bold text-inter mb-2 text-primary">
          {item.title}
        </h3>
        <p className="text-sm text-gray-600 mb-2">
          {item.description.substring(0, 100)}...
        </p>
        <div className="flex justify-between items-center">
          <div className="flex gap-2">
            <span className="text-xs bg-primary text-gray-100 border border-primary px-2 py-1 rounded-full">
              {item.category}
            </span>
            <span className="text-xs text-gray-500 border border-primary px-2 py-1 rounded-full">
              {item.format}
            </span>
          </div>

          <button
            onClick={(e) => {
              e.stopPropagation();
              onToggleFavorite();
            }}
            className={`text-2xl ${
              isFavorite ? "text-red-500" : "text-gray-300"
            } transition-colors duration-300`}
          >
            <FontAwesomeIcon icon={faBookmark} />
          </button>
        </div>
      </div>
    </div>
  );
});

export default Bookshelf;
