import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBoards, createBoard, deleteBoard } from '../features/boards/boardSlice';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPaperclip, faTrash } from '@fortawesome/free-solid-svg-icons';
import { selectUser } from '../features/user/userSlice';
import config from '../config/config';
import { addToast } from '../features/ui/uiSlice';

const Board = () => {
    const dispatch = useDispatch();
    const boards = useSelector(state => state.boards.boards);
    const boardStatus = useSelector(state => state.boards.status);
    const user = useSelector(selectUser);
    const [newBoardName, setNewBoardName] = useState('');

    useEffect(() => {
        if (boardStatus === 'idle') {
            dispatch(fetchBoards({userId: user._id}));
        }
    }, [boardStatus, dispatch]);

    const handleCreateBoard = async () => {
        if (newBoardName.trim()) {
            await dispatch(createBoard({ name: newBoardName, userId: user?._id })).unwrap();
            setNewBoardName('');
        }
    };

    const handleDeleteBoard = async (boardId) => {
        if (window.confirm('Are you sure you want to delete this board?')) {
            await dispatch(deleteBoard({ _id: boardId, userId: user?._id })).unwrap();
            dispatch(addToast('Board deleted successfully!', 'success'));
        }
    };

    return (
        <div className="container mx-auto p-4 min-h-screen">
            <h1 className="text-3xl font-bold mb-4 text-primary text-poetsen">{config.boards_title}</h1>
            <p className="text-body mb-6">
                <FontAwesomeIcon icon={faInfoCircle} className='text-primary mr-2' />Notes or Boards are organized lists where you can save text responses, answer explanations, images, or prompts that you may want to reuse in future sessions. 
                Use this page to manage your notes effectively. Create new notes to organize your content, or delete notes that you no longer need.
            </p>
            <div className="mb-4">
                <input
                    type="text"
                    placeholder={`New ${config.boards_title_singular} Name`}
                    value={newBoardName}
                    onChange={(e) => setNewBoardName(e.target.value)}
                    className="border rounded px-4 py-2 mr-2 text-black w-full md:w-auto"
                />
                <button
                    onClick={handleCreateBoard}
                    className="bg-primary text-white px-4 py-2 rounded mt-2 md:mt-0"
                >
                    Create {config.boards_title_singular}
                </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {boards.length > 0 ? (
                    boards.map(board => (
                        <div key={board._id} className="flex bg-[#FFF9C4] flex-row items-center text-gray-800 rounded-xl p-4 shadow hover:bg-gray-200">
                            <Link to={`/boards/${board._id}`} className="flex-grow flex items-center py-4">
                                <FontAwesomeIcon icon={faPaperclip} className='text-primary mr-2' />
                                <h2 className="text-xl font-semibold text-poetsen">{board.name}</h2>
                            </Link>
                            <FontAwesomeIcon
                                icon={faTrash}
                                className="text-red-600 ml-4 cursor-pointer"
                                onClick={() => handleDeleteBoard(board._id)}
                            />
                        </div>
                    ))
                ) : (
                    <div className="col-span-1 md:col-span-2 lg:col-span-3 text-center text-primary mt-20">
                        <p className="text-lg">You have not created any notes yet.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Board;
