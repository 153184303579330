import React from 'react';

const ModalPromptSave = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" style={{zIndex: '10'}}>
      <div className="bg-white p-4 rounded text-black text-center w-3/5">
        {children}
        <button className='bg-gray-300 hover:bg-gray-400 rounded p-2 mt-10' onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ModalPromptSave;
