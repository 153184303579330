// src/components/ModalJSON.js
import React, { useState, useEffect } from 'react';
import config from '../config/config';
import FileUpload from './FileUpload';
import SidebarProfilesCard from './SidebarProfilesCard';
import { copyToClipboard } from '../utils/commons';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { closeModal } from '../features/modals/jsonModalSlice';
import { addToast } from '../features/ui/uiSlice';
import { faArrowUp, faCopy, faUpload, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ModalJSON = () => {
  const dispatch = useDispatch();
  const { isOpen, jsonData, apiEndpoint, type } = useSelector((state) => state.jsonModal);
  const apiURL = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState(jsonData);

  const [showOption, setShowOption] = useState();
    const [imageUrl, setImageUrl] = useState();

    const handleProfileClick = (content) => {
        copyToClipboard(content);
      };

  useEffect(() => {
    if (type === 'Blog Post') {
      setFormData({
        ...jsonData,
        author: jsonData.author || '',
        headerImage: jsonData.headerImage || '',
      });
    } else {
      setFormData(jsonData);
    }
  }, [jsonData, type]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      await axios.post(apiEndpoint, formData);
      dispatch(addToast(`${type} added!`, 'success'));
      dispatch(closeModal());
    } catch (error) {
      dispatch(addToast(`Error submitting form: ${error}`, 'error'));
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
      <div className="bg-body rounded-lg p-6 w-full lg:w-1/2 relative overflow-y-auto max-h-80vh slim-scrollbar">
        <button
          className="absolute text-3xl top-2 right-2 hover:text-gray-500"
          onClick={() => dispatch(closeModal())}
        >
          &times;
        </button>
        <div className='flex flex-row gap-4 p-2'>
                <button className='flex flex-row text-center p-2 bg-body hover:bg-gray-700 hover:text-white border border-primary text-primary rounded w-full justify-center items-center' onClick={() => setShowOption("profiles")}>
                    <FontAwesomeIcon icon={faUser} className="mr-2" />Profiles</button>
                <button className='flex flex-row text-center p-2 bg-body hover:bg-gray-700 hover:text-white border border-primary text-primary rounded w-full justify-center items-center' onClick={() => setShowOption("upload")}>
                    <FontAwesomeIcon icon={faUpload} className="mr-2" />Upload</button>
                {showOption && <button className='flex flex-row text-center p-2 bg-body hover:bg-gray-700 hover:text-white border border-primary text-primary rounded w-full justify-center items-center' onClick={() => setShowOption()}>
                    <FontAwesomeIcon icon={faArrowUp} className="" /></button>
                }
            </div>
          {showOption && <div className="flex flex-row w-full hidden xl:block p-4 border border-primary mt-2 md:mt-0 overflow-auto rounded">
                {showOption === "profiles" && (
                  <SidebarProfilesCard
                    onProfileClick={handleProfileClick}
                    header={'Click a profile to copy its contents to your clipboard.'}
                  />
                )}
                {showOption === "upload" && (
                  <div>
                    <h2 className="text-center text-poetsen text-primary">Upload an Image/File</h2>
                    <FileUpload
                      uploadUrl={`${apiURL}/admin/upload`}
                      onSuccess={(url) => setImageUrl(url)}
                    />
                    {imageUrl && (<div className="flex flex-row justify-between w-full gap-4">
                      <img src={imageUrl} alt="Uploaded" className="w-12 h-auto" />
                      <button className='flex flex-row text-center p-2 bg-body border border-primary text-primary rounded w-full justify-center items-center' onClick={() => copyToClipboard(imageUrl)}>
                        <FontAwesomeIcon icon={faCopy} className="mr-2" />Copy URL</button>
                      </div>)}
                  </div>
                )}
              </div>}
        <h2 className="text-xl font-bold mb-4 text-poetsen text-primary mt-4">Edit/Add {type}</h2>
        <form>
          {type === 'Blog Post' && (
            <>
              <div className="mb-4">
                <label className="block font-bold mb-1 capitalize">Author</label>
                <select
                  name="author"
                  value={formData.author || ''}
                  onChange={handleChange}
                  className="w-full text-black px-3 py-2 border border-gray-300 rounded"
                >
                  <option value="">Select Author</option>
                  <option value="Brady Lewis">Brady Lewis</option>
                  <option value="Zach Crain">Zach Crain</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block font-bold mb-1 capitalize">Post To</label>
                <select
                  name="app_id"
                  value={formData.app_id || ''}
                  onChange={handleChange}
                  className="w-full text-black px-3 py-2 border border-gray-300 rounded"
                >
                  <option value="">Select Blog</option>
                  <option value="6647e961cc5bbe61ddc4c735">GAIM.AI</option>
                  <option value="661852defbc4c47034c9f046">Andy's Place</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block font-bold mb-1 capitalize">Header Image</label>
                <input
                  type="text"
                  name="headerImage"
                  value={formData.headerImage || ''}
                  onChange={handleChange}
                  className="w-full text-black px-3 py-2 border border-gray-300 rounded"
                />
              </div>
            </>
          )}
          {Object.keys(formData).map((key) => (
            key !== 'author' && key !== 'headerImage' && (
              <div key={key} className="mb-4">
                <label className="block font-bold mb-1 capitalize">{key}</label>
                {String(formData[key]).length > 100 ? (
                  <textarea
                    name={key}
                    value={formData[key] || ''}
                    onChange={handleChange}
                    className="w-full text-black px-3 py-2 border border-gray-300 rounded"
                  />
                ) : (
                  <input
                    type="text"
                    name={key}
                    value={formData[key] || ''}
                    onChange={handleChange}
                    className="w-full text-black px-3 py-2 border border-gray-300 rounded"
                  />
                )}
              </div>
            )
          ))}
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={handleSubmit}
              className="px-4 py-2 bg-primary text-white rounded hover:bg-green-400 text-poetsen"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={() => dispatch(closeModal())}
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 text-poetsen"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalJSON;
