import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointDown, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard } from '../utils/commons';
import { addToast } from '../features/ui/uiSlice';
import { setCurrentPrompt } from '../features/chat/chatSlice';
import { faHandPointRight, faHandPointUp } from '@fortawesome/free-regular-svg-icons';

const NextActionOptions = ({setInputValue, handleSend, options}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const currentTool = useSelector(selectCurrentTool);
    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleCopy = async (text) => {
        const isCopied = await copyToClipboard(text);
        if (isCopied) {
            dispatch(addToast('Copied to clipboard!', 'success'));
        } else {
            dispatch(addToast('Failed to copy!', 'error'));
        }
    }

    const handleSubmit = (prompt) => {
      setInputValue(prompt);
      handleSend(prompt);
    };
  
    return (
      <div className="w-full mb-4">
        <div className="w-full text-poetsen flex flex-col gap-2 p-2 md:p-4 border border-primary rounded shadow max-h-fit overflow-auto slim-scrollbar">
          <div className="flex items-center justify-center space-x-4 w-full text-xl leading-none mb-1">
            <FontAwesomeIcon icon={faHandPointUp} className='mr-2' />Choose an Option or Type a Message
          </div>
          <div className='flex flex-wrap gap-2 justify-center items-center w-full text-body'>
            {options?.map((prompt, index) => (
              <div key={index} className="px-4 py-1 text-inter font-bold text-sm lg:text-base rounded-lg items-center cursor-pointer bg-primary hover:bg-gray-700 hover:text-gray-200 p-1 pl-2 border border-gray-700" onClick={() => handleSubmit(prompt)}>
                <FontAwesomeIcon icon={faWandMagicSparkles} className='mr-2' />{prompt}
              </div>
            ))}
            </div>
          </div>
      </div>
    );
};

export default NextActionOptions;
